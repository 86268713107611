import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import { withApollo } from "react-apollo";
import List from "./List";
import Create from "./Create";

import "./style.scss";
import Edit from "./Edit";

class Roles extends Component {
  render() {
    const { match } = this.props;

    return (
      <>
        <Route exact path={`${match.url}`} component={List} />
        <Route exact path={`${match.url}/create`} component={Create} />
        <Route exact path={`${match.url}/edit`} component={Edit} />
      </>
    );
  }
}

export default withRouter(withApollo(Roles));
