import React, { Component } from "react";

// Import components
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import LocationSelector from "../common/LocationSelector";
import WatermarkSelector from "../common/WatermarkSelector";
import moment from "moment";
import { Form, Header, Input, Select } from "../common";

class MachineCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bridgeId: undefined,
      code: "",
      name: "",
      address: "",
      watermarks: [],
      watermark: undefined,
      modules: [],
      moduleSelected: undefined,
      bridgeSelected: undefined,
      branchSelected: undefined,
      watermarkSelected: undefined,
      noteMachine: undefined,
      coinMachine: undefined,
      noteAddress: "",
      coinAddress: "",
      operatingHours: [
        {
          dayOfWeek: 0,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 1,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 2,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 3,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 4,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 5,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 6,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
      ],
    };
  }

  componentDidMount() {
    // Get watermarks and Branches
    this.props.client
      .query({
        query: gql`
          query {
            modules {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);
        this.setState({
          modules: result.data.modules.map((b) => {
            return {
              name: b.name,
              value: b.id,
            };
          }),
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleInputs = (e) => {
    this.setState({ [e.name]: e.value });
  };

  onBridgeSelected = (bridgeNode) => {
    this.setState({ bridgeSelected: bridgeNode.id });
  };

  onBranchSelected = (branch) => {
    this.setState({ branchSelected: branch });
  };

  onWatermarkSelected = (watermark) => {
    this.setState({ watermarkSelected: watermark });
  };

  create = () => {
    const {
      code,
      name,
      bridgeSelected,
      moduleSelected,
      noteMachine,
      noteAddress,
      coinAddress,
      watermarkSelected,
      operatingHours,
    } = this.state;

    // Get device names, types & ids
    switch (noteMachine) {
      case "1.1":
        var noteDeviceName = "CI-10B";
        var noteDeviceType = 1;
        var noteDeviceId = 1;
        break;
      case "1.2":
        noteDeviceName = "CI-50B";
        noteDeviceType = 1;
        noteDeviceId = 2;
        break;
      default:
        break;
    }

    let machineDetail = {
      code,
      name,
      type: Number(moduleSelected),
      watermarkId: watermarkSelected === "default" ? null : watermarkSelected,
      cashDevices: [
        {
          name: noteDeviceName,
          address: noteAddress,
          cashType: 1,
          deviceType: noteDeviceType,
          deviceId: noteDeviceId,
        },
        {
          name: "CI-10C",
          address: coinAddress,
          cashType: 2,
          deviceType: 1,
          deviceId: 1,
        },
      ],
      bridgeNodeId: bridgeSelected,
      operatingHours,
      createdBy: this.props.userAuth.userId,
    };

    console.log("machineDetail", machineDetail);

    this.props.client
      .mutate({
        variables: {
          userId: this.props.userAuth.userId,
          machineDetail,
        },
        mutation: gql`
          mutation createMachine(
            $userId: String!
            $machineDetail: MachineInput!
          ) {
            createMachine(userId: $userId, machineDetail: $machineDetail) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("success");
          this.props.history.goBack();
        } else if (result.errors) {
          alert(result.errors[0].message);
        }
      })
      .catch((error) => {
        alert(error);
        console.log(error);
      });
  };

  operatingChanged = (e) => {
    let oldState = this.state.operatingHours;
    let index = Number(e.target.name.replace("start", "").replace("end", ""));

    if (e.target.type === "checkbox") {
      if (e.target.checked) {
        // Set to 24 hours
        oldState[index] = {
          dayOfWeek: index,
          is24Hours: true,
          startTime: "",
          endTime: "",
        };
      } else {
        oldState[index] = {
          dayOfWeek: index,
          is24Hours: false,
          startTime: "",
          endTime: "",
        };
      }
    } else {
      if (e.target.name.includes("start")) {
        // Start
        oldState[index].startTime = e.target.value;
      } else {
        // End
        oldState[index].endTime = e.target.value;
      }
    }

    this.setState({ operatingHours: oldState });
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value }, this.onChange);
  };

  render() {
    const {
      code,
      name,
      branchSelected,
      modules,
      moduleSelected,
      noteMachine,
      coinMachine,
      noteAddress,
      coinAddress,
      watermarkSelected,
    } = this.state;

    // let noteMachines = [
    //   {
    //     type: 1,
    //     machines: [
    //       {
    //         id: 1,
    //         name: "CI-10B"
    //       },
    //       {
    //         id: 2,
    //         name: "CI-50B"
    //       }
    //     ]
    //   },
    //   {
    //     type: 2,
    //     machines: []
    //   }
    // ];

    // let coinMachines = [
    //   {
    //     type: 1,
    //     machines: [
    //       {
    //         id: 1,
    //         name: "CI-10C"
    //       }
    //     ]
    //   }
    // ];

    return (
      <>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title="Add new machine" level={1}>
          <button className="main slim" onClick={this.create}>
            Add
          </button>
        </Header>
        <Form>
          <Header title="Basic information" level={3} />
          <Input
            title="Code"
            name="code"
            placeholder="Enter a code"
            onChange={this.handleInputs}
            value={code}
          />
          <Input
            title="Name"
            name="name"
            placeholder="Enter a name"
            onChange={this.handleInputs}
            value={name}
          />
          <Select
            title="Type"
            value={moduleSelected}
            name="moduleSelected"
            placeholder="Select a type"
            options={modules}
            onChange={this.handleSelect}
            error={""}
          />
          <LocationSelector
            mode="bridge"
            branchSelected={this.onBranchSelected}
            bridgeSelected={this.onBridgeSelected}
          />
          {branchSelected && (
            <>
              <label>Watermark</label>
              <WatermarkSelector
                value={watermarkSelected}
                branchId={branchSelected ? branchSelected.id : undefined}
                onChange={this.onWatermarkSelected}
              />
            </>
          )}
        </Form>
        <Form>
          <Header title="Note machine" level={3} />
          <Select
            title="Module"
            value={noteMachine}
            name="noteMachine"
            placeholder="Select a type"
            options={[
              {
                name: "(Recycler) CI-10B",
                value: "1.1",
              },
              {
                name: "(Recycler) CI-50B",
                value: "1.2",
              },
            ]}
            onChange={this.handleSelect}
            error={""}
          />
          <Input
            title="Address"
            name="noteAddress"
            placeholder="Enter note machine address"
            onChange={this.handleInputs}
            value={noteAddress}
          />
        </Form>
        <Form>
          <Header title="Coin machine" level={3} />
          <Select
            title="Module"
            value={coinMachine}
            name="coinMachine"
            placeholder="Select a type"
            options={[
              {
                name: "(Recycler) CI-10C",
                value: "1.1",
              },
            ]}
            onChange={this.handleSelect}
            error={""}
          />
          <Input
            title="Address"
            name="coinAddress"
            placeholder="Enter coin machine address"
            onChange={this.handleInputs}
            value={coinAddress}
          />
        </Form>
        <Form>
          <Header title="Operating hours" level={3} />
          <table>
            <thead>
              <tr>
                <th>Day</th>
                <th>24 hrs</th>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>
            <tbody>
              {this.state.operatingHours.map((day, i) => (
                <tr key={i}>
                  <td>{moment().day(day.dayOfWeek).format("dddd")}</td>
                  <td>
                    <input
                      type="checkbox"
                      name={day.dayOfWeek}
                      onChange={this.operatingChanged}
                      checked={day.is24Hours}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name={`start${day.dayOfWeek}`}
                      onChange={this.operatingChanged}
                      value={day.startTime}
                      disabled={day.is24Hours}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name={`end${day.dayOfWeek}`}
                      onChange={this.operatingChanged}
                      value={day.endTime}
                      disabled={day.is24Hours}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(MachineCreate)));
