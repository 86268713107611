import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import { Input, Select, Form } from "../../common";

class GroupsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      companyId: this.props.userAuth.companyId,
      categories: [],
      categorySelected: undefined,
      loading: true,
    };
  }

  componentDidMount() {
    const { companyId } = this.state;
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { companyId },
        query: gql`
          query getCategories($companyId: String!) {
            categories(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let categories = result.data.categories.map((c) => {
          return {
            name: c.name,
            value: c.id,
          };
        });

        let categorySelected =
          categories.length === 1 ? categories[0].id : undefined;

        this.setState({
          categories,
          categorySelected,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  onCreate = () => {
    const { companyId, name, categorySelected } = this.state;

    console.log("Name: ", name);
    console.log("CompanyId: ", companyId);
    console.log("CategorySelected", categorySelected);

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          group: {
            companyId,
            name,
            categoryId: Number(categorySelected),
          },
        },
        mutation: gql`
          mutation createGroup($group: GroupInput!) {
            createGroup(group: $group) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to create group.");
      });
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value });
  };

  render() {
    const { name, categories, categorySelected } = this.state;

    return (
      <div className="group__create">
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>

        <div className="header">
          <div>
            <h1>Add new group</h1>
            <button className="main" onClick={this.onCreate}>
              Create
            </button>
          </div>
        </div>
        <Form>
          <h4>Basic Information</h4>
          <Select
            title="Category"
            value={categorySelected}
            name="categorySelected"
            placeholder="Select a category"
            options={categories}
            onChange={this.handleSelect}
          />
          <Input
            title="Name"
            name="name"
            placeholder="Enter name of group."
            onChange={this.handleInputs}
            value={name}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(GroupsCreate)));
