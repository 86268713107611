import { gql } from "apollo-boost";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { addAlert } from "../../../actions";
import { Form, Header, Input, Select, Switch } from "../../common";

class CashMachines extends Component {
  constructor(props) {
    super(props);

    this.state = {
      machineId: "",
      loading: true,
      useNote: false,
      useCoin: false,
      noteMachine: undefined,
      coinMachine: undefined,
      noteAddress: "",
      coinAddress: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loading && prevProps !== this.props) {
      let { id, cashDevices } = this.props.info;

      let noteMachineTemp = cashDevices.filter((c) => c.cashType === 1);
      let coinMachineTemp = cashDevices.filter((c) => c.cashType === 2);

      if (noteMachineTemp) {
        var noteMachine = noteMachineTemp[0];
      }

      if (coinMachineTemp) {
        var coinMachine = coinMachineTemp[0];
      }

      this.setState({
        loading: false,
        machineId: id,
        useNote: noteMachine ? true : false,
        noteMachine: noteMachine ? `1.${noteMachine.deviceId}` : "",
        noteAddress: noteMachine ? noteMachine.address : "",
        useCoin: coinMachine ? true : false,
        coinMachine: coinMachine ? `1.${coinMachine.deviceId}` : "",
        coinAddress: coinMachine ? coinMachine.address : "",
      });
    }
  }

  setLoading = (loading) => {
    return new Promise((resolve, reject) => {
      this.setState({ loading }, () => {
        resolve(true);
      });
    });
  };

  handleInputs = (e) => {
    this.setState({ [e.name]: e.value });
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value }, this.onChange);
  };

  onToggled = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  save = () => {
    let {
      machineId,
      useNote,
      useCoin,
      noteMachine,
      coinMachine,
      noteAddress,
      coinAddress,
    } = this.state;

    let cashDevices = [];

    // * Get device names, types & ids
    if (useNote && noteMachine) {
      switch (noteMachine) {
        case "1.1":
          var noteDeviceName = "CI-10B";
          var noteDeviceType = 1;
          var noteDeviceId = 1;
          break;
        case "1.2":
          noteDeviceName = "CI-50B";
          noteDeviceType = 1;
          noteDeviceId = 2;
          break;
        default:
          break;
      }

      cashDevices.push({
        name: noteDeviceName,
        address: noteAddress,
        cashType: 1,
        deviceType: noteDeviceType,
        deviceId: noteDeviceId,
      });
    }

    if (useCoin && coinMachine) {
      cashDevices.push({
        name: "CI-10C",
        address: coinAddress,
        cashType: 2,
        deviceType: 1,
        deviceId: 1,
      });
    }

    this.setLoading(true)
      .then((_) => {
        return this.props.client.mutate({
          variables: {
            machineId,
            userId: this.props.userAuth.userId,
            cashDevices,
          },
          mutation: gql`
            mutation update(
              $userId: String!
              $machineId: String!
              $cashDevices: [CashDeviceInput!]!
            ) {
              editMachineCashDevices(
                userId: $userId
                machineId: $machineId
                cashDevices: $cashDevices
              )
            }
          `,
        });
      })
      .then((result) => {
        if (!result.data) {
          throw new Error("Failed");
        }

        this.props.addAlert({
          type: "success",
          title: "All set",
          msg: `Basic info updated`,
        });

        this.setLoading(false);
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Failed to update",
          msg: error.message,
        });

        this.setLoading(false);
      });
  };

  render() {
    let {
      loading,
      useNote,
      useCoin,
      noteMachine,
      coinMachine,
      noteAddress,
      coinAddress,
    } = this.state;

    return (
      <Form>
        <Header title="Machines" level={3}>
          {!loading && (
            <button className="main slim" onClick={this.save}>
              Save
            </button>
          )}
        </Header>
        <Header title="Note machine" level={4}>
          <Switch isOn={useNote} handleToggle={this.onToggled} name="useNote" />
        </Header>
        {useNote && (
          <>
            <Select
              title="Module"
              value={noteMachine}
              name="noteMachine"
              placeholder="Select a type"
              options={[
                {
                  name: "(Recycler) CI-10B",
                  value: "1.1",
                },
                {
                  name: "(Recycler) CI-50B",
                  value: "1.2",
                },
              ]}
              onChange={this.handleSelect}
              error={""}
            />
            <Input
              title="Address"
              name="noteAddress"
              placeholder="Enter note machine address"
              onChange={this.handleInputs}
              value={noteAddress}
            />
          </>
        )}
        <Header title="Coin machine" level={4}>
          <Switch isOn={useCoin} handleToggle={this.onToggled} name="useCoin" />
        </Header>
        {useCoin && (
          <>
            <Select
              title="Module"
              value={coinMachine}
              name="coinMachine"
              placeholder="Select a type"
              options={[
                {
                  name: "(Recycler) CI-10C",
                  value: "1.1",
                },
              ]}
              onChange={this.handleSelect}
              error={""}
            />
            <Input
              title="Address"
              name="coinAddress"
              placeholder="Enter coin machine address"
              onChange={this.handleInputs}
              value={coinAddress}
            />
          </>
        )}
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(CashMachines)
);
