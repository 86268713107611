import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

// import "./style.scss";
import "../../../../App.scss";

import Input from "../../../common/Input";
import Select from "../../../common/Select";
import Form from "../../../common/Form";

class PromotionConditionsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      condition: "",
      validUntil: "",
      selectedDay: new Date(),
      branches: [],
      branchSelected: undefined,
      loading: true,
    };
  }

  componentDidMount() {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches.map((b) => {
          return {
            name: b.name,
            value: b.id,
          };
        });

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState({
          branches,
          branchSelected,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  onCreate = () => {
    const { condition, validUntil, branchSelected } = this.state;

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          promotionCondition: {
            branchId: branchSelected,
            condition,
            validUntil,
          },
        },
        mutation: gql`
          mutation createPromotionCondition(
            $promotionCondition: PromotionConditionInput!
          ) {
            createPromotionCondition(promotionCondition: $promotionCondition) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to create item.");
      });
  };

  handleBranch = (e) => {
    let branchId = e.target.value;

    this.setState({ [e.target.name]: branchId });
  };

  handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
    this.setState({
      selectedDay,
    });
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value });
  };

  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  render() {
    const { condition, branches, branchSelected, selectedDay } = this.state;

    console.log("state", this.state);
    return (
      <div className="promotionCondition__create">
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>

        <div className="header">
          <div>
            <h1>Add new condition</h1>
            <button className="main" onClick={this.onCreate}>
              Create
            </button>
          </div>
        </div>
        <Form loading={this.state.loading}>
          <h4>Basic Information</h4>
          <Select
            title="Branch"
            value={branchSelected}
            name="branchSelected"
            placeholder="Select a branch"
            options={branches}
            onChange={this.handleSelect}
          />
          <Input
            title="Condition"
            name="condition"
            placeholder="Enter promotion condition."
            onChange={this.handleInputs}
            value={condition}
          />

          <label>Date</label>
          <DayPickerInput
            value={selectedDay}
            onDayChange={this.handleDayChange}
            dayPickerProps={{
              selectedDays: selectedDay,
              disabledDays: {
                daysOfWeek: [0, 6],
                after: new Date(),
              },
            }}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps)(PromotionConditionsCreate))
);
