import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";
import DateSelection from "../../DateSelection";
import moment from "moment";
import { CSVLink } from "react-csv";

import "./style.scss";
import "./styles.css";

let headers = [
  { label: "Date", key: "createdOn" },
  { label: "Total", key: "total" },
  { label: "Serial Number", key: "serialNumber" },
  { label: "Machine", key: "machine" },
  { label: "User", key: "user" }
];

let dataSkimmings = [];

class SkimmingReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branches: [],
      branchSelected: undefined,
      selectedDay: null,
      toggleDate: false,
      machines: [],
      machineSelected: undefined,
      records: [],
      total: this.currencyFormat(0),
      isShowSkim: false,
      machineNow: ""
    };
  }

  componentDidMount() {
    this.getBranches();
  }









  getBranches = () => {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
              createdOn
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches;

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;
        //   let branchSelectedDate =
        //     branches.length === 1 ? branches[0].createdOn : new Date();

        this.setState(
          {
            branches: branches,
            branchSelected,
          },
          () => {
            this.getMachines();
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  getMachines = () => {
    this.props.client
      .query({
        variables: {
          branchId: this.state.branchSelected,
        },
        query: gql`
          query getMachines($branchId: String!) {
            branchMachines(branchId: $branchId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        this.setState({
          machines: result.data.branchMachines,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  getRecords = () => {
    if (!this.state.machineSelected) {
      if (this.state.machineSelected != this.state.machineNow) {
        this.state.machineSelected = this.state.machineNow
      }else {
        return;
      }
    }

    this.props.client
      .query({
        variables: {
          branchId: this.state.branchSelected,
          machineId: this.state.machineSelected,
          date: this.state.selectedDay,
        },
        query: gql`
          query getMachines(
            $branchId: String!
            $machineId: String!
            $date: DateTime
          ) {
            skimmings(branchId: $branchId, machineId: $machineId, date: $date) {
              id
              createdOn
              total
              serialNumber
              machineId
              user {
                id
                name
              }
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          return;
        }

        console.log(result.data.skimmings);

        let total = result.data.skimmings.reduce((p, c) => p + c.total, 0);

        let formatted = this.currencyFormat(total);

        // console.log(total);

        this.setState({
          total: formatted,
          records: result.data.skimmings,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  toggleDateSelection = () => {
    this.setState({ toggleDate: true });
  };

  closeDateSelection = () => {
    this.setState({ toggleDate: false }, () => {
      this.getRecords();
    });
  };

  onDateSelected = (date) => {
    this.setState({ toggleDate: false, selectedDay: date }, () => {
      this.getRecords();
    });
  };




  handleInput = (e) => {
    let elementName = e.target.name;
    let machineCurrent = e.target.value;

    this.setState({ [e.target.name]: e.target.value }, () => {
      if (elementName === "machineSelected") {
        this.getRecords();
        this.setState({
          isShowSkim: true,
          machineSelected: undefined,
          machineNow: machineCurrent
        })
      } else if (elementName === "branchSelected") {
        this.getMachines();
        this.setState({
          isShowSkim: false
        })
      }
    });


  };



  currencyFormat(amount) {
    let format = new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
      minimumFractionDigits: 2,
    });

    return format.format(amount);
  }

  render() {
    const {
      branches,
      branchSelected,
      machineSelected,
      machines,
      toggleDate,
      selectedDay,
      records,
      total,
    } = this.state;

    // Remap replenishments
    let machinesAsObject = machines.reduce((p, c) => {
      p[c.id] = c.name;
      return p;
    }, {});

    let replenishRemapped = records.map((t) => {
      t.machine = machinesAsObject[t.machineId];
      return t;
    });





    let reportDate = moment(this.state.selectedDay).format("D-M-YY");
    let currentDate = moment().format("DMYYHMM");
    let fileName = `TransactionReport_${reportDate}_(${currentDate})`;

    // let dataDecoration = () => {

    //   replenishRemapped.map((item) => {
    //     dataSkimmings.push({
    //       date: item.createdOn,
    //       total: item.total,
    //       serial: item.serialNumber,
    //       machine: item.machine,
    //       user: item.user ? item.user.name : "Anonymous"
    //     })
    //   })

    // }

    // dataDecoration()


    console.log(`Log now machine ${this.state.machineNow}`);

    console.log(`Log original skimmings ${dataSkimmings.length}`);



    let contentsSkim = (
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Total</th>
            <th>Serial number</th>
            <th>Machine</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          {replenishRemapped.length === 0 && (
            <tr>
              <td colSpan="100%">No transactions found</td>
            </tr>
          )}
          {replenishRemapped.map((r, i) => (
            <tr key={`rep${i}`}>
              <td>{i + 1}</td>
              <td>{moment(r.createdOn).format("D/M/YY H:mm")}</td>
              <td>{r.total}</td>
              <td>{r.serialNumber}</td>
              <td>{r.machine}</td>
              <td>{r.user ? r.user.name : "Anonymous"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )


    if (!this.state.isShowSkim) {
      contentsSkim = (
        <div>

        </div>
      )
    }


    return (
      <div id="report__replenishment">
        <h1>Skimming Report</h1>
        <CSVLink filename={`${fileName}.csv`} className="excel_report" type="button" data={replenishRemapped} headers={headers} >
          Export excel
        </CSVLink>
        <div className="summary">
          <h1>Total</h1>
          <span>{total}</span>
        </div>
        <div className="transactions">
          <div className="header">
            <h2>Replenishments</h2>
          </div>
          <div className="filter">
            <div>
              <label>Branches</label>
              <select
                name="branchSelected"
                value={branchSelected}
                onChange={this.handleInput}
              >
                {!branchSelected && <option>Select branch</option>}
                {branches.map((b) => (
                  <option key={b.id} value={b.id}>
                    {b.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Date</label>
              <input
                readOnly={true}
                type="text"
                value={
                  selectedDay
                    ? moment(selectedDay).format("D MMM YYYY")
                    : "Real-time"
                }
                onClick={this.toggleDateSelection}
              />
            </div>
            {branchSelected && (
              <div>
                <label>Machine</label>
                <select
                  name="machineSelected"
                  value={machineSelected}
                  onChange={this.handleInput}
                >
                  {!machineSelected && (
                    <option value={undefined}>Select machine</option>
                  )}
                  {machines.map((m, i) => (
                    <option key={m.code} value={m.id}>
                      {m.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>



          <div className="records">



            {contentsSkim}


          </div>
        </div>
        {toggleDate && (
          <DateSelection
            selection={selectedDay}
            close={this.closeDateSelection}
            onSelect={this.onDateSelected}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(SkimmingReport)));
