import React from "react";
import APITable from "../../APITable";

const returnCash = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [];

  let resp = [];

  return (
    <APITable
      title="Return Cash"
      desc="Return coins that remain inside the inlet hopper"
      command="ReturnCash"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default returnCash;
