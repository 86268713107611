import React from "react";
import APITable from "../../APITable";

const cancelCashIn = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [
    {
      name: "Amount",
      desc: "Amount to be displayed on the customer display",
    },
  ];

  let resp = [];

  return (
    <APITable
      title="Refresh Sales Total"
      desc="Refresh the Sales amount reflected on the Customer display only, doesn't have affect on the amount that will be used to calculate change"
      command="RefreshSalesTotal"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default cancelCashIn;
