import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Input, Select, Form } from "../../common";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branches: [],
      branchSelected: undefined,
      shops: [],
      shopSelected: undefined,
      name: "",
    };
  }

  componentDidMount() {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches.map((b) => {
          return {
            name: b.name,
            value: b.id,
          };
        });

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState({
          branches,
          branchSelected,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  getShops = (branchId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { branchId },
        query: gql`
          query getShops($branchId: String!) {
            shops(branchId: $branchId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let shops = result.data.shops.map((c) => {
            return {
              name: c.name,
              value: c.id,
            };
          });
          let shopSelected = shops.length === 1 ? shops[0].id : undefined;

          this.setState({ shops, shopSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value });
  };

  handleBranch = (e) => {
    this.setState({ [e.name]: e.value }, () => {
      this.getShops(e.value);
    });
  };

  onCreate = () => {
    const { branchSelected, shopSelected, name } = this.state;

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          kitchen: {
            branchId: branchSelected,
            name,
            shopId: shopSelected,
          },
        },
        mutation: gql`
          mutation createKitchen($kitchen: KitchenInput!) {
            createKitchen(kitchen: $kitchen) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
        if (result.errors) {
          alert(result.errors[0].message);
        }
      })
      .catch((error) => {
        // console.log(errors.message);
        alert("Unable to create item.");
      });
  };

  render() {
    const { branches, branchSelected, shops, shopSelected, name } = this.state;

    return (
      <div>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <div className="header">
          <h1>Add new kitchen</h1>
          <button className="main" onClick={this.onCreate}>
            Create
          </button>
        </div>
        <Form>
          <Select
            title="Branch"
            value={branchSelected}
            name="branchSelected"
            placeholder="Select a branch."
            options={branches}
            onChange={this.handleBranch}
          />
          <Select
            title="Shop"
            value={shopSelected}
            name="shopSelected"
            placeholder="Select a shop."
            options={shops}
            onChange={this.handleSelect}
          />
          <Input
            title="Name"
            name="name"
            placeholder="Enter name of kitchen."
            onChange={this.handleInputs}
            value={name}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(Create)));
