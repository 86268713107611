import React, { Component } from "react";
import moment from "moment";
import { gql } from "apollo-boost";

export default class TransactionDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      performingAction: false
    };
  }

  void = transactionId => {
    if (window.confirm("Are you sure you want to void this transaction?")) {
      this.setState({ performingAction: true }, () => {
        this.props.client
          .mutate({
            variables: {
              transactionId: this.props.id,
              isServer: true
            },
            mutation: gql`
              mutation createMachine(
                $transactionId: String!
                $isServer: Boolean!
              ) {
                voidTransaction(
                  transactionId: $transactionId
                  isServer: $isServer
                ) {
                  status
                }
              }
            `
          })
          .then(result => {
            if (result.data) {
              alert("Void success!");
              this.props.close();
            } else if (result.errors) {
              alert(result.errors[0].message);
              this.setState({ performingAction: false });
            }
          })
          .catch(error => {
            alert(error);
            console.log(error);
          });
      });
    }
  };

  getPaymentType = id => {
    switch (id) {
      case 0:
        return "Cash";
      case 1:
        return "Card";
      case 2:
        return "QR";
      case 3:
        return "Privilege";
      default:
        return null;
    }
  };

  render() {
    const { transaction } = this.props;
    return (
      <div id="healthcare__transaction__detail">
        <div className="container">
          <span className="close" onClick={this.props.close}>
            <img
              height="10"
              width="10"
              alt="close-popup"
              src={process.env.PUBLIC_URL + "/assets/icons/close.svg"}
            />
          </span>
          <h1>Transaction detail</h1>
          <label>Invoice</label>
          <span>
            {transaction.bookNo}-{transaction.billNo}
          </span>
          <label>HN</label>
          <span>{transaction.hn}</span>
          <label>Patient name</label>
          <span>{transaction.patientName}</span>
          <label>Cash total</label>
          <span>{transaction.cashAmount}</span>
          <label>Privilege</label>
          <span>{transaction.privilegeAmount}</span>
          <div className="details">
            <h2>Details</h2>
            {transaction.details.map((d, index) => {
              return (
                <div key={index} className="payment">
                  <label>Type</label>
                  <span>{this.getPaymentType(d.paymentType)}</span>
                  <label>Date</label>
                  <span>{moment(d.createdOn).format("D/M/YY H:mm")}</span>
                  <label>Amount</label>
                  <span>{d.amount}</span>
                  {d.paymentType !== 0 && (
                    <>
                      <label>Approve code</label>
                      <span>{d.approveCode}</span>
                      <label>Merchant Id</label>
                      <span>{d.merchantId}</span>
                      <label>Terminal Id</label>
                      <span>{d.terminalId}</span>
                      <label>Trace no.</label>
                      <span>{d.traceInvoiceNumber}</span>
                    </>
                  )}
                </div>
              );
            })}
          </div>
          {transaction.status === 0 && !this.state.performingAction && (
            <button className="warning" onClick={this.void}>
              Void transaction
            </button>
          )}
          {this.state.performingAction && <p>Perfroming void, please wait</p>}
        </div>
      </div>
    );
  }
}
