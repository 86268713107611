import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";

class CreateCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: [],
      modulesSelected: [],
      logoImage: null
    };
  }

  componentDidMount() {
    // Get current modules available
    this.props.client
      .query({
        query: gql`
          query {
            modules {
              id
              name
            }
          }
        `
      })
      .then(result => {
        this.setState({ modules: result.data.modules });
      })
      .catch(error => {
        alert(error.message);
      });
  }

  handleFile = e => {
    var files = e.target.files;
    if (files.length > 1) {
      // Too many
      alert("You can only select 1 file");
    } else if (files.length === 1) {
      // Ok
      this.getBase64(files[0], result => {
        var base64Data = result;
        this.setState({ logoImage: base64Data });
      });
    } else {
      this.setState({ logoImage: null });
    }
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  }

  create = (id, name) => {
    if (this.state.modulesSelected.length === 0) {
      alert("Please select at least one module!");
      return;
    }

    this.props.client
      .mutate({
        variables: {
          companyDetail: {
            company: {
              code: id.value,
              name: name.value,
              avatar: this.state.logoImage
            },
            modules: this.state.modulesSelected.map(m => Number(m))
          }
        },
        mutation: gql`
          mutation createCompany($companyDetail: NewCompanyInput!) {
            createCompany(companyDetail: $companyDetail) {
              id
            }
          }
        `
      })
      .then(result => {
        if (result.data) {
          alert("success");
          this.props.history.goBack();
        }
      })
      .catch(error => {
        alert(error.message);
      });
  };

  onModuleSelectionChanged = e => {
    if (e.target.checked) {
      // Selected
      let newSelection = this.state.modulesSelected.concat(e.target.value);
      this.setState({ modulesSelected: newSelection });
    } else {
      // Deselected
      let newSelection = this.state.modulesSelected.filter(
        m => m !== e.target.value
      );
      this.setState({ modulesSelected: newSelection });
    }
  };

  render() {
    const { modules } = this.state;

    let id;
    let name;

    return (
      <div>
        <button onClick={() => this.props.history.goBack()}>Go back</button>
        <h1>Add new Company</h1>
        <label>Company Id</label>
        <input
          name="id"
          type="text"
          ref={node => {
            id = node;
          }}
        />
        <br />
        <label>Company Name</label>
        <input
          name="name"
          type="text"
          ref={node => {
            name = node;
          }}
        />
        <br />
        <input
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={this.handleFile}
        />
        <br />
        <label>Modules</label>
        <br />
        {modules.map(m => (
          <Fragment key={m.code}>
            <label>
              <input
                type="checkbox"
                name={m.name}
                value={m.id}
                onChange={this.onModuleSelectionChanged}
              />
              {m.name}
            </label>
            <br />
          </Fragment>
        ))}
        <button onClick={e => this.create(id, name)}>Create</button>
      </div>
    );
  }
}

export default withRouter(withApollo(CreateCompany));
