import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import Header from "../common/Header";
import TabControl from "../common/TabControl";
import Tab from "../common/TabControl/Tab";
import { gql } from "apollo-boost";

import qs from "querystring";
import { addAlert } from "../../actions";
import Basic from "./Basic";
import Security from "./Security";
import Notification from "./Notification";
import DispenseFloat from "./DispenseFloat";
import ExchangeLimit from "./ExchangeLimit";
import { Switch } from "../common";

class Profile extends Component {
  constructor(props) {
    super(props);

    let params = qs.parse(this.props.location.search.replace("?", ""));

    this.state = {
      loading: true,
      displayName: "",
      location: "",
      username: "",
      authKey: "",
      password: "",
      newPassword: "",
      verifyNewPassword: "",
      userId: params.id || "",
      ownProfile: params.id ? false : true,
      isActive: true,
      changingStatus: false,
    };
  }

  componentDidMount() {
    let { userId } = this.state;

    userId = userId || this.props.userAuth.userId;

    console.log("[Profile] User id", userId);

    this.setState({ userId }, () => {
      this.getDisplayName(userId);
    });
  }

  getDisplayName = async (userId) => {
    this.setState({ loading: true }, () => {
      // Get user name and info
      this.props.client
        .query({
          variables: { userId },
          query: gql`
            query getUserInfo($userId: String!) {
              user(id: $userId) {
                name
                isActive
              }
            }
          `,
        })
        .then((result) => {
          if (result.data) {
            const { name, isActive } = result.data.user;

            this.setState({
              displayName: name,
              loading: false,
              isActive,
            });
          }
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    });
  };

  handleInputs = (e) => {
    this.setState({ [e.name]: e.value });
  };

  changePassword = () => {
    const { userId, password, newPassword, verifyNewPassword } = this.state;

    if (password === "" || newPassword === "" || verifyNewPassword === "") {
      return;
    }

    // Check whether new password match
    if (newPassword !== verifyNewPassword) {
      return;
    }

    this.props.client
      .mutate({
        variables: {
          userId,
          currentPassword: password,
          newPassword: newPassword,
        },
        mutation: gql`
          mutation update(
            $userId: String!
            $currentPassword: String!
            $newPassword: String!
          ) {
            updatePassword(
              userId: $userId
              currentPassword: $currentPassword
              newPassword: $newPassword
            ) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.props.addAlert({
            type: "success",
            title: "All set",
            msg: `Security info updated`,
          });

          this.setState({
            password: "",
            newPassword: "",
            verifyNewPassword: "",
          });
        } else {
          this.props.addAlert({
            type: "error",
            title: "Failed to update security",
            msg: result.errors[0].message,
          });
        }
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Failed to update security",
          msg: error.message,
        });
      });
  };

  onToggleChanged = (e) => {
    let isActive = e.target.checked;

    // * Update use activeness
    this.setState({ changingStatus: true }, () => {
      this.props.client
        .mutate({
          variables: {
            userId: this.state.userId,
            isActive,
          },
          mutation: gql`
            mutation update($userId: String!, $isActive: Boolean!) {
              updateUserActiveness(userId: $userId, isActive: $isActive)
            }
          `,
        })
        .then(({ data }) => {
          if (!data) {
            alert("Unable to update user status");
            this.setState({ changingStatus: false });
            return;
          }

          this.setState({ changingStatus: false, isActive });
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  render() {
    const {
      displayName,
      userId,
      ownProfile,
      loading,
      isActive,
      changingStatus,
    } = this.state;

    if (loading) {
      return <h1>Loading</h1>;
    }

    return (
      <div id="userProfile">
        {!ownProfile && (
          <button
            className="return"
            onClick={() => this.props.history.goBack()}
          >
            Return
          </button>
        )}
        <Header
          title={ownProfile ? "Edit profile" : `Editing ${displayName}`}
          level="3"
          subtitle={userId}
        >
          {!ownProfile && (
            <div className="userProfile__status">
              <label>User's Status</label>
              {changingStatus ? (
                "Updating"
              ) : (
                <Switch
                  name="isActive"
                  isOn={isActive}
                  handleToggle={this.onToggleChanged}
                />
              )}
            </div>
          )}
        </Header>
        <TabControl>
          <Tab title="Basic information">
            <Basic userId={userId} />
            <DispenseFloat userId={userId} />
            <ExchangeLimit userId={userId} />
          </Tab>
          <Tab title="Security">
            <Security userId={userId} />
          </Tab>
          <Tab title="Notifications">
            <Notification userId={userId} />
          </Tab>
        </TabControl>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(Profile))
);
