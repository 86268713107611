import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import "./style.scss";
import { withRouter } from "react-router-dom";

export const SubNavigation = withRouter(props => {
  const subNav = useSelector(state => state.subNav);

  const navigate = url => {
    if (!props.history || !url) {
      return;
    }

    props.history.push(url);
  };

  return (
    <div className="subnav">
      {subNav.map((d, index) => (
        <Fragment key={`subNav${index}`}>
          <span
            onClick={() => index + 1 < subNav.length && navigate(d.url)}
            className="subnav__span"
          >
            {d.title.toUpperCase()}
          </span>
          {index < subNav.length - 1 && (
            <svg
              className="subnav__separator"
              width="5"
              height="10"
              viewBox="0 0 8 16"
              role="img"
              aria-hidden="true"
            >
              <path
                d="M5 8l-5 8h3l5-8-5-8H0z"
                fill="#266ff0"
                fillRule="evenodd"
                role="presentation"
              ></path>
            </svg>
          )}
        </Fragment>
      ))}
    </div>
  );
});
