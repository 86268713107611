import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import headerReducer from "./header.reducer";
import subNavRedcuer from "./subNavigation.reducer";
import navReducer from "./nav.reducer";
import apolloReducer from "./apollo.reducers";
import alertReducer from "./alert.reducers";

const allReducers = combineReducers({
  userAuth: authReducer,
  header: headerReducer,
  subNav: subNavRedcuer,
  nav: navReducer,
  alerts: alertReducer,
  apollo: apolloReducer,
});

export default allReducers;
