import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import moment from "moment";

import "react-day-picker/lib/style.css";

import DateSelection from "../../DateSelection";

import Summary from "./Summary";

class TransactionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transactions: undefined,
      loading: true,
      branches: [],
      branchSelected: undefined,
      machines: [],
      machineSelected: undefined,
      take: 100,
      skip: 0,
      endOfDayPeriod: undefined,
      realtime: true,
      selectedDay: null,
      toggleDate: false,
      grandTotal: 0,
      received: 0,
      changed: 0,
    };
  }

  componentDidMount() {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        let branches = result.data.branches;
        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState(
          {
            branches: branches,
            branchSelected,
            loading: false,
          },
          () => {
            if (branchSelected) {
              this.getBranchesTransactions(branchSelected);
              this.getMachines();
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  getBranchesTransactions = (branchId) => {
    // Get endDate from query endOfDayPeriod first.
    // console.log("selected day :" + this.state.selectedDay);
    this.getEndOfDay();
  };

  handleInput = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.name === "machineSelected") {
      let value = e.target.value === "All" ? undefined : e.target.value;
      this.setState({ machineSelected: value });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.getEndOfDay();
  };

  getMachines = () => {
    if (!this.state.branchSelected) {
      return;
    }

    this.props.client
      .query({
        variables: {
          branchId: this.state.branchSelected,
        },
        query: gql`
          query getMachines($branchId: String!) {
            branchMachines(branchId: $branchId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        this.setState({
          machines: result.data.branchMachines,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  getEndOfDay = () => {
    if (!this.state.branchSelected) {
      return;
    }
    this.props.client
      .query({
        variables: {
          realtime: this.state.realtime,
          date: this.state.selectedDay,
          branchId: this.state.branchSelected,
        },
        query: gql`
          query getEndOfDayPeriod(
            $realtime: Boolean
            $date: DateTime
            $branchId: String!
          ) {
            endOfDayPeriod(
              realtime: $realtime
              date: $date
              branchId: $branchId
            ) {
              startDate
              endDate
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          console.log("EndPeriod", result.data);
          this.setState({ endOfDayPeriod: result.data.endOfDayPeriod }, () => {
            this.getTransaction();
          });
        }
      })
      .catch((error) => {
        //alert(error.message);
        alert("Error in get Period");
      });
  };

  getTransaction = () => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: {
          branchId: this.state.branchSelected,
          skip: this.state.skip,
          take: this.state.take,
          endDate: this.state.endOfDayPeriod.endDate,
          startDate: this.state.endOfDayPeriod.startDate,
          machineId: this.state.machineSelected,
        },
        query: gql`
          query getTransactions(
            $branchId: String!
            $skip: Int
            $take: Int
            $endDate: DateTime!
            $startDate: DateTime!
            $machineId: String
          ) {
            transactions(
              branchId: $branchId
              skip: $skip
              take: $take
              endDate: $endDate
              startDate: $startDate
              machineId: $machineId
            ) {
              id
              transactionId
              totalAmount
              received
              changed
              branchId
              createdDate
              status
              machineId
            }
            transactionSummary(
              branchId: $branchId
              machineId: $machineId
              startDate: $startDate
              endDate: $endDate
            ) {
              grandTotal
              received
              changed
            }
            branchMachines(branchId: $branchId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          //this.setState();
          console.log("Data", result.data);
          const {
            transactions,
            branchMachines,
            transactionSummary,
          } = result.data;

          if (!transactions) {
            this.setState({
              transactions: [],
              grandTotal: this.currencyFormat(0),
              received: this.currencyFormat(0),
              changed: this.currencyFormat(0),
            });
            return;
          }

          // Re map transactions where the machine name instead
          var machines = branchMachines.reduce((p, c) => {
            p[c.id] = c.name;
            return p;
          }, {});
          var transRemapped = transactions.map((t) => {
            t.machine = machines[t.machineId];

            return t;
          });

          this.setState({
            transactions: transRemapped || [],
            grandTotal: this.currencyFormat(transactionSummary.grandTotal),
            received: this.currencyFormat(transactionSummary.received),
            changed: this.currencyFormat(transactionSummary.changed),
          });
          // this.setState({ transactions: result.data.transactions }, () => {
          //   this.getTransactionSummary();
          // });
        }
      })
      .catch((error) => {
        console.log(error);
        //alert("Error in this");
        //alert(error.message);
      });
  };

  getTransactionSummary = () => {
    console.log("branchSelected", this.state.branchSelected);
    console.log("machineSelected", this.state.machineSelected);
    console.log("date", this.state.selectedDay);
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: {
          branchId: this.state.branchSelected,
          machineId: this.state.machineSelected,
          date: this.state.selectedDay,
        },
        query: gql`
          query getSummary(
            $branchId: String!
            $machineId: String
            $date: DateTime
          ) {
            transactionSummary(
              branchId: $branchId
              machineId: $machineId
              date: $date
            ) {
              grandTotal
              received
              changed
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          console.log("Summary: ", result.data.transactionSummary);
          let grandTotal = result.data.transactionSummary.grandTotal;
          let received = result.data.transactionSummary.received;
          let changed = result.data.transactionSummary.changed;

          this.setState({
            grandTotal: this.currencyFormat(grandTotal),
            received: this.currencyFormat(received),
            changed: this.currencyFormat(changed),
          });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleBranch = (e) => {
    let branchId = e.target.value;

    this.setState({ [e.target.name]: branchId }, () => {
      this.getBranchesTransactions(branchId);
      this.getMachines();
    });
  };

  // handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
  //   this.setState(
  //     {
  //       selectedDay
  //     },
  //     () => this.getEndOfDay()
  //   );
  // };

  handleCheckBoxes = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  createTransaction = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  toggleDateSelection = () => {
    this.setState({ toggleDate: true });
  };

  closeDateSelection = () => {
    this.setState({ toggleDate: false });
  };

  onDateSelected = (date) => {
    // this.setState({ toggleDate: false, selectedDay: date });
    this.setState(
      { toggleDate: false, selectedDay: date, realtime: false },
      () => this.getEndOfDay()
    );
  };

  showDetail = (transactionId) => {
    //console.log("TransactionId:", transactionId);
    this.props.history.push(
      `${this.props.location.pathname}/transactionDetails?id=${transactionId}`
    );
  };

  currencyFormat(amount) {
    var format = new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
      minimumFractionDigits: 2,
    });

    return format.format(amount);
  }

  render() {
    const {
      transactions,
      branches,
      branchSelected,
      machines,
      machineSelected,
      selectedDay,
      toggleDate,
      grandTotal,
      received,
      changed,
    } = this.state;
    //console.log(grandTotal, received, changed);
    return (
      <div className="report__transaction">
        <h1>Sales Report</h1>
        <div className="summary">
          <Summary
            grandTotal={grandTotal}
            received={received}
            changed={changed}
          />
        </div>
        <div className="transactions">
          <div className="header">
            <h2>Transactions</h2>
          </div>
          <div>
            <div className="filter">
              <div>
                <label>Branch</label>
                <select
                  name="branchSelected"
                  value={branchSelected}
                  onChange={this.handleBranch}
                >
                  {!branchSelected && <option>Select branch</option>}
                  {branches &&
                    branches.map((b) => (
                      <option key={b.id} value={b.id}>
                        {b.name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label>Date</label>
                <input
                  readOnly={true}
                  type="text"
                  value={
                    selectedDay
                      ? moment(selectedDay).format("D MMM YYYY")
                      : "Real-time"
                  }
                  onClick={this.toggleDateSelection}
                />
              </div>
              {branchSelected && (
                <div>
                  <label>Machine</label>
                  <select
                    name="machineSelected"
                    value={machineSelected}
                    onChange={this.handleInput}
                  >
                    <option value={undefined}>All</option>
                    {machines.map((m, i) => (
                      <option key={m.code} value={m.id}>
                        {m.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="list">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>TransactionId</th>
                    <th>Total Amount</th>
                    <th>Received</th>
                    <th>Changed</th>
                    <th>Machine</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions &&
                    transactions.map((t, index) => (
                      <tr key={index} onClick={() => this.showDetail(t.id)}>
                        <td>{index + 1}</td>
                        <td>{moment(t.createdDate).format("D/M/YY H:mm")}</td>
                        <td>{t.transactionId}</td>
                        <td>{t.totalAmount}</td>
                        <td>{t.received}</td>
                        <td>{t.changed}</td>
                        {/* <td>{t.machineId}</td> */}
                        <td>{t.machine}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {toggleDate && (
          <DateSelection
            selection={selectedDay}
            close={this.closeDateSelection}
            onSelect={this.onDateSelected}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps)(TransactionsList))
);
