import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";

class UserCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      username: "",
      authKey: "",
      password: "",
      verifyPassword: "",
      companies: [],
      selectedCompany: undefined,
    };
  }

  componentDidMount() {
    // Get companies and their admin default group id
    this.props.client
      .query({
        query: gql`
          query {
            companies {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        this.setState({ companies: result.data.companies });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  handleInputs = (event) => {
    // let eventName = event.target.name;
    // let eventValue = event.target.value;

    this.setState({ [event.target.name]: event.target.value });
  };

  addUser = () => {
    var userDetail = {
      name: this.state.name,
      username: this.state.username,
      authKey: this.state.username,
      password: this.state.password,
      companyId: this.state.selectedCompany,
      forcePasswordUpdate: true,
    };

    this.props.client
      .mutate({
        variables: {
          userDetail,
        },
        mutation: gql`
          mutation addCompanyAdmin($userDetail: UserInput!) {
            createCompanyAdmin(userDetail: $userDetail) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          alert("Unable to create new admin");
          return;
        }

        alert("Created new admin successfully");
        this.props.history.goBack();
      })
      .catch((error) => {
        alert(error);
      });
  };

  render() {
    const {
      name,
      username,
      password,
      verifyPassword,
      companies,
      selectedCompany,
    } = this.state;

    return (
      <div>
        <label>Name</label>
        <br />
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={name}
          onChange={this.handleInputs}
        />
        <br />
        <label>Username</label>
        <br />
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={username}
          onChange={this.handleInputs}
        />
        <br />
        <label>Authorization key</label>
        <br />
        <input
          type="text"
          name="authKey"
          placeholder="Authorization key"
          value={username}
          onChange={this.handleInputs}
        />
        <br />
        <label>Password</label>
        <br />
        <input
          type="password"
          name="password"
          value={password}
          placeholder="Password"
          onChange={this.handleInputs}
        />
        <br />
        <label>Verify password</label>
        <br />
        <input
          type="password"
          name="verifyPassword"
          value={verifyPassword}
          placeholder="Verify Password"
          onChange={this.handleInputs}
        />
        <br />
        <label>Company</label>
        <select
          value={selectedCompany}
          name="selectedCompany"
          onChange={this.handleInputs}
        >
          {!selectedCompany && <option>Select company</option>}
          {companies.map((value, index) => (
            <option value={value.id} key={index}>
              {value.name}
            </option>
          ))}
        </select>
        <br />
        <button onClick={this.addUser}>Add</button>
      </div>
    );
  }
}

export default withRouter(withApollo(UserCreate));
