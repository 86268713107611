import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

import ControlCenter from "./controlCenter";
import BridgeService from "./bridgeService";
import SelectAPI from "./selectAPI";

import "./style.scss";

class APIReference extends Component {
  render() {
    const {
      match: { url },
    } = this.props;

    return (
      <div className="api">
        <Route exact path={`${url}/`} component={SelectAPI} />
        <Route path={`${url}/bridge`} component={BridgeService} />
        <Route path={`${url}/controlcenter`} component={ControlCenter} />
      </div>
    );
  }
}

export default withRouter(APIReference);
