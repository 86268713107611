import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import readXlsxFile from "read-excel-file";
import AWS from "aws-sdk";

import Select from "../../../common/Select";
import Form from "../../../common/Form";

class ItemImport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: undefined,
      brandSelected: undefined,
      skuSelected: undefined,
      itemCodeSelected: undefined,
      nameSelected: undefined,
      displayNameSelected: undefined,
      categorySelected: undefined,
      groupSelected: undefined,
      unitSelected: undefined,
      priceSelected: undefined,
      dataRows: undefined,
      brandId: undefined,
      branches: [],
      branchId: undefined,
      companyId: this.props.userAuth.companyId,
      userId: this.props.userAuth.userId,
      hadItemTemplate: false,
      itemImportTemplate: undefined,
      images: undefined,
      shops: [],
      shopSelected: undefined,
    };
  }

  componentDidMount() {
    //this.getImportTemplate(this.props.userAuth.companyId);
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches.map((b) => {
          return {
            name: b.name,
            value: b.id,
          };
        });

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState({
          branches,
          branchSelected,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  onReturn = () => {
    this.props.history.goBack();
  };

  handleFile = (e) => {
    var files = e.target.files;
    console.log("File", files);
    readXlsxFile(files[0]).then((rows) => {
      this.setState({ dataRows: rows });
      let columns = rows[0].map((r, index) => {
        return {
          name: r,
          value: index,
        };
      });
      this.setState({ columns }, () => {
        console.log("Column test ", columns[0].name);
      });
    });
  };

  handleFolder = (e) => {
    var images = e.target.files;
    let data = this.state.dataRows;
    data.shift();

    if (data.length !== images.length) {
      //console.log("Data: ", data);
      //console.log("Images: ", images.length);
      alert("Image number not match excel number!");
    } else {
      //console.log("Images: ", images);
      //console.log("Images File: ", images[0].name);

      this.setState({ images: Array.from(images) });
    }
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value }, () => {
      console.log(e.value);
    });
  };

  handleSelectBranch = (e) => {
    this.setState({ [e.name]: e.value }, () => {
      this.getShops(e.value);
    });
    this.getImportTemplate(this.state.companyId);
  };

  getImportTemplate = (companyId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { companyId },
        query: gql`
          query getImportTemplate($companyId: String!) {
            importTemplate(companyId: $companyId) {
              id
              brand
              sku
              itemCode
              name
              displayName
              category
              group
              unit
              price
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          //console.log("Result Template: ", result.data.importTemplate);

          //console.log("Result: ", result.data);
          const itemImportTemplate = result.data.importTemplate;

          console.log(
            "ImportTemplate in getImportTemplate :",
            itemImportTemplate
          );

          let brandSelected =
            this.state.columns.find((b) => b.name === itemImportTemplate.brand)
              ?.value || undefined;

          let skuSelected =
            this.state.columns.find((c) => c.name === itemImportTemplate.sku)
              ?.value || undefined;

          let itemCodeSelected =
            this.state.columns.find(
              (i) => i.name === itemImportTemplate.itemCode
            )?.value || undefined;

          let nameSelected =
            this.state.columns.find((n) => n.name === itemImportTemplate.name)
              ?.value || undefined;

          let displayNameSelected =
            this.state.columns.find(
              (d) => d.name === itemImportTemplate.displayName
            )?.value || undefined;

          let categorySelected =
            this.state.columns.find(
              (c) => c.name === itemImportTemplate.category
            )?.value || undefined;

          let groupSelected =
            this.state.columns.find((c) => c.name === itemImportTemplate.group)
              ?.value || undefined;

          let unitSelected =
            this.state.columns.find((c) => c.name === itemImportTemplate.unit)
              ?.value || undefined;

          let priceSelected =
            this.state.columns.find((p) => p.name === itemImportTemplate.price)
              ?.value || undefined;

          this.setState({
            brandSelected,
            skuSelected,
            itemCodeSelected,
            nameSelected,
            displayNameSelected,
            categorySelected,
            groupSelected,
            unitSelected,
            priceSelected,
            itemImportTemplate,
            hadItemTemplate: true,
          });
        }
      })
      .catch((error) => {
        // alert("No template found!");
        //this.setState({ hadItemTemplate: false });
        console.log(error);
      });
  };

  getShops = (branchId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { branchId },
        query: gql`
          query getShops($branchId: String!) {
            shops(branchId: $branchId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let shops = result.data.shops.map((c) => {
            return {
              name: c.name,
              value: c.id,
            };
          });
          let shopSelected = shops.length === 1 ? shops[0].id : undefined;

          this.setState({ shops, shopSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  createItemTemplate = () => {
    //console.log(this.state.columns);
    const {
      columns,
      companyId,
      brandSelected,
      skuSelected,
      itemCodeSelected,
      nameSelected,
      displayNameSelected,
      categorySelected,
      groupSelected,
      branchSelected,
      shopSelected,
      unitSelected,
      priceSelected,
    } = this.state;

    // console.log("companyId ", companyId);
    // console.log("brand ", columns[brandSelected].name);
    // console.log("sku ", columns[skuSelected].name);
    // console.log("itemCode ", columns[itemCodeSelected].name);
    // console.log("name ", columns[nameSelected].name);
    // console.log("displayName ", columns[displayNameSelected].name);
    // console.log("category ", columns[categorySelected].name);
    // console.log("group ", columns[groupSelected].name);
    // console.log("branchId ", branchSelected);
    // console.log("shopId ", shopSelected);
    // console.log("unit ", columns[unitSelected].name);
    // console.log("price ", columns[priceSelected].name);

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          importItem: {
            companyId,
            brand: columns[brandSelected].name,
            sku: columns[skuSelected].name,
            itemCode: columns[itemCodeSelected].name,
            name: columns[nameSelected].name,
            displayName: columns[displayNameSelected].name,
            category: columns[categorySelected].name,
            group: columns[groupSelected].name,
            branchId: branchSelected,
            shopId: shopSelected,
            unit: columns[unitSelected].name,
            price: columns[priceSelected].name,
          },
        },
        mutation: gql`
          mutation createTemplate($importItem: importTemplateInput!) {
            createImportTemplate(importTemplate: $importItem) {
              id
              companyId
              branchId
              shopId
              brand
              sku
              itemCode
              name
              displayName
              category
              group
              unit
              price
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Success!");
          //this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.log(error.message);
        // alert("Unable to create importItemTemplate");
      });
  };

  updateItemTemplate = () => {
    const {
      columns,
      itemImportTemplate,
      companyId,
      branchSelected,
      shopSelected,
      brandSelected,
      skuSelected,
      itemCodeSelected,
      nameSelected,
      displayNameSelected,
      categorySelected,
      groupSelected,
      unitSelected,
      priceSelected,
    } = this.state;
    //console.log("itemImportTemplate", itemImportTemplate);

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          importItem: {
            id: itemImportTemplate.id,
            companyId,
            brand: columns[brandSelected].name,
            sku: columns[skuSelected].name,
            itemCode: columns[itemCodeSelected].name,
            name: columns[nameSelected].name,
            displayName: columns[displayNameSelected].name,
            category: columns[categorySelected].name,
            group: columns[groupSelected].name,
            branchId: branchSelected,
            shopId: shopSelected,
            unit: columns[unitSelected].name,
            price: columns[priceSelected].name,
          },
        },
        mutation: gql`
          mutation updateImportTemplate($importItem: importTemplateInput!) {
            updateImportTemplate(importTemplate: $importItem) {
              id
              companyId
              branchId
              shopId
              brand
              sku
              itemCode
              name
              displayName
              category
              group
              unit
              price
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          // alert("Success!");
          //this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to update importItemTemplate");
      });
  };

  onImport = async () => {
    const {
      brandSelected,
      skuSelected,
      itemCodeSelected,
      nameSelected,
      displayNameSelected,
      branchSelected,
      shopSelected,
      categorySelected,
      groupSelected,
      unitSelected,
      priceSelected,
      dataRows,
      companyId,
      images,
      hadItemTemplate,
      userId,
    } = this.state;

    var dataArr = dataRows.map((row) => {
      return {
        branch: branchSelected,
        shop: shopSelected,
        brand: row[brandSelected],
        sku: row[skuSelected],
        itemCode: row[itemCodeSelected].toString(),
        name: row[nameSelected],
        displayName: row[displayNameSelected],
        category: row[categorySelected],
        group: row[groupSelected],
        unit: row[unitSelected],
        price: row[priceSelected],
        image: "arararar",
      };
    });

    for (const data of dataArr) {
      let found = images.find((image) => {
        const imageName = image.name.substring(0, image.name.lastIndexOf("."));
        return imageName === data.name;
      });

      if (!found) {
        console.log(`Data not found: ${data.name}`);

        // TODO: Handle if data from excel isn't matching any image name

        return;
      }

      // console.log(`Data found : ${data.name}`);
    }

    /**
     * Upload image to SPACES
     */
    var spacesEndpoint = new AWS.Endpoint(
      process.env.REACT_APP_SPACES_ENDPOINT
    );

    var s3 = new AWS.S3({
      endpoint: spacesEndpoint,
      accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
    });

    for (const image of images) {
      var params = {
        Body: image,
        Bucket: process.env.REACT_APP_SPACES_BUCKET,
        Key: `items/${branchSelected}/${image.name}`,
        ACL: "public-read",
        ContentType: image.type,
      };

      try {
        let data = await s3.upload(params).promise();
        // Not error then find data in excel that match image's name
        const imageName = image.name.substring(0, image.name.lastIndexOf("."));
        const dataIndex = dataArr.findIndex((d) => d.name === imageName);

        // console.log("DataIndex", dataIndex);
        dataArr[dataIndex].image = data.Location.toString();
      } catch (err) {
        // console.log(err);
      }
    }

    console.log("UserId", userId);
    // console.log("dataArr after set image: ", dataArr);
    // console.log("BranchId: ", branchSelected);
    // console.log("ShopId: ", shopSelected);
    // console.log("CompanyId: ", companyId);
    // console.log("importItem: ", dataArr);

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          branchId: branchSelected,
          shopId: shopSelected,
          companyId,
          userId,
          importItem: dataArr,
        },
        mutation: gql`
          mutation importItems(
            $branchId: String!
            $shopId: String!
            $companyId: String!
            $userId: String!
            $importItem: [ImportItemInput!]!
          ) {
            importItems(
              branchId: $branchId
              shopId: $shopId
              companyId: $companyId
              userId: $userId
              importItem: $importItem
            ) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          // console.log("DATAARR:", dataArr);
          alert("Success!");
          //this.props.history.goBack();
        }
      })
      .catch((error) => {
        alert(error.message);
        console.log(error);
      });

    if (hadItemTemplate) {
      this.updateItemTemplate();
    } else {
      this.createItemTemplate();
    }
  };

  render() {
    const {
      branches,
      shops,
      columns,
      brandSelected,
      skuSelected,
      itemCodeSelected,
      nameSelected,
      displayNameSelected,
      branchSelected,
      shopSelected,
      categorySelected,
      groupSelected,
      unitSelected,
      priceSelected,
      images,
    } = this.state;
    //console.log("Columns in render", columns);
    //console.log("Column test ", columns[1].name);
    //console.log("ShopSelected: ", shopSelected);
    return (
      <div>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>

        <div className="header">
          <div>
            <h1>Import Items</h1>
            <button className="main" onClick={this.onImport}>
              Import
            </button>
          </div>
        </div>
        <Form>
          <h4>Select excel file</h4>
          <input type="file" onChange={this.handleFile} />
          {columns && (
            <div>
              <h4>Select folder picture</h4>
              <input
                type="file"
                directory=""
                webkitdirectory=""
                onChange={this.handleFolder}
              />
            </div>
          )}
          {columns && images && (
            <div>
              <Select
                title="Branch"
                value={branchSelected}
                name="branchSelected"
                placeholder="Select a branch column"
                options={branches}
                onChange={this.handleSelectBranch}
              />
              {branchSelected && (
                <>
                  <Select
                    title="Shop"
                    value={shopSelected}
                    name="shopSelected"
                    placeholder="Select a shop column"
                    options={shops}
                    onChange={this.handleSelect}
                  />
                  <Select
                    title="Brand"
                    value={brandSelected}
                    name="brandSelected"
                    placeholder="Select a brand column"
                    options={columns}
                    onChange={this.handleSelect}
                  />
                  <Select
                    title="SKU"
                    value={skuSelected}
                    name="skuSelected"
                    placeholder="Select a sku column"
                    options={columns}
                    onChange={this.handleSelect}
                  />
                  <Select
                    title="ItemCode"
                    value={itemCodeSelected}
                    name="itemCodeSelected"
                    placeholder="Select a itemCode column"
                    options={columns}
                    onChange={this.handleSelect}
                  />
                  <Select
                    title="์Name"
                    value={nameSelected}
                    name="nameSelected"
                    placeholder="Select a name column"
                    options={columns}
                    onChange={this.handleSelect}
                  />
                  <Select
                    title="DisplayName"
                    value={displayNameSelected}
                    name="displayNameSelected"
                    placeholder="Select a displayName column"
                    options={columns}
                    onChange={this.handleSelect}
                  />
                  <Select
                    title="Category"
                    value={categorySelected}
                    name="categorySelected"
                    placeholder="Select a category column"
                    options={columns}
                    onChange={this.handleSelect}
                  />
                  <Select
                    title="Group"
                    value={groupSelected}
                    name="groupSelected"
                    placeholder="Select a group column"
                    options={columns}
                    onChange={this.handleSelect}
                  />
                  <Select
                    title="Unit"
                    value={unitSelected}
                    name="unitSelected"
                    placeholder="Select a unit column"
                    options={columns}
                    onChange={this.handleSelect}
                  />
                  <Select
                    title="Price"
                    value={priceSelected}
                    name="priceSelected"
                    placeholder="Select a price column"
                    options={columns}
                    onChange={this.handleSelect}
                  />
                </>
              )}
            </div>
          )}
        </Form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(ItemImport)));
