import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import HealthcareReport from "./HealthcareReport";
import TransactionInfo from "./TransactionInfo";

class Healthcare extends Component {
  render() {
    const url = this.props.match.url;

    return (
      <>
        <Route exact path={url} component={HealthcareReport} />
        <Route path={`${url}/transaction`} component={TransactionInfo} />
      </>
    );
  }
}

export default withRouter(Healthcare);
