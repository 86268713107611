import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import InventoryPreview from "./inventoryPreview";
import qs from "querystring";
import TabControl from "../../common/TabControl";
import Tab from "../../common/TabControl/Tab";

// Style
import "./style.scss";

class MachineDetail extends Component {
  constructor(props) {
    super(props);

    // Get id
    let params = qs.parse(this.props.location.search.replace("?", ""));

    this.state = {
      id: params.id,
      name: "",
      status: null,
      cashDevices: [],
      loading: true,
      tab: 0,
    };
  }

  componentDidMount() {
    let machineId = this.state.id;

    this.props.client
      .query({
        variables: {
          machineId,
        },
        query: gql`
          query getInv($machineId: String!) {
            machine(machineId: $machineId) {
              name
              cashDevices {
                name
              }
            }
            machineStatus(machineId: $machineId) {
              isOnline
              hasError
              receiptStatus
            }
          }
        `,
      })
      .then((response) => {
        let status = response.data.machineStatus;
        let machine = response.data.machine;
        let cashDevices = response.data.machine.cashDevices;

        this.setState({
          name: machine.name,
          status,
          cashDevices,
          loading: false,
        });
      })
      .catch((error) => {
        console.log("Error", error.message);
      });
  }

  displayInventory = () => {
    this.setState({ tab: 1 });
  };

  displayOverview = () => {
    this.setState({ tab: 0 });
  };
  goBack = (_) => {
    this.props.history.goBack();
  };

  render() {
    const { id, name, status } = this.state;

    const isOnline =
      status === null ? "unknown" : status.isOnline ? "online" : "offline";

    return (
      <div id="machine__detail">
        <button className="return" onClick={this.goBack}>
          Return
        </button>
        <div className="machine__info">
          <img
            height="28"
            src={process.env.PUBLIC_URL + "/assets/icons/kiosk-small.svg"}
            alt="machineType"
          />
          <div className="detail">
            <h1>
              {name}
              <span className={`machine__connectivity ${isOnline}`} />
            </h1>
            <span>{id}</span>
          </div>
          <div className="edit">
            <button className="main">Edit</button>
          </div>
        </div>
        <TabControl>
          <Tab title="Inventory">
            <InventoryPreview id={id} />
          </Tab>
          <Tab title="Overview"></Tab>
        </TabControl>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(MachineDetail)));
