import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { ExcelRenderer } from "react-excel-renderer";

//import { ApolloClient, InMemoryCache, HttpLink } from "apollo-boost";
import { gql } from "apollo-boost";
import { SubNavigation } from "../../subNavigation";
import { addNavigation, replaceNavigation, navigateTo } from "../../../actions";

class excels extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      loading: true,
      dataLoaded: false,
      rows: undefined,
      cols: undefined
    };
  }

  componentDidMount() {
    this.props.replaceNavigation({
      url: "excels",
      title: "SelfOrdering"
    });

    this.props.addNavigation({
      url: "excels",
      title: "excel"
    });

    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
            user(id: $userId) {
              name
            }
          }
        `
      })
      .then(result => {
        this.setState({
          loading: false,
          user: result.data.user.name
        });
      })
      .catch(error => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  fileHandler = event => {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({
          dataLoaded: true,
          cols: resp.cols,
          rows: resp.rows
        });
      }
    });
  };

  render() {
    //const { match } = this.props;
    /*
    const cache = new InMemoryCache();

    var client = new ApolloClient({
      cache,
      link: new HttpLink({
        // uri: "http://128.199.250.139/graphql"
        // uri: "http://localhost:4000"
        uri: process.env.REACT_APP_GRAPHQL_ADDR_SELFORDERING
      })
    });

    */

    return (
      <div>
        <SubNavigation />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addNavigation: payload => dispatch(addNavigation(payload)),
    replaceNavigation: payload => dispatch(replaceNavigation(payload)),
    navigateTo: payload => dispatch(navigateTo(payload))
  };
};

const mapStateToProps = state => {
  return {
    userAuth: state.userAuth
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(excels))
);
