import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";

class SubLink extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      toggled: this.props.toggled ? this.props.toggled : false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ toggled: !this.state.toggled });
  }

  render() {
    const { link, currentPath } = this.props;
    const { toggled } = this.state;

    let mainClassName = toggled ? "links toggled" : "links";

    let contentClassName = currentPath.includes("/reports/machines/")
      ? "link active"
      : "link";

    return (
      <div className={mainClassName} onClick={this.toggle}>
        <div className={contentClassName}>
          <div className="link__content">
            <svg height="16" width="16" viewBox="0 0 32 32">
              <rect className="cls-1" x="2" y="2" width="11" height="28" />
              <rect className="cls-1" x="19" y="2" width="11" height="14" />
              <rect className="cls-1" x="19" y="22" width="11" height="8" />
            </svg>
            <span>{link.title}</span>
            <svg
              className="dropDownArrow"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg"
              height="10px"
              width="10px"
            >
              <g
                id="icons/actions/arrowDown"
                strokeWidth="1"
                fill="#C3CDDD"
                fillRule="evenodd"
              >
                <path d="M4.98346367,5.58578196 L8.29466652,2.29112439 C8.68616771,1.90157945 9.3193307,1.90316529 9.70887564,2.29466648 C10.0984206,2.68616766 10.0968347,3.31933065 9.70533356,3.70887559 L5.68524617,7.70887557 C5.2937415,8.09842398 4.66057164,8.09683413 4.27102816,7.70532455 L0.291115581,3.70532457 C-0.0984244295,3.31381848 -0.096830602,2.68065551 0.294675494,2.2911155 C0.68618159,1.90157549 1.31934456,1.90316932 1.70888457,2.29467541 L4.98346367,5.58578196 Z"></path>
              </g>
            </svg>
          </div>
        </div>
        <div className="sublinks">
          {link.links.map((sublink, j) => {
            return (
              <NavLink
                key={j}
                className="sublink"
                activeClassName="active"
                to={sublink.pathname}
              >
                {sublink.title}
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  }
}

export default SubLink;
