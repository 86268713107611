import { gql } from "apollo-boost";
import React, { Component } from "react";
// import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      floats: undefined,
      loading: true,
    };
  }

  componentDidMount = () => {
    this.getFloats();
  };

  getFloats = () => {
    this.props.client
      .query({
        variables: { companyId: this.props.userAuth.companyId },
        query: gql`
          query getFloats($companyId: String!) {
            companyDispenseFloats(companyId: $companyId) {
              id
              name
              createdOn
              currency
              branch {
                id
                name
              }
              denominations {
                value
                pieces
                device
                include
              }
            }
          }
        `,
      })
      .then((response) => {
        if (response.data) {
          let floats = response.data.companyDispenseFloats;

          for (let float of floats) {
            let sum = float.denominations.reduce((prev, curr) => {
              return prev + curr.value * curr.pieces;
            }, 0);
            float.total = sum;
          }

          this.setState({
            floats,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createFloat = (e) => {
    this.props.history.push("/floats/create");
  };

  edit = (index) => {
    this.props.history.push("/floats/edit", {
      float: this.state.floats[index],
    });
  };

  delete = (index) => {
    var self = this;
    // * Get float to delete
    let float = this.state.floats[index];

    var proceed = window.confirm(
      `Are you sure you want to delete ${float.name}?`
    );
    if (!proceed) {
      // Proceed
      return;
    }

    this.props.client
      .mutate({
        variables: {
          dispenseFloatId: float.id,
        },
        mutation: gql`
          mutation update($dispenseFloatId: String!) {
            deleteDispenseFloat(dispenseFloatId: $dispenseFloatId)
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Deleted successfully");
          self.getFloats();
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  render() {
    const { loading, floats } = this.state;

    const stroke1Style = {
      fill: "none",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      stroke: "#2d9bf3",
      strokeWidth: "2",
    };

    const stroke2Style = {
      fill: "none",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      stroke: "#283e62",
      strokeWidth: "2",
    };

    return (
      <div>
        <div>
          <div className="greet">
            <div className="watermark__icon">
              <svg width="24" height="24">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path style={stroke1Style} d="M11.5,6A5.5,5.5,0,0,1,6,.5" />
                    <path
                      style={stroke1Style}
                      d="M11.5,6A5.5,5.5,0,0,0,17,.5"
                    />
                    <path style={stroke1Style} d="M.5,6A5.5,5.5,0,0,0,6,.5" />
                    <path
                      style={stroke1Style}
                      d="M17,.5A5.5,5.5,0,0,0,22.5,6"
                    />
                    <path
                      style={stroke2Style}
                      d="M11.5,19.75A5.5,5.5,0,0,1,6,14.25"
                    />
                    <path
                      style={stroke2Style}
                      d="M11.5,19.75a5.5,5.5,0,0,0,5.5-5.5"
                    />
                    <path
                      style={stroke2Style}
                      d="M.5,19.75A5.5,5.5,0,0,0,6,14.25"
                    />
                    <path
                      style={stroke2Style}
                      d="M17,14.25a5.5,5.5,0,0,0,5.5,5.5"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <h1>Welcome to Floats</h1>
            <button onClick={this.createFloat}>Create</button>
          </div>
        </div>
        <div>
          <div className="list">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Currency</th>
                  <th>Total</th>
                  <th>Branch</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="6">Loading</td>
                  </tr>
                ) : floats ? (
                  floats.map((f, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{f.name}</td>
                      <td>{f.currency}</td>
                      <td>{f.total}</td>
                      <td>{f.branch.name}</td>
                      <td>
                        <button
                          className="slim"
                          onClick={() => this.edit(index)}
                        >
                          Edit
                        </button>
                        <button
                          className="slim"
                          onClick={() => this.delete(index)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No dispense float found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps)(List));
