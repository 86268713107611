import React, { Component } from "react";
import { withApollo } from "react-apollo";

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grandTotal: this.props.grandTotal,
      received: this.props.received,
      changed: this.props.changed
    };
  }

  componentDidMount() {
    if (this.state.grandTotal && this.state.received && this.state.changed) {
      this.setState({
        grandTotal: this.currencyFormat(this.state.grandTotal),
        received: this.currencyFormat(this.state.received),
        changed: this.currencyFormat(this.state.changed)
      });
    }
  }

  currencyFormat(amount) {
    var format = new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
      minimumFractionDigits: 2
    });

    return format.format(amount);
  }

  render() {
    return (
      <>
        <div className="grand">
          <h4>{this.props.grandTotal}</h4>
          <h3>Grand sales total</h3>
        </div>
        <div className="detail">
          <h3>received</h3>
          <h4>{this.props.received}</h4>
        </div>
        <div className="detail">
          <h3>Changed</h3>
          <h4>{this.props.changed}</h4>
        </div>
      </>
    );
  }
}

export default withApollo(Summary);
