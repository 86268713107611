import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import Select from "../../common/Select";
import Form from "../../common/Form";
import { Header } from "../../common";

class GroupsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      categorySelected: undefined,
      groups: undefined,
      loading: true,
      companyId: this.props.userAuth.companyId,
    };
  }

  componentDidMount() {
    this.getCategories(this.props.userAuth.companyId);
  }

  getCategories = (companyId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { companyId },
        query: gql`
          query getCategories($companyId: String!) {
            categories(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let categories = result.data.categories.map((c) => {
            return {
              name: c.name,
              value: c.id,
            };
          });
          let categorySelected =
            categories.length === 1 ? categories[0].id : undefined;

          this.setState({ categories, categorySelected });
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
      });
  };

  getGroups = (categoryId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { categoryId },
        query: gql`
          query getGroups($categoryId: String!) {
            groups(categoryId: $categoryId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let groups = result.data.groups.map((c) => {
            return {
              name: c.name,
              id: c.id,
            };
          });
          let groupSelected = groups.length === 1 ? groups[0].id : undefined;

          this.setState({ groups, groupSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value }, () => this.getGroups(e.value));
  };

  createGroup = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  editGroup = (unitId) => {
    this.props.history.push(`${this.props.location.pathname}/edit/${unitId}`);
  };

  deleteGroup = (unitId) => {
    let confirmation = window.confirm("Confirm deletion?");

    if (!confirmation) {
      return;
    }

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id: +unitId,
        },
        mutation: gql`
          mutation deleteGroup($id: Int!) {
            deleteGroup(id: $id)
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Deleted group successfully!");

          this.getGroups(this.state.categorySelected);
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to delete group");
      });
  };

  render() {
    const { categories, categorySelected, groups } = this.state;

    let groupRender = groups ? (
      groups.map((g, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{g.name}</td>
          <td>
            <button className="slim" onClick={() => this.editGroup(g.id)}>
              Edit
            </button>
            <button className="slim" onClick={() => this.deleteGroup(g.id)}>
              Delete
            </button>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="100%">No groups have been added</td>
      </tr>
    );

    return (
      <>
        <Header title="Groups">
          <button className="slim" onClick={this.createGroup}>
            Create
          </button>
        </Header>
        <Form>
          <Header title="Filter" level="4" />
          <Select
            title="Category"
            value={categorySelected}
            name="categorySelected"
            placeholder="Select a category."
            options={categories}
            onChange={this.handleSelect}
          />
        </Form>
        <div>
          <table className="group__table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>{groupRender}</tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(GroupsList)));
