import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import { Input, Form, Header } from "../../common";

class ShopCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      originalName: "",
      name: "",
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) {
      return;
    }

    // * Get shop info

    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id,
        },
        query: gql`
          query shopInfo($id: String!) {
            shop(ID: $id) {
              name
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          return;
        }

        let { shop } = result.data;

        this.setState({
          loading: false,
          originalName: shop.name,
          name: shop.name,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  onSave = () => {
    const { id } = this.props.match.params;
    const { name } = this.state;

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id,
          name,
        },
        mutation: gql`
          mutation updateShop($id: String!, $name: String!) {
            updateShop(id: $id, name: $name) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        //console.log(error.message);
        alert("Unable to update shop.");
      });
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  render() {
    const { originalName, name, loading } = this.state;

    return (
      <div className="shop__create">
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title={`Editing ${originalName}`} level={3}>
          <button className="slim" onClick={this.onSave}>
            Save
          </button>
        </Header>
        <Form loading={loading}>
          <h4>Basic Information</h4>
          <Input
            title="Name"
            name="name"
            placeholder=""
            onChange={this.handleInputs}
            value={name}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(ShopCreate)));
