import React from "react";
import APITable from "../../APITable";

const getDetailedInventory = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let resp = [
    {
      name: "Inventory",
      desc: "Machine’s inventory in detail",
      type: " ",
      el: [
        {
          name: "Notes",
          desc: "Notes inventory",
          type: "DenominationType[]",
        },
        {
          name: "Coins",
          desc: "Coins inventory",
          type: "DenominationType[]",
        },
        {
          name: "Mix",
          desc: "Mix inventory",
          type: "DenominationType[]",
        },
        {
          name: "Cassette",
          desc: "Notes Collection Cassette inventory",
          type: "DenominationType[]",
        },
        {
          name: "Overflow",
          desc: "Coins Overflow Box inventory",
          type: "DenominationType[]",
        },
        {
          name: "RejectBox1",
          desc: "Main reject box",
          type: "DenominationType[]",
        },
        {
          name: "RejectBox2",
          desc: "Secondary reject box",
          type: "DenominationType[]",
        },
      ],
    },
  ];

  return (
    <APITable
      title="Get Detailed Inventory"
      desc="Retrieves the detailed inventory of the machine"
      command="GetDetailedInventory"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      resp={resp}
    />
  );
};

export default getDetailedInventory;
