import React, { Component } from "react";
import { Form, Header } from "../../../common";

export default class CompletedQueue extends Component {
  updateQueueStatus = (id, status) => {
    if (this.props.updateQueueStatus) this.props.updateQueueStatus(id, status);
  };
  render() {
    let { detail } = this.props;

    if (!detail) {
      return null;
    }

    return (
      <div key={detail.id} className="queue__item">
        <Form>
          <Header title={detail.queueNumber} level="3">
            <button
              className="slim"
              onClick={() =>
                this.updateQueueStatus(detail.id, +detail.status + 1)
              }
            >
              {detail.status === -1 ? "Complete" : "Received"}
            </button>
          </Header>
        </Form>
      </div>
    );
  }
}
