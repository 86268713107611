import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { gql } from "apollo-boost";
import { connect } from "react-redux";
import Header from "../../common/Header";
import Form from "../../common/Form";
import { Input } from "../../common";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      name: "",
      roles: [],
    };
  }

  componentDidMount() {
    // Get roles available for this company
    this.props.client
      .query({
        variables: { companyId: this.props.userAuth.companyId },
        query: gql`
          query getRoles($companyId: String!) {
            roles(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let roles = result.data.roles.map((r) => {
            return { used: false, info: r };
          });

          this.setState({ roles });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  create = () => {
    let { name, roles } = this.state;
    let { userAuth } = this.props;

    if (!name) {
      alert("Please enter a Name");
      return;
    }

    // Get roles selected
    roles = roles.filter((r) => r.used).map((r) => r.info.id);

    if (roles.length === 0) {
      alert("Please select at least one role");
      return;
    }

    const groupDetail = {
      name: name,
      companyId: userAuth.companyId,
      createdBy: userAuth.userId,
    };

    console.log(groupDetail);
    console.log(roles);

    this.props.client
      .mutate({
        variables: {
          groupDetail,
          roles,
        },
        mutation: gql`
          mutation createGroup($groupDetail: GroupInput!, $roles: [String!]!) {
            createGroup(groupDetail: $groupDetail, roles: $roles) {
              id
            }
          }
        `,
      })
      .then((response) => {
        if (response.data) {
          // Success
          alert("Success");

          this.props.history.goBack();
        } else {
          alert(
            "Unable to create new Group, can be caused by duplicate group name"
          );
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  handleInput = (e) => {
    if (e.target.type === "checkbox") {
      let index = Number(e.target.name);

      let roles = this.state.roles;
      roles[index].used = e.target.checked;

      this.setState({ roles });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleInputs = (e) => {
    this.setState({ [e.name]: e.value }, this.onChange);
  };

  clearFilter = () => {
    this.setState({ filter: "" });
  };

  render() {
    let { name, roles, filter } = this.state;

    roles = roles.filter((r) =>
      r.info.name.toLowerCase().trim().includes(filter.trim())
    );

    return (
      <div>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title="Create new group">
          <button className="slim" onClick={this.create}>
            Create
          </button>
        </Header>
        <Form>
          <Input
            title="Name"
            name="name"
            placeholder="Enter group name"
            onChange={this.handleInputs}
            value={name}
            error={""}
          />
          <Header title="Roles" level="3">
            <label>Filter</label>
            <input
              name="filter"
              type="text"
              placeholder="Role name to filter"
              onChange={this.handleInput}
              value={filter}
            />
            <button className="slim" onClick={this.clearFilter}>
              Clear
            </button>
          </Header>
          <div className="c-roleSelection">
            {roles.map((r, index) => (
              <Fragment key={index}>
                <label className="c-roleSelection__role">
                  <input
                    className="c-roleSelection__roleName"
                    type="checkbox"
                    name={index}
                    checked={r.used}
                    onChange={this.handleInput}
                  />
                  {r.info.name}
                </label>
              </Fragment>
            ))}
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(Create)));
