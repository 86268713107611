import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";
// import qs from "querystring";

class LineCallback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    var error = this.getParameterByName("error");

    if (error) {
      var errorMessage = this.getParameterByName("error_description");
      alert(errorMessage);
      return;
    }

    var code = this.getParameterByName("code");
    var state = this.getParameterByName("state");

    console.log("code", code);
    console.log("state", state);

    // Check if state equals current user Id
    if (state !== this.props.userAuth.userId) {
      // TODO: Doesn't match
      return;
    }

    // Match
    // Register to the gateway amd redirect back to user profile

    this.props.client
      .mutate({
        variables: {
          userId: this.props.userAuth.userId,
          code
        },
        mutation: gql`
          mutation register($userId: String!, $code: String!) {
            registerNotificaitons(userId: $userId, code: $code) {
              id
            }
          }
        `
      })
      .then(result => {
        if (result.data) {
          alert("success");
        } else {
          alert("Failed to register");
        }

        this.setState({ loading: false });
      })
      .catch(err => {});
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  render() {
    console.log(this.props);

    if (this.state.loading) {
      return <div>Please wait...</div>;
    }

    return <Redirect to="/profile" />;
  }
}

const mapStateToProps = state => {
  return {
    userAuth: state.userAuth
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(LineCallback)));
