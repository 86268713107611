import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import { Input, Form, Select, Header } from "../../common";

class ShopCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branches: [],
      branchSelected: undefined,
      name: "",
    };
  }
  componentDidMount() {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        //console.log(result.data);

        let branches = result.data.branches.map((b) => {
          return {
            name: b.name,
            value: b.id,
          };
        });

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState({
          branches: branches,
          branchSelected,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  onCreate = () => {
    const { branchSelected, name } = this.state;

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          shop: {
            branchId: branchSelected,
            name,
            createdBy: this.props.userAuth.userId,
          },
        },
        mutation: gql`
          mutation createShop($shop: ShopInput!) {
            createShop(shop: $shop) {
              id
              branchId
              name
              createdOn
              createdBy
            }
          }
        `,
      })
      .then((result) => {
        if (result.errors) {
          for (const error of result.errors) {
            alert(error.message);
          }

          return;
        }

        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        //console.log(error.message);
        alert(`Unable to create shop: ${error.message}`);
      });
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value });
  };

  render() {
    const { branches, branchSelected, name } = this.state;

    return (
      <div className="shop__create">
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title="Add new shop">
          <button className="slim" onClick={this.onCreate}>
            Create
          </button>
        </Header>
        <Form>
          <h4>Basic Information</h4>
          <Select
            title="Branch"
            value={branchSelected}
            name="branchSelected"
            placeholder="Select a branch."
            options={branches}
            onChange={this.handleSelect}
          />
          <Input
            title="Name"
            name="name"
            placeholder="Enter name of shop."
            onChange={this.handleInputs}
            value={name}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(ShopCreate)));
