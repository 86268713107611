import React, { Component } from "react";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import Select from "./Select";

class WatermarkSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      watermarks: [],
      watermark: undefined,
    };
  }

  componentDidMount = () => {
    if (!this.props.branchId || !this.props.bridgeNodeId) {
      return;
    }

    if (this.props.branchId) {
      this.getWatermarks(this.props.branchId);
    } else {
      this.getWatermarksByBridgeId(this.props.bridgeNodeId);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    let oldProps = prevProps;
    let currentProps = this.props;

    if (oldProps !== currentProps) {
      console.log(currentProps);

      this.setState({ watermark: currentProps.value }, () => {
        if (currentProps.branchId) {
          this.getWatermarks(currentProps.branchId);
        } else {
          this.getWatermarksByBridgeId(currentProps.bridgeNodeId);
        }
      });
    }
  }

  /** Method to retrieve current watermarks for the branch provided */
  getWatermarks = (branchId) => {
    console.log("Retrieving new watermarks");

    this.props.client
      .query({
        variables: {
          branchId,
        },
        query: gql`
          query getWatermarks($branchId: String) {
            watermarks(branchId: $branchId) {
              id
              name
              currency
            }
          }
        `,
      })
      .then(({ data }) => {
        let watermarks = data.watermarks;

        this.setState({ watermarks });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  /** Method to retrieve current watermarks for the branch provided */
  getWatermarksByBridgeId = (bridgeNodeId) => {
    console.log("Retrieving new watermarks by bridge");

    this.props.client
      .query({
        variables: {
          bridgeNodeId,
        },
        query: gql`
          query getWatermarks($bridgeNodeId: String) {
            watermarks(bridgeNodeId: $bridgeNodeId) {
              id
              name
              currency
            }
          }
        `,
      })
      .then(({ data }) => {
        if (!data) {
          return;
        }

        let watermarks = data.watermarks;

        this.setState({ watermarks });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleInput = (event) => {
    this.setState({ [event.name]: event.value });

    if (this.props.onChange) {
      this.props.onChange(event.value);
    }
  };

  render() {
    const { watermark, watermarks } = this.state;
    return (
      <>
        <Select
          value={watermark}
          name="watermark"
          placeholder="Select watermark"
          options={watermarks.map((b) => {
            b.value = b.id;
            return b;
          })}
          onChange={this.handleInput}
        />
      </>
    );
  }
}

export default withApollo(WatermarkSelector);
