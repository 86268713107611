import React, { Component } from "react";
import Policies from "./Policies";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import Input from "../../common/Input";
import Form from "../../common/Form";

class Create extends Component {
  constructor(props) {
    super(props);

    let searchParams = new URLSearchParams(props.location.search);

    let roleId = searchParams.get("id");

    this.state = {
      roleId,
      name: "",
      statements: [],
      loading: true,
      companyResources: undefined,
      currentData: undefined,
    };
  }

  componentDidMount() {
    let { roleId } = this.state;

    if (!roleId) {
      this.props.history.goBack();
    }

    // Get available resources and permissions
    this.props.client
      .query({
        variables: { companyId: this.props.userAuth.companyId, roleId },
        query: gql`
          query getData($companyId: String!, $roleId: String!) {
            companyResources(companyId: $companyId) {
              id
              name
              permissions {
                id
                description
                action
                resourceId
              }
            }
            role(roleId: $roleId) {
              id
              name
              policy {
                id
                permissions {
                  id
                  value
                  resourceId
                }
              }
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          return;
        }

        let { role, companyResources } = result.data;

        this.setState({
          loading: false,
          currentData: role,
          name: role.name,
          companyResources,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  policyChanged = (statements) => {
    this.setState({ statements });
  };

  save = () => {
    const { roleId, name, statements } = this.state;

    if (!name) {
      alert("Please enter a name!");
      return;
    }

    if (!statements || statements.length === 0) {
      alert("Please select at least one Statement");
      return;
    }

    let permissions = statements.reduce((prev, curr) => {
      return prev.concat(...curr.permissions);
    }, []);

    if (!permissions || permissions.length === 0) {
      alert("Please select Permissions");
      return;
    }

    let permissionIds = permissions.map((p) => +p);

    this.props.client
      .mutate({
        variables: {
          id: roleId,
          name: name,
          permissions: permissionIds,
        },
        mutation: gql`
          mutation createRole(
            $id: String!
            $name: String!
            $permissions: [Int!]!
          ) {
            updateRole(id: $id, name: $name, permissions: $permissions) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("success");
          this.props.history.goBack();
        } else {
          alert(result.errors.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  render() {
    const { name, companyResources, currentData } = this.state;

    return (
      <div id="roles__create">
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <div className="header">
          <h1>Edit role</h1>
          <div>
            <button className="main slim" onClick={this.save}>
              Save
            </button>
          </div>
        </div>
        <Form loading={this.state.loading}>
          <h4>Basic information</h4>
          <Input
            title="Name"
            name="name"
            placeholder="Enter role name"
            onChange={this.handleInputs}
            value={name}
          />
          <h4>Policies</h4>
          <Policies
            statements={currentData?.policy}
            companyResources={companyResources}
            policyChanged={this.policyChanged}
          />
        </Form>
        {/* <img alt="" src={asdasd} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(Create)));
