import { gql } from "apollo-boost";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Form, Header, Select } from "../common";

let availHours = [
  { name: "0", value: "00" },
  { name: "1", value: "01" },
  { name: "2", value: "02" },
  { name: "3", value: "03" },
  { name: "4", value: "04" },
  { name: "5", value: "05" },
  { name: "6", value: "06" },
  { name: "7", value: "07" },
  { name: "8", value: "08" },
  { name: "9", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
  { name: "13", value: "13" },
  { name: "14", value: "14" },
  { name: "15", value: "15" },
  { name: "16", value: "16" },
  { name: "17", value: "17" },
  { name: "18", value: "18" },
  { name: "19", value: "19" },
  { name: "20", value: "20" },
  { name: "21", value: "21" },
  { name: "22", value: "22" },
  { name: "23", value: "23" },
];

let availMins = [
  { name: "0", value: "00" },
  { name: "15", value: "15" },
  { name: "30", value: "30" },
  { name: "45", value: "45" },
];

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      branches: undefined,
      branchSelected: undefined,
      hours: "00",
      minutes: "00",
    };
  }

  componentDidMount() {
    this.getSchedules();
  }

  getSchedules = () => {
    this.props.client
      .query({
        variables: {
          companyId: this.props.userAuth.companyId || "",
          userId: this.props.userAuth.userId || "",
        },
        query: gql`
          query get($companyId: String!, $userId: String!) {
            branches(userId: $userId) {
              id
              name
            }
            taskSchedules(companyId: $companyId) {
              id
              cutOffTime
              branch {
                id
                name
              }
            }
          }
        `,
      })
      .then(({ data }) => {
        if (!data) {
          return;
        }

        let { branches, taskSchedules } = data;

        let existingBranchesWithTask = taskSchedules
          .map((ts) => ts.branch)
          .map((tsb) => tsb.id);

        let tmp = branches.filter(
          (b) => !existingBranchesWithTask.includes(b.id)
        );

        this.setState({
          branches: tmp.map((b) => {
            b.value = b.id;
            return b;
          }),
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false, taskSchedules: [] });
      });
  };

  handleInputs = (event) => {
    this.setState({ [event.name]: event.value });
  };

  create = () => {
    const { branches, branchSelected, hours, minutes } = this.state;

    if (!branchSelected) {
      alert("Please select a branch");
      return;
    }

    let cutOffTime = `${hours}:${minutes}`;

    if (
      !window.confirm(
        `Are you sure you want to create auto End of Day at ${cutOffTime} for ${
          branches.filter((b) => b.id === branchSelected)[0].name
        }?`
      )
    ) {
      return;
    }

    this.props.client
      .mutate({
        variables: {
          branchId: branchSelected,
          cutOffTime,
        },
        mutation: gql`
          mutation get($cutOffTime: String!, $branchId: String) {
            createTaskSchedule(
              taskScheduleDetail: {
                cutOffTime: $cutOffTime
                branchId: $branchId
              }
            )
          }
        `,
      })
      .then(({ data }) => {
        if (!data) {
          return;
        }

        if (!data) {
          alert("Unable to create task schedule!");
          return;
        }

        alert("Success");
        this.props.history.push("/schedules");
      })
      .catch((error) => {
        console.error(error);
        alert("Failed");
      });
  };

  render() {
    const { branches, branchSelected, hours, minutes } = this.state;
    return (
      <>
        <button className="return" onClick={this.create}>
          Return
        </button>
        <Header title="New auto End of Day">
          <button className="slim" onClick={this.create}>
            Create
          </button>
        </Header>
        <Form>
          <Select
            title="Branch"
            value={branchSelected}
            name="branchSelected"
            placeholder="Select a branch"
            options={branches || []}
            onChange={this.handleInputs}
          />
          <Select
            title="Hours"
            value={hours}
            name="hours"
            placeholder="Select hours"
            options={availHours}
            onChange={this.handleInputs}
          />
          <Select
            title="Minutes"
            value={minutes}
            name="minutes"
            placeholder="Select minutes"
            options={availMins}
            onChange={this.handleInputs}
          />
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps)(Create));
