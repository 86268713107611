import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import Create from "./Create";
import List from "./List";
import { connect } from "react-redux";
import Edit from "./Edit";

class Group extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { match } = this.props;

    return (
      <div>
        <div>
          <Route exact path={match.url} component={List} />
          <Route
            exact
            path={`${match.url}/create`}
            render={(props) => (
              <Create {...props} userAuth={this.props.userAuth} />
            )}
          />
          <Route
            path={`${match.url}/edit`}
            render={(props) => (
              <Edit {...props} userAuth={this.props.userAuth} />
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(connect(mapStateToProps)(Group));
