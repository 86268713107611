import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import Create from "./Create";
import List from "./List";
import Edit from "./Edit";

class Schedules extends Component {
  render() {
    const { match } = this.props;
    return (
      <>
        <Route
          path={`${match.url}/create`}
          render={(props) => <Create {...props} returnPath={match.url} />}
        />
        <Route
          path={`${match.url}/edit`}
          render={(props) => <Edit {...props} returnPath={match.url} />}
        />
        <Route exact path={match.url} component={List} />
      </>
    );
  }
}

export default withRouter(Schedules);
