import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import PromotionsList from "./PromotionsList";
import PromotionsCreate from "./Create";
import PromotionsEdit from "./Edit";
import { addNavigation, replaceNavigation, navigateTo } from "../../../actions";

import "./style.scss";

class promotions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      loading: true,
    };
  }

  render() {
    const { match } = this.props;

    return (
      <div className="promotions">
        <Route exact path={match.url} component={PromotionsList} />
        <Route
          exact
          path={`${match.url}/create`}
          component={PromotionsCreate}
        />
        <Route
          exact
          path={`${match.url}/edit/:id`}
          component={PromotionsEdit}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNavigation: (payload) => dispatch(addNavigation(payload)),
    replaceNavigation: (payload) => dispatch(replaceNavigation(payload)),
    navigateTo: (payload) => dispatch(navigateTo(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(promotions))
);
