import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import NotFound from "./components/NotFound";

export const PrivateRoute = props => {
  const { isAuth, isGlobalAdmin } = useSelector(state => state.userAuth);

  const { path, component: Component } = props;

  let routeRendered = isAuth ? (
    !isGlobalAdmin ? (
      <Component />
    ) : (
      <NotFound />
    )
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: path }
      }}
    />
  );

  return <Route path={path}>{routeRendered}</Route>;
};

export const GlobalAdminRoute = props => {
  const { isAuth, isGlobalAdmin } = useSelector(state => state.userAuth);

  console.log("private", props);

  const { path, component: Component } = props;

  let routeRendered = isAuth ? (
    isGlobalAdmin ? (
      <Component />
    ) : (
      <NotFound />
    )
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: path }
      }}
    />
  );

  return <Route path={path}>{routeRendered}</Route>;
};
