import { gql } from "apollo-boost";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Form, Header } from "../common";

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      taskSchedules: undefined,
    };
  }

  componentDidMount() {
    this.getSchedules();
  }

  getSchedules = () => {
    this.props.client
      .query({
        variables: {
          companyId: this.props.userAuth.companyId || "",
        },
        query: gql`
          query get($companyId: String!) {
            taskSchedules(companyId: $companyId) {
              id
              cutOffTime
              branch {
                id
                name
              }
            }
          }
        `,
      })
      .then(({ data }) => {
        if (!data) {
          return;
        }

        let { taskSchedules } = data;

        this.setState({
          loading: false,
          taskSchedules: taskSchedules.map((ts) => {
            // * Add 7 hrs to cutOffTime
            let tmp = ts.cutOffTime.split(":");
            let hrs = +tmp[0] + 7;

            hrs = hrs >= 24 ? hrs - 24 : hrs;

            ts.cutOffTime = `${hrs}:${tmp[1]}`;

            return ts;
          }),
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false, taskSchedules: [] });
      });
  };

  create = () => {
    const { match } = this.props;
    this.props.history.push(`${match.url}/create`);
  };

  edit = (task) => {
    const { match } = this.props;
    this.props.history.push(`${match.url}/edit?id=${task.id}`);
  };

  delete = (task) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${task.branch.name} task?`
      )
    ) {
      return;
    }

    let taskId = task.id;

    this.props.client
      .mutate({
        variables: {
          taskScheduleId: +taskId,
        },
        mutation: gql`
          mutation get($taskScheduleId: Int!) {
            deleteTaskSchedule(taskScheduleId: $taskScheduleId)
          }
        `,
      })
      .then(({ data }) => {
        if (!data) {
          return;
        }

        if (data.deleteTaskSchedule) {
          this.getSchedules();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const { loading, taskSchedules } = this.state;
    return (
      <>
        <Header title="Task Schedules" />
        <Form loading={loading}>
          <Header title="Auto End of Day" level={3}>
          <button className="slim" onClick={this.create}>
              EOD Now
            </button>
            <button className="slim" onClick={this.create}>
              Create
            </button>
          </Header>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Branch</th>
                <th>Cut-off time</th>
              </tr>
            </thead>
            <tbody>
              {taskSchedules &&
                taskSchedules.map((ts, index) => (
                  <tr key={ts.id}>
                    <td>{index + 1}</td>
                    <td>{ts.branch ? ts.branch.name : "-"}</td>
                    <td>{ts.cutOffTime}</td>
                    <td>
                      <button className="slim" onClick={() => this.edit(ts)}>
                        Edit
                      </button>
                    </td>
                    <td>
                      <button className="slim" onClick={() => this.delete(ts)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps)(List));
