import React from "react";
import APITable from "../../APITable";

const collect = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [
    {
      name: "Option",
      desc:
        "Specify whether to define your own waterlevel to be used (1 -> Define yourself, 2 -> Use system's watermark level)",
      type: "string",
    },
    {
      name: "Denominations",
      desc: "Specify denominations to be left inside the machine",
      tags: ["optional"],
      type: "DenominationType[]",
    },
  ];
  let resp = [
    {
      name: "Collected",
      desc: "Denominations that were collected",
      type: "DenominationType[]",
      el: [
        {
          name: "Denominations",
          desc: "Recycling denominations that were collected",
          type: "DenominationType[]",
        },
        {
          name: "Mix",
          desc: "Mix denominations that were collected",
          type: "DenominationType[]",
        },
        {
          name: "IFCassette",
          desc:
            "Denomination collected that were inside the Interface cassette",
          type: "DenominationType[]",
        },
      ],
    },
  ];

  return (
    <APITable
      title="Partial Collection"
      desc="Transports cash from the recycling stackers to the collection cassettes/box, but leave a certain amount of denomination that were specified"
      command="PartialCollect"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default collect;
