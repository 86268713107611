import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { connect } from "react-redux";

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: undefined,
      importFile: null,
    };
  }

  componentDidMount() {
    let { userAuth } = this.props;
    this.props.client
      .query({
        variables: {
          companyId: this.props.userAuth.companyId,
        },
        query: gql`
          query getUsers($companyId: String!) {
            users(companyId: $companyId) {
              id
              name
              isActive
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        if (result.data) {
          // * Check permissions
          let canUpdate = false;

          if (userAuth.authorityLevel === 0) {
            canUpdate = true;
          } else {
            let hasPermission = userAuth.permissions.filter(
              (p) => p.id === "2002"
            );

            canUpdate = hasPermission.length > 0;
          }

          console.log(userAuth);

          let userSort = result.data.users;
          
          userSort.sort(function(x,y){
            let a = x.name.toUpperCase(),
                b = y.name.toUpperCase();
                return a == b ? 0 : a > b ? 1 : -1;
          });

          this.setState({ users: userSort, canUpdate });
        }
      })
      .catch((error) => {
        alert(error.message);
      });

    // axios.get("/api/user/getUsers").then(response => {
    //   this.setState({ users: response.data });
    // });
  }

  createUser = () => {
    this.props.history.push(`${this.props.match.url}/create`);
  };

  editUser = (id) => {
    this.props.history.push(`${this.props.match.url}/edit?id=${id}`);
  };

  handleChange = (selectorFiles) => {
    this.setState({ importFile: selectorFiles[0] }, () =>
      console.log(this.state)
    );
  };

  importUsers = () => {
    const formData = new FormData();
    formData.append("file", this.state.importFile);
    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data"
    //   }
    // };
  };

  render() {
    const { users, canUpdate } = this.state;

    return (
      <div className="user__list">
        <h1>Users</h1>
        <button onClick={this.createUser}>Create new user</button>
        <br />
        <label>Import Users</label>
        <input
          type="file"
          name="fileToUpload"
          id="fileToUpload"
          onChange={(e) => this.handleChange(e.target.files)}
        />
        <br />
        <button
          onClick={this.importUsers}
          disabled={this.state.importFile ? false : true}
        >
          Import
        </button>
        <div className="section">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, index) => (
                  <tr key={`user${index}`}>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>{user.isActive ? "Active" : "Unactive"}</td>
                    <td>
                      {canUpdate && (
                        <button onClick={() => this.editUser(user.id)}>
                          edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(UsersList)));
