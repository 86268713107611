import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { gql } from "apollo-boost";
import { connect } from "react-redux";
import Header from "../../common/Header";
import Form from "../../common/Form";
import { Input } from "../../common";

class Edit extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(props.location.search);
    const groupId = urlParams.get("id");

    this.state = {
      groupId,
      filter: "",
      name: "",
      roles: [],
      userAuth: props.userAuth,
      hasEdits: false,
    };
  }

  componentDidMount() {
    const { userAuth, groupId } = this.state;

    if (!groupId) {
      this.props.history.goBack();
    }

    // Get roles available for this company
    this.props.client
      .query({
        variables: { companyId: userAuth.companyId, groupId: groupId },
        query: gql`
          query getInfo($companyId: String!, $groupId: String!) {
            group(groupId: $groupId) {
              id
              name
              roles {
                id
              }
            }
            roles(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let { roles, group } = result.data;

          let rolesProcessed = roles.map((r) => {
            return {
              used:
                group.roles.filter((role) => role.id === r.id).length === 0
                  ? false
                  : true,
              info: r,
            };
          });

          this.setState({ roles: rolesProcessed, name: group.name });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  edit = () => {
    let { groupId, name, roles } = this.state;

    if (!name) {
      alert("Please enter a Name");
      return;
    }

    // Get roles selected
    roles = roles.filter((r) => r.used).map((r) => r.info.id);

    if (roles.length === 0) {
      alert("Please select at least one role");
      return;
    }

    this.props.client
      .mutate({
        variables: {
          id: groupId,
          name,
          roles,
        },
        mutation: gql`
          mutation createGroup(
            $id: String!
            $name: String!
            $roles: [String!]!
          ) {
            updateGroup(id: $id, name: $name, roles: $roles) {
              id
            }
          }
        `,
      })
      .then((response) => {
        if (response.data) {
          // Success
          alert("Update success");

          this.props.history.goBack();
        } else {
          alert(response.error);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  handleInput = (e) => {
    if (e.target.type === "checkbox") {
      let index = Number(e.target.name);

      let roles = this.state.roles;
      roles[index].used = e.target.checked;

      this.setState({ roles, hasEdits: true });
    } else {
      this.setState({ [e.target.name]: e.target.value, hasEdits: true });
    }
  };

  handleInputs = (e) => {
    this.setState({ [e.name]: e.value, hasEdits: true });
  };

  clearFilter = () => {
    this.setState({ filter: "" });
  };

  render() {
    let { name, roles, filter, hasEdits } = this.state;

    roles = roles.filter((r) =>
      r.info.name.toLowerCase().trim().includes(filter.trim())
    );

    return (
      <div>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title="Create new group">
          <button className="slim" onClick={this.edit} disabled={!hasEdits}>
            Save
          </button>
        </Header>
        <Form>
          <Input
            title="Name"
            name="name"
            placeholder="Enter group name"
            onChange={this.handleInputs}
            value={name}
            error={""}
          />
          <Header title="Roles" level="3">
            <label>Filter</label>
            <input
              name="filter"
              type="text"
              placeholder="Role name to filter"
              onChange={this.handleInput}
              value={filter}
            />
            <button className="slim" onClick={this.clearFilter}>
              Clear
            </button>
          </Header>
          <div className="c-roleSelection">
            {roles.map((r, index) => (
              <Fragment key={index}>
                <label className="c-roleSelection__role">
                  <input
                    className="c-roleSelection__roleName"
                    type="checkbox"
                    name={index}
                    checked={r.used}
                    onChange={this.handleInput}
                  />
                  {r.info.name}
                </label>
              </Fragment>
            ))}
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(Edit)));
