export const userAuthorized = (payload) => {
  return {
    type: "AUTHORIZED",
    payload,
  };
};

export const userSignedOut = () => {
  return {
    type: "SIGNED_OUT",
  };
};

export const addNavigation = (payload) => {
  return {
    type: "ADD",
    payload,
  };
};

export const navigateTo = (payload) => {
  return {
    type: "NAVTO",
    payload,
  };
};

export const replaceNavigation = (payload) => {
  return {
    type: "REPLACE",
    payload,
  };
};

export const setHeader = (payload) => {
  return {
    type: "SET",
    payload,
  };
};

export const toggleNav = () => {
  return {
    type: "TOGGLENAV",
  };
};

export const setApolloClient = (payload) => {
  return {
    type: "SETAPOLLOCLIENT",
    payload,
  };
};

export const addAlert = (payload) => {
  return {
    type: "ADD_ALERT",
    payload,
  };
};

export const removeAlert = (payload) => {
  return {
    type: "REMOVE_ALERT",
    payload,
  };
};
