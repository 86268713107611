import React, { Component } from "react";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import Select from "./Select";

class LocationSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branch: undefined,
      branches: [],
      bridge: undefined,
      bridges: [],
      mode: props.mode,
      preSelect: props.preSelect
    };
  }

  getCompanyOfBusinessUnit = async businessUnitId => {
    var response = await this.props.client.query({
      variables: {
        businessUnitId: businessUnitId
      },
      query: gql`
        query companyOfBusinessUnit($businessUnitId: String!) {
          companyOfBusinessUnit(businessUnitId: $businessUnitId) {
            _id
          }
        }
      `
    });

    return response.data.companyOfBusinessUnit._id;
  };

  getBusinessUnitOfBranch = async branchId => {
    var response = await this.props.client.query({
      variables: {
        branchId: branchId
      },
      query: gql`
        query getBusinessUnit($branchId: String!) {
          businessUnitOfBranch(branchId: $branchId) {
            _id
          }
        }
      `
    });

    return response.data.businessUnitOfBranch._id;
  };

  componentDidMount() {
    // Get companies
    this.props.client
      .query({
        variables: {
          companyId: this.props.userAuth.companyId
        },
        query: gql`
          query getBranches($companyId: String!) {
            company(id: $companyId) {
              branches {
                id
                code
                name
                currency
              }
            }
          }
        `
      })
      .then(result => {
        let branches = result.data.company.branches;

        this.setState({ branches });
      })
      .catch(err => {
        console.log(err);
      });
  }

  preSelectUnits = async () => {
    const { preSelect, preSelectMode } = this.props;

    const { mode } = this.state;

    if (preSelect) {
      console.log("Performing preslection");

      let modeToPreSelect = preSelectMode ? preSelectMode : mode;

      if (modeToPreSelect === "branch") {
        // Get business unit
        var businessUnitId = await this.getBusinessUnitOfBranch(preSelect);

        // Get company selected
        let companyId = await this.getCompanyOfBusinessUnit(businessUnitId);

        await this.companySelected(companyId);
        await this.businessUnitSelected(businessUnitId);
        await this.branchSelected(preSelect);
      } else if (modeToPreSelect === "bu") {
        // Get company selected
        let companyId = await this.getCompanyOfBusinessUnit(preSelect);

        await this.companySelected(companyId);
        await this.businessUnitSelected(businessUnitId);
      } else if (modeToPreSelect === "company") {
        await this.companySelected(preSelect);
      }

      this.setState({ preSelect });
    }
  };

  componentDidUpdate = () => {
    try {
      if (this.props.mode && this.state.mode !== this.props.mode) {
        this.setState({ mode: this.props.mode });
      } else if (this.props.preSelect !== this.state.preSelect) {
        this.setState({ preSelect: this.props.preSelect }, () => {
          this.preSelectUnits();
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  companySelected = async event => {
    var companyId = event;

    if (event.target) {
      companyId = event.target.value;
    }

    // Filter out company selected
    let companySelected = this.state.companies.filter(
      c => c._id === companyId
    )[0];

    let businessUnits = companySelected.businessUnits.map(value => {
      return {
        id: value._id,
        name: value.name
      };
    });

    this.setState({ company: companyId, businessUnits });

    if (this.props.companySelected) {
      this.props.companySelected(companySelected);
    }

    return true;
  };

  businessUnitSelected = async event => {
    // Get Branches associated with the selected business unit
    var businessUnit = event;

    if (event.target) {
      businessUnit = event.target.value;
    }
    try {
      let result = await this.props.client.query({
        variables: {
          businessUnitId: businessUnit
        },
        query: gql`
          query getBranches($businessUnitId: String!) {
            businessUnit(businessUnitId: $businessUnitId) {
              _id
              id
              name
              branches {
                _id
                id
                name
                currency
              }
            }
          }
        `
      });

      let branches = result.data.businessUnit.branches.map((value, index) => {
        return {
          id: value._id,
          name: value.name,
          currency: value.currency
        };
      });
      this.setState({ branches, businessUnit });

      if (this.props.businessUnitSelected) {
        this.props.businessUnitSelected(result.data.businessUnit);
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  /** Event fired when branch is selected */
  branchSelected = event => {
    if (event) {
      var branchId = event.value;
    }

    this.props.client
      .query({
        variables: {
          branchId: branchId
        },
        query: gql`
          query getBridgeNodes($branchId: String!) {
            bridgeNodes(branchId: $branchId) {
              id
              name
            }
          }
        `
      })
      .then(result => {
        this.setState({ branch: branchId, bridges: result.data.bridgeNodes });

        if (this.props.branchSelected) {
          // Get full branch detail of the branch selected
          let branchSelected = this.state.branches.filter(
            b => b.id === branchId
          )[0];

          this.props.branchSelected(branchSelected);
        }
      })
      .catch(err => {
        console.log("Error", err);
      });
  };

  bridgeSelected = event => {
    let bridgeId = event.value;

    this.setState({ [event.name]: event.value }, () => {
      let bridge = this.state.bridges.filter(b => b.id === bridgeId)[0];

      if (this.props.bridgeSelected) {
        this.props.bridgeSelected(bridge);
      }
    });
  };

  render() {
    const { mode, branches, branch, bridges, bridge } = this.state;

    console.log(branches);

    let branchRender = (
      <>
        <label className="heading">Branch</label>
        <Select
          value={branch}
          name="branch"
          placeholder="Select branch"
          options={branches.map(b => {
            b.value = b.id;
            return b;
          })}
          onChange={this.branchSelected}
        />
      </>
    );

    let bridgeRender = (
      <>
        <label>Bridge node</label>
        <Select
          value={bridge}
          name="bridge"
          placeholder="Select bridge node"
          options={bridges.map(b => {
            b.value = b.id;
            return b;
          })}
          onChange={this.bridgeSelected}
        />
      </>
    );

    switch (mode) {
      case "branch":
        return <>{branchRender}</>;
      case "bridge":
        return (
          <>
            {branchRender}
            {bridgeRender}
          </>
        );
      default:
        return <>No mode provided</>;
    }
  }
}

const mapStateToProps = state => {
  return {
    userAuth: state.userAuth
  };
};

export default withApollo(connect(mapStateToProps)(LocationSelector));
