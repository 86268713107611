import { gql } from "apollo-boost";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Form, Header } from "../../../common";

import "./style.scss";

class Detail extends Component {
  constructor(props) {
    super(props);

    console.log(props);

    this.state = {
      loading: true,
      id: undefined,
      branchId: undefined,
    };
  }

  componentDidMount() {
    if (!this.props.match.params.id) {
      this.props.history.goBack();
      return;
    }

    let id = this.props.match.params.id;
    let branchId = this.props.location.state.branchId || undefined;

    this.props.client
      .query({
        variables: { id },
        query: gql`
          query getDetail($id: String!) {
            exchange(id: $id) {
              id
              total
              user {
                name
              }
              deposited {
                currency
                value
                pieces
              }
              dispensed {
                currency
                value
                pieces
              }
            }
          }
        `,
      })
      .then(({ data }) => {
        if (!data) {
          alert("No exchange detail could be found");
          return;
        }

        this.setState({
          id,
          loading: false,
          exchange: data.exchange,
          branchId,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return = () => {
    if (this.state.branchId) {
      this.props.history.push(
        `/reports/machines/exchanges?branchId=${this.state.branchId}`
      );
    } else {
      this.props.history.push(`/reports/machines/exchanges`);
    }
  };

  render() {
    let { id, exchange, loading } = this.state;

    return (
      <>
        <button className="return" onClick={this.return}>
          Return
        </button>
        <Form loading={loading}>
          <Header title={"Transaction detail"} subtitle={id} level="3">
            <div className="exchangeDetail__sub">
              {exchange && (
                <>
                  <span className="exchangeDetail__header">Exchange total</span>
                  <span className="exchangeDetail__total">
                    {exchange.total}
                  </span>
                </>
              )}
            </div>
          </Header>
        </Form>
        <div className="exchangeDetail__denoms">
          <div className="exchangeDeposited">
            <Form loading={loading}>
              <Header title="Deposited" level="4" />
              <table>
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Pieces</th>
                  </tr>
                </thead>
                <tbody>
                  {exchange &&
                    exchange.deposited.map((d, i) => (
                      <tr key={i}>
                        <td>{`${d.currency} ${d.value}`}</td>
                        <td>{d.pieces}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Form>
          </div>
          <div className="exchangeDispensed">
            <Form loading={loading}>
              <Header title="Dispensed" level="4" />
              <table>
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Pieces</th>
                  </tr>
                </thead>
                <tbody>
                  {exchange &&
                    exchange.dispensed.map((d, i) => (
                      <tr key={i}>
                        <td>{`${d.currency} ${d.value}`}</td>
                        <td>{d.pieces}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withApollo(Detail));
