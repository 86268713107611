import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import moment from "moment";
import Header from "../../common/Header";
import Form from "../../common/Form";

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
    };
  }

  componentDidMount() {
    this.fetchGroups();
  }

  fetchGroups = () => {
    this.props.client
      .query({
        variables: { companyId: this.props.userAuth.companyId },
        query: gql`
          query getGroups($companyId: String!) {
            groups(companyId: $companyId) {
              id
              isAdmin
              createdOn
              name
              user {
                name
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          console.log(result.data);

          this.setState({
            groups: result.data.groups.filter((g) => !g.isAdmin),
          });
        } else {
          console.log(result.errors.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  create = () => {
    this.props.history.push(`${this.props.match.url}/create`);
  };

  edit = (id) => {
    this.props.history.push(`${this.props.match.url}/edit?id=${id}`);
  };

  delete = (id) => {
    this.props.client
      .mutate({
        variables: {
          id,
        },
        mutation: gql`
          mutation deleteGroup($id: String!) {
            deleteGroup(groupId: $id)
          }
        `,
      })
      .then((response) => {
        if (response.data && response.data.deleteGroup) {
          // Success
          alert("Delete successfully");

          this.fetchGroups();
        } else {
          alert("There are still users using this Group!");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  render() {
    const { groups } = this.state;

    return (
      <>
        <Form>
          <Header title="Group list" level="2">
            <button className="slim" onClick={this.create}>
              Create
            </button>
          </Header>
          <table className="c-groups__list">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Created on</th>
                <th>Created by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {groups.map((g, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{g.name}</td>
                  <td>{moment(g.createdOn).format("D/M/YY HH:mm")}</td>
                  <td>{g.user.name}</td>
                  <td>
                    <button className="slim" onClick={() => this.edit(g.id)}>
                      Edit
                    </button>
                    <button className="slim" onClick={() => this.delete(g.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(List)));
