import React, { Component, Fragment } from "react";

let defaultReq = [
  {
    name: "MachineId",
    desc: "Specify which machine to control",
    tags: ["attribute"]
  },
  {
    name: "Id",
    desc: "Assign an identifier for the requested command"
  },
  {
    name: "SequenceNumber",
    desc: "Assign a sequence number of the request for another reference"
  }
];

let defaultResp = [
  {
    name: "MachineId",
    desc: "Returns which machine sent back the response",
    tags: ["attribute"]
  },
  {
    name: "Id",
    desc: "Returns the request’s identifier"
  },
  {
    name: "SequenceNumber",
    desc: "Returns the request’s sequence number"
  },
  {
    name: "Result",
    desc: "Request’s result code",
    tags: ["attribute"]
  },
  {
    name: "User",
    desc: "User who performed the request",
    tags: ["attribute"]
  }
];

export default class APITable extends Component {
  generateRender = (e, nestLevel = 0) => {
    return e.map((r, index) => (
      <>
        <tr key={`req${index}`}>
          <td className={`nest${nestLevel}`}>
            {r.name}
            {r.tags
              ? r.tags.map((t, tindex) => (
                  <span key={`${index}${tindex}`} className="tag__attr">
                    {t}
                  </span>
                ))
              : null}
          </td>
          <td className="type">{r.type || "string"}</td>
          <td>{r.desc}</td>
        </tr>
        {r.el && this.generateRender(r.el, nestLevel + 1)}
      </>
    ));
  };

  render() {
    let {
      title,
      desc,
      command,
      req,
      resp,
      includeDefault
      // haveMachineId,
    } = this.props;

    // Assign empty array if it was undefined or null
    req = req || [];
    resp = resp || [];

    if (includeDefault) {
      req.unshift(...defaultReq);
      resp.unshift(...defaultResp);
    }

    // if (!haveMachineId) {
    //   req = req.filter((r) => r.name !== "MachineId");
    //   resp = resp.filter((r) => r.name !== "MachineId");
    // }

    let reqRender = this.generateRender(req);
    let respRender = this.generateRender(resp);

    return (
      <>
        <h1>{title}</h1>
        <p className="description">
          {Array.isArray(desc)
            ? desc.map((d, dindex) => (
                <Fragment key={`desc${dindex}`}>
                  {d}
                  <br />
                </Fragment>
              ))
            : desc}
        </p>
        <p className="command">
          Command<span>{command}</span>
        </p>
        <h2>Request body</h2>
        <table>
          <thead>
            <tr>
              <th>Parameter</th>
              <th className="type">Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>{reqRender}</tbody>
        </table>
        <h2>Response body</h2>
        <table>
          <thead>
            <tr>
              <th>Parameter</th>
              <th className="type">Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>{respRender}</tbody>
        </table>
      </>
    );
  }
}
