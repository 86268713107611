import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Form, Header } from "../../../common";

class QueueCard extends Component {
  updateQueueStatus = (id, status) => {
    if (this.props.updateQueueStatus) this.props.updateQueueStatus(id, status);
  };

  render() {
    let { detail } = this.props;

    if (!detail) {
      return null;
    }

    return (
      <div key={detail.id} className="queue__item">
        <Form>
          <Header title={detail.queueNumber} level="3">
            <button
              className="slim"
              onClick={() =>
                this.updateQueueStatus(detail.id, +detail.status + 1)
              }
            >
              {detail.status === -1 ? "Complete" : "Received"}
            </button>
          </Header>
          <table>
            <thead>
              <tr>
                <td>#</td>
                <td>Name</td>
                <td>Quantity</td>
              </tr>
            </thead>
            <tbody>
              {detail.transactionDetails &&
                detail.transactionDetails.map((detail, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{detail.item.name}</td>
                    <td>{detail.quantity}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Form>
      </div>
    );
  }
}

export default withApollo(QueueCard);
