import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Header } from "../../common";

class CategoriesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: undefined,
      loading: true,
      companyId: this.props.userAuth.companyId,
    };
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories() {
    const { companyId } = this.state;

    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { companyId },
        query: gql`
          query getCategories($companyId: String!) {
            categories(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        let categories = result.data.categories;
        this.setState(
          {
            categories,
            loading: false,
          },
          () => {
            console.log("state categories", categories);
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  createCategory = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  editCategory = (unitId) => {
    this.props.history.push(`${this.props.location.pathname}/edit/${unitId}`);
  };

  deleteCategory = (unitId) => {
    var confirm = window.confirm(`Confirm deletion?`);

    if (!confirm) {
      return;
    }

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id: +unitId,
        },
        mutation: gql`
          mutation deleteCategory($id: Int!) {
            deleteCategory(id: $id)
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Deleted category successfully!");

          this.getCategories();
        }
      })
      .catch((error) => {
        //console.log(error.message);
        alert("Unable to delete category");
      });
  };

  render() {
    const { categories } = this.state;
    let categoriesRender =
      categories && categories.length > 0 ? (
        categories.map((c, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{c.name}</td>
            <td>
              <button className="slim" onClick={() => this.editCategory(c.id)}>
                Edit
              </button>
              <button
                className="slim"
                onClick={() => this.deleteCategory(c.id)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="100%">No categories have been added</td>
        </tr>
      );

    return (
      <>
        <Header title="Categories">
          <button className="slim" onClick={this.createCategory}>
            Create
          </button>
        </Header>
        <div>
          <table className="category__table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>{categoriesRender}</tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(CategoriesList)));
