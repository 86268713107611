import React, { PureComponent } from "react";

import "./style.scss";
import { withRouter } from "react-router-dom";

class TabControl extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: props.tab || 0
    };

    console.log("Tab control props", props);
  }

  switchTo = index => {
    this.setState({ currentTab: index }, () => {
      // Call parent method if sent
      if (this.props.onSwitch) {
        this.props.onSwitch(index);
      }
    });
  };

  render() {
    const { currentTab: tab } = this.state;
    const { children } = this.props;

    if (!Array.isArray(children) || children.length === 0) {
      console.log("No children found in Tab Control");
      return <></>;
    }

    // if (children.some(c => c.type.name !== "Tab")) {
    //   console.log("No Tab children");
    //   console.log(children.toString());

    //   try {
    //     for (const child of children) {
    //       console.log(child);
    //       console.log(child.toString());
    //       console.log(JSON.stringify(child));
    //     }
    //   } catch (error) {}

    //   return <></>;
    // }

    var index = tab || 0;

    return (
      <>
        <div className="tabcontrol">
          {children.map((c, i) => (
            <div
              key={i}
              className={index === i ? "tabitem active" : "tabitem"}
              onClick={_ => this.switchTo(i)}
            >
              {c.props.title}
            </div>
          ))}
        </div>
        {children[index]}
      </>
    );
  }
}

export default withRouter(TabControl);
