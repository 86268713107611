import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Form, Header, Select } from "../../common";

import "./style.scss";

class ItemsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: undefined,
      loading: true,
      branches: [],
      branchSelected: undefined,
    };

    console.log("item props", this.props);
  }

  componentDidMount() {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        let branches = result.data.branches;

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState(
          {
            branches: branches,
            branchSelected,
            loading: false,
          },
          () => {
            if (branchSelected) {
              this.getBranchesItems(branchSelected);
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  getBranchesItems = (branchId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { branchId },
        query: gql`
          query getItems($branchId: String!) {
            items(branchId: $branchId) {
              id
              brand {
                name
              }
              sku
              itemCode
              name
              displayName
              group {
                name
              }
              price
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let itemSort = result.data.items;

          itemSort.sort(function(x,y){
            let a = x.sku.toUpperCase(),
              b = y.sku.toUpperCase();
              return a == b ? 0 : a > b ? 1 : -1;
          });
          this.setState({ items: itemSort });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleBranch = (e) => {
    this.setState({ [e.name]: e.value }, () => {
      this.getBranchesItems(e.value);
    });
  };

  createItem = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  importItem = () => {
    this.props.history.push(`${this.props.location.pathname}/import`);
  };

  editItem = (id) => {
    this.props.history.push(`${this.props.match.url}/edit/${id}`);
  };

  deleteItem = (id) => {
    let confirmation = window.confirm("Confirm deletion?");

    if (!confirmation) {
      return;
    }

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          itemId: id,
        },
        mutation: gql`
          mutation deleteItem($itemId: String!) {
            deleteItem(itemId: $itemId)
          }
        `,
      })
      .then((response) => {
        if (response.data && response.data.deleteItem) {
          // Success
          alert("Delete successfully!");

          this.getBranchesItems(this.state.branchSelected);
        } else {
          alert("Unable to delete item!");
        }
      })
      .catch((error) => {
        console.error(error);
        alert(`Unable to delete item: ${error.message}`);
      });
  };

  render() {
    const { items, branches, branchSelected } = this.state;

    return (
      <>
        <Header title="Items">
          <button onClick={this.importItem}>Import</button>
          <button onClick={this.createItem}>Create</button>
        </Header>
        <Form>
          <Header title="Filter" level="4" />
          <Select
            title="Branch"
            value={branchSelected}
            name="branchSelected"
            placeholder="Select branch to lookup"
            options={branches.map((b) => {
              b.value = b.id;
              return b;
            })}
            onChange={this.handleBranch}
          />
        </Form>
        <Form className="items__result">
          <table className="items__table">
            <thead>
              <tr>
                <th>#</th>
                <th>SKU</th>
                <th>ItemCode</th>
                <th>Name</th>
                <th>DisplayName</th>
                <th>Group</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map((i, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.sku}</td>
                    <td>{i.itemCode}</td>
                    <td>{i.name}</td>
                    <td>{i.displayName}</td>
                    <td>{i.group.name}</td>
                    <td>{i.price}</td>
                    <td>
                      <button
                        className="slim"
                        onClick={() => this.editItem(i.id)}
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="slim"
                        onClick={() => this.deleteItem(i.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Form>
        <div>
          <div className="item__table"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(ItemsList)));
