import React, { Component } from "react";
import { Form, Header, Input } from "../common";
import DenominationSelection from "./DenominationSelection";
import { gql } from "apollo-boost";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      name: "",
      currency: "",
      branch: "",
      hasLimitCount: false,
      countLimit: 0,
      hasLimitAmount: false,
      amountLimit: [0, 0, 0, 0, 0, 0, 0],
      float: undefined,
      defaultFloat: undefined,
      saving: false,
    };
  }

  componentDidMount() {
    let state = this.props.location.state;

    if (!state.float) {
      this.props.history.goBack();
      return;
    }

    let float = state.float;

    this.setState({
      id: float.id,
      name: float.name,
      currency: float.currency,
      branch: float.branch.name,
      defaultFloat: float.denominations,
    });
  }

  handleInputs = (event) => {
    let eventName = event.name || event.target.name;
    let eventValue = event.value || event.target.value;

    if (eventName === "countLimit") {
      let amount = Number(eventValue);

      if (amount >= 0) {
        this.setState({ countLimit: amount }, () => {});
      }
    } else {
      this.setState({ [eventName]: eventValue }, () => {
        if (eventName === "roleId") {
          // Get machines associated with the selected Role
          //   this.getMachines(eventValue);
        }
      });
    }
  };

  handleCheckBoxes = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;

    this.setState({ [event.target.name]: event.target.checked }, () => {
      if (name === "hasLimitAmount" && !checked) {
        this.setState({
          amountLimit: [0, 0, 0, 0, 0, 0, 0],
        });
      } else if (name === "hasLimitCount" && !checked) {
        this.setState({
          countLimit: 0,
        });
      }
    });
  };

  handleAmountLimitInputs = (event, index) => {
    let currentAmountLimit = this.state.amountLimit;

    let amount = Number(event.target.value);

    if (amount >= 0) {
      currentAmountLimit[index] = amount;

      this.setState({ amountLimit: currentAmountLimit });
    }
  };

  onDenomSelectionChanged = (selection) => {
    console.log(selection);

    this.setState({ float: selection });
  };

  edit = () => {
    this.setState({ saving: true }, () => {
      var newFloat = null;

      const { id, name, float } = this.state;

      if (!name) {
        return;
      }

      if (float) {
        const {
          denominationsAvailable,
          denominationsDevice,
          pieces,
          rowDisabled,
        } = float;

        newFloat = [];
        denominationsAvailable.forEach((denom, index) => {
          newFloat.push({
            device: denominationsDevice[index],
            value: denom,
            pieces: pieces[index],
            include: !rowDisabled[index],
          });
        });
      }

      this.props.client
        .mutate({
          variables: {
            dispenseFloatId: id,
            name,
            denominations: newFloat,
          },
          mutation: gql`
            mutation update(
              $dispenseFloatId: String!
              $name: String!
              $denominations: [FloatDenominationInput!]
            ) {
              updateDispenseFloat(
                dispenseFloatId: $dispenseFloatId
                name: $name
                denominations: $denominations
              )
            }
          `,
        })
        .then((result) => {
          if (result.data) {
            alert("success");
            this.props.history.goBack();
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    });
  };

  render() {
    const { name, currency, branch, defaultFloat, saving } = this.state;

    return (
      <>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Return
        </button>
        <Form>
          <Header title="Edit Float">
            {saving ? (
              "Saving"
            ) : (
              <button className="edit" onClick={this.edit}>
                Save
              </button>
            )}
          </Header>
          <Input
            title="Name"
            name="name"
            placeholder="Enter your name"
            onChange={this.handleInputs}
            value={name}
          />
          <label>Branch</label>
          <span>{branch}</span>
        </Form>
        {defaultFloat && (
          <DenominationSelection
            onChange={this.onDenomSelectionChanged}
            currency={currency}
            defaultValue={defaultFloat}
          />
        )}
      </>
    );
  }
}

export default withRouter(withApollo(Edit));
