import React, { Component } from "react";
import { Redirect, NavLink } from "react-router-dom";

import "./style.scss";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { userAuthorized } from "../../actions";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      error: "",
      redirectToReferrer: false,
      usernameError: "",
      passwordError: "",
    };

    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }

  dismissError() {
    this.setState({ error: "" });
  }

  handleSubmit(evt) {
    evt.preventDefault();

    if (!this.state.username) {
      this.setState({ usernameError: "Username cannot be blank" });
    } else if (!this.state.password) {
      this.setState({ passwordError: "Password cannot be blank" });
    } else {
      let userAuth = undefined;

      this.props.client
        .query({
          variables: {
            username: this.state.username,
            password: this.state.password,
          },
          query: gql`
            query AuthUser($username: String!, $password: String!) {
              authUser(username: $username, password: $password) {
                id
                group {
                  id
                  isAdmin
                  adminLevel
                }
                company {
                  id
                  modules {
                    id
                  }
                }
                branch {
                  id
                }
                authorityLevel
              }
            }
          `,
        })
        .then((result) => {
          if (result.data.authUser) {
            userAuth = result.data.authUser;

            let { group } = userAuth;

            if (group.isAdmin) {
              return false;
            }

            return this.props.client.query({
              variables: { groupId: userAuth.group.id },
              query: gql`
                query opt($groupId: String!) {
                  getGroupPermissions(groupId: $groupId) {
                    id
                    value
                    resourceId
                  }
                }
              `,
            });
          } else {
            this.setState({
              usernameError: "Invalid username or password",
              passwordError: "",
            });

            return false;
          }
        })
        .then((result) => {
          if (result || result.data) {
            var permissions = result.data.getGroupPermissions;
          }

          if (!userAuth) {
            return;
          }

          let { id, group, company, branch, authorityLevel } = userAuth;

          this.props.userAuthorized({
            userId: id,
            companyId: company ? company.id : null,
            branchId: branch ? branch.id : null,
            authorityLevel: authorityLevel,
            group: group,
            permissions: permissions || [],
            modules: company ? company.modules.map((m) => +m.id) : [],
          });
        })
        .catch((error) => {
          console.log(error);

          alert("Unable to connect to server!");
        });
    }
  }

  handleUserChange(evt) {
    this.setState({
      username: evt.target.value,
    });
  }

  handlePassChange(evt) {
    this.setState({
      password: evt.target.value,
    });
  }

  render() {
    const { userAuth } = this.props;
    const { from } = this.props.location.state || {
      from: {
        pathname: userAuth.isGlobalAdmin ? "/overseer/dashboard" : "/dashboard",
      },
    };

    const { username, usernameError, password, passwordError } = this.state;

    if (userAuth.isAuth) {
      return <Redirect to={from} />;
    } else {
      return (
        <>
          <div id="login">
            <form onSubmit={this.handleSubmit}>
              <img
                src={process.env.PUBLIC_URL + "/assets/logo.png"}
                alt="overseerLogo"
                height="48"
              />
              <h2>Welcome back</h2>
              <h3>Log in to continue</h3>
              <label>Username or email address</label>
              <input
                className={usernameError ? "error" : ""}
                type="text"
                value={username}
                onChange={this.handleUserChange}
                autoFocus
              />
              <span className="error__message">{usernameError}</span>
              <label>Password</label>
              <input
                className={passwordError ? "error" : ""}
                type="password"
                value={password}
                onChange={this.handlePassChange}
              />
              <span className="error__message">{passwordError}</span>
              <input
                className="button"
                type="submit"
                value="Log in"
                data-test="submit"
              />
            </form>
          </div>
          <div className="footer">
            <div>
              <NavLink
                className="dev"
                to="/developers/api/controlcenter/startSales"
              >
                Developers
              </NavLink>
              <NavLink className="guide" to="/">
                User guide
              </NavLink>
            </div>
            <span>© 2020 Creatus Co., Ltd. All rights reserved.</span>
          </div>
        </>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userAuthorized: (userId) => dispatch(userAuthorized(userId)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(Login));
