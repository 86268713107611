import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";

import "./style.scss";

// Import components
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { addNavigation, replaceNavigation, setHeader } from "../../actions";
import Overview from "./overview";
import Branch from "./branch";
import { Greeting } from "./Greeting";
import { gql } from "apollo-boost";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      branches: []
    };
  }

  componentDidMount() {
    // Set header title
    this.props.setHeader("Dashboard");

    // Change the sub nav bar first
    this.props.replaceNavigation({
      url: "dashboard",
      title: "dashboard"
    });

    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId
        },
        query: gql`
          query getData($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
            user(id: $userId) {
              name
            }
          }
        `
      })
      .then(result => {
        if (!result.data) {
          // Couldn't get data
        }

        let branches = result.data.branches;
        let user = result.data.user.name;

        this.setState({
          branches,
          user
        });
      })
      .catch(error => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  onBranchSelected = branch => {
    // Get branch code
    this.props.history.push(`${this.props.match.url}/${branch.code}`, {
      ...branch
    });

    // Add Sub nav bar
    this.props.addNavigation({
      url: `${this.props.match.url}/${branch.code}`,
      title: branch.name
    });
  };

  render() {
    const { match } = this.props;
    const { user, branches } = this.state;

    return (
      <>
        <div className="dashboard">
          <Route
            exact
            path={match.url}
            render={props => (
              <>
                <Greeting name={user} />
                <Overview
                  {...props}
                  onBranchSelected={this.onBranchSelected}
                  branches={branches}
                />
              </>
            )}
          />
          <Route
            exact
            path={`${match.url}/:code`}
            render={props => (
              <>
                <Greeting name={user} />
                <Branch {...props} />
              </>
            )}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userAuth: state.userAuth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setHeader: payload => dispatch(setHeader(payload)),
    replaceNavigation: payload => dispatch(replaceNavigation(payload)),
    addNavigation: payload => dispatch(addNavigation(payload))
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
);
