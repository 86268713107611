import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class refund extends Component {
  render() {
    return (
      <div className="commandInfo">
        <h1>Refund</h1>
        <p className="description">
          Refund a previous transaction using the reference and dispense out the
          cash
        </p>
        <p className="command">
          Command<span>Refund</span>
        </p>
        <h2>Request body</h2>
        <table>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MachineId (TODO)</td>
              <td>string</td>
              <td>Specify which machine to dispense cash from</td>
            </tr>
            <tr>
              <td>Id</td>
              <td>string</td>
              <td>
                Assigns an identifier to distinguish this request from others,
                this is usefull for when multiple event handlers are assigned to
                this completed callback, this field can be used to distinguish
                the request from other same request
              </td>
            </tr>
            <tr>
              <td>Sequence Number</td>
              <td>string</td>
              <td>Assign a another unique identifier for this request</td>
            </tr>
            <tr>
              <td>Reference</td>
              <td>string</td>
              <td>
                Setting a unique identifier for the referencing this sales
                transaction
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td>decimal</td>
              <td>
                Set the sales total amount for the machine to accept & perform
                sales on
              </td>
            </tr>
            <tr>
              <td>Popup</td>
              <td>boolean</td>
              <td>
                Specify whether to popup Control Center when performing the
                operation
              </td>
            </tr>
          </tbody>
        </table>
        <h2>Response body</h2>
        <table>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Result</td>
              <td>int</td>
              <td>The request's result code</td>
            </tr>
            <tr>
              <td>MachineId</td>
              <td>string</td>
              <td>Machine that was operated on</td>
            </tr>
            <tr>
              <td>Id</td>
              <td>string</td>
              <td>The unique indentifier of the request</td>
            </tr>
            <tr>
              <td>Sequence Number</td>
              <td>string</td>
              <td>The secondary unique indentifier the request</td>
            </tr>
            <tr>
              <td>Reference</td>
              <td>string</td>
              <td>
                Setting a unique identifier for the referencing this sales
                transaction
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td>decimal</td>
              <td>
                Set the sales total amount for the machine to accept & perform
                sales on
              </td>
            </tr>
            <tr>
              <td>Dispensed</td>
              <td>Denomination[]</td>
              <td>
                The denominations that are dispensed out during the the
                transaction
              </td>
            </tr>
            <tr>
              <td>Error</td>
              <td>string</td>
              <td>Error message of when the request fails</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(refund);
