import React from "react";
import APITable from "../../APITable";

const startReplenishmentFromEntrance = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [];

  let resp = [];

  return (
    <APITable
      title="Start Replenishment from Entrance"
      desc="Starts a replenishment operation, where the cash is deposited from the inlet hopper"
      command="StartReplenishmentFromEntrance"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default startReplenishmentFromEntrance;
