import React, { Component } from "react";
import { Form, Header, Input } from "../common";
import DenominationSelection from "./DenominationSelection";
import { gql } from "apollo-boost";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import LocationSelector from "../common/LocationSelector";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      currency: "",
      branchId: undefined,
      hasLimitCount: false,
      countLimit: 0,
      hasLimitAmount: false,
      amountLimit: [0, 0, 0, 0, 0, 0, 0],
      float: undefined,
    };
  }

  handleInputs = (event) => {
    let eventName = event.name || event.target.name;
    let eventValue = event.value || event.target.value;

    if (eventName === "countLimit") {
      let amount = Number(eventValue);

      if (amount >= 0) {
        this.setState({ countLimit: amount }, () => {});
      }
    } else {
      this.setState({ [eventName]: eventValue }, () => {
        if (eventName === "roleId") {
          // Get machines associated with the selected Role
          //   this.getMachines(eventValue);
        }
      });
    }
  };

  handleCheckBoxes = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;

    this.setState({ [event.target.name]: event.target.checked }, () => {
      if (name === "hasLimitAmount" && !checked) {
        this.setState({
          amountLimit: [0, 0, 0, 0, 0, 0, 0],
        });
      } else if (name === "hasLimitCount" && !checked) {
        this.setState({
          countLimit: 0,
        });
      }
    });
  };

  handleAmountLimitInputs = (event, index) => {
    let currentAmountLimit = this.state.amountLimit;

    let amount = Number(event.target.value);

    if (amount >= 0) {
      currentAmountLimit[index] = amount;

      this.setState({ amountLimit: currentAmountLimit });
    }
  };

  onDenomSelectionChanged = (selection) => {
    console.log(selection);

    this.setState({ float: selection });
  };

  branchSelected = (branch) => {
    this.setState({ currency: branch.currency, branchId: branch.id });
  };

  create = () => {
    var watermark = [];

    const { name, branchId, float } = this.state;

    if (!float || !branchId || !name) {
      return;
    }

    const {
      denominationsAvailable,
      denominationsDevice,
      pieces,
      rowDisabled,
      currency,
    } = float;

    denominationsAvailable.forEach((denom, index) => {
      watermark.push({
        device: denominationsDevice[index],
        value: denom,
        pieces: pieces[index],
        include: !rowDisabled[index],
      });
    });

    let watermarkDetail = {
      name,
      currency,
      denominations: watermark,
      users: [],
    };

    this.props.client
      .mutate({
        variables: {
          detail: watermarkDetail,
          branch: branchId,
        },
        mutation: gql`
          mutation create($detail: DispenseFloatInput!, $branch: String!) {
            createDispenseFloat(detail: $detail, branchId: $branch) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("success");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  render() {
    const { name, currency } = this.state;

    return (
      <>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Return
        </button>
        <Form>
          <Header title="Create new Float">
            <button className="slim" onClick={this.create}>
              Create
            </button>
          </Header>
          <Input
            title="Name"
            name="name"
            placeholder="Enter your name"
            onChange={this.handleInputs}
            value={name}
          />
          <LocationSelector
            mode="branch"
            branchSelected={this.branchSelected}
          />
          {/* <label>
            <input
              type="checkbox"
              name="hasLimitCount"
              checked={hasLimitCount}
              onChange={this.handleCheckBoxes}
            />
            Count limit
          </label>
          {hasLimitCount && (
            <>
              <input
                type="text"
                name="countLimit"
                value={countLimit}
                disabled={!hasLimitCount}
                onChange={this.handleInputs}
              />
              <br />
            </>
          )}
          <label>
            <input
              type="checkbox"
              name="hasLimitAmount"
              checked={hasLimitAmount}
              onChange={this.handleCheckBoxes}
            />
            Amount limit
          </label>
          {hasLimitAmount && (
            <>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>Monday</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={amountLimit[0]}
                        onChange={(e) => this.handleAmountLimitInputs(e, 0)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Tuesday</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={amountLimit[1]}
                        onChange={(e) => this.handleAmountLimitInputs(e, 1)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Wednesday</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={amountLimit[2]}
                        onChange={(e) => this.handleAmountLimitInputs(e, 2)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Thursday</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={amountLimit[3]}
                        onChange={(e) => this.handleAmountLimitInputs(e, 3)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Friday</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={amountLimit[4]}
                        onChange={(e) => this.handleAmountLimitInputs(e, 4)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Saturday</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={amountLimit[5]}
                        onChange={(e) => this.handleAmountLimitInputs(e, 5)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Sunday</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={amountLimit[6]}
                        onChange={(e) => this.handleAmountLimitInputs(e, 6)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
            </>
          )} */}
        </Form>
        <DenominationSelection
          onChange={this.onDenomSelectionChanged}
          currency={currency}
        />
      </>
    );
  }
}

export default withRouter(withApollo(Create));
