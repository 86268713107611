import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Form, Input, Header } from "../../../common";
import PromotionDetail from "../Create/PromotionDetail";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      discountTypeSelected: undefined,
      maximumAmount: undefined,
      minimumAmount: undefined,
      amount: undefined,
      customDetails: null,
    };
  }

  componentDidMount() {
    let { id } = this.props.match.params;

    if (!id) {
      return;
    }

    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id,
        },
        query: gql`
          query getInfo($id: String!) {
            promotion(ID: $id) {
              name
              branchId
              discountTypeId
              promotionDetails {
                amount
                minimumAmount
              }
              fixedDetails {
                itemId
                price
              }
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          console.error("Promotion not found");
          return;
        }

        let {
          name,
          branchId,
          discountTypeId,
          promotionDetails,
          fixedDetails,
        } = result.data.promotion;

        this.setState({
          originalName: name,
          name,
          branchSelected: branchId,
          discountTypeSelected: discountTypeId,
          amount: promotionDetails[0].amount,
          minimumAmount: promotionDetails[0].minimumAmount,
          customDetails: fixedDetails,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        alert("Fatal error");
      });
  }

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value });
  };

  onDetailChanged = (e) => {
    console.log("Details Changed", e);

    this.setState({ customDetails: e });
  };

  onEdit = () => {
    const { discountTypeSelected } = this.state;

    if (+discountTypeSelected === 3) {
      // * Check duplicate

      // variables.promotionInput.fixedDetails = customDetails.map((c) => {
      //   return {
      //     itemId: c.id,
      //     price: +c.price,
      //   };
      // });

      this.onEditFixedPromotion();
    }
  };

  onEditFixedPromotion = () => {
    let { name, amount, minimumAmount, customDetails } = this.state;

    let id = this.props.match.params.id;

    // * contruct fixed details
    let fixedDetails = customDetails.map((c) => {
      return {
        itemId: c.id || c.itemId,
        price: +c.price,
      };
    });

    let variables = {
      id,
      name,
      minQty: +minimumAmount,
      maxQty: +amount,
      fixedDetails,
    };

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables,
        mutation: gql`
          mutation edit(
            $id: String!
            $name: String!
            $minQty: Int!
            $maxQty: Int!
            $fixedDetails: [FixedPromotionDetailInput!]!
          ) {
            editFixedPromotion(
              id: $id
              name: $name
              minQty: $minQty
              maxQty: $maxQty
              fixedDetails: $fixedDetails
            )
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to create promotion.");
      });
  };

  render() {
    const {
      originalName,
      name,
      branchSelected,
      discountTypeSelected,
      amount,
      minimumAmount,
      customDetails,
    } = this.state;

    // * Get wordings shown in Detail
    var minAmountTitle = "Minimum quantity";
    var minAmountDesc = "Enter minimum quantity of items (0 no limit)";
    var amountTitle = "Maximum quantity";
    var amountDesc = "Enter max quantity of items (0 no limit)";

    return (
      <div>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title={`Editing ${originalName}`}>
          <button className="slim" onClick={this.onEdit}>
            Edit
          </button>
        </Header>
        <Form>
          <Header title="Basic information" level="4" />
          <Input
            title="Name"
            name="name"
            placeholder="Enter a name"
            onChange={this.handleInputs}
            value={name}
          />
        </Form>
        {branchSelected && discountTypeSelected && (
          <>
            <Form>
              <h4>Promotion rules</h4>
              <Input
                title={minAmountTitle}
                name="minimumAmount"
                placeholder={minAmountDesc}
                onChange={this.handleInputs}
                value={minimumAmount}
              />
              <Input
                title={amountTitle}
                name="amount"
                placeholder={amountDesc}
                onChange={this.handleInputs}
                value={amount}
              />
            </Form>
            <PromotionDetail
              type={discountTypeSelected}
              branchId={branchSelected}
              onChanged={this.onDetailChanged}
              defaultValue={customDetails}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(Edit)));
