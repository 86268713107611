import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import { Input, Form, Header } from "../../common";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originalName: "",
      name: "",
      loading: true,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) {
      return;
    }

    // * Get shop info

    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id: +id,
        },
        query: gql`
          query unitInfo($id: Int!) {
            category(id: $id) {
              name
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          return;
        }

        let { category } = result.data;

        this.setState({
          loading: false,
          originalName: category.name,
          name: category.name,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  onEdit = () => {
    const { name } = this.state;
    const { id } = this.props.match.params;

    if (!id) {
      return;
    }

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id: +id,
          name,
        },
        mutation: gql`
          mutation updateCategory($id: Int!, $name: String!) {
            updateCategory(id: $id, name: $name)
          }
        `,
      })
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          for (const error of result.errors) {
            alert(`Error: ${error.message}`);
          }

          return;
        }

        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to create group.");
      });
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value });
  };

  render() {
    const { name, originalName } = this.state;

    return (
      <div className="category__create">
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title={`Editing ${originalName}`}>
          <button className="slim" onClick={this.onEdit}>
            Save
          </button>
        </Header>
        <Form>
          <h4>Basic Information</h4>
          <Input
            title="Name"
            name="name"
            placeholder="Enter name of category"
            onChange={this.handleInputs}
            value={name}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(Edit)));
