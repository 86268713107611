import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import Select from "../../common/Select";
import Form from "../../common/Form";
import { Header } from "../../common";

class PromotionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promotions: undefined,
      loading: true,
      branches: [],
      branchSelected: undefined,
    };
  }

  componentDidMount() {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        //console.log(result.data);

        let branches = result.data.branches.map((b) => {
          return {
            name: b.name,
            value: b.id,
          };
        });

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState(
          {
            branches,
            branchSelected,
            loading: false,
          },
          () => {
            if (branchSelected) {
              this.getBranchesPromotions(branchSelected);
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  getBranchesPromotions = (branchId) => {
    console.log("BranchId: ", branchId);
    this.props.client.query({
      context: {
        route: "selfOrdering",
      },
        variables: { branchId },
        query: gql`
          query getPromotions($branchId: String!) {
            promotions(branchId: $branchId) {
              id
              name
              promotionType {
                name
              }
              discountType {
                name
              }
              discountAmount
              maximumAmount
              validUntil
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.setState({ promotions: result.data.promotions });
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
      });
  };

  handleBranch = (e) => {
    this.setState({ [e.name]: e.value }, () => {
      this.getBranchesPromotions(e.value);
    });
  };

  createPromotion = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  render() {
    const { promotions, branches, branchSelected } = this.state;

    let promotionRender =
      promotions && promotions.length > 0 ? (
        promotions.map((p, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{p.name}</td>
            <td>{p.promotionType?.name}</td>
            <td>{p.discountType?.name}</td>
            <td>{p.discountAmount}</td>
            <td>{p.maximumAmount}</td>
            <td>{p.validUntil}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="100%">No promotion have been added</td>
        </tr>
      );

    return (
      <div className="promotion__list">
        <Header title="Promotions">
          <button className="slim" onClick={this.createPromotion}>
            Create
          </button>
        </Header>
        <Form>
          <Header title="Filter" level="4" />
          <Select
            title="Branch"
            value={branchSelected}
            name="branchSelected"
            placeholder="Select a branch."
            options={branches}
            onChange={this.handleBranch}
          />
        </Form>
        <div>
          <table className="promotion__table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Type</th>
                <th>Discount Type</th>
                <th>Amount</th>
                <th>MaximumAmount</th>
                <th>Valid Until</th>
              </tr>
            </thead>
            <tbody>{promotionRender}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(PromotionsList)));