import { gql } from "apollo-boost";
import moment from "moment";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { addAlert } from "../../../actions";
import { Form, Header } from "../../common";

class OperatingHours extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      machineId: "",
      operatingHours: [
        {
          dayOfWeek: 0,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 1,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 2,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 3,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 4,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 5,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
        {
          dayOfWeek: 6,
          is24Hours: true,
          startTime: "",
          endTime: "",
        },
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loading && prevProps !== this.props) {
      this.setState({
        loading: false,
        machineId: this.props.info.id,
        operatingHours: this.props.info.operatingHours.map((oh) => {
          delete oh["__typename"];
          return oh;
        }),
      });
    }
  }

  operatingChanged = (e) => {
    let oldState = this.state.operatingHours;
    let index = Number(e.target.name.replace("start", "").replace("end", ""));

    if (e.target.type === "checkbox") {
      if (e.target.checked) {
        // Set to 24 hours
        oldState[index] = {
          dayOfWeek: index,
          is24Hours: true,
          startTime: "",
          endTime: "",
        };
      } else {
        oldState[index] = {
          dayOfWeek: index,
          is24Hours: false,
          startTime: "",
          endTime: "",
        };
      }
    } else {
      if (e.target.name.includes("start")) {
        // Start
        oldState[index].startTime = e.target.value;
      } else {
        // End
        oldState[index].endTime = e.target.value;
      }
    }

    this.setState({ operatingHours: oldState });
  };

  setLoading = (loading) => {
    console.log("Setting loading", loading);
    return new Promise((resolve, reject) => {
      this.setState({ loading }, () => {
        resolve(true);
      });
    });
  };

  save = () => {
    let { machineId, operatingHours } = this.state;

    let variables = {
      machineId,
      userId: this.props.userAuth.userId,
      operatingHours,
    };

    console.log(JSON.stringify(variables));

    this.setLoading(true)
      .then((_) => {
        return this.props.client.mutate({
          variables,
          mutation: gql`
            mutation update(
              $userId: String!
              $machineId: String!
              $operatingHours: [OperatingHourInput!]!
            ) {
              editMachineOperatingHours(
                userId: $userId
                machineId: $machineId
                operatingHours: $operatingHours
              )
            }
          `,
        });
      })
      .then((result) => {
        if (!result.data) {
          throw new Error("Failed");
        }

        this.props.addAlert({
          type: "success",
          title: "All set",
          msg: `Basic info updated`,
        });

        this.setLoading(false);
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Failed to update",
          msg: error.message,
        });

        this.setLoading(false);
      });
  };

  render() {
    let { loading, operatingHours } = this.state;

    return (
      <Form>
        <Header title="Operating hours" level={3}>
          {!loading && (
            <button className="main slim" onClick={this.save}>
              Save
            </button>
          )}
        </Header>
        <table>
          <thead>
            <tr>
              <th>Day</th>
              <th>24 hrs</th>
              <th>Start</th>
              <th>End</th>
            </tr>
          </thead>
          <tbody>
            {operatingHours.map((day, i) => (
              <tr key={i}>
                <td>{moment().day(day.dayOfWeek).format("dddd")}</td>
                <td>
                  <input
                    type="checkbox"
                    name={day.dayOfWeek}
                    onChange={this.operatingChanged}
                    checked={day.is24Hours}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name={`start${day.dayOfWeek}`}
                    onChange={this.operatingChanged}
                    value={day.startTime}
                    disabled={day.is24Hours}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name={`end${day.dayOfWeek}`}
                    onChange={this.operatingChanged}
                    value={day.endTime}
                    disabled={day.is24Hours}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(OperatingHours)
);
