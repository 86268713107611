import React, { Component } from "react";
import { Form, Header, Input } from "../common";
import { gql } from "apollo-boost";
import { addAlert } from "../../actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";

class Security extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      newPassword: "",
      verifyNewPassword: "",
      userId: this.props.userId,
      passwordError: "",
      newPasswordError: "",
      verifyNewPasswordError: "",
    };
  }

  handleInputs = (e) => {
    this.setState({ [e.name]: e.value });
  };

  validate = () => {
    const { password, newPassword, verifyNewPassword } = this.state;
    let {
      passwordError,
      newPasswordError,
      verifyNewPasswordError,
    } = this.state;

    let valid = true;

    passwordError = "";
    newPasswordError = "";
    verifyNewPasswordError = "";

    if (password === "" || newPassword === "" || verifyNewPassword === "") {
      passwordError = !password && "Can't leave blank";
      newPasswordError = !newPassword && "Can't leave blank";
      verifyNewPasswordError = !verifyNewPassword && "Can't leave blank";
      valid = false;
    }

    // Check whether new password match
    if (newPassword !== verifyNewPassword) {
      newPasswordError = "Password don't match";
      verifyNewPasswordError = "Password don't match";

      valid = false;
    }

    this.setState({ passwordError, newPasswordError, verifyNewPasswordError });

    return valid;
  };

  changePassword = () => {
    const { userId, password, newPassword } = this.state;

    if (!this.validate()) {
      return;
    }

    this.props.client
      .mutate({
        variables: {
          userId,
          currentPassword: password,
          newPassword: newPassword,
        },
        mutation: gql`
          mutation update(
            $userId: String!
            $currentPassword: String!
            $newPassword: String!
          ) {
            updatePassword(
              userId: $userId
              currentPassword: $currentPassword
              newPassword: $newPassword
            ) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.props.addAlert({
            type: "success",
            title: "All set",
            msg: `Security info updated`,
          });

          this.setState({
            password: "",
            newPassword: "",
            verifyNewPassword: "",
          });
        } else {
          this.props.addAlert({
            type: "error",
            title: "Failed to update security",
            msg: result.errors[0].message,
          });
        }
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Failed to update security",
          msg: error.message,
        });
      });
  };

  render() {
    const {
      password,
      newPassword,
      verifyNewPassword,
      passwordError,
      newPasswordError,
      verifyNewPasswordError,
    } = this.state;

    return (
      <Form>
        <Header title="Change password" level="3" />
        <Input
          type="password"
          title="Current password"
          name="password"
          placeholder="Enter current password"
          onChange={this.handleInputs}
          value={password}
          error={passwordError}
        />
        <Input
          type="password"
          title="New password"
          name="newPassword"
          onChange={this.handleInputs}
          value={newPassword}
          error={newPasswordError}
        />
        <Input
          type="password"
          title="Confirm new password"
          name="verifyNewPassword"
          onChange={this.handleInputs}
          value={verifyNewPassword}
          error={verifyNewPasswordError}
        />
        <Header level="3">
          <button className="slim" onClick={this.changePassword}>
            Update
          </button>
        </Header>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(Security))
);
