import React from "react";
import APITable from "../../APITable";

const cancelCashIn = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let resp = [
    {
      name: "Deposited",
      desc: "Denominations that were deposited into the machine",
      type: "DenominationType[]",
    },
    {
      name: "Dispensed",
      desc: "Denominations that were dispensed out of the machine",
      type: "DenominationType[]",
    },
  ];

  return (
    <APITable
      title="Cancel Cash In"
      desc="Cancels the current Cash In operation and returns cash that were deposited"
      command="CancelCashIn"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      resp={resp}
    />
  );
};

export default cancelCashIn;
