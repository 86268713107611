import React, { Component } from "react";
import { gql } from "apollo-boost";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Select, Form, Header } from "../common";
import { addAlert } from "../../actions";

class EditAccessibility extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authLevel: undefined,
      branches: [],
      branchSelected: undefined,
      groups: [],
      groupSelected: undefined,
      userId: props.userId,
      authLevelError: "",
      groupError: "",
      branchError: "",
      loading: true,
    };

    console.log(props);
  }

  componentDidMount() {
    // Get branches
    this.props.client
      .query({
        variables: {
          userId: this.state.userId,
          companyId: this.props.userAuth.companyId,
        },
        query: gql`
          query getData($userId: String!, $companyId: String!) {
            user(id: $userId) {
              authorityLevel
              groupId
              branchId
            }
            branches(userId: $userId) {
              id
              code
              name
            }
            groups(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          // Couldn't get data
        }

        console.log(result.data);

        let branches = result.data.branches.map((b) => {
          return {
            name: b.name,
            value: b.id,
          };
        });

        let groups = result.data.groups.map((g) => {
          return {
            name: g.name,
            value: g.id,
          };
        });

        let { authorityLevel, branchId, groupId } = result.data.user;

        this.setState({
          branches,
          groups,
          authLevel: authorityLevel,
          branchSelected: branchId,
          groupSelected: groupId,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: "Unable to connect to server" });
      });
  }

  changeLevel = (level) => {
    let { groupSelected, branchSelected, authLevel } = this.state;

    authLevel = level;

    if (level === 0) {
      groupSelected = undefined;
      branchSelected = undefined;
    } else if (level === 1) {
      branchSelected = undefined;
    }

    this.setState({ groupSelected, branchSelected, authLevel }, this.onChange);
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value }, this.onChange);
  };

  onChange = () => {
    console.log(this.state);

    if (this.props.onChange) {
      this.props.onChange(this.state);
    }
  };

  validate = () => {
    let {
      authLevel,
      groupSelected,
      branchSelected,
      authLevelError,
      groupError,
      branchError,
    } = this.state;

    let valid = true;

    if (authLevel === undefined) {
      authLevelError =
        authLevel === undefined ? "Please select an authority level" : "";
    }

    // Check access control
    if (authLevel > 0) {
      if (!groupSelected) {
        valid = false;
      } else if (authLevel > 1 && !branchSelected) {
        valid = false;
      }

      groupError =
        authLevel > 0 && !groupSelected ? "Please assign a group" : "";
      branchError =
        authLevel > 1 && !branchSelected ? "Please assign a brnach" : "";
    }

    this.setState({
      authLevelError,
      groupError,
      branchError,
    });

    return valid;
  };

  updateAccessibility = () => {
    if (!this.validate()) {
      return;
    }

    const { userId, authLevel, groupSelected, branchSelected } = this.state;

    this.props.addAlert({
      type: "info",
      title: "Applying new changes",
      msg: "Hang tight, while we're applying changes",
    });

    this.props.client
      .mutate({
        variables: {
          userId,
          authorityLevel: authLevel,
          groupId: groupSelected,
          branchId: branchSelected,
        },
        mutation: gql`
          mutation update(
            $userId: String!
            $authorityLevel: Int!
            $groupId: String
            $branchId: String
          ) {
            updateUserAccessibility(
              userId: $userId
              authorityLevel: $authorityLevel
              groupId: $groupId
              branchId: $branchId
            ) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.props.addAlert({
            type: "success",
            title: "All set",
            msg: `Accessibility updated`,
          });
        } else {
          this.props.addAlert({
            type: "error",
            title: "Failed to update user",
            msg: result.errors[0].message,
          });
        }
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Failed to update user",
          msg: error.message,
        });
      });
  };

  render() {
    const {
      authLevel,
      branches,
      branchSelected,
      groups,
      groupSelected,
      authLevelError,
      groupError,
      branchError,
      loading,
    } = this.state;

    // Generate options depending on the access level selected
    var options = <></>;

    if (authLevel > 0) {
      let groupSection = (
        <>
          <Select
            title="Group"
            value={groupSelected}
            name="groupSelected"
            placeholder="Select a group"
            options={groups}
            onChange={this.handleSelect}
            error={groupError}
          />
        </>
      );

      if (authLevel === 1) {
        // Company
        options = <>{groupSection}</>;
      } else if (authLevel === 2) {
        // Branch
        options = (
          <>
            {groupSection}
            <Select
              title="Branch"
              value={branchSelected}
              name="branchSelected"
              placeholder="Select a branch"
              options={branches}
              onChange={this.handleSelect}
              error={branchError}
            />
          </>
        );
      }
    }

    return (
      <Form className="accessibility" loading={loading}>
        <h4>Accessibility</h4>
        <div>
          <label>Authority level</label>
          <span className="error">{authLevelError}</span>
          <div className="authorityLevels">
            <div
              className={authLevel === 0 ? "level selected" : "level"}
              onClick={() => this.changeLevel(0)}
            >
              <div className="checkbox" />
              <div className="content">
                <label>Administrative</label>
                <span>Highest accessibility</span>
              </div>
            </div>
            <div
              className={authLevel === 1 ? "level selected" : "level"}
              onClick={() => this.changeLevel(1)}
            >
              <div className="checkbox" />
              <div className="content">
                <label>Company</label>
                <span>Multiple branches access</span>
              </div>
            </div>
            <div
              className={authLevel === 2 ? "level selected" : "level"}
              onClick={() => this.changeLevel(2)}
            >
              <div className="checkbox" />
              <div className="content">
                <label>Branch</label>
                <span>Specific branch access</span>
              </div>
            </div>
          </div>
          {options}
          <Header level="3">
            <button className="main slim" onClick={this.updateAccessibility}>
              Update
            </button>
          </Header>
        </div>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(EditAccessibility)
);
