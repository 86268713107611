import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { gql } from "apollo-boost";

// import { HeaderContext } from "../../../HeaderContext";

class CompanyList extends Component {
  // static contextType = HeaderContext;

  constructor(props) {
    super(props);

    this.state = {
      greetings: this.props.greetings,
      loading: true,
      companies: []
    };
  }

  componentDidMount() {
    console.log("Past routes", this.context[0]);

    // this.context[1](prev => [
    //   { title: "Dashboard", path: "/dashboard", state: { dashboardLevel: 0 } }
    // ]);

    this.props.client
      .query({
        query: gql`
          query {
            companies {
              id
              code
              name
              avatar
            }
          }
        `
      })
      .then(result => {
        this.setState({ companies: result.data.companies, loading: false });
      })
      .catch(error => {
        alert(error.message);
      });
  }

  addCompany = () => {
    const { match } = this.props;
    this.props.history.push(`${match.url}/create`);
  };

  render() {
    const { greetings, companies } = this.state;

    let companiesRender = companies ? (
      companies.map((c, index) => (
        <div
          key={index}
          className="dashboard__card"
          onClick={() => this.props.lookupCompany(c)}
        >
          <img src={c.avatar} alt="logo" height="36" />
          <p className="id">{c.code}</p>
          <p className="name">{c.name}</p>
        </div>
      ))
    ) : (
      <></>
    );

    return (
      <div className="dashboard">
        <div className="dashboard__greetings">
          <h1>{greetings}</h1>
          <h2>Select one of the following company to lookup it up</h2>
        </div>
        <div className="dashboard__grid companies">
          <div className="dashboard__addCard" onClick={this.addCompany}>
            <img
              alt="add"
              height="64"
              width="64"
              src={process.env.PUBLIC_URL + "/icons/add.png"}
            />
            <p>Add company</p>
          </div>
          {companiesRender}
        </div>
      </div>
    );
  }
}

export default withRouter(withApollo(CompanyList));
