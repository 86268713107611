import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";
import DateSelection from "../../DateSelection";
import moment from "moment";

import "./style.scss";
import { Header } from "../../../common";
import Summary from "./Summary";

const STATUS = {
  idle: "idle",
  gettingInfo: "gettingInfo",
  gettingRecords: "gettingRecords",
};

class DepositReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branches: [],
      branchSelected: undefined,
      selectedDay: null,
      toggleDate: false,
      machines: [],
      category: [],
      machineSelected: undefined,
      records: [],
      total: this.currencyFormat(0),
      status: STATUS.gettingInfo,
    };
  }

  componentDidMount() {
    this.getBranches();
  }

  getBranches = () => {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
              createdOn
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches;

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;
        //   let branchSelectedDate =
        //     branches.length === 1 ? branches[0].createdOn : new Date();

        this.setState(
          {
            branches: branches,
            branchSelected,
            status: STATUS.idle,
          },
          () => {
            this.getMachines();
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  getMachines = () => {
    console.log(
      "Fetching machines of selected branch",
      this.state.branchSelected
    );

    if (!this.state.branchSelected) {
      return;
    }

    this.props.client
      .query({
        variables: {
          branchId: this.state.branchSelected,
        },
        query: gql`
          query getMachines($branchId: String!) {
            branchMachines(branchId: $branchId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        this.setState({
          machines: result.data.branchMachines,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  getRecords = () => {
    this.setState({ status: STATUS.gettingRecords }, () => {
      console.log("Getting records");

      this.props.client
        .query({
          variables: {
            branchId: this.state.branchSelected,
            date: this.state.selectedDay,
          },
          query: gql`
            query getData($branchId: String!, $date: DateTime) {
              deposits(branchId: $branchId, date: $date) {
                id
                createdOn
                transactionId
                total
                machineId
                category
                user {
                  id
                  name
                }
                deposited {
                  pieces
                  value
                }
                dispensed {
                  pieces
                  value
                }
              }
              branchMachines(branchId: $branchId) {
                id
                code
                name
              }
            }
          `,
        })
        .then((result) => {
          if (!result.data) {
            console.log("No data");
            return;
          }

          console.log(result.data.deposits);

          this.setState({
            records: result.data.deposits,
            machines: result.data.branchMachines,
            status: STATUS.idle,
          });
        })
        .catch((error) => {
          this.setState({ error: "Unable to connect to server" });
        });
    });
  };

  toggleDateSelection = () => {
    this.setState({ toggleDate: true });
  };

  closeDateSelection = () => {
    this.setState({ toggleDate: false }, () => {
      //   this.getRecords();
    });
  };

  onDateSelected = (date) => {
    this.setState({ toggleDate: false, selectedDay: date }, () => {
      this.getRecords();
    });
  };

  handleInput = (e) => {
    var elementName = e.target.name;

    this.setState({ [e.target.name]: e.target.value }, () => {
      if (elementName === "machineSelected") {
        // this.getRecords();
      } else if (elementName === "branchSelected") {
        // this.getMachines();
        this.getRecords();
      }
    });
  };

  currencyFormat(amount) {
    var format = new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
      minimumFractionDigits: 2,
    });

    return format.format(amount);
  }

  exportExcel = () => {
    // ! Not implemented yet
    if (!this.state.branchSelected) {
      alert("Please select branch");
      return;
    }

    this.props.client
      .query({
        variables: {
          branchId: this.state.branchSelected,
          date: this.state.selectedDay,
        },
        query: gql`
          query getExcel($branchId: String!, $date: DateTime) {
            exportDeposits(branchId: $branchId, date: $date)
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        // File name
        let reportDate = moment(this.state.selectedDay).format("D-M-YY");
        let currentDate = moment().format("DMYYHMM");
        let fileName = `TransactionReport_${reportDate}_(${currentDate})`;

        this.downloadFile(result.data.exportDeposits, fileName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadFile(blobContent, name) {
    let FileSaver = require("file-saver");
    let blob = new Blob(
      [
        this.base64toBlob(
          blobContent,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ),
      ],
      {}
    );
    FileSaver.saveAs(blob, `${name}.xlsx`);
  }

  render() {
    const {
      status,
      branches,
      branchSelected,
      machineSelected,
      categorySelected,
      category,
      machines,
      toggleDate,
      selectedDay,
      records,
    } = this.state;

    // Remap replenishments
    var machinesAsObject = machines.reduce((p, c) => {
      p[c.id] = c.name;
      return p;
    }, {});

    var categoryAsObject = category.reduce((p, c) => {
      p[c.id] = c.name;
      return p;
    }, {});

    let deposited = 0;
    let dispensed = 0;
    let transactions = [];

    let total = 0;

    records
      .filter((r) =>
        machineSelected ? r.machineId === machineSelected : r.machineId
      )
      .forEach((t) => {
        t.machine = machinesAsObject[t.machineId];

        t.deposited.forEach((dep) => {
          deposited += dep.pieces * dep.value;
        });

        t.dispensed.forEach((dep) => {
          dispensed += dep.pieces * dep.value;
        });

        total += t.total;

        transactions.push(t);
      });

    console.log(deposited);
    console.log(dispensed);

    return (
      <div id="report__transaction">
        <Header title="Transaction Report" level="1">
          <button onClick={this.exportExcel}>Export excel</button>
        </Header>
        <div className="summary">
          <Summary
            grandTotal={this.currencyFormat(total)}
            received={this.currencyFormat(deposited)}
            changed={this.currencyFormat(dispensed)}
          />
        </div>
        <div className="transactions">
          <div className="header">
            <h2>Transactions</h2>
          </div>
          <div className="filter">
            <div>
              <label>Branches</label>
              <select
                disabled={status !== STATUS.idle ? true : false}
                name="branchSelected"
                value={branchSelected}
                onChange={this.handleInput}
              >
                {!branchSelected && <option>Select branch</option>}
                {branches.map((b) => (
                  <option key={b.id} value={b.id}>
                    {b.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Date</label>
              <input
                disabled={status !== STATUS.idle ? true : false}
                readOnly={true}
                type="text"
                value={
                  selectedDay
                    ? moment(selectedDay).format("D MMM YYYY")
                    : "Real-time"
                }
                onClick={this.toggleDateSelection}
              />
            </div>
            {branchSelected && (
              <div>
                <label>Machine</label>
                <select
                  disabled={status !== STATUS.idle ? true : false}
                  name="machineSelected"
                  value={machineSelected}
                  onChange={this.handleInput}
                >
                  {!machineSelected && (
                    <option value={undefined}>Select machine</option>
                  )}
                  {machines.map((m, i) => (
                    <option key={m.code} value={m.id}>
                      {m.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {/* {machineSelected && (
              <div>
                <label>Category</label>
                <select
                  disabled={status !== STATUS.idle ? true : false}
                  name="categorySelected"
                  value={categorySelected}
                  onChange={this.handleInput}
                >
                  {!categorySelected && (
                    <option value={undefined}>Select category</option>
                  )}
                  {category.map((c, i) => (
                    <option key={c.code} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            )} */}
          </div>
          <div className="records">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Transaction</th>
                  <th>Total</th>
                  <th>Machine</th>
                  <th>User</th>
                  <th>Category</th>
                </tr>
              </thead>
              <tbody>
                {status === STATUS.gettingRecords ? (
                  <tr>
                    <td colSpan="100%">Retrieving records</td>
                  </tr>
                ) : (
                  transactions.length === 0 && (
                    <tr>
                      <td colSpan="100%">No transactions found</td>
                    </tr>
                  )
                )}
                {transactions.map((r, i) => (
                  <tr key={`rep${i}`}>
                    <td>{i + 1}</td>
                    <td>{moment(r.createdOn).format("D/M/YY H:mm")}</td>
                    <td>{r.transactionId}</td>
                    <td>{r.total}</td>
                    <td>{r.machine}</td>
                    <td>{r.user ? r.user.name : "Anonymous"}</td>
                    <td>{r.category}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {toggleDate && (
          <DateSelection
            selection={selectedDay}
            close={this.closeDateSelection}
            onSelect={this.onDateSelected}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(DepositReport)));
