import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

class BranchesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branches: undefined,
    };

    console.log("branch props", this.props);
  }

  componentWillMount = () => {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query getBranches($userId: String) {
            branches(userId: $userId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.setState({ branches: result.data.branches });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  createStore = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  render() {
    const { branches } = this.state;

    return (
      <div>
        <button onClick={this.createStore}>Create</button>
        <ul>
          {branches &&
            branches.map((value, index) => (
              <li key={value.id}>{`${value.id} ${value.name}`}</li>
            ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(BranchesList)));
