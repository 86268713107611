import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import Edit from "./Edit";
import Create from "./Create";
import List from "./List";

class Floats extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={match.url} component={List} />
        <Route exact path={`${match.url}/create`} component={Create} />
        <Route exact path={`${match.url}/edit`} component={Edit} />
      </div>
    );
  }
}

export default withRouter(Floats);
