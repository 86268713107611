import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import BasicInfo from "./BasicInfo";
// import qs from "querystring";
import "./style.scss";
import Accessibility from "./Accessibility";
import { gql } from "apollo-boost";
import { Header } from "../../common";
import { addAlert } from "../../../actions";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basicInfo: {},
      accessibility: {},
      nameError: "",
      usernameError: "",
      authKeyError: "",
      passwordError: "",
      verifyPasswordError: "",
      authLevelError: "",
      groupError: "",
      branchError: "",
    };
  }

  onBasicInfoChange = (basicInfo) => {
    this.setState({ basicInfo });
  };

  onAccessibilityChange = (accessibility) => {
    this.setState({ accessibility });
  };

  onReturn = () => {
    this.props.history.goBack();
  };

  validateData = () => {
    let {
      basicInfo: { name, username, authKey, password, verifyPassword },
      accessibility: { branchSelected, groupSelected, authLevel },
      nameError,
      usernameError,
      authKeyError,
      passwordError,
      verifyPasswordError,
      authLevelError,
      groupError,
      branchError,
    } = this.state;

    let valid = true;

    // Check blank fields
    if (
      !name ||
      !username ||
      !authKey ||
      !password ||
      !verifyPassword ||
      authLevel === undefined
    ) {
      valid = false;

      nameError = name ? "" : "Cannot leave blank";
      usernameError = username ? "" : "Cannot leave blank";
      authKeyError = authKey ? "" : "Cannot leave blank";
      passwordError = password ? "" : "Cannot leave blank";
      verifyPasswordError = verifyPassword ? "" : "Cannot leave blank";
      authLevelError =
        authLevel === undefined ? "Please select an authority level" : "";

      console.log(authLevel);
    }

    // Check password
    if (password && verifyPassword) {
      if (password !== verifyPassword) {
        passwordError = "";
        verifyPasswordError = "Password do not match";

        valid = false;
      }
    }

    // Check access control
    if (authLevel > 0) {
      if (!groupSelected) {
        valid = false;
      } else if (authLevel > 1 && !branchSelected) {
        valid = false;
      }

      groupError =
        authLevel > 0 && !groupSelected ? "Please assign a group" : "";
      branchError =
        authLevel > 1 && !branchSelected ? "Please assign a brnach" : "";
    }

    this.setState({
      nameError,
      usernameError,
      authKeyError,
      passwordError,
      verifyPasswordError,
      authLevelError,
      groupError,
      branchError,
    });

    return valid;
  };

  onCreate = () => {
    const {
      basicInfo: { name, username, authKey, password, forcePasswordUpdate },
      accessibility: { branchSelected, groupSelected, authLevel },
    } = this.state;

    if (!this.validateData()) {
      return;
    }

    this.props.addAlert({
      type: "info",
      title: "Adding user",
      msg: "Hang tight while we're adding the user",
    });

    let userDetail = {
      name,
      username,
      authKey,
      password,
      companyId: this.props.userAuth.companyId,
      groupId: groupSelected ? groupSelected : null,
      branchId: branchSelected ? branchSelected : null,
      authorityLevel: authLevel,
      forcePasswordUpdate,
    };

    this.props.client
      .mutate({
        variables: {
          userDetail,
        },
        mutation: gql`
          mutation createUser($userDetail: UserInput!) {
            createUser(userDetail: $userDetail) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.props.addAlert({
            type: "success",
            title: "All set",
            msg: "User has now been created",
          });
          this.props.history.goBack();
        } else {
          this.props.addAlert({
            type: "error",
            title: "Creation failed",
            msg: result.errors[0].message,
          });
        }
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Creation failed",
          msg: error.message,
        });
      });
  };

  render() {
    const {
      editMode,
      // loading,
      nameError,
      usernameError,
      authKeyError,
      passwordError,
      verifyPasswordError,
      authLevelError,
      groupError,
      branchError,
    } = this.state;

    let infoValidation = {
      nameError,
      usernameError,
      authKeyError,
      passwordError,
      verifyPasswordError,
    };

    let accessValidation = {
      authLevelError,
      groupError,
      branchError,
    };

    return (
      <div className="user__create">
        <button className="return" onClick={this.onReturn}>
          Back
        </button>
        <Header title={editMode ? "Edit user" : "Add new user"}>
          <button className="slim" disabled={false} onClick={this.onCreate}>
            Add
          </button>
        </Header>
        <BasicInfo
          onChange={this.onBasicInfoChange}
          validation={infoValidation}
        />
        <Accessibility
          onChange={this.onAccessibilityChange}
          validation={accessValidation}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(Create))
);
