import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import TabControl from "../common/TabControl";
import Tab from "../common/TabControl/Tab";
import Groups from "./groups";
import Roles from "./roles";
import Header from "../common/Header";

class AccessControl extends Component {
  render() {
    const { match } = this.props;

    let groupPath = match.url + "/groups";
    let rolesPath = match.url + "/roles";

    return (
      <>
        <Header
          title="Access Control"
          level="3"
          icon={process.env.PUBLIC_URL + "/assets/icons/kiosk-small.svg"}
        ></Header>
        <TabControl>
          <Tab title="Groups">
            <Redirect to={groupPath} />
          </Tab>
          <Tab title="Roles">
            <Redirect to={rolesPath} />
          </Tab>
        </TabControl>
        <Route path={groupPath} component={Groups} />
        <Route path={rolesPath} component={Roles} />
      </>
    );
  }
}

export default withRouter(AccessControl);
