import React, { Component } from "react";

// Import components
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Header } from "../../common";
import qs from "querystring";
import { addAlert } from "../../../actions";
import Basic from "./Basic";
import CashMachines from "./CashMachines";
import OperatingHours from "./OperatingHours";

class Edit extends Component {
  constructor(props) {
    super(props);

    let params = qs.parse(this.props.location.search.replace("?", ""));

    this.state = {
      id: params.id,
      loading: true,
      machine: undefined,
    };
  }

  componentDidMount() {
    if (!this.state.id) {
      alert("Machine not found");
      this.props.history.push("/machines");
    }

    // * Get Machine details, watermarks and Branches
    this.props.client
      .query({
        variables: {
          machineId: this.state.id,
        },
        query: gql`
          query getInfo($machineId: String!) {
            machine(machineId: $machineId) {
              id
              code
              name
              type
              watermarkId
              bridgeNodeId
              cashDevices {
                id
                cashType
                deviceId
                address
              }
              operatingHours {
                dayOfWeek
                is24Hours
                startTime
                endTime
              }
            }
            modules {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          this.props.addAlert({
            type: "error",
            title: "Failed to retrieve",
            msg: "No machine info was found",
          });
          return;
        }

        let { modules, machine } = result.data;

        this.setState({
          loading: false,
          machine,

          modules: modules.map((b) => {
            return {
              name: b.name,
              value: b.id,
            };
          }),
        });
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Failed to retrieve",
          msg: error.message,
        });
      });
  }

  render() {
    const { machine, modules } = this.state;

    return (
      <>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title="Editing machine" level={1} />
        <Basic info={machine} modules={modules} />
        <CashMachines info={machine} modules={modules} />
        <OperatingHours info={machine} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(Edit))
);
