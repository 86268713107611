import React from "react";
import APITable from "../../APITable";

const getMachineStatus = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let resp = [
    {
      name: "Status",
      desc: "Status of each unit",
      el: [
        {
          name: "Controller",
          desc: "Controller's status",
        },
        {
          name: "Note",
          desc: "Note’s status",
        },
        {
          name: "Coin",
          desc: "Coin’s status",
        },
      ],
    },
    {
      name: "VerificationInfo",
      desc: "The machines verification information",
      el: [
        {
          name: "Recycling",
          desc: "Recycling unit verification information",
          el: [
            {
              name: "Required",
              desc: "Tells whether the unit requires verification or not",
            },
            {
              name: "Denomination",
              desc: "The denominations that requires verification",
              type: "DenominationType[]",
            },
          ],
        },
        {
          name: "Collection",
          desc: "Collection unit verification information",
          el: [
            {
              name: "Required",
              desc: "Tells whether the unit requires verification or not",
            },
            {
              name: "Devices",
              desc: "Device’s collection unit that requires verification",
            },
          ],
        },
        {
          name: "Mix",
          desc: "Mix unit verification information",
          el: [
            {
              name: "Required",
              desc: "Tells whether the unit requires verification or not",
            },
          ],
        },
      ],
    },
  ];

  return (
    <APITable
      title="Get Machine Status"
      desc="RRequests for the current device’s status and verification information"
      command="GetStatus"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      resp={resp}
    />
  );
};

export default getMachineStatus;
