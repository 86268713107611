import React from "react";
import APITable from "../../APITable";

const getDispensableInventory = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let resp = [
    {
      name: "Inventory",
      desc: "The dispensable inventory inside the machine",
    },
  ];

  return (
    <APITable
      title="Get Dispensable Inventory"
      desc="Retrieves the machine’s dispensable inventory"
      command="GetDispensableInventory"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      resp={resp}
    />
  );
};

export default getDispensableInventory;
