import React, { Component } from "react";

export default class CollectionBar extends Component {
  render() {
    const { denominations } = this.props;

    var filtered = denominations.filter(d => d.pieces !== 0);

    return (
      <div className="collection__container">
        <div className="collection__bar">
          {filtered.map((denom, index) => {
            // Get percentage
            let denommax = denom.max;
            if(denommax === 0){
              denommax = 450
            }
            let percentage = (denom.pieces / denommax) * 100 * 2.56;

            if (percentage < 4) {
              percentage = 4;
            }

            let tooltip = `THB ${denom.value}: ${denom.pieces} pcs`;

            return (
              <div
                key={index}
                title={tooltip}
                className={`denomination__bar i${index}`}
                style={{ width: percentage }}
              />
            );
          })}
        </div>
        <div className="collection__detail">
          {filtered.map((denom, index) => {
            let tooltip = (
              <>
                <label>{`THB ${denom.value}: `}</label>
                <span>{`${denom.pieces} pcs`}</span>
              </>
            );

            return (
              <div key={index} className={`i${index}`}>
                {tooltip}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
