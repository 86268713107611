import React, { Component } from "react";

import "./style.scss";
import { connect } from "react-redux";
import Identicon from "identicon.js";
import jssha from "jssha";
import { gql } from "apollo-boost";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import { userSignedOut, toggleNav } from "../../actions";

class Header extends Component {
  constructor(props) {
    super(props);

    var shaObj = new jssha("SHA-512", "TEXT");
    shaObj.setHMACKey(
      props.userAuth.companyId ? props.userAuth.companyId : "creatus",
      "TEXT"
    );
    shaObj.update(props.userAuth.userId);
    var hash = shaObj.getHMAC("HEX");

    var data = new Identicon(hash, 28).toString();

    this.state = {
      avatar: `data:image/png;base64,${data}`,
      userName: "",
      groupName: "",
      dropdownToggled: false,
    };
  }

  componentDidMount() {
    // Get User's name
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query getUser($userId: String!) {
            user(id: $userId) {
              name
              group {
                name
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          if (result.data.user) {
            this.setState({
              userName: result.data.user.name,
              groupName: result.data.user.group.name,
            });
          } else {
            // Logout
            this.props.userSignedOut();
          }
        }
      })
      .catch((error) => {
        // TODO: Navigate to page where connection is
        console.log("error", error);
      });
  }

  onRouteChanged() {
    if (this.props.nav && this.props.windowSize === "small") {
      // Toggle nav off
      this.toggleHam();
    }
  }

  toggleDropdown = () => {
    this.setState({ dropdownToggled: !this.state.dropdownToggled });
  };

  toggleHam = () => {
    this.props.toggleNav();
  };

  render() {
    const { avatar, userName, groupName, dropdownToggled } = this.state;

    const { nav } = this.props;

    let hamClassname = nav ? "ham hamRotate ham4 active" : "ham hamRotate ham4";

    hamClassname = nav ? "active" : "normal";

    return (
      <div className="main__header">
        <div id="ham" className={hamClassname} onClick={this.toggleHam}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {/* <svg
          className={hamClassname}
          onClick={this.toggleHam}
          viewBox="0 0 100 100"
          width="80"
        >
          <path
            className="line top"
            d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
          />
          <path className="line middle" d="m 70,50 h -40" />
          <path
            className="line bottom"
            d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
          />
        </svg> */}
        <h1 className="title">{this.props.header}</h1>
        <div className="notification">
          <img
            height="20"
            alt="bell"
            src={process.env.PUBLIC_URL + "/assets/icons/bell.svg"}
          />
        </div>
        <div className="user" onClick={this.toggleDropdown}>
          <svg className="arrow" viewBox="0 0 10 10" width="10px" height="10px">
            <g strokeWidth="1" fill="#9AA8BD" fillRule="evenodd">
              <path d="M5.58578196,5.01653637 L2.29467541,8.29111547 C1.90316932,8.68065548 1.90157549,9.31381845 2.2911155,9.70532455 C2.68065551,10.0968306 3.31381848,10.0984245 3.70532457,9.70888446 L7.70532455,5.72897188 C8.09683413,5.3394284 8.09842398,4.70625854 7.70887557,4.31475387 L3.70887559,0.294666476 C3.31933065,-0.0968347079 2.68616766,-0.0984205515 2.29466648,0.291124395 C1.90316529,0.680669342 1.90157945,1.31383233 2.29112439,1.70533352 L5.58578196,5.01653637 Z"></path>
            </g>
          </svg>
          <img alt="bell" src={avatar} />
          <div className="user__detail">
            <h1>{userName}</h1>
            <span>{groupName}</span>
          </div>
        </div>
        <div
          className={
            dropdownToggled ? "user__dropdown active" : "user__dropdown"
          }
        >
          <div className="other">
            <Link to="/profile">Edit profile</Link>
            <Link to="/profile">Support</Link>
          </div>
          <footer onClick={this.props.userSignedOut}>
            <span>Logout</span>
            <svg
              width="16"
              height="14"
              viewBox="0 0 18 16"
              xmlns="http://www.w3.org/2000/svg"
              className="css-rgtra7 e6gharr6"
            >
              <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g
                  transform="translate(-663.000000, -225.000000)"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <g transform="translate(664.000000, 226.000000)">
                    <path d="M16,8.5 L16,12.3 C16,13 15.6,13.5 15,13.5 L1,13.5 C0.4,13.5 0,13 0,12.3 L0,8.5"></path>
                    <path d="M8,1.5 L8,9.5"></path>
                    <polyline points="6 2.5 8 0.5 10 2.5"></polyline>
                  </g>
                </g>
              </g>
            </svg>
          </footer>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userSignedOut: () => dispatch(userSignedOut()),
    toggleNav: () => dispatch(toggleNav()),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
    header: state.header,
    nav: state.nav,
  };
};

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(Header));
