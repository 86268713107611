const defaultState = {
  isAuth: false,
  userId: null,
  companyId: null,
  branchId: null,
  authorityLevel: null,
  group: null,
  permissions: null,
  isGlobalAdmin: false,
  modules: [],
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "AUTHORIZED":
      return {
        isAuth: true,
        userId: action.payload.userId,
        companyId: action.payload.companyId,
        branchId: action.payload.branchId,
        authorityLevel: action.payload.authorityLevel,
        group: action.payload.group,
        permissions: action.payload.permissions,
        modules: action.payload?.modules,
        isGlobalAdmin:
          action.payload.group.isAdmin && action.payload.group.adminLevel === 0,
      };
    case "SIGNED_OUT":
      return defaultState;
    default:
      return state;
  }
};

export default authReducer;
