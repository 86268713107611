import React from "react";
import APITable from "../../APITable";

const canceltReplenishmentFromEntrance = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [];
  let resp = [
    {
      name: "Replenished",
      desc: "Denominations that were replenished during the operation",
      type: "DenominationType[]",
    },
    {
      name: "Dispensed",
      desc: "Denominations that was dispensed out of the machine",
      type: "DenominationType[]",
    },
  ];

  return (
    <APITable
      title="Cancel Replenishment from Entrance"
      desc="Attempts to cancels the current replenishment from entrance operation, and return cash 	that were replenished beforehand"
      command="CancelReplenishmentFromEntrance"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default canceltReplenishmentFromEntrance;
