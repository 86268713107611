import React, { PureComponent } from "react";

import "./style.scss";

export default class MultiSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selections: props.value || [],
      toggled: false,
      prevOptions: props.options,
    };

    // if (props.value) {
    //   let match = props.options.filter(o => o.value === props.value);

    //   if (match.length > 0) {
    //     this.state.selection = {
    //       value: match[0].value,
    //       name: match[0].name
    //     };
    //   }
    // }

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // componentDidUpdate(prevProps, prevState) {

  //   this.setState({ selections: this.props.value });

  // }

  static getDerivedStateFromProps(nextProps, state) {
    // Check if only thing changing is toggling
    if (nextProps.options.length === 0) {
      // return empty
      return {
        selections: [],
        prevOptions: nextProps.options,
      };
    }

    if (state.prevOptions.length > 0) {
      // Check if first option is the same
      if (
        state.prevOptions[0][nextProps.valueProp || "value"] !==
        nextProps.options[0][nextProps.valueProp || "value"]
      ) {
        return {
          selections: [],
          prevOptions: nextProps.options,
        };
      }
    }

    return {
      prevOptions: nextProps.options,
      selections: nextProps.value || [],
    };
  }

  handleSelection = (e) => {
    var selections = this.state.selections;

    // Check if already exist
    var index = selections.findIndex((val) => val === e);

    if (index >= 0) {
      // Already exist
      // remove
      selections.splice(index, 1);
    } else {
      // New
      // add to the sequence
      selections.push(e);
    }

    this.setState(
      {
        selections,
      },
      () => {
        this.forceUpdate();
        this.onChange();
      }
    );
  };

  removeSelection = (val) => {
    var selections = this.state.selections.filter((v) => v !== val);

    this.setState({ selections }, () => {
      // this.onChange();
      this.forceUpdate();
    });
  };

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.node.contains(event.target)) {
      return;
    }

    if (this.state.toggled) {
      this.setState({ toggled: false });
    }
  }

  onChange = (_) => {
    this.props.onChange({
      values: this.state.selections,
      name: this.props.name,
    });
  };

  toggle = (e) => {
    if (!e.target.className || e.target.className === "selection") {
      return;
    }

    this.setState({ toggled: !this.state.toggled });
  };

  render() {
    const { title, options, placeholder, displayProp, valueProp } = this.props;
    const { toggled, selections } = this.state;

    // Get appropiate classname
    var selectClassName = toggled ? "multiselect active" : "multiselect";

    if (!title) {
      selectClassName += " no__title";
    }

    return (
      <div ref={(node) => (this.node = node)} className={selectClassName}>
        {title && <label>{title}</label>}
        <div className="combobox" onClick={this.toggle}>
          {selections.length > 0 ? (
            selections.map((selection, sIndex) => {
              // Get name to show
              if (options.length === 0) {
                return null;
              }

              var action = options.find(
                (o) => o[valueProp || "value"] === selection
              );

              return (
                <div key={sIndex} className="selection">
                  {action[displayProp || "name"]}
                  <img
                    height="18"
                    width="18"
                    src={process.env.PUBLIC_URL + "/assets/icons/remove.svg"}
                    alt="close"
                    onClick={() => this.removeSelection(selection)}
                  />
                </div>
              );
            })
          ) : (
            <span className="placeholder">{placeholder}</span>
          )}
        </div>
        <div className="options">
          {options.map((option, index) => {
            var optionValue = option[valueProp || "value"];
            // var selected = selection === option[valueProp || "value"];

            var selected = selections.includes(optionValue);

            return (
              <span
                className={selected ? "selected" : ""}
                key={index}
                onClick={() => this.handleSelection(optionValue)}
              >
                <input type="checkbox" checked={selected} readOnly />
                {displayProp ? option[displayProp] : option.name}
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}
