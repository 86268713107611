const subNavRedcuer = (state = [], action) => {
  switch (action.type) {
    case "ADD":
      return state.concat(action.payload);
    case "NAVTO":
      return state.slice(0, action.payload);
    case "REPLACE":
      return Array.isArray(action.payload)
        ? [...action.payload]
        : [action.payload];
    default:
      return [];
  }
};

export default subNavRedcuer;
