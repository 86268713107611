import React, { Component } from "react";
import Range from "./Range";
import { TabControl, Tab } from "../../../../common";
import Daily from "./Daily";

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
    };
  }

  onDateSelected = (start, end) => {
    var startClone = new Date(start.getTime());
    var endClone = new Date(end.getTime());

    endClone.setDate(endClone.getDate() + 1);

    console.log(startClone);
    console.log(endClone);

    this.props.onDateSelected(startClone, endClone);
  };

  onSwitch = (tabIndex) => {
    this.setState({ tab: tabIndex });
  };

  render() {
    const { tab } = this.state;

    return (
      <div className="c-dashboardFilter">
        <h3 className="c-dashboardFilter__header">Filter</h3>
        <div className="c-dashboardFilter__type">
          <TabControl onSwitch={this.onSwitch}>
            <Tab title="Daily"></Tab>
            <Tab title="Range"></Tab>
          </TabControl>
        </div>
        <div className="c-dashboardFilter__content">
          {tab === 0 ? (
            <Daily onDateSelected={this.onDateSelected} />
          ) : (
            <Range onDateSelected={this.onDateSelected} />
          )}
        </div>
      </div>
    );
  }
}

export default Filter;
