import React, { Component } from "react";
import Machines from "./machines";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { addNavigation } from "../../../actions";
import { gql } from "apollo-boost";
import { withApollo } from "react-apollo";
import Summary from "./Summary";

class Branch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: [],
    };
  }

  componentDidMount() {
    /**
     * Get neccessary info to show on the page
     * 1. Modules
     * 2.
     */

    this.props.client
      .query({
        variables: { companyId: this.props.userAuth.companyId },
        query: gql`
          query getInfo($companyId: String!) {
            company(id: $companyId) {
              modules {
                id
                name
              }
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
        }

        console.log(result.data.company.modules);
      });
  }

  render() {
    const { state: branch } = this.props.location;

    if (!branch) {
      return <Redirect to="/dashboard" />;
    }

    // Generate moduel render
    // let moduleRender = this.state.modules.map((m, index) => <div></div>);

    return (
      <div className="dashboardContent">
        <div>
          <Summary branchId={branch.id} />
        </div>
        <div>
          <Machines branchId={branch.id} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNavigation: (payload) => dispatch(addNavigation(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(Branch))
);
