var defaultVal = {
  itemManagement: undefined
};

const apolloReducer = (state = defaultVal, action) => {
  switch (action.type) {
    case "SETAPOLLOCLIENT":
      console.log("apollo payload", action);

      state[action.payload.name] = action.payload.client;

      console.log("new state", state);

      return state;
    case "CLEARAPOLLOCLIENT":
      return defaultVal;
    default:
      return state;
  }
};

export default apolloReducer;
