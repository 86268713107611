import React, { PureComponent } from "react";

import "./style.scss";

export default class Input extends PureComponent {
  componentDidUpdate(prevProps, prevState) {}

  onChange = (e) => {
    this.props.onChange({
      name: this.props.name,
      value: e.target.value,
    });
  };

  render() {
    const { value, title, placeholder, description, type, error } = this.props;

    return (
      <div className="input">
        <label>{title}</label>
        <input
          autoComplete="off"
          className={error ? "error" : ""}
          type={type ? type : "text"}
          value={value}
          placeholder={placeholder}
          onChange={this.onChange}
        />
        <span className={error ? "error" : "description"}>
          {error ? error : description}
        </span>
      </div>
    );
  }
}
