import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import AWS from "aws-sdk";

import { Header, Input, Select, Form } from "../../../common";

class ItemsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: this.props.userAuth.companyId,
      id: "",
      sku: "",
      itemCode: "",
      name: "",
      displayName: "",
      categories: [],
      categorySelected: undefined,
      itemImage: null,
      branchId: "",
      price: "",
      brands: [],
      brandSelected: undefined,
      units: [],
      unitSelected: undefined,
      branches: [],
      branchSelected: undefined,
      shops: [],
      shopSelected: undefined,
      imageName: undefined,
      image: undefined,
    };
  }

  componentDidMount() {
    this.getBrands(this.props.userAuth.companyId);
    this.getCategories(this.props.userAuth.companyId);
    this.getUnits(this.props.userAuth.companyId);
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches.map((b) => {
          return {
            name: b.name,
            value: b.id,
          };
        });

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState({
          branches,
          branchSelected,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  getBrands = (companyId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { companyId },
        query: gql`
          query getBrands($companyId: String!) {
            brands(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let brands = result.data.brands.map((b) => {
            return {
              name: b.name,
              value: b.id,
            };
          });
          let brandSelected = brands.length === 1 ? brands[0].id : undefined;

          this.setState({ brands, brandSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  getCategories = (companyId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { companyId },
        query: gql`
          query getCategories($companyId: String!) {
            categories(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let categories = result.data.categories.map((c) => {
            return {
              name: c.name,
              value: c.id,
            };
          });
          let categorySelected =
            categories.length === 1 ? categories[0].id : undefined;

          this.setState({ categories, categorySelected });
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
      });
  };

  getGroups = (categoryId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { categoryId },
        query: gql`
          query getGroups($categoryId: String!) {
            groups(categoryId: $categoryId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let groups = result.data.groups.map((c) => {
            return {
              name: c.name,
              value: c.id,
            };
          });
          let groupSelected = groups.length === 1 ? groups[0].id : undefined;

          this.setState({ groups, groupSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  getUnits = (companyId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { companyId },
        query: gql`
          query getUnits($companyId: String!) {
            units(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let units = result.data.units.map((b) => {
            return {
              name: b.name,
              value: b.id,
            };
          });
          let unitSelected = units.length === 1 ? units[0].id : undefined;

          this.setState({ units, unitSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  getShops = (branchId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { branchId },
        query: gql`
          query getShops($branchId: String!) {
            shops(branchId: $branchId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let shops = result.data.shops.map((c) => {
            return {
              name: c.name,
              value: c.id,
            };
          });
          let shopSelected = shops.length === 1 ? shops[0].id : undefined;

          this.setState({ shops, shopSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleFile = (e) => {
    var files = e.target.files;

    if (files.length > 1) {
      // Too many
      alert("You can only select 1 file");
    } else if (files.length === 1) {
      this.setState({ itemImage: files[0] });
    } else {
      this.setState({ itemImage: null });
    }
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value });
  };

  handleBranch = (e) => {
    this.setState({ [e.name]: e.value }, () => {
      this.getShops(e.value);
    });
  };

  handleCategory = (e) => {
    this.setState({ [e.name]: e.value }, () => {
      this.getGroups(e.value);
    });
  };

  onCreate = () => {
    const {
      brandSelected,
      sku,
      itemCode,
      name,
      displayName,
      groupSelected,
      branchSelected,
      shopSelected,
      unitSelected,
      price,
      itemImage,
    } = this.state;

    if (
      !branchSelected ||
      !shopSelected ||
      !brandSelected ||
      !name ||
      !displayName ||
      !sku ||
      !itemCode ||
      !groupSelected ||
      !unitSelected ||
      !price ||
      !itemImage
    ) {
      alert("Please fill in all areas");
      console.error("State for checking missing data", this.state);
      return;
    }

    //console.log(this.state);

    // Configure client for use with Spaces

    var spacesEndpoint = new AWS.Endpoint(
      process.env.REACT_APP_SPACES_ENDPOINT
    );
    var s3 = new AWS.S3({
      endpoint: spacesEndpoint,
      accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
    });

    var params = {
      Body: this.state.itemImage,
      Bucket: process.env.REACT_APP_SPACES_BUCKET,
      Key: `items/${branchSelected}/${itemImage.name}`,
      ACL: "public-read",
      ContentType: itemImage.type,
    };

    s3.upload(params, (err, data) => {
      if (!err) {
        this.props.client
          .mutate({
            context: {
              route: "selfOrdering",
            },
            variables: {
              item: {
                brandId: Number(brandSelected),
                sku,
                itemCode,
                name,
                displayName,
                groupId: Number(groupSelected),
                image: data.Location.toString(),
                branchId: branchSelected,
                shopId: shopSelected,
                price: Number(price),
                unitId: Number(unitSelected),
              },
            },
            mutation: gql`
              mutation createItem($item: ItemInput!) {
                createItem(item: $item) {
                  id
                }
              }
            `,
          })
          .then((result) => {
            if (result.data) {
              alert("Success!");
              this.props.history.goBack();
            }
          })
          .catch((error) => {
            console.log(error.message);
            alert("Unable to create item.");
          });
      } else {
        console.log(err);
      }
    });
  };

  render() {
    const {
      sku,
      itemCode,
      name,
      displayName,
      categories,
      categorySelected,
      groups,
      groupSelected,
      price,
      branches,
      branchSelected,
      shops,
      shopSelected,
      brands,
      brandSelected,
      units,
      unitSelected,
    } = this.state;

    return (
      <div>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title="Add new item">
          <button className="slim" onClick={this.onCreate}>
            Create
          </button>
        </Header>
        <Form>
          <Header title="Select shop" level="4" />
          <Select
            title="Branch"
            value={branchSelected}
            name="branchSelected"
            placeholder="Select a branch."
            options={branches}
            onChange={this.handleBranch}
          />
          {branchSelected && (
            <Select
              title="Shop"
              value={shopSelected}
              name="shopSelected"
              placeholder="Select a shop."
              options={shops}
              onChange={this.handleSelect}
            />
          )}
        </Form>
        <Form>
          <Header title="General information" level="4" />
          <Select
            title="Brand"
            value={brandSelected}
            name="brandSelected"
            placeholder="Select a brand column"
            options={brands}
            onChange={this.handleSelect}
          />
          <Input
            title="Name"
            name="name"
            placeholder="Enter name of item."
            onChange={this.handleInputs}
            value={name}
          />
          <Input
            title="Display name"
            name="displayName"
            placeholder="Enter display name of item."
            onChange={this.handleInputs}
            value={displayName}
          />
          <Input
            title="SKU"
            name="sku"
            placeholder="Enter sku of item."
            onChange={this.handleInputs}
            value={sku}
          />
          <Input
            title="Item code"
            name="itemCode"
            placeholder="Enter itemCode of item."
            onChange={this.handleInputs}
            value={itemCode}
          />
          {units && (
            <Select
              title="Unit"
              value={unitSelected}
              name="unitSelected"
              placeholder="Select a unit column"
              options={units}
              onChange={this.handleSelect}
            />
          )}
          <Input
            title="Price"
            name="price"
            placeholder="Enter price of item."
            onChange={this.handleInputs}
            value={price}
          />
          <label>Image</label>
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={this.handleFile}
          />
        </Form>
        <Form>
          <Header title="Group" level="4" />
          <Select
            title="Category"
            value={categorySelected}
            name="categorySelected"
            placeholder="Select a category column"
            options={categories}
            onChange={this.handleCategory}
          />
          {groups && (
            <Select
              title="Group"
              value={groupSelected}
              name="groupSelected"
              placeholder="Select a group column"
              options={groups}
              onChange={this.handleSelect}
            />
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(ItemsCreate)));
