import React from "react";
import APITable from "../../APITable";

const endCashIn = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let resp = [
    {
      name: "Deposited",
      desc: "Denominations that were deposited into the machine",
      type: "DenominationType[]",
    },
  ];

  return (
    <APITable
      title="End Cash In"
      desc="Finalizes and stops the current cash in operation"
      command="EndCashIn"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      resp={resp}
    />
  );
};

export default endCashIn;
