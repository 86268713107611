import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import { withApollo } from "react-apollo";

import Login from "./components/login";
import Dashboard from "./components/dashboard";
import Watermarks from "./components/watermarks";
import NotFound from "./components/NotFound";
import Developers from "./components/developers";

import HospitalTransactions from "./components/reports/healthcare";
import ReplenishmentReport from "./components/reports/machines/replenishment";
import SkimmingReport from "./components/reports/machines/skimming";
import DepositReport from "./components/reports/machines/deposit";
import ExchangeReport from "./components/reports/machines/exchange";
import ExchangeReportDetail from "./components/reports/machines/exchange/Detail";
import ShiftsReport from "./components/reports/shift";

import Users from "./components/users";

import OverseerCompany from "./globalAdmin/components/company/Company";
import OverseerOverview from "./globalAdmin/components/overview/Overview";
import OverseerUser from "./globalAdmin/components/user/Users";

import Machines from "./components/machines";
import Profile from "./components/profile";

import NavigationBar from "./components/navigation";
import LineCallback from "./components/lineCallback";

// Import CSS Styles
// import "./Fonts.css";
import "./fonts/Fonts.scss";
import "./App.scss";
import "./styles/NavigationBar.scss";
// import "./styles/Header.scss";
import Branches from "./components/branches";
import { connect } from "react-redux";
import { userAuthorized, userSignedOut, toggleNav, addAlert } from "./actions";
import { PrivateRoute, GlobalAdminRoute } from "./PrivateRoute";

import Items from "./components/selfOrdering/items";
import Header from "./components/header";
import Transactions from "./components/reports/selfOrdering/transactions";
import Promotions from "./components/selfOrdering/promotions";
import PromotionConditions from "./components/selfOrdering/promotionConditions";
import Brands from "./components/selfOrdering/brands";
import Categories from "./components/selfOrdering/categories";
import Groups from "./components/selfOrdering/groups";
import Units from "./components/selfOrdering/units";
import Kitchens from "./components/selfOrdering/kitchens";
import CustomerDisplays from "./components/selfOrdering/customerDisplays";
import Shops from "./components/selfOrdering/shops";

import Excels from "./components/selfOrdering/excels";

import AccessControl from "./components/accessControl";
import Alerts from "./components/alerts";
import Floats from "./components/floats";
import Schedules from "./components/schedules";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      navActive: false,
      group: undefined,
      headerTrasparent: true,
      loading: false,
      width: 0,
      height: 0,
      windowSize: undefined,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();

    window.addEventListener("beforeunload", this.onUnload);
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.nav && this.state.windowSize === "small") {
        // Toggle nav off
        this.props.toggleNav();
      }
    }
  }

  determineWindowSize = (width) => {
    return width <= 768 ? "small" : width <= 1024 ? "medium" : "large";
  };

  updateWindowDimensions = () => {
    var newWindowSize = this.determineWindowSize(window.innerWidth);

    if (newWindowSize !== this.state.windowSize) {
      this.setState({ windowSize: newWindowSize }, (_) => {
        if (
          (newWindowSize === "small" && this.props.nav) ||
          (newWindowSize !== "small" && !this.props.nav)
        ) {
          // Toggle nav
          this.props.toggleNav();
        }
      });
    }
  };

  toggleNav = () => {
    this.setState({ navActive: !this.state.navActive });
  };

  closeSideNav = () => {
    this.setState({ navActive: false });
  };

  handleScroll = () => {
    var lastScrollY = window.scrollY;

    if (lastScrollY >= 24 && this.state.headerTrasparent) {
      // this.setState({ headerTrasparent: false });
    } else if (lastScrollY < 24 && !this.state.headerTrasparent) {
      // this.setState({ headerTrasparent: true });
    }
  };

  addAlerts = () => {
    this.props.addAlert({
      type: "info",
      msg: "Message",
      title: "Header",
    });
  };

  render() {
    const { loading, windowSize } = this.state;
    const { isAuth } = this.props.userAuth;

    if (loading) {
      return <h1>Loading</h1>;
    }

    let contentClassName = isAuth ? "main__content" : "main__login";

    return (
      <div id="layout">
        <Route path="/developers" component={Developers} />
        {isAuth && <NavigationBar windowSize={windowSize} />}
        <div className="main__container">
          <Alerts />
          {isAuth && <Header windowSize={windowSize} />}
          <div className={contentClassName}>
            <Switch>
              <GlobalAdminRoute
                path="/overseer/dashboard"
                component={OverseerOverview}
              />
              <GlobalAdminRoute
                path="/overseer/companies"
                component={OverseerCompany}
              />
              <GlobalAdminRoute
                path="/overseer/users"
                component={OverseerUser}
              />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/branches" component={Branches} />
              <PrivateRoute path="/machines" component={Machines} />
              <PrivateRoute path="/users" component={Users} />
              <PrivateRoute path="/accessControl" component={AccessControl} />
              <PrivateRoute path="/schedules" component={Schedules} />
              <PrivateRoute
                path="/reports/healthcare"
                component={HospitalTransactions}
              />
              <PrivateRoute
                path="/reports/machines/replenishments"
                component={ReplenishmentReport}
              />
              <PrivateRoute
                path="/reports/machines/skimmings"
                component={SkimmingReport}
              />
              <PrivateRoute
                path="/reports/machines/deposits"
                component={DepositReport}
              />
              <PrivateRoute
                exact
                path="/reports/machines/exchanges"
                component={ExchangeReport}
              />
              <PrivateRoute
                path="/reports/machines/exchanges/detail/:id"
                component={ExchangeReportDetail}
              />
              <PrivateRoute path="/floats" component={Floats} />
              <PrivateRoute path="/watermarks" component={Watermarks} />
              <PrivateRoute path="/brands" component={Brands} />
              <PrivateRoute path="/items" component={Items} />
              <PrivateRoute path="/categories" component={Categories} />
              <PrivateRoute path="/groups" component={Groups} />
              <PrivateRoute path="/units" component={Units} />
              <PrivateRoute path="/shops" component={Shops} />

              <PrivateRoute
                path="/reports/pos/transactions"
                component={Transactions}
              />
              <PrivateRoute path="/reports/shifts" component={ShiftsReport} />
              <PrivateRoute path="/promotions" component={Promotions} />
              <PrivateRoute
                path="/selfOrdering/promotionConditions"
                component={PromotionConditions}
              />
              <PrivateRoute path="/brands" component={Brands}></PrivateRoute>
              <PrivateRoute
                path="/app/kitchens"
                component={Kitchens}
              ></PrivateRoute>
              <PrivateRoute
                path="/app/customerDisplays"
                component={CustomerDisplays}
              ></PrivateRoute>
              <PrivateRoute path="/selfOrdering/excels" component={Excels} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/line/callback" component={LineCallback} />
              <Route path="/" exact render={(props) => <Login {...props} />} />
              <Route path="/login" render={(props) => <Login {...props} />} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userAuthorized: (userId) => dispatch(userAuthorized(userId)),
    userSignedOut: () => dispatch(userSignedOut()),
    toggleNav: () => dispatch(toggleNav()),
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
    nav: state.nav,
  };
};

export default withApollo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
);
