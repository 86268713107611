import React from "react";

import "./style.scss";

const Form = props => {
  const { loading } = props;

  var className = loading ? "form loading" : "form show";

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div className={className}>
      <>{props.children}</>
      <div className="ph">
        <div className="ph-header"></div>
        <div className="ph-content m"></div>
        <div className="ph-content l"></div>
        <div className="ph-content s"></div>
        <div className="bottom">
          <div className="ph-content submit"></div>
        </div>
      </div>
    </div>
  );
};

export default Form;
