import React, { Component } from "react";

import LocationSelector from "../common/LocationSelector";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";

const thaiDenoms = [
  { value: 1000, device: 1 },
  { value: 500, device: 1 },
  { value: 100, device: 1 },
  { value: 50, device: 1 },
  { value: 20, device: 1 },
  { value: 10, device: 2 },
  { value: 5, device: 2 },
  { value: 2, device: 2 },
  { value: 1, device: 2 },
  { value: 0.5, device: 2 },
  { value: 0.25, device: 2 },
];

const singaporeDenoms = [
  { value: 1000, device: 1 },
  { value: 100, device: 1 },
  { value: 50, device: 1 },
  { value: 10, device: 1 },
  { value: 5, device: 1 },
  { value: 2, device: 1 },
  { value: 1, device: 2 },
  { value: 0.5, device: 2 },
  { value: 0.2, device: 2 },
  { value: 0.1, device: 2 },
  { value: 0.05, device: 2 },
];

class WatermarkCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currency: "",
      editMode: false,
      watermarkId: "",
      watermarkName: "",
      pieces: [],
      rowDisabled: [],
      denominationsAvailable: [],
      denominationsDevice: [],
      branch: undefined,
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePiecesChange = (event) => {
    let pieces = this.state.pieces.slice();

    if (!isNaN(event.target.value)) {
      pieces[event.target.name] = Number(event.target.value);
      this.setState({ pieces: pieces });
    } else if (event.target.value === "") {
      pieces[event.target.name] = 0;
      this.setState({ pieces: pieces });
    }
  };

  handleThresholdChange = (event) => {
    let pieces = this.state.thresholds.slice();

    if (!isNaN(event.target.value)) {
      pieces[event.target.name] = Number(event.target.value);
      this.setState({ thresholds: pieces });
    } else if (event.target.value === "") {
      pieces[event.target.name] = 0;
      this.setState({ thresholds: pieces });
    }
  };

  onChangeExcludeValue = (event) => {
    const rowNo = Number(event.target.name);

    let tempRowDisabled = this.state.rowDisabled;
    let tempPieces = this.state.pieces;
    let tempThresholds = this.state.thresholds;

    tempRowDisabled[rowNo] = !event.target.checked;
    tempPieces[rowNo] = 0;
    tempThresholds[rowNo] = 0;

    this.setState({
      rowDisabled: tempRowDisabled,
      pieces: tempPieces,
      thresholds: tempThresholds,
    });
  };

  createWatermark = () => {
    var watermark = [];

    this.state.denominationsAvailable.forEach((denom, index) => {
      watermark.push({
        device: this.state.denominationsDevice[index],
        value: denom,
        pieces: this.state.pieces[index],
        include: !this.state.rowDisabled[index],
      });
    });

    let watermarkDetail = {
      name: this.state.watermarkName,
      currency: this.state.currency,
      denominations: watermark,
    };

    this.props.client
      .mutate({
        variables: {
          watermarkDetail,
          branch: this.state.branch.id,
        },
        mutation: gql`
          mutation createWatermark(
            $watermarkDetail: WatermarkInput!
            $branch: String!
          ) {
            createWatermark(
              watermarkDetail: $watermarkDetail
              branchId: $branch
            ) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("success");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  branchSelected = (branch) => {
    // Render denomination selection
    let currency = branch.currency;
    let pieces = [];
    let thresholds = [];
    let rowDisabled = [];
    let denominationsAvailable = [];
    let denominationsDevice = [];

    let denomsToUse = [];

    if (currency === "THB") {
      denomsToUse = thaiDenoms;
    } else {
      denomsToUse = singaporeDenoms;
    }

    for (const denom of denomsToUse) {
      pieces.push(0);
      thresholds.push(0);
      rowDisabled.push(true);
      denominationsAvailable.push(denom.value);
      denominationsDevice.push(denom.device);
    }

    this.setState({
      branch,
      currency,
      pieces,
      thresholds,
      rowDisabled,
      denominationsAvailable,
      denominationsDevice,
    });
  };

  render() {
    const { currency, editMode, pieces, watermarkName } = this.state;

    var grandTotal = pieces.reduce((previous, current, index) => {
      return previous + current * this.state.denominationsAvailable[index];
    }, 0);

    return (
      <div>
        <div>
          <div className="create__info">
            <label>Name</label>
            <br />
            <input
              type="text"
              name="watermarkName"
              placeholder="Name"
              value={watermarkName}
              onChange={this.handleInputChange}
            />
            <br />
            <LocationSelector
              mode="branch"
              branchSelected={this.branchSelected}
            />
            <br />
            <button
              className="cancel"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Back
            </button>
            {editMode ? (
              <button className="submit" onClick={this.saveWatermark}>
                Save
              </button>
            ) : (
              <button className="submit" onClick={this.createWatermark}>
                Create
              </button>
            )}
          </div>
        </div>
        <div>
          <div className="create__denominations">
            <div>
              <h2>Denomination</h2>
              <span className="subHeader">{currency}</span>
              <span className="total">
                Grand Total: <b>{grandTotal}</b>
              </span>
            </div>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Include</th>
                    <th>Pieces</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.denominationsAvailable.map((denom, index) => (
                    <tr key={`denom${index}`}>
                      <td>{`${denom}`}</td>
                      <td>
                        <input
                          type="checkbox"
                          name={index}
                          checked={!this.state.rowDisabled[index]}
                          onChange={this.onChangeExcludeValue}
                        />
                      </td>
                      <td>
                        <input
                          name={index}
                          placeholder="0"
                          maxLength="4"
                          onChange={this.handlePiecesChange}
                          type="text"
                          value={
                            this.state.rowDisabled[index] ? "-" : pieces[index]
                          }
                          disabled={
                            this.state.rowDisabled[index] ? "disabled" : ""
                          }
                        />
                      </td>
                      <td>{denom * pieces[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(WatermarkCreate);
