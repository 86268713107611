import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import { gql } from "apollo-boost";
import { addNavigation, replaceNavigation, navigateTo } from "../../../actions";

import QueuesList from "./QueuesList";

import "./style.scss";
import List from "./List";

class CustomerDisplays extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      loading: true,
      branches: [],
      branchSelected: undefined,
    };
  }

  componentDidMount() {
    this.props.replaceNavigation({
      url: "customerDisplays",
      title: "SelfOrdering",
    });

    this.props.addNavigation({
      url: "customerDisplays",
      title: "customerDisplay",
    });

    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
            user(id: $userId) {
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches;

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState({
          branches: branches,
          branchSelected,
          loading: false,
          user: result.data.user.name,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  render() {
    const { match } = this.props;

    return (
      <div>
        <Route exact path={match.url} render={(props) => <List {...props} />} />
        <Route
          exact
          path={`${match.url}/:kitchenId`}
          render={(props) => <QueuesList {...props} />}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNavigation: (payload) => dispatch(addNavigation(payload)),
    replaceNavigation: (payload) => dispatch(replaceNavigation(payload)),
    navigateTo: (payload) => dispatch(navigateTo(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(CustomerDisplays))
);
