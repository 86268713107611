import React, { Component } from "react";
import { withApollo, Subscription } from "react-apollo";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { ApolloClient, InMemoryCache } from "apollo-boost";
import { WebSocketLink } from "apollo-link-ws";
import Card from "./Card";
import { Header } from "../../common";
import Advert from "./Advert";

const sub2 = gql`
  subscription onUpdateQueue($kitchenId: String!) {
    onCustomerDisplayQueueUpdated(kitchenId: $kitchenId) {
      id
      queueNumber
      status
    }
  }
`;

let link = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WS_SELFORDERING,
  options: {
    timeout: -1,
    reconnect: true,
  },
});

// link.maxConnectTimeGenerator.duration = () =>
//   this.subClient.maxConnectTimeGenerator.max;

let subscriptionClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    watchMutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    mutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

class QueuesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kitchenSelected: this.props.match.params.kitchenId,
      kitchenName: "",
      queues: [],
      isFullScreen: false,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);

    this.getKitchenInfo()
      .then(() => {
        this.getKitchenQueues();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction = (event) => {
    // * Only listen for ESC key
    if (event.keyCode !== 27) {
      return;
    }

    // * Skip if already full screen
    if (!this.state.isFullScreen) {
      return;
    }

    this.setState({ isFullScreen: false });
  };

  getKitchenInfo = () => {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          context: {
            route: "selfOrdering",
          },
          variables: { id: this.state.kitchenSelected },
          query: gql`
            query getKitchen($id: String!) {
              kitchen(ID: $id) {
                name
              }
            }
          `,
        })
        .then((result) => {
          if (result.data) {
            this.setState({ kitchenName: result.data.kitchen.name }, () => {
              resolve(true);
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getKitchenQueues = () => {
    // get queues of kitchen
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { kitchenId: this.state.kitchenSelected, status: 0 },
        query: gql`
          query getNewQueues($kitchenId: String!, $status: Int) {
            QueuesByKitchen(kitchenId: $kitchenId, status: $status) {
              id
              queueNumber
              status
              transactionDetails {
                item {
                  id
                  brand {
                    name
                  }
                  sku
                  itemCode
                  name
                  displayName
                  group {
                    name
                  }
                  image
                }
                quantity
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          console.log("Result: ", result.data);
          let queues = result.data.QueuesByKitchen;

          // Set state to render
          this.setState({ queues });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  onQueueStatusUpdated = (queue) => {
    // Kitchen has been updated
    console.log("Queue", queue);

    let { queues } = this.state;

    const beforeCount = queues.length;

    console.log("Current queues", queues);

    const newQueues = queues.filter((q) => q.id !== queue.id);
    const afterCount = newQueues.length;

    console.log("New Queues", newQueues);

    if (afterCount !== beforeCount) {
      this.setState({ queues });
    }
  };

  toggleFullScreen = () => {
    this.setState({ isFullScreen: !this.state.isFullScreen });
  };

  getQueueRender = (queues) => {
    if (!queues) {
      return <h2>No queues completed</h2>;
    }

    return queues.map((q) => (
      <div key={q.id} className="queue__item">
        <p>{q.queueNumber}</p>
      </div>
    ));
  };

  render() {
    const { queues, kitchenSelected, kitchenName, isFullScreen } = this.state;

    let queueToRender = queues ? (
      queues.map((n) => (
        <Card
          key={n.id}
          detail={n}
          updateQueueStatus={this.updateQueueStatus}
        />
      ))
    ) : (
      <h2>No queues</h2>
    );

    return (
      <>
        {!isFullScreen && (
          <button
            className="return"
            onClick={() => this.props.history.goBack()}
          >
            Back
          </button>
        )}
        <div className={isFullScreen ? "cDisplay fullScreen" : "cDisplay"}>
          {isFullScreen && <Advert />}
          <div className="cDisplay__content">
            <div className="cDisplay__info">
              {!isFullScreen ? (
                <Header title={kitchenName}>
                  <button
                    className="slim"
                    onClick={() => this.toggleFullScreen()}
                  >
                    Full Screen
                  </button>
                </Header>
              ) : (
                <h1>{kitchenName}</h1>
              )}
            </div>
            <div className="cDisplay__queues">
              <Subscription
                subscription={sub2}
                variables={{
                  kitchenId: kitchenSelected,
                }}
                client={subscriptionClient}
              >
                {(result) => {
                  if (!result.loading) {
                    console.log("Sub data result", result.data);
                  }

                  if (
                    result.data &&
                    result.data.onCustomerDisplayQueueUpdated
                  ) {
                    const newQueues = result.data.onCustomerDisplayQueueUpdated;

                    let renderToAddOn = newQueues.map((n) => (
                      <Card
                        key={n.id}
                        detail={n}
                        updateQueueStatus={this.updateQueueStatus}
                      />
                    ));

                    return renderToAddOn;
                  }

                  return queueToRender;
                }}
              </Subscription>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(QueuesList)));
