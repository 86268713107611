import { gql } from "apollo-boost";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Form, Header, Select } from "../common";
import qs from "querystring";

let availHours = [
  { name: "0", value: "00" },
  { name: "1", value: "01" },
  { name: "2", value: "02" },
  { name: "3", value: "03" },
  { name: "4", value: "04" },
  { name: "5", value: "05" },
  { name: "6", value: "06" },
  { name: "7", value: "07" },
  { name: "8", value: "08" },
  { name: "9", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
  { name: "13", value: "13" },
  { name: "14", value: "14" },
  { name: "15", value: "15" },
  { name: "16", value: "16" },
  { name: "17", value: "17" },
  { name: "18", value: "18" },
  { name: "19", value: "19" },
  { name: "20", value: "20" },
  { name: "21", value: "21" },
  { name: "22", value: "22" },
  { name: "23", value: "23" },
];

let availMins = [
  { name: "0", value: "00" },
  { name: "15", value: "15" },
  { name: "30", value: "30" },
  { name: "45", value: "45" },
];

class Edit extends Component {
  constructor(props) {
    super(props);

    let params = qs.parse(this.props.location.search.replace("?", ""));

    this.state = {
      taskId: params.id || undefined,
      branch: undefined,
      loading: true,
      hours: "00",
      minutes: "00",
    };
  }

  componentDidMount() {
    if (!this.state.taskId) {
      alert("No task Id to edit");
      this.props.history.goBack();
    }

    this.getSchedules();
  }

  getSchedules = () => {
    this.props.client
      .query({
        variables: {
          taskId: +this.state.taskId,
        },
        query: gql`
          query get($taskId: Int!) {
            taskScheduleById(taskScheduleId: $taskId) {
              cutOffTime
              branch {
                id
                name
              }
            }
          }
        `,
      })
      .then(({ data }) => {
        if (!data) {
          return;
        }

        let { taskScheduleById } = data;

        let cutOffTimeSplit = taskScheduleById.cutOffTime.split(":");

        let offset = new Date().getTimezoneOffset() / 60;

        let hrs = +cutOffTimeSplit[0] - offset;

        hrs = hrs >= 24 ? hrs - 24 : hrs;

        console.log(hrs);

        this.setState({
          hours: ("0" + hrs).slice(-2),
          minutes: cutOffTimeSplit[1],
          branch: taskScheduleById.branch,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  };

  handleInputs = (event) => {
    this.setState({ [event.name]: event.value });
  };

  edit = () => {
    const { hours, minutes } = this.state;

    let cutOffTime = `${hours}:${minutes}`;

    this.props.client
      .mutate({
        variables: {
          taskId: +this.state.taskId,
          cutOffTime,
        },
        mutation: gql`
          mutation get($cutOffTime: String!, $taskId: Int!) {
            editTaskSchedule(taskScheduleId: $taskId, cutOffTime: $cutOffTime)
          }
        `,
      })
      .then(({ data }) => {
        if (!data) {
          return;
        }

        if (!data) {
          alert("Unable to edit task schedule!");
          return;
        }

        alert("Success");
        this.props.history.push("/schedules");
      })
      .catch((error) => {
        console.error(error);
        alert("Failed");
      });
  };

  render() {
    const { hours, minutes, branch } = this.state;
    return (
      <>
        <button
          className="return"
          onClick={() => this.props.history.push("/schedules")}
        >
          Return
        </button>
        <Header title={`Editing ${branch ? branch.name : "loading"}`}>
          <button className="slim" onClick={this.edit}>
            Edit
          </button>
        </Header>
        <Form>
          <Select
            title="Hours"
            value={hours}
            name="hours"
            placeholder="Select hours"
            options={availHours}
            onChange={this.handleInputs}
          />
          <Select
            title="Minutes"
            value={minutes}
            name="minutes"
            placeholder="Select minutes"
            options={availMins}
            onChange={this.handleInputs}
          />
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps)(Edit));
