import React, { Component } from "react";
import { withApollo } from "react-apollo";
import MachineTransactionSummary from "./MachineTransactionSummary";
import Filter from "./Filter";
import PointOfSales from "./PointOfSales";
import { startOfDay } from "date-fns";

class Summary extends Component {
  constructor(props) {
    super(props);

    let start = new Date();
    let end = new Date();
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    end.setDate(end.getDate() + 1);

    this.state = {
      range: {
        start,
        end,
      },
    };
  }

  onDateChanged = (start, end) => {
    let tmp1 = startOfDay(start);
    let tmp2 = startOfDay(end);

    this.setState({ range: { start: tmp1, end: tmp2 } });
  };

  render() {
    return (
      <div className="dashboard__summary">
        <Filter onDateSelected={this.onDateChanged} />
        <PointOfSales
          branchId={this.props.branchId}
          filter={this.state.range}
        />
        <MachineTransactionSummary
          branchId={this.props.branchId}
          filter={this.state.range}
        />
      </div>
    );
  }
}

export default withApollo(Summary);
