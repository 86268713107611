import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";

// Import related components
import CompanyList from "./CompanyList";
import CreateCompany from "./CompanyCreate";

class Company extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  createNewCompany = () => {};

  render() {
    const { match } = this.props;

    console.log(match);

    return (
      <div>
        <Route exact path={match.url} render={CompanyList} />
        <Route exact path={`${match.url}/create`} render={CreateCompany} />
      </div>
    );
  }
}

export default withRouter(withApollo(Company));
