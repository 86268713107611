import React, { Component } from "react";
import { gql } from "apollo-boost";
import { withApollo } from "react-apollo";

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grandTotal: 0,
      cashTotal: 0,
      privilegeTotal: 0,
      cardTotal: 0,
      qrTotal: 0
    };
  }

  componentDidMount() {
    this.getSummary();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedDay !== this.props.selectedDay ||
      prevProps.branchId !== this.props.branchId ||
      prevProps.machineId !== this.props.machineId
    ) {
      this.getSummary();
    }
  }

  getSummary = () => {
    if (!this.props.branchId) {
      return;
    }
    this.props.client
      .query({
        variables: {
          branchId: this.props.branchId,
          machineId: this.props.machineId,
          date: this.props.selectedDay
        },
        query: gql`
          query getTransactions(
            $branchId: String!
            $machineId: String
            $date: DateTime
          ) {
            transactionSummary(
              branchId: $branchId
              machineId: $machineId
              date: $date
            ) {
              cash
              privilege
              card
              qr
            }
          }
        `
      })
      .then(result => {
        if (result.data) {
          const transactionSummary = result.data.transactionSummary;

          const { cash, privilege, card, qr } = transactionSummary;

          this.setState({
            grandTotal: this.currencyFormat(cash + privilege),
            cashTotal: this.currencyFormat(cash),
            privilegeTotal: this.currencyFormat(privilege),
            cardTotal: this.currencyFormat(card),
            qrTotal: this.currencyFormat(qr)
          });
        } else {
          alert("No sales date of the requested parameters");
        }
      })
      .catch(error => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  currencyFormat(amount) {
    var format = new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
      minimumFractionDigits: 2
    });

    return format.format(amount);
  }

  render() {
    const {
      grandTotal,
      cashTotal,
      privilegeTotal,
      cardTotal,
      qrTotal
    } = this.state;

    return (
      <>
        <div className="grand">
          <h4>{grandTotal}</h4>
          <h3>Grand sales total</h3>
        </div>
        <div className="detail">
          <h3>Cash</h3>
          <h4>{cashTotal}</h4>
        </div>
        <div className="detail">
          <h3>Card</h3>
          <h4>{cardTotal}</h4>
        </div>
        <div className="detail">
          <h3>QR</h3>
          <h4>{qrTotal}</h4>
        </div>
        <div className="detail">
          <h3>Privilege</h3>
          <h4>{privilegeTotal}</h4>
        </div>
      </>
    );
  }
}

export default withApollo(Summary);
