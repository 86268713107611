import React, { Component } from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { connect } from "react-redux";

class Edit extends Component {
  constructor(props) {
    super(props);

    console.log(props);

    // Check whether neccessary states are provided
    let state = props.location.state;

    if (!state || !state.watermark) {
      this.props.history.goBack();
    }

    let watermark = state.watermark;

    this.state = {
      watermarkId: watermark.id,
      currency: watermark.currency,
      branch: watermark.branch.name,
      watermarkName: watermark.name,
      pieces: [],
      rowDisabled: [],
      denominationsAvailable: [],
      denominationsDevice: [],
    };
  }

  componentDidMount() {
    let pieces = [];
    let rowDisabled = [];
    let denominationsAvailable = [];
    let denominationsDevice = [];

    for (const denom of this.props.location.state.watermark.denominations) {
      pieces.push(denom.pieces);
      rowDisabled.push(!denom.include);
      denominationsAvailable.push(denom.value);
      denominationsDevice.push(denom.device);
    }

    this.setState({
      pieces,
      rowDisabled,
      denominationsAvailable,
      denominationsDevice,
    });
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePiecesChange = (event) => {
    let pieces = this.state.pieces.slice();

    if (!isNaN(event.target.value)) {
      pieces[event.target.name] = Number(event.target.value);
      this.setState({ pieces: pieces });
    } else if (event.target.value === "") {
      pieces[event.target.name] = 0;
      this.setState({ pieces: pieces });
    }
  };

  onChangeExcludeValue = (event) => {
    const rowNo = Number(event.target.name);

    let tempRowDisabled = this.state.rowDisabled;
    let tempPieces = this.state.pieces;

    tempRowDisabled[rowNo] = !event.target.checked;
    tempPieces[rowNo] = 0;

    this.setState({
      rowDisabled: tempRowDisabled,
      pieces: tempPieces,
    });
  };

  saveWatermark = () => {
    var watermark = [];

    this.state.denominationsAvailable.forEach((denom, index) => {
      watermark.push({
        device: this.state.denominationsDevice[index],
        value: denom,
        pieces: this.state.pieces[index],
        include: !this.state.rowDisabled[index],
      });
    });

    this.props.client
      .mutate({
        variables: {
          watermarkId: this.state.watermarkId,
          name: this.state.watermarkName,
          denominations: watermark,
        },
        mutation: gql`
          mutation updateWatermark(
            $watermarkId: String!
            $name: String!
            $denominations: [WatermarkDenominationInput!]!
          ) {
            updateWatermark(
              watermarkId: $watermarkId
              name: $name
              denominations: $denominations
            )
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("success");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  render() {
    const { currency, pieces, watermarkName, branch } = this.state;

    var grandTotal = pieces.reduce((previous, current, index) => {
      return previous + current * this.state.denominationsAvailable[index];
    }, 0);

    return (
      <div>
        <div>
          <div className="create__info">
            <span>Branch</span>
            <br />
            <label>{branch}</label>
            <br />
            <label>Watermark name</label>
            <br />
            <input
              type="text"
              name="watermarkName"
              placeholder="Name"
              value={watermarkName}
              onChange={this.handleInputChange}
            />
            <br />
            <button
              className="cancel"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Back
            </button>
            <button className="submit" onClick={this.saveWatermark}>
              Save
            </button>
          </div>
        </div>
        <div>
          <div className="create__denominations">
            <div>
              <h2>Denomination</h2>
              <span className="subHeader">{currency}</span>
              <span className="total">
                Grand Total: <b>{grandTotal}</b>
              </span>
            </div>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Include</th>
                    <th>Pieces</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.denominationsAvailable.map((denom, index) => (
                    <tr key={`denom${index}`}>
                      <td>{`${denom}`}</td>
                      <td>
                        <input
                          type="checkbox"
                          name={index}
                          checked={!this.state.rowDisabled[index]}
                          onChange={this.onChangeExcludeValue}
                        />
                      </td>
                      <td>
                        <input
                          name={index}
                          placeholder="0"
                          maxLength="4"
                          onChange={this.handlePiecesChange}
                          type="text"
                          value={
                            this.state.rowDisabled[index] ? "-" : pieces[index]
                          }
                          disabled={
                            this.state.rowDisabled[index] ? "disabled" : ""
                          }
                        />
                      </td>
                      <td>{denom * pieces[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps)(Edit));
