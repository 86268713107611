import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { gql } from "apollo-boost";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Header } from "../common";
import { addAlert } from "../../actions";
import moment from "moment";

class MachineList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      machines: undefined,
    };
  }

  componentDidMount() {
    this.getMachines();
  }

  getMachines = () => {
    this.props.client
      .query({
        variables: {
          companyId: this.props.userAuth.companyId,
        },
        query: gql`
          query getMachines($companyId: String!) {
            companyMachines(companyId: $companyId) {
              id
              name
              code
              createdOn
              cashDevices {
                name
              }
              user {
                name
              }
            }
          }
        `,
      })
      .then((result) => {
        this.setState({ machines: result.data.companyMachines });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  disableMachine = (machine) => {
    if (!window.confirm(`Are you sure you want to delete ${machine.name}?`)) {
      return;
    }

    this.props.addAlert({
      type: "info",
      title: "Deleting",
      msg: "Hang tight, while we're applying changes",
    });

    this.props.client
      .mutate({
        variables: {
          userId: this.props.userAuth.userId,
          machineId: machine.id,
        },
        mutation: gql`
          mutation disable($userId: String!, $machineId: String!) {
            disableMachine(userId: $userId, machineId: $machineId)
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.props.addAlert({
            type: "success",
            title: "All set",
            msg: `Machine deleted`,
          });

          this.getMachines();
        } else {
          this.props.addAlert({
            type: "error",
            title: "Failed to delete machine",
            msg: result.errors[0].message,
          });
        }
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Failed to delete machine",
          msg: error.message,
        });
      });
  };

  render() {
    const { match } = this.props;
    const { machines } = this.state;

    return (
      <div className="machine_list">
        <Header title="Machines">
          <Link to={`${match.url}/create`}>
            <button className="slim">Create</button>
          </Link>
        </Header>
        <div>
          <table className="machine__table">
            <thead>
              <tr>
                <th>#</th>
                <th>Code</th>
                <th>Name</th>
                <th>Cash devices</th>
                <th>Created on</th>
                <th>Created by</th>
              </tr>
            </thead>
            <tbody>
              {machines ? (
                machines.map((m, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{m.code}</td>
                    <td>{m.name}</td>
                    <td>
                      {m.cashDevices.map((cd) => (
                        <span key={cd.name}>{cd.name}</span>
                      ))}
                    </td>
                    <td>{moment(m.createdOn).format("D/M/YY HH:mm")}</td>
                    <td>{m.user.name}</td>
                    <td>
                      <button
                        className="slim"
                        onClick={() =>
                          this.props.history.push(
                            `${this.props.match.url}/edit?id=${m.id}`
                          )
                        }
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="slim"
                        onClick={() => this.disableMachine(m)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="100%">No machines have been added</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(MachineList))
);
