import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admins: []
    };
  }

  componentDidMount() {
    // Get all users that are admins
    this.props.client
      .query({
        query: gql`
          query {
            admins {
              name
              group {
                name
                adminLevel
              }
              company {
                name
              }
            }
          }
        `
      })
      .then(result => {
        this.setState({ admins: result.data.admins });
      })
      .catch(error => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  addUser = () => {
    const { match } = this.props;
    this.props.history.push(`${match.url}/create`);
  };

  render() {
    const { admins } = this.state;
    return (
      <div>
        <button onClick={this.addUser}>Add user</button>
        <h1>Users</h1>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Level</th>
              <th>Company</th>
            </tr>
          </thead>
          <tbody>
            {admins &&
              admins.map((admin, index) => (
                <tr key={`user${index}`}>
                  <td>{index + 1}</td>
                  <td>{admin.name}</td>
                  <td>{`${admin.group.name} (${admin.group.adminLevel})`}</td>
                  <td>{admin.company ? admin.company.name : "-"}</td>
                  <td>
                    <button onClick={() => this.editUser(admin._id)}>
                      edit
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(withApollo(UserList));
