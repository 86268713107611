import React from "react";
import APITable from "../../APITable";

const collect = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [];
  let resp = [
    {
      name: "Collected",
      desc: "Denominations that were collected",
      type: "DenominationType[]",
      el: [
        {
          name: "Denominations",
          desc: "Recycling denominations that were collected",
          type: "DenominationType[]",
        },
        {
          name: "Mix",
          desc: "Mix denominations that were collected",
          type: "DenominationType[]",
        },
        {
          name: "IFCassette",
          desc:
            "Denomination collected that were inside the Interface cassette",
          type: "DenominationType[]",
        },
      ],
    },
  ];

  return (
    <APITable
      title="Verification Collection"
      desc={[
        "Perform a verification collection on the machine",
        "• Required notes to be collected will transported to the Collection Cassette only",
        "• But coins will be transported to the Exit hopper only",
      ]}
      command="VerifyCollect"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default collect;
