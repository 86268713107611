import React, { Component } from "react";
import Input from "../../common/Input";
import { Form } from "../../common";

export default class BasicInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      username: "",
      authKey: "",
      password: "",
      verifyPassword: "",
      forcePasswordUpdate: false,
    };
  }

  handleInputs = (e) => {
    if (e.target?.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked }, this.onChange);
    } else {
      this.setState({ [e.name]: e.value }, this.onChange);
    }
  };

  onChange = () => {
    this.props.onChange(this.state);
  };

  render() {
    const {
      name,
      username,
      authKey,
      password,
      verifyPassword,
      forcePasswordUpdate,
    } = this.state;

    const {
      nameError,
      usernameError,
      authKeyError,
      passwordError,
      verifyPasswordError,
    } = this.props.validation;

    return (
      <Form>
        <h4>Basic Information</h4>
        <div>
          <Input
            title="Name"
            name="name"
            placeholder="Enter your name"
            onChange={this.handleInputs}
            value={name}
            error={nameError}
          />
          <Input
            title="Username"
            name="username"
            placeholder="Username"
            onChange={this.handleInputs}
            value={username}
            error={usernameError}
          />
          <Input
            title="Authorization Key"
            name="authKey"
            placeholder="Authorization Key"
            onChange={this.handleInputs}
            description="Key must be a whole number"
            value={authKey}
            error={authKeyError}
          />
          <div className="password__section">
            <Input
              title="Password"
              name="password"
              placeholder="Enter your password"
              onChange={this.handleInputs}
              type="password"
              value={password}
              error={passwordError}
            />
            <Input
              title="Verify password"
              name="verifyPassword"
              placeholder="Verify password"
              onChange={this.handleInputs}
              type="password"
              value={verifyPassword}
              error={verifyPasswordError}
            />
          </div>
          <label>
            <input
              type="checkbox"
              name="forcePasswordUpdate"
              checked={forcePasswordUpdate}
              onChange={this.handleInputs}
            />
            Force password change on login
          </label>
        </div>
      </Form>
    );
  }
}
