import React from "react";
import APITable from "../../APITable";

const enableDenominations = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [
    {
      name: "Denominations",
      desc: "Denominations to be permitted",
      type: "DenominationType[]",
    },
  ];

  let resp = [];

  return (
    <APITable
      title="Enable Denominations"
      desc="Permit denominations to be accepted by the machine"
      command="EnableDenominations"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default enableDenominations;
