import React from "react";
import APITable from "../../APITable";

const lock = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [
    {
      name: "Device",
      desc:
        "Define which device collection unit to lock (1 -> Note, 2 -> Coin)",
      type: "string",
    },
  ];

  let resp = [];

  return (
    <APITable
      title="Lock Collection Unit"
      desc="Locks the collection unit of the specified device"
      command="LockCollectionUnit"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default lock;
