import React, { Component } from "react";
import { addAlert } from "../../actions";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Form, Header, Input } from "../common";
import { gql } from "apollo-boost";
import EditAccessibility from "./EditAccessibility";

class Basic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      name: "",
      username: "",
      authKey: "",
      userId: props.userId,
    };
  }

  componentDidMount() {
    const { userId } = this.state;

    // Get user name and info
    this.props.client
      .query({
        variables: { userId },
        query: gql`
          query getUserInfo($userId: String!) {
            user(id: $userId) {
              name
              username
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          console.log(JSON.stringify(result.data));

          const { name, username } = result.data.user;

          this.setState({
            name: name,
            username: username,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  }

  handleInputs = (e) => {
    this.setState({ [e.name]: e.value });
  };

  updateBasicInfo = () => {
    const { userId, name, username, authKey } = this.state;
    if (!name || !username) {
      return;
    }

    this.props.addAlert({
      type: "info",
      title: "Applying new changes",
      msg: "Hang tight, while we're applying changes",
    });

    this.props.client
      .mutate({
        variables: {
          userId,
          name,
          username,
          authKey,
        },
        mutation: gql`
          mutation update(
            $userId: String!
            $name: String!
            $username: String!
            $authKey: String
          ) {
            updateUserBasicInfo(
              userId: $userId
              name: $name
              username: $username
              authKey: $authKey
            ) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.props.addAlert({
            type: "success",
            title: "All set",
            msg: `Updated ${name} basic info`,
          });

          this.setState({
            displayName: name,
            name,
            username,
            authKey: "",
          });
        } else {
          this.props.addAlert({
            type: "error",
            title: "Failed to update user",
            msg: result.errors[0].message,
          });
        }
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Failed to update user",
          msg: error.message,
        });
      });
  };

  render() {
    const { loading, name, username, authKey, userId } = this.state;

    let accessValidation = {
      authLevelError: "",
      groupError: "",
      branchError: "",
    };

    return (
      <>
        <Form loading={loading}>
          <Header title="Basic information" level="3" />
          <Input
            title="Name"
            name="name"
            placeholder="Enter your name"
            onChange={this.handleInputs}
            value={name}
          />
          <Input
            title="Username"
            name="username"
            placeholder="Enter your username"
            onChange={this.handleInputs}
            value={username}
          />
          <Input
            title="Authorization key"
            name="authKey"
            placeholder="Enter a auth key to change"
            onChange={this.handleInputs}
            value={authKey}
          />
          <Header level="3">
            <button className="main slim" onClick={this.updateBasicInfo}>
              Update
            </button>
          </Header>
        </Form>
        <EditAccessibility validation={accessValidation} userId={userId} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(Basic))
);
