import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Form, Header, Select } from "../../common";

class KitchensList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kitchens: undefined,
      loading: true,
      branches: [],
      branchSelected: undefined,
      categories: [],
      categorySelected: undefined,
      groups: [],
      groupSelected: undefined,
    };
  }

  componentDidMount() {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        let branches = result.data.branches.map((c) => {
          return {
            name: c.name,
            value: c.id,
          };
        });

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState(
          {
            branches: branches,
            branchSelected,
            loading: false,
          },
          () => {
            if (branchSelected) {
              this.getKitchens(branchSelected);
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  getKitchens = (branchId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { branchId },
        query: gql`
          query getKitchens($branchId: String!) {
            kitchens(branchId: $branchId) {
              id
              name
              shop {
                id
                name
              }
              status
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let kitchens = result.data.kitchens;
          this.setState({ kitchens });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value }, () => this.getKitchens(e.value));
  };

  createKitchen = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  showQueue = (kitchenId) => {
    console.log("KitchenId:", kitchenId);
    this.props.history.push(
      `${this.props.location.pathname}/kitchenQueues?id=${kitchenId}`
    );
  };

  editKitchen = (unitId) => {
    this.props.history.push(`${this.props.location.pathname}/edit/${unitId}`);
  };

  deleteKitchen = (kitchenid) => {
    let confirmation = window.confirm("Confirm deletion?");

    if (!confirmation) {
      return;
    }

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id: kitchenid,
        },
        mutation: gql`
          mutation deleteKitchen($id: String!) {
            deleteKitchen(id: $id)
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Deleted kitchen successfully!");

          this.getKitchens(this.state.branchSelected);
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to delete kitchen");
      });
  };

  render() {
    const { kitchens, branches, branchSelected } = this.state;

    return (
      <>
        <Header title="Kitchens">
          <button className="slim" onClick={this.createKitchen}>
            Create new kitchen
          </button>
        </Header>
        <Form>
          <Header title="Filter" level="4" />
          <Select
            title="Branch"
            value={branchSelected}
            name="branchSelected"
            placeholder="Select a branch"
            options={branches}
            onChange={this.handleSelect}
          />
        </Form>
        <table className="group__table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Shop</th>
            </tr>
          </thead>
          <tbody>
            {kitchens && kitchens.length > 0 ? (
              kitchens.map((i, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{i.name}</td>
                  <td>{i.shop.name}</td>
                  <td>
                    <button
                      className="slim"
                      onClick={() => this.editKitchen(i.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="slim"
                      onClick={() => this.deleteKitchen(i.id)}
                    >
                      Delete
                    </button>
                  </td>
                  <td>
                    <button
                      className="slim"
                      onClick={() => this.showQueue(i.id)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="100%">No kitchens in this branch</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(KitchensList)));
