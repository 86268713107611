import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";
import { connect } from "react-redux";
import moment from "moment";

import { getBranches } from "../../../helpers/Helper";

// import { SubNavigation } from "../../subNavigation";

import "./style.scss";
import "./pagination.scss";
import "react-day-picker/lib/style.css";
import { replaceNavigation, addNavigation } from "../../../actions";
import Transactions from "./Transactions";
import Summary from "./Summary";
import DateSelection from "../DateSelection";
import TransactionDetail from "./TransactionDetail";

class HealthcareReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      branches: [],
      branchSelected: undefined,
      performingAction: false,
      selectedDay: null,
      toggleDate: false,
      machines: [],
      machineSelected: undefined,
      transactionSelected: undefined
    };
  }

  componentDidMount() {
    // Change the sub nav bar first
    this.props.replaceNavigation([
      {
        url: "",
        title: "Reports"
      },
      {
        url: "",
        title: "Hospital"
      },
      {
        url: `Test`,
        title: "Transactions"
      }
    ]);

    getBranches(this.props.client, this.props.userAuth.userId)
      .then(branches => {
        this.setState({
          branches,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handlePagination = page => {
    console.log("Switch to page", page);

    this.getBranchesTransactions(
      this.state.branchSelected,
      page,
      this.state.take
    );
  };

  handleInput = e => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.name === "machineSelected") {
      let value = e.target.value === "All" ? undefined : e.target.value;
      this.setState({ machineSelected: value });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  getMachines = () => {
    if (!this.state.branchSelected) {
      return;
    }

    this.props.client
      .query({
        variables: {
          branchId: this.state.branchSelected
        },
        query: gql`
          query getMachines($branchId: String!) {
            branchMachines(branchId: $branchId) {
              id
              code
              name
            }
          }
        `
      })
      .then(result => {
        console.log(result.data);

        this.setState({
          machines: result.data.branchMachines
        });
      })
      .catch(error => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  handleBranch = e => {
    let branchId = e.target.value;

    this.setState({ [e.target.name]: branchId, currentPage: 1 }, () => {
      this.getMachines();
    });
  };

  handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
    this.setState({ selectedDay });
  };

  downloadFile(blobContent, name) {
    let FileSaver = require("file-saver");
    let blob = new Blob(
      [
        this.base64toBlob(
          blobContent,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ],
      {}
    );
    FileSaver.saveAs(blob, `${name}.xlsx`);
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  saveAsXlsxFile = () => {
    if (!this.state.machineSelected) {
      alert("Please select machine");
      return;
    }

    this.props.client
      .query({
        variables: {
          branchId: this.state.branchSelected,
          machineId: this.state.machineSelected,
          date: this.state.selectedDay
        },
        query: gql`
          query getExcel(
            $branchId: String!
            $machineId: String!
            $date: DateTime
          ) {
            exportExcels(
              branchId: $branchId
              machineId: $machineId
              date: $date
            )
          }
        `
      })
      .then(result => {
        console.log(result.data);

        // File name
        let reportDate = moment(this.state.selectedDay).format("D-M-YY");
        let currentDate = moment().format("DMYYHMM");
        let fileName = `TransactionReport_${reportDate}_(${currentDate})`;

        this.downloadFile(result.data.exportExcels, fileName);
      })
      .catch(err => {
        console.log(err);
      });
  };

  toggleDateSelection = () => {
    this.setState({ toggleDate: true });
  };

  closeDateSelection = () => {
    this.setState({ toggleDate: false });
  };

  onDateSelected = date => {
    this.setState({ toggleDate: false, selectedDay: date });
  };

  onLookup = t => {
    console.log(t);

    // this.setState({ transactionSelected: t });

    this.props.history.push(`/reports/healthcare/transaction?id=${t.id}`);
  };

  closeDetail = () => {
    this.setState({ transactionSelected: undefined });
  };

  render() {
    const {
      loading,
      branches,
      branchSelected,
      totalRecords,
      currentPage,
      take,
      toggleDate,
      selectedDay,
      machines,
      machineSelected,
      transactionSelected
    } = this.state;

    if (loading) {
      return <div>Loading</div>;
    }

    let togglePagination = totalRecords > take;

    if (togglePagination) {
      let pages = Math.ceil(totalRecords / take);
      var pagination = Array.apply(null, { length: pages }).map((e, i) => {
        if (currentPage === i + 1) {
          // Current page
          return (
            <li key={i}>
              <span className="active">{i + 1}</span>
            </li>
          );
        } else {
          return (
            <li key={i} onClick={() => this.handlePagination(i + 1)}>
              <span>{i + 1}</span>
            </li>
          );
        }
      });
    }

    return (
      <>
        <div className="report__hospital__transaction">
          <h1>Healthcare Report</h1>
          <div className="summary">
            <Summary
              branchId={branchSelected}
              machineId={machineSelected}
              selectedDay={selectedDay}
            />
          </div>
          <div className="transactions">
            <div className="header">
              <h2>Transactions</h2>
              <div>
                <button className="main" onClick={this.saveAsXlsxFile}>
                  Export excel
                </button>
              </div>
            </div>
            <div>
              <div className="filter">
                <div>
                  <label>Branches</label>
                  <select
                    name="branchSelected"
                    value={branchSelected}
                    onChange={this.handleBranch}
                  >
                    {!branchSelected && <option>Select branch</option>}
                    {branches.map(b => (
                      <option key={b.id} value={b.id}>
                        {b.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Date</label>
                  <input
                    readOnly={true}
                    type="text"
                    value={
                      selectedDay
                        ? moment(selectedDay).format("D MMM YYYY")
                        : "Real-time"
                    }
                    onClick={this.toggleDateSelection}
                  />
                </div>
                {branchSelected && (
                  <div>
                    <label>Machine</label>
                    <select
                      name="machineSelected"
                      value={machineSelected}
                      onChange={this.handleInput}
                    >
                      <option value={undefined}>All</option>
                      {machines.map((m, i) => (
                        <option key={m.code} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {togglePagination && (
                  <ul className="pagination modal-1">
                    <li>
                      <span className="prev">&laquo;</span>
                    </li>
                    {pagination}
                    <li>
                      <span className="next">&raquo;</span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <Transactions
              onLookup={this.onLookup}
              branchId={branchSelected}
              selectedDay={selectedDay}
              machine={machineSelected}
            />
          </div>
        </div>
        {toggleDate && (
          <DateSelection
            selection={selectedDay}
            close={this.closeDateSelection}
            onSelect={this.onDateSelected}
          />
        )}
        {transactionSelected && (
          <TransactionDetail
            transaction={transactionSelected}
            close={this.closeDetail}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    replaceNavigation: payload => dispatch(replaceNavigation(payload)),
    addNavigation: payload => dispatch(addNavigation(payload))
  };
};

const mapStateToProps = state => {
  return {
    userAuth: state.userAuth
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(HealthcareReport))
);
