import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";

// Import related components
import UsersList from "./UsersList";

import "./style.scss";
import Create from "./create";
import Profile from "../profile";

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { match } = this.props;

    return (
      <>
        <Route exact path={match.url} render={UsersList} />
        <Route exact path={`${match.url}/create`} render={Create} />
        <Route exact path={`${match.url}/edit`} render={Profile} />
      </>
    );
  }
}

export default withRouter(Users);
