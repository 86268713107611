import React, { Component } from "react";
import { withApollo, Subscription } from "react-apollo";
import { withRouter } from "react-router-dom";
import { gql } from "apollo-boost";
import qs from "querystring";
import Header from "../../common/Header";
import Form from "../../common/Form";
import moment from "moment";
import { connect } from "react-redux";

/**
 * Display transctions info
 */

const SUBSCRIPTION_LISTENFORVOIDRESPONSE = gql`
  subscription listenForVoidResponse {
    listenForVoidResponse {
      transactionId
      result
    }
  }
`;

class TransactionInfo extends Component {
  constructor(props) {
    super(props);

    let params = qs.parse(this.props.location.search.replace("?", ""));

    this.state = {
      transactionId: params.id || undefined,
      tranasction: undefined,
      retrievingReceipt: false,
      receiptData: undefined,
      performingRefund: false
    };
  }

  componentDidMount() {
    console.log("apollo", this.props.apollo);

    if (!this.state.transactionId) {
      alert("No transaction id provided!");
      this.return();
    }

    // Get transction details of the transactions
    this.props.client
      .query({
        variables: {
          transactionId: this.state.transactionId
        },
        query: gql`
          query getDetails($transactionId: String!) {
            transaction(transactionId: $transactionId) {
              id
              createdOn
              patientName
              hn
              bookNo
              billNo
              transactionId
              total
              cashAmount
              privilegeAmount
              details {
                id
                createdOn
                paymentType
                amount
                approveCode
                merchantId
                terminalId
                traceInvoiceNumber
                status
              }
            }
          }
        `
      })
      .then(result => {
        if (result.data) {
          var transaction = result.data.transaction;
          this.setState({ transaction });
        }
        console.log(result);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return = () => {
    this.props.history.push("/reports/healthcare");
  };

  getReceipt = () => {
    this.setState({ retrievingReceipt: true }, () => {
      this.props.client
        .query({
          variables: {
            transactionId: this.state.transaction.id
          },
          query: gql`
            query getReceipt($transactionId: String!) {
              transactionReceipt(transactionId: $transactionId) {
                data
              }
            }
          `
        })
        .then(result => {
          if (result.data) {
            var firstReceipt = result.data.transactionReceipt[0];
            var receiptData = firstReceipt.data;

            var a = document.createElement("a"); //Create <a>
            a.href = "data:image/png;base64," + receiptData; //Image Base64 Goes here
            a.download = this.state.transaction.transactionId + ".png";
            a.click();

            this.setState({ retrievingReceipt: false });
          }
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  getPaymentType = id => {
    switch (id) {
      case 0:
        return "Cash";
      case 1:
        return "Card";
      case 2:
        return "QR";
      case 3:
        return "Privilege";
      default:
        return null;
    }
  };

  void = () => {
    if (window.confirm("Are you sure you want to void this transaction?")) {
      this.setState({ performingRefund: true }, () => {
        this.props.client
          .mutate({
            variables: {
              transactionId: this.state.transactionId,
              isServer: true
            },
            mutation: gql`
              mutation voidTransaction($transactionId: String!) {
                requestVoid(transactionId: $transactionId)
              }
            `
          })
          .then(result => {
            if (result.errors && result.errors.length > 0) {
              this.setState({ performingRefund: false });
              alert(result.errors[0].message);
            }
          })
          .catch(error => {
            alert(error);
            console.log(error);
          });
      });
    }
  };

  finishRefunding = result => {
    if (this.state.performingRefund) {
      this.setState({ performingRefund: false }, () => {
        alert(`Void result: ${result ? "Success" : "Failed!"}`);
      });
    }
  };

  render() {
    const {
      transaction: trans,
      retrievingReceipt,
      performingRefund
    } = this.state;

    if (!trans) {
      return <div>Loading</div>;
    }

    // let overview = (
    //   <>
    //     <h3>Grand total</h3>
    //     <label>{trans.total}</label>
    //     <h3>Date</h3>
    //     <label>{moment(trans.createdOn).format("D/M/Y HH:mm:ss")}</label>
    //     <h3>HN</h3>
    //     <label>{trans.hn}</label>
    //     <h3>Patient</h3>
    //     <label>{trans.patientName}</label>
    //   </>
    // );

    // let details = (
    //   <>
    //     <h2>Details</h2>
    //     {trans.details.map((d, index) => {
    //       return (
    //         <div key={index} className="payment">
    //           <label>Type</label>
    //           <span>{this.getPaymentType(d.paymentType)}</span>
    //           <label>Date</label>
    //           <span>{moment(d.createdOn).format("D/M/YY H:mm")}</span>
    //           <label>Amount</label>
    //           <span>{d.amount}</span>
    //           {d.paymentType !== 0 && (
    //             <>
    //               <label>Approve code</label>
    //               <span>{d.approveCode}</span>
    //               <label>Merchant Id</label>
    //               <span>{d.merchantId}</span>
    //               <label>Terminal Id</label>
    //               <span>{d.terminalId}</span>
    //               <label>Trace no.</label>
    //               <span>{d.traceInvoiceNumber}</span>
    //             </>
    //           )}
    //         </div>
    //       );
    //     })}
    //   </>
    // );

    return (
      <div>
        {performingRefund && (
          <Subscription subscription={SUBSCRIPTION_LISTENFORVOIDRESPONSE}>
            {({ data, loading }) => {
              if (!loading) {
                this.finishRefunding(data.listenForVoidResponse.result);
              }
              return <></>;
            }}
          </Subscription>
        )}

        <button className="return" onClick={this.return}>
          Return
        </button>
        <Header
          title={`${trans.bookNo}-${trans.billNo}`}
          subtitle={trans.transactionId}
          level="3"
          icon={process.env.PUBLIC_URL + "/assets/icons/kiosk-small.svg"}
        >
          {retrievingReceipt ? (
            "Retrieving receipt data"
          ) : (
            <button onClick={this.getReceipt}>Get receipt</button>
          )}
          {!performingRefund && (
            <button onClick={this.void}>Void transaction</button>
          )}
        </Header>
        <div className="healthcare__transaction__info">
          <Form className="summary">
            <span>{trans.total}</span>
            <label>Grand total</label>
            <div className="details">
              {trans.cashAmount > 0 && (
                <div className="detail">
                  <span>{trans.cashAmount}</span>
                  <label>Cash</label>
                </div>
              )}
              {trans.privilegeAmount > 0 && (
                <div className="detail">
                  <span>{trans.privilegeAmount}</span>
                  <label>Privilege</label>
                </div>
              )}
            </div>
          </Form>
          <Form className="info">
            <h4>Transaction info</h4>
            <label>HN</label>
            <span>{trans.hn}</span>
            <label>Patient's name</label>
            <span>{trans.patientName}</span>
            <label>Transaction date</label>
            <span>{moment(trans.createdOn).format("D/M/Y HH:mm:ss")}</span>
          </Form>
          <Form className="payments">
            <h4>Payments</h4>
            <table>
              <thead>
                <tr>
                  <th>Created on</th>
                  <th>Type</th>
                  <th>Total</th>
                  <th>Approve code</th>
                  <th>Merchant Id</th>
                  <th>Terminal Id</th>
                  <th>Trance Invoice No</th>
                </tr>
              </thead>
              <tbody>
                {trans.details.map((d, index) => {
                  return (
                    <tr key={index}>
                      <td>{moment(d.createdOn).format("D/M/YY H:mm")}</td>
                      <td>{this.getPaymentType(d.paymentType)}</td>
                      <td>{d.amount}</td>
                      <td>{d.approveCode}</td>
                      <td>{d.merchantId}</td>
                      <td>{d.terminalId}</td>
                      <td>{d.traceInvoiceNumber}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    apollo: state.apollo
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps)(TransactionInfo))
);
