import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import MachineList from "./MachineList";
import MachineCreate from "./MachineCreate";

import "./style.scss";
import MachineDetail from "./machineDetail";
import Edit from "./Edit";

// Import related components

class Machine extends Component {
  createNewCompany = () => {};

  render() {
    const { match } = this.props;
    return (
      <>
        <div className="machines__list">
          <Route exact path={match.url} render={MachineList} />
          <Route exact path={`${match.url}/create`} render={MachineCreate} />
          <Route exact path={`${match.url}/detail`} render={MachineDetail} />
          <Route exact path={`${match.url}/edit`} render={Edit} />
        </div>
      </>
    );
  }
}

export default withRouter(Machine);
