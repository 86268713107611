import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { connect } from "react-redux";
import Select from "../../common/Select";
import Form from "../../common/Form";

class List extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      branches: [],
      branchSelected: undefined,
      kitchens: [],
      kitchenSelected: "",
      kitchenName: "",
      queues: [],
      confirm: false,
      isFullScreen: false,
    };
  }

  componentDidMount() {
    this.getBranches();
  }

  getBranches = () => {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches.map((b) => {
          return {
            name: b.name,
            value: b.id,
          };
        });

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState({
          branches,
          branchSelected,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  getKitchens = (branchId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { branchId },
        query: gql`
          query getKitchens($branchId: String!) {
            kitchens(branchId: $branchId) {
              id
              name
              shop {
                name
              }
              status
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let kitchens = result.data.kitchens.map((k) => {
            return {
              name: k.name,
              value: k.id,
            };
          });

          let kitchenSelected = kitchens.length === 1 ? kitchens[0].id : "";

          this.setState({ kitchens, kitchenSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleSelectBranch = (e) => {
    this.setState({ [e.name]: e.value });
    this.getKitchens(e.value);
  };

  handleSelectKitchen = (e) => {
    //console.log("E", e);
    let kitchens = this.state.kitchens;
    let kitchen = kitchens.find((k) => k.value === e.value);
    this.setState({ kitchenName: kitchen.name, [e.name]: e.value });
  };

  onConfirm = () => {
    const branchConfirm = this.state.branchSelected;
    const kitchenConfirm = this.state.kitchenSelected;
    if (branchConfirm && kitchenConfirm) {
      const { match } = this.props;

      this.props.history.push(`${match.url}/${kitchenConfirm}`);
    } else {
      alert("Please select branch and kitchen!");
    }
  };

  render() {
    const { branches, branchSelected, kitchens, kitchenSelected } = this.state;

    return (
      <Form>
        <Select
          title="Branch"
          value={branchSelected}
          name="branchSelected"
          placeholder="Select a branch"
          options={branches}
          onChange={this.handleSelectBranch}
        />
        <Select
          title="Kitchen"
          value={kitchenSelected}
          name="kitchenSelected"
          placeholder="Select a kitchen"
          options={kitchens}
          onChange={this.handleSelectKitchen}
        />
        <button className="main" onClick={this.onConfirm}>
          Confirm
        </button>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(List)));
