import { gql } from "apollo-boost";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { addAlert } from "../../../actions";
import { Form, Header, Input, Select } from "../../common";
import WatermarkSelector from "../../common/WatermarkSelector";

class Basic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      machineId: "",
      code: "",
      name: "",
      modules: [],
      moduleSelected: "",
      watermarkSelected: "",
      bridgeNodeId: undefined,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { info, modules } = this.props;

    if (!info) {
      return;
    }

    if (this.state.loading && info.code !== this.state.code) {
      let { id, code, name, type, watermarkId, bridgeNodeId } = info;

      this.setState({
        modules,
        code,
        name,
        moduleSelected: type,
        watermarkSelected: watermarkId,
        machineId: id,
        bridgeNodeId,
        loading: false,
      });
    }
  }

  handleInputs = (e) => {
    this.setState({ [e.name]: e.value });
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value }, this.onChange);
  };

  onWatermarkSelected = (watermark) => {
    this.setState({ watermarkSelected: watermark });
  };

  setLoading = (loading) => {
    console.log("Setting loading", loading);
    return new Promise((resolve, reject) => {
      this.setState({ loading }, () => {
        resolve(true);
      });
    });
  };

  save = () => {
    let {
      code,
      name,
      moduleSelected,
      watermarkSelected,
      machineId,
    } = this.state;

    let variables = {
      userId: this.props.userAuth.userId,
      code,
      name,
      type: moduleSelected,
      watermarkId: watermarkSelected,
      machineId,
    };

    this.setLoading(true)
      .then((_) => {
        return this.props.client.mutate({
          variables,
          mutation: gql`
            mutation createMachine(
              $userId: String!
              $name: String!
              $code: String!
              $type: Int!
              $watermarkId: String!
              $machineId: String!
            ) {
              editMachineBasicInfo(
                userId: $userId
                watermarkId: $watermarkId
                name: $name
                code: $code
                type: $type
                machineId: $machineId
              )
            }
          `,
        });
      })
      .then((result) => {
        if (!result.data) {
          throw new Error("Failed");
        }

        this.props.addAlert({
          type: "success",
          title: "All set",
          msg: `Basic info updated`,
        });

        this.setLoading(false);
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Failed to update",
          msg: error.message,
        });

        this.setLoading(false);
      });
  };

  render() {
    const {
      loading,
      code,
      name,
      moduleSelected,
      modules,
      bridgeNodeId,
      watermarkSelected,
    } = this.state;

    return (
      <Form>
        <Header title="Basic information" level={3}>
          {!loading && (
            <button className="main slim" onClick={this.save}>
              Save
            </button>
          )}
        </Header>
        <Input
          title="Code"
          name="code"
          placeholder="Enter a code"
          onChange={this.handleInputs}
          value={code}
        />
        <Input
          title="Name"
          name="name"
          placeholder="Enter a name"
          onChange={this.handleInputs}
          value={name}
        />
        <Select
          title="Type"
          value={moduleSelected}
          name="moduleSelected"
          placeholder="Select a type"
          options={modules}
          onChange={this.handleSelect}
          error={""}
        />
        <label>Watermark</label>
        <WatermarkSelector
          bridgeNodeId={bridgeNodeId}
          value={watermarkSelected}
          onChange={this.onWatermarkSelected}
        />
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(Basic));
