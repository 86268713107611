import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Header } from "../../common";

class BrandsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brands: undefined,
      loading: true,
      companySelected: undefined,
    };
  }

  componentDidMount() {
    this.setState({ companySelected: this.props.userAuth.companyId }, () => {
      this.getBrands();
    });
  }

  getBrands = () => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { companyId: this.state.companySelected },
        query: gql`
          query getBrands($companyId: String!) {
            brands(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log("Result Brands:", result);
        if (result.data) {
          this.setState({ brands: result.data.brands });
        }
      })
      .catch((error) => {
        console.log("Error", error);
        alert(error.message);
      });
  };

  createBrand = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  editBrand = (brandId) => {
    this.props.history.push(`${this.props.location.pathname}/edit/${brandId}`);
  };

  deleteBrand = (brandId) => {
    let confirmation = window.confirm("Confirm deletion?");

    if (!confirmation) {
      return;
    }

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id: +brandId,
        },
        mutation: gql`
          mutation deleteBrand($id: Int!) {
            deleteBrand(id: $id)
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Deleted successfully!");

          this.getBrands();
        }
      })
      .catch((error) => {
        //console.log(error.message);
        alert("Unable to delete shop");
      });
  };

  render() {
    const { brands } = this.state;
    let brandRender =
      brands && brands.length > 0 ? (
        brands.map((b, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{b.name}</td>
            <td>
              <button className="slim" onClick={() => this.editBrand(b.id)}>
                Edit
              </button>
              <button className="slim" onClick={() => this.deleteBrand(b.id)}>
                Delete
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="100%">No brands have been added</td>
        </tr>
      );

    return (
      <>
        <Header title="Brands">
          <button className="slim" onClick={this.createBrand}>
            Create
          </button>
        </Header>
        <div>
          <table className="brand__table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>{brandRender}</tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(BrandsList)));
