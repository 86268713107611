import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";

// Import related components
import UserList from "./UserList";
import UserCreate from "./UserCreate";

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={match.url} render={UserList} />
        <Route exact path={`${match.url}/create`} render={UserCreate} />
      </div>
    );
  }
}

export default withRouter(withApollo(Users));
