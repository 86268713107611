const navReducer = (state = true, action) => {
  switch (action.type) {
    case "TOGGLENAV":
      return !state;
    default:
      return true;
  }
};

export default navReducer;
