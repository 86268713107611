import React, { Component } from "react";
import DayPicker from "react-day-picker";

export default class DateSelection extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDay: this.props.selection,
    };
  }

  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  }

  close = () => {
    this.props.close();
  };

  onSelect = () => {
    this.props.onSelect(this.state.selectedDay);
  };

  onRealtime = () => {
    this.props.onSelect(null);
  };

  render() {
    return (
      <div id="date__selection">
        <div className="container">
          <span className="close" onClick={this.close}>
            <img
              height="10"
              width="10"
              alt="close-popup"
              src={process.env.PUBLIC_URL + "/assets/icons/close.svg"}
            />
          </span>
          <h1>Select date</h1>
          <DayPicker
            selectedDays={this.state.selectedDay}
            onDayClick={this.handleDayClick}
          />
          <button className="main" onClick={this.onSelect}>
            Confirm date
          </button>
          {!this.props.disableRealTime && (
            <button className="secondary" onClick={this.onRealtime}>
              Show real-time
            </button>
          )}
        </div>
      </div>
    );
  }
}
