import { gql } from "apollo-boost";
import React, { Component } from "react";
// import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      watermarks: undefined,
    };
  }

  componentDidMount = () => {
    this.getWatermarks();
  };

  getWatermarks = () => {
    this.props.client
      .query({
        variables: {
          companyId: this.props.userAuth.companyId,
        },
        query: gql`
          query companyWatermarks($companyId: String!) {
            companyWatermarks(companyId: $companyId) {
              id
              name
              createdOn
              currency
              isDefault
              branch {
                id
                name
              }
              denominations {
                value
                pieces
                device
                include
              }
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          throw new Error("");
        }

        this.setState({ watermarks: result.data.companyWatermarks });
      })
      .catch((error) => {
        //alert(error.message);
        console.error(error.message);
      });
  };

  createWatermark = (e) => {
    this.props.history.push(`${this.props.match.url}/create`);
  };

  editWatermark = (watermark) => {
    this.props.history.push(`${this.props.match.url}/edit`, {
      watermark,
    });
  };

  deleteWatermark = (watermark) => {
    var self = this;
    var proceed = window.confirm(
      `Are you sure you want to delete ${watermark.name}?`
    );
    if (!proceed) {
      // Proceed
      return;
    }

    this.props.client
      .mutate({
        variables: {
          watermarkId: watermark.id,
        },
        mutation: gql`
          mutation update($watermarkId: String!) {
            deleteWatermark(watermarkId: $watermarkId)
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Deleted successfully");
          self.getWatermarks();
        } else {
          alert("Unable to delete!");
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  render() {
    const { watermarks } = this.state;

    const stroke1Style = {
      fill: "none",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      stroke: "#2d9bf3",
      strokeWidth: "2",
    };

    const stroke2Style = {
      fill: "none",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      stroke: "#283e62",
      strokeWidth: "2",
    };

    return (
      <div>
        <div>
          <div className="greet">
            <div className="watermark__icon">
              <svg width="24" height="24">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path style={stroke1Style} d="M11.5,6A5.5,5.5,0,0,1,6,.5" />
                    <path
                      style={stroke1Style}
                      d="M11.5,6A5.5,5.5,0,0,0,17,.5"
                    />
                    <path style={stroke1Style} d="M.5,6A5.5,5.5,0,0,0,6,.5" />
                    <path
                      style={stroke1Style}
                      d="M17,.5A5.5,5.5,0,0,0,22.5,6"
                    />
                    <path
                      style={stroke2Style}
                      d="M11.5,19.75A5.5,5.5,0,0,1,6,14.25"
                    />
                    <path
                      style={stroke2Style}
                      d="M11.5,19.75a5.5,5.5,0,0,0,5.5-5.5"
                    />
                    <path
                      style={stroke2Style}
                      d="M.5,19.75A5.5,5.5,0,0,0,6,14.25"
                    />
                    <path
                      style={stroke2Style}
                      d="M17,14.25a5.5,5.5,0,0,0,5.5,5.5"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <h1>Welcome to Watermarks</h1>
            <p>
              Determines the denomination level for a single or groups of cash
              machines, also useful when monitoring a machines inventory. Can be
              tailored to fit the stores operations & usages
            </p>
            <button onClick={this.createWatermark}>Create</button>
          </div>
        </div>
        <div>
          <div className="list">
            <h2>List of Watermark</h2>
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Currency</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {watermarks ? (
                  watermarks.map((watermark, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{watermark.name}</td>
                      <td>{watermark.currency}</td>
                      <td>
                        {!watermark.isDefault && (
                          <>
                            <button
                              className="slim"
                              onClick={() => this.editWatermark(watermark)}
                            >
                              Edit
                            </button>
                            <button
                              className="slim"
                              onClick={() => this.deleteWatermark(watermark)}
                            >
                              Delete
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No watermarks found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps)(List));
