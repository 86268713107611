import React, { Component } from "react";
import { NavLink, withRouter, Switch, Route } from "react-router-dom";

import "./style.scss";
import APIReference from "./apiReference";
import { connect } from "react-redux";

class Developer extends Component {
  render() {
    const {
      match: { url },
      userAuth: { isAuth },
    } = this.props;

    return (
      <div id="developers">
        <nav className="header">
          <div className="nav__content">
            <img
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
              alt="logo"
              height="28"
            />
            <h1>for Developers</h1>
            <div className="links">
              <NavLink to={`${url}/quickstart`} activeClassName="selected">
                Quickstart
              </NavLink>
              <NavLink to={`${url}/api`} activeClassName="selected">
                API Reference
              </NavLink>
              <NavLink to={`${url}/sdk`} activeClassName="selected">
                SDKs
              </NavLink>
              <NavLink to={`${url}/tutorial`} activeClassName="selected">
                Tutorials
              </NavLink>
            </div>
            <NavLink className="external" to="/">
              User manual
            </NavLink>
            <NavLink className="external" to="/dashboard">
              {isAuth ? "Dashboard" : "Login"}
            </NavLink>
          </div>
        </nav>
        <div className="content">
          <Switch>
            <Route path={`${url}/api`} component={APIReference} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(connect(mapStateToProps)(Developer));
