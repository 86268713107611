import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";

class SalesSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      total: 0,
    };
  }

  currencyFormat(amount) {
    var format = new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
      minimumFractionDigits: 2,
    });

    return format.format(amount);
  }

  componentDidMount() {
    this.getSummary();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({ loading: true }, () => this.getSummary());
    }
  }

  getSummary = () => {
    if (!this.props.branchId) {
      return;
    }

    let {
      filter: { start, end },
    } = this.props;

    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: {
          branchId: this.props.branchId,
          startDate: start,
          endDate: end,
        },
        query: gql`
          query getSummary(
            $startDate: DateTime!
            $endDate: DateTime!
            $branchId: String!
          ) {
            transactionSummary(
              endDate: $endDate
              startDate: $startDate
              branchId: $branchId
            ) {
              grandTotal
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          throw new Error("");
        }

        let { grandTotal } = result.data.transactionSummary;

        this.setState({ total: grandTotal, loading: false });
      })
      .catch((error) => {
        //alert(error.message);
        console.error(error.message);
        this.setState({ total: 0, loading: false });
      });
  };

  render() {
    let { total, loading } = this.state;

    return (
      <div className="c-posGrandSales">
        <h1>Grand Sales Total</h1>
        <p>{loading ? "Loading" : this.currencyFormat(total)}</p>
      </div>
    );
  }
}

export default withApollo(SalesSummary);
