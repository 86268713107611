import React, { PureComponent } from "react";

import "./style.scss";

export default class Select extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    // Select mounted
    console.log("Select mounted");

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleSelection = (e) => {
    this.setState(
      {
        toggled: !this.state.toggled,
      },
      () => {
        this.props.onChange({
          value: e.value,
          name: this.props.name,
        });
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {}

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.node.contains(event.target)) {
      return;
    }

    if (this.state.toggled) {
      this.setState({ toggled: false });
    }
  }

  onChange = (_) => {
    this.props.onChange({
      value: this.state.selection,
      name: this.props.name,
    });
  };

  toggle = () => {
    this.setState({ toggled: !this.state.toggled });
  };

  render() {
    const {
      title,
      options,
      placeholder,
      value,
      error,
      description,
    } = this.props;
    const { toggled } = this.state;

    // Get appropiate classname
    var selectClassName = toggled ? "select active" : "select";

    let desc = description ? description : "";

    if (!title) {
      selectClassName += " no__title";
    }

    if (value !== undefined && value !== null && options.length > 0) {
      let match = options.find((o) => o.value.toString() === value.toString());

      if (!match) {
        //console.log("A ha 2!");

        var toShow = placeholder;
      } else {
        //console.log("A ha 3");
        toShow = match.name;
      }
    } else {
      //console.log("A ha 4!");
      toShow = placeholder;
    }

    var comboboxClassname = value ? "combobox hasSelection" : "combobox";

    if (error) {
      comboboxClassname += " error";
    }

    return (
      <div ref={(node) => (this.node = node)} className={selectClassName}>
        {title && <label>{title}</label>}
        <div className={comboboxClassname} onClick={this.toggle}>
          {toShow}
          <svg viewBox="0 0 10 10" width="10px" height="10px">
            <g strokeWidth="1" fill="#9AA8BD" fillRule="evenodd">
              <path d="M5.58578196,5.01653637 L2.29467541,8.29111547 C1.90316932,8.68065548 1.90157549,9.31381845 2.2911155,9.70532455 C2.68065551,10.0968306 3.31381848,10.0984245 3.70532457,9.70888446 L7.70532455,5.72897188 C8.09683413,5.3394284 8.09842398,4.70625854 7.70887557,4.31475387 L3.70887559,0.294666476 C3.31933065,-0.0968347079 2.68616766,-0.0984205515 2.29466648,0.291124395 C1.90316529,0.680669342 1.90157945,1.31383233 2.29112439,1.70533352 L5.58578196,5.01653637 Z" />
            </g>
          </svg>
        </div>
        <div className="options">
          {options.map((option, index) => {
            return (
              <span
                className={value === option.value ? "selected" : ""}
                key={index}
                onClick={() => this.handleSelection(option)}
              >
                {option.name}
              </span>
            );
          })}
        </div>
        <span className={error ? "error" : "description"}>
          {error ? error : desc}
        </span>
      </div>
    );
  }
}
