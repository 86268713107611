import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Toast from "../common/Toast";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { removeAlert } from "../../actions";

class Alerts extends PureComponent {
  componentDidUpdate(prevProps, prevState) {
    const { alerts } = this.props;

    if (alerts && alerts.length > 0) {
      // Get latest alert
      let newAlert = alerts[alerts.length - 1];

      setTimeout(
        function() {
          if (this.props.alerts.some(a => a.id === newAlert.id)) {
            this.removeTimedOutAlert(newAlert.id);
          }
        }.bind(this),
        5000
      );
    }
  }

  removeTimedOutAlert = id => {
    this.props.removeAlert(id);
  };

  render() {
    const { alerts } = this.props;

    return (
      <div className="toasts">
        {alerts.map((a, i) => (
          <Toast
            key={`alert${i}`}
            id={a.id}
            title={a.title}
            type={a.type}
            msg={a.msg}
          />
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeAlert: payload => dispatch(removeAlert(payload))
  };
};

const mapStateToProps = state => {
  return {
    alerts: state.alerts
  };
};

export default withApollo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Alerts))
);
