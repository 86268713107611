import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import Select from "../../../common/Select";
import MultiSelect from "../../../common/MultiSelect";

class Policies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resources: [],
      permissions: [],
      statements: [
        {
          resourceId: undefined,
          permissions: [],
        },
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.companyResources === undefined) {
      console.log(this.props);

      // Group the resources
      let statements = this.props.statements
        ? this.props.statements.permissions.reduce((prev, curr) => {
            let found = false;
            for (let i = 0; i < prev.length; i++) {
              const element = prev[i];
              if (element.resourceId === curr.resourceId) {
                found = true;

                element.permissions.push(curr.id);
              }
            }

            if (!found) {
              prev.push({
                resourceId: curr.resourceId,
                permissions: [curr.id],
              });
            }

            return prev;
          }, [])
        : [
            {
              resourceId: undefined,
              permissions: [],
            },
          ];

      let notifyAbove = this.props.statements ? true : false;

      var resources = this.props.companyResources.map((r) => {
        r.value = r.id;
        return r;
      });

      var permissions = resources
        .reduce((prev, curr) => {
          return prev.concat(...curr.permissions);
        }, [])
        .map((p) => {
          p.name = p.id;
          return p;
        });

      this.setState({ resources, permissions, statements }, () => {
        if (notifyAbove) {
          this.props.policyChanged(statements);
        }
      });
    }
  }

  handleResourceSelection = (e) => {
    let index = Number(e.name);
    let resourceId = Number(e.value);

    let statements = this.state.statements;

    // Check if selected resource already exist in current selections
    let matches = statements.filter((s) => s.resourceId === resourceId);

    if (matches.length > 0) {
      // Duplication found]
      alert("Resource has already been selected");
      return;
    } else {
      statements[index] = {
        resourceId,
        permissions: [],
      };

      this.setState({ statements }, () => {
        this.props.policyChanged(this.state.statements);
      });
    }
  };

  addPolicy = () => {
    this.setState(
      {
        statements: this.state.statements.concat({
          resourceId: undefined,
          permissions: [],
        }),
      },
      () => {
        // this.props.policyChanged(this.state.statements);
      }
    );
  };

  removePolicy = (index) => {
    let statements = this.state.statements;

    let i = Number(index);

    console.log(i);

    statements.splice(Number(index), 1);
    this.setState({ statements });
  };

  handlePermissionSelection = (e) => {
    var { statements } = this.state;

    console.log(e.values);

    // Index is in name
    var index = Number(e.name);

    statements[index].permissions = e.values;
    this.setState(
      { statements },
      this.props.policyChanged(this.state.statements)
    );
  };

  render() {
    const { resources, statements, permissions } = this.state;

    return (
      <div>
        <table id="policies__table">
          <thead>
            <tr>
              <th>Resource</th>
              <th>Permissions</th>
            </tr>
          </thead>
          <tbody>
            {statements.map((statement, index) => (
              <tr key={`statement${index}`}>
                <td>
                  <Select
                    value={statement.resourceId}
                    name={index}
                    placeholder="Select resource"
                    options={resources}
                    onChange={this.handleResourceSelection}
                  />
                </td>
                <td className="permissions">
                  {statement.resourceId !== "-1" && (
                    <>
                      <MultiSelect
                        name={index}
                        displayProp="action"
                        valueProp="id"
                        placeholder="Click to assign permissions"
                        options={
                          permissions.filter(
                            (p) => p.resourceId === Number(statement.resourceId)
                          ) || []
                        }
                        value={statement.permissions}
                        onChange={this.handlePermissionSelection}
                      />
                    </>
                  )}
                  {statements.length > 1 && (
                    <span
                      className="delete"
                      onClick={(_) => this.removePolicy(index)}
                    >
                      Remove
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="main slim" onClick={this.addPolicy}>
          Add another policy
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps)(Policies));
