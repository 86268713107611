import React, { Component } from "react";

import "./style.scss";

export default class Header extends Component {
  render() {
    const { title, children, level, subtitle, icon } = this.props;

    var actions = Array.isArray(children) ? (
      children.filter(
        (c) => c.type === "button" || c.type === "input" || c.type === "label"
      )
    ) : (
      <>{children}</>
    );

    var headerLevel = level || "1";

    return (
      <div className="heading">
        {icon && <img height="28" src={icon} alt="headerIcon" />}
        <div className="title">
          <span className={`l${headerLevel}`}>{title}</span>
          <span className="subtitle">{subtitle}</span>
        </div>
        <div className="actions">{actions}</div>
      </div>
    );
  }
}
