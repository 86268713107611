import { gql } from "apollo-boost";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { addAlert } from "../../actions";
import { Form, Header, Input, Switch } from "../common";

class DispenseFloat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      amount: 0,
      error: "",
      enabled: false,
      userId: this.props.userId,
    };
  }

  componentDidMount() {
    this.props.client
      .query({
        variables: { id: this.state.userId },
        query: gql`
          query getInfo($id: String!) {
            user(id: $id) {
              exchangeLimit
            }
          }
        `,
      })
      .then(({ data }) => {
        if (!data) {
          return;
        }

        let amount = data.user.exchangeLimit;
        let enabled = amount < 0 ? false : true;

        this.setState({
          amount: amount < 0 ? 0 : amount,
          enabled,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  update = (amount) => {
    this.props.addAlert({
      type: "info",
      title: "Applying new changes",
      msg: "Hang tight, while we're applying changes",
    });

    this.props.client
      .mutate({
        variables: {
          userId: this.state.userId,
          amount: +amount,
        },
        mutation: gql`
          mutation update($userId: String!, $amount: Float!) {
            updateUserExchangeLimit(userId: $userId, amount: $amount) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          this.props.addAlert({
            type: "error",
            title: "Failed to update limit",
            msg: "Please try again later",
          });
          return;
        }

        this.props.addAlert({
          type: "success",
          title: "Success",
          msg: "User's exchange limit updated",
        });

        if (amount < 0) {
          this.setState({
            amount: 0,
          });
        }
      })
      .catch((error) => {
        this.props.addAlert({
          type: "error",
          title: "Failed to update limit",
          msg: error.message,
        });
      });
  };

  onCompletedGettingFloats = (current, df) => {
    this.setState({
      loading: false,
      dispenseFloatSelected: current,
      dispenseFloats: df,
      enabled: current ? true : false,
    });
  };

  handleInputs = (e) => {
    this.setState({ [e.name]: e.value });
  };

  onToggleChanged = (e) => {
    let checked = e.target.checked;

    if (!checked) {
      this.update(-1);
    }

    this.setState({ enabled: e.target.checked });
  };

  render() {
    const { loading, amount, enabled: exhangeEnabled } = this.state;

    return (
      <Form loading={loading}>
        <Header title="Exchange float" level="3">
          <Switch
            name="exhangeEnabled"
            isOn={exhangeEnabled}
            handleToggle={this.onToggleChanged}
          />
        </Header>
        {exhangeEnabled && (
          <>
            <Input
              title="Amount limit"
              name="amount"
              placeholder="Enter an amount to limit"
              onChange={this.handleInputs}
              value={amount}
            />
            <Header level="3">
              <button className="main slim" onClick={() => this.update(amount)}>
                Update
              </button>
            </Header>
          </>
        )}
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(DispenseFloat)
);
