import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.scss";

export default class Select extends Component {
  render() {
    return (
      <div className="intro">
        <h1>API Reference</h1>
        <p>
          Dive into our full API Reference Documentation and seamlessly
          integrate <br />
          to cash machines with our Overseer APIs.
        </p>
        <div className="options">
          <div className="api__card bridge">
            <h5>Bridge service</h5>
            <span>
              Core API for controlling cash machines and receiving event
              notifications from them
            </span>
            <Link to="/developers/api/bridge">Proceed</Link>
          </div>
          <div className="api__card bridge">
            <h5>Control Conter</h5>
            <span>
              Light and simple integration to quickly kickoff POCs and into
              production
            </span>
            <Link to="/developers/api/controlcenter">Proceed</Link>
          </div>
        </div>
      </div>
    );
  }
}
