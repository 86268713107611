import React from "react";
import APITable from "../../APITable";

const GetServiceStatus = () => {
  let includeDefault = true;
  let haveMachineId = false;

  let resp = [
    {
      name: "Status",
      desc: "Returns true if online and false if offline",
    },
  ];

  return (
    <APITable
      title="Get Service Status"
      desc="Retrieves the service most updated status"
      command="GetServiceStatus"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      resp={resp}
    />
  );
};

export default GetServiceStatus;
