import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";
import { Form } from "../common";

class Notification extends Component {
  constructor(props) {
    super(props);

    let { userId } = this.props;

    // Get user profile
    var clientId = process.env.REACT_APP_LINE_CLIENTID;
    var callbackTemp = process.env.REACT_APP_LINE_CALLBACKURI;

    console.log("Client id", clientId);
    console.log("Callback uri", callbackTemp);

    var location = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${callbackTemp}&scope=notify&state=${userId}`;

    this.state = {
      location,
      isConnected: false,
      loading: true
    };
  }

  componentDidMount() {
    // Check whether the user already has LINE Notify connected
    this.props.client
      .query({
        variables: { userId: this.props.userId },
        query: gql`
          query checkConnection($userId: String!) {
            isConnectedToNotification(userId: $userId)
          }
        `
      })
      .then(response => {
        if (response.data) {
          this.setState({
            isConnected: response.data.isConnectedToNotification,
            loading: false
          });
        }
      })
      .catch(err => {
        alert("Can't connect to server");
      });
  }

  disconnect = e => {
    this.props.client
      .mutate({
        variables: { userId: this.props.userId },
        mutation: gql`
          mutation disconnect($userId: String!) {
            disconnectNotifications(userId: $userId)
          }
        `
      })
      .then(response => {
        if (response.data && response.data.disconnectNotifications) {
          this.setState({
            isConnected: false,
            loading: false
          });
        }
      })
      .catch(err => {
        alert(err.message);
      });
  };

  render() {
    const { location, loading, isConnected } = this.state;

    return (
      <Form loading={loading}>
        {isConnected ? (
          <div>
            <h3>You're already connected with LINE notifications</h3>
            <button onClick={this.disconnect}>Disconnect notifications</button>
          </div>
        ) : (
          <div>
            <h3>Press register to connect with LINE notifications</h3>
            <a href={location}>Register</a>
          </div>
        )}
      </Form>
    );
  }
}

export default withApollo(Notification);
