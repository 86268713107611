import React from "react";
import APITable from "../../APITable";

const getMachines = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [];

  let resp = [
    {
      name: "Machines",
      desc: "Returns registered in the service",
      type: "MachineType[]",
    },
  ];

  return (
    <APITable
      title="Get Machines"
      desc="Get current machines registered in the service"
      command="GetMachines"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default getMachines;
