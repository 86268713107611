import React from "react";
import "./style.css";

const Switch = ({ isOn, handleToggle, name, disabled }) => {
  return (
    <>
      <input
        name={name}
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new-${name}`}
        type="checkbox"
        disabled={disabled}
      />
      <label
        style={{ backgroundColor: isOn && "#06D6A0" }}
        className="react-switch-label"
        htmlFor={`react-switch-new-${name}`}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;
