import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Form, Input, Select, Header } from "../../../common";
import PromotionDetail from "./PromotionDetail";

import "react-day-picker/lib/style.css";

import MultiSelect from "../../../common/MultiSelect";

import DateSelection from "../../../../components/reports/DateSelection";

import "../../../users/style.scss";

class PromotionsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: this.props.userAuth.companyId,
      userId: this.props.userAuth.userId,
      branches: [],
      branchSelected: undefined,
      shops: [],
      shopSelected: undefined,
      name: "",
      types: [],
      typeSelected: undefined,
      discountTypes: [],
      discountTypeSelected: undefined,
      discountAmount: undefined,
      discountWhole: false,
      maximumAmount: undefined,
      amount: undefined,
      minimumAmount: undefined,
      toggleDate: false,
      id: "",
      validUntil: "",
      promotionPrice: [],
      selectedDay: new Date(),
      promotions: [],
      selectionPromotions: undefined,
      combinations: undefined,
      customDetails: undefined,
    };
  }

  componentDidMount() {
    this.props.client
    .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        let branches = result.data.branches.map((b) => {
          return {
            name: b.name,
            value: b.id,
          };
        });

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState({
          branches,
          branchSelected,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });

    this.getTypes(this.state.companyId);
    this.getDiscountTypes();
  }

  getShops = (branchId) => {
    this.props.client.query({
      context: {
        route: "selfOrdering",
      },
        variables: { branchId },
        query: gql`
          query getShops($branchId: String!) {
            shops(branchId: $branchId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let shops = result.data.shops.map((c) => {
            return {
              name: c.name,
              value: c.id,
            };
          });
          let shopSelected = shops.length === 1 ? shops[0].id : undefined;

          this.setState({ shops, shopSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  getTypes = (companyId) => {
    this.props.client.query({
      context: {
        route: "selfOrdering",
      },
        variables: { companyId },
        query: gql`
          query getPromotionTypes($companyId: String!) {
            promotionTypes(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let types = result.data.promotionTypes.map((t) => {
            return {
              name: t.name,
              value: t.id,
            };
          });
          let typeSelected = types.length === 1 ? types[0].id : undefined;

          this.setState({ types, typeSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  getDiscountTypes = () => {
    this.props.client.query({
      context: {
        route: "selfOrdering",
      },
        query: gql`
          query getDiscountTypes {
            discountTypes {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let discountTypes = result.data.discountTypes.map((d) => {
            return {
              name: d.name,
              value: d.id,
            };
          });
          let discountTypeSelected =
            discountTypes.length === 1 ? discountTypes[0].id : undefined;

          this.setState({ discountTypes, discountTypeSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  getPromotions = (branchId) => {
    this.props.client.query({
      context: {
        route: "selfOrdering",
      },
        variables: { branchId },
        query: gql`
          query getPromotions($branchId: String!) {
            promotions(branchId: $branchId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let promotions = result.data.promotions.map((p) => {
            return {
              name: p.name,
              value: p.id,
            };
          });
          this.setState({ promotions });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value });
  };

  onCreate = () => {
    const {
      branchSelected,
      name,
      userId,
      typeSelected,
      discountTypeSelected,
      discountAmount,
      discountWhole,
      maximumAmount,
      selectedDay,
      itemSelected,
      amount,
      minimumAmount,
      combinations,
      customDetails,
    } = this.state;

    let variables = {
      promotionInput: {
        branchId: branchSelected,
        name,
        promotionTypeId: Number(typeSelected),
        discountTypeId: Number(discountTypeSelected),
        discountAmount: Number(discountAmount || 0),
        discountWhole: discountWhole,
        maximumAmount: Number(maximumAmount || 0),
        validUntil: selectedDay,
        createdBy: userId,
        promotionDetails: [
          {
            itemId: itemSelected,
            amount: Number(amount),
            minimumAmount: Number(minimumAmount),
          },
        ],
        promotionCombinations: combinations,
        isActive: true,
      },
    };

    if (+discountTypeSelected === 3) {
      // * Check duplicate

      variables.promotionInput.fixedDetails = customDetails.map((c) => {
        return {
          itemId: c.id,
          price: +c.price,
        };
      });
    }

    this.props.client.mutate({
      context: {
        route: "selfOrdering",
      },
        variables,
        mutation: gql`
          mutation createPromotion($promotionInput: PromotionInput!) {
            createPromotion(promotionInput: $promotionInput) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to create promotion.");
      });
  };

  getBranchesItems = (branchId) => {
    this.props.client.query({
      context: {
        route: "selfOrdering",
      },
        variables: { branchId },
        query: gql`
          query getItems($branchId: String!) {
            items(branchId: $branchId) {
              id
              name
              price
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let items = result.data.items.map((i) => {
            return {
              name: i.name,
              value: i.id,
            };
          });
          let itemSelected = items.length === 1 ? items[0].id : undefined;
          this.setState({ items, itemSelected });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleBranch = (e) => {
    this.setState({ [e.name]: e.value }, () => {
      this.getShops(e.value);
      this.getBranchesItems(e.value);
      this.getPromotions(e.value);
    });
  };

  toggleDateSelection = () => {
    this.setState({ toggleDate: true });
  };

  closeDateSelection = () => {
    this.setState({ toggleDate: false });
  };

  onDateSelected = (date) => {
    this.setState({ toggleDate: false, selectedDay: date, realtime: false });
  };

  handleWholeChange = (event) => {
    const target = event.target;
    const value =
      target.name === "discountWhole" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handlePromotionSelection = (e) => {
    var { combinations } = this.state;
    combinations = e.values;
    console.log("combinations", combinations);
    this.setState({ combinations });
  };

  onDetailChanged = (e) => {
    console.log("Details Changed", e);

    this.setState({ customDetails: e });
  };

  render() {
    const {
      name,
      branches,
      branchSelected,
      shops,
      shopSelected,
      types,
      typeSelected,
      discountTypes,
      discountTypeSelected,
      items,
      itemSelected,
      amount,
      minimumAmount,
      selectedDay,
      toggleDate,
      promotions,
      combinations,
    } = this.state;

    console.log("discount type", discountTypeSelected);

    return (
      <div>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <div className="header">
          <h1>Add new promotion</h1>
          <button className="main" onClick={this.onCreate}>
            Create
          </button>
        </div>
        <Form>
          <Input
            title="Promotion name"
            name="name"
            placeholder="Enter promotion name"
            onChange={this.handleInputs}
            value={name}
          />
          <Select
            title="Branch"
            value={branchSelected}
            name="branchSelected"
            placeholder="Select a branch."
            options={branches}
            onChange={this.handleBranch}
          />

          <Select
            title="Shop"
            value={shopSelected}
            name="shopSelected"
            placeholder="Select a shop."
            options={shops}
            onChange={this.handleSelect}
          />

          {shopSelected && (
            <div>
              <Select
                title="Type"
                value={typeSelected}
                name="typeSelected"
                placeholder="Select a promotion type."
                options={types}
                onChange={this.handleSelect}
              />
              <Select
                title="Discount Type"
                value={discountTypeSelected}
                name="discountTypeSelected"
                placeholder="Select a Discount type."
                options={discountTypes}
                onChange={this.handleSelect}
              />
              {/* <Input
                title="Discount Amount"
                name="discountAmount"
                placeholder="Enter discount amount."
                onChange={this.handleInputs}
                value={discountAmount}
              />
              <Input
                title="Maximum Discount"
                name="maximumAmount"
                placeholder="Enter maximum discount amount."
                onChange={this.handleInputs}
                value={maximumAmount}
              /> */}
              {/* <div>
                <label>ValidUntil</label>
                <input
                  readOnly={true}
                  type="text"
                  value={
                    selectedDay
                      ? moment(selectedDay).format("D MMM YYYY")
                      : "No selected date"
                  }
                  onClick={this.toggleDateSelection}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  defaultChecked={discountWhole}
                  onChange={this.handleWholeChange}
                />
                <label>Discount Whole Bill</label>
              </div> */}
            </div>
          )}
        </Form>
        <PromotionDetail
          type={discountTypeSelected}
          branchId={branchSelected}
          onChanged={this.onDetailChanged}
        />
        <Form>
          <h4>Promotion Details</h4>
          {typeSelected === "2" && items && (
            <Select
              title="Item"
              value={itemSelected}
              name="itemSelected"
              placeholder="Select an item."
              options={items}
              onChange={this.handleSelect}
            />
          )}
          <Input
            title="Amount"
            name="amount"
            placeholder="Enter maximum amount of item."
            onChange={this.handleInputs}
            value={amount}
          />
          <Input
            title="Minimum Amount"
            name="minimumAmount"
            placeholder="Enter minimum amount of item."
            onChange={this.handleInputs}
            value={minimumAmount}
          />
        </Form>
        <Form>
          <h4>Promotion Combinations</h4>
          {promotions && (
            <>
              <MultiSelect
                name="promotions"
                displayProp="name"
                valueProp="value"
                placeholder="Click to assign promotions"
                options={promotions || []}
                value={combinations}
                onChange={this.handlePromotionSelection}
              />
            </>
          )}
        </Form>
        {toggleDate && (
          <DateSelection
            selection={selectedDay}
            close={this.closeDateSelection}
            onSelect={this.onDateSelected}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps)(PromotionsCreate))
);