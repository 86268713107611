import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import { Input, Form, Header } from "../../common";

class UnitsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      loading: true,
    };
  }

  componentDidMount() {}

  onCreate = () => {
    const { name } = this.state;
    const { companyId, userId } = this.props.userAuth;

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          unit: {
            companyId,
            name,
            createdBy: userId,
          },
        },
        mutation: gql`
          mutation createUnit($unit: UnitInput!) {
            createUnit(unit: $unit) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          for (const error of result.errors) {
            alert(`Error: ${error.message}`);
          }

          return;
        }

        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to create new Unit");
      });
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value });
  };

  render() {
    const { name } = this.state;

    return (
      <div className="unit__create">
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title="Add new a unit">
          <button className="slim" onClick={this.onCreate}>
            Create
          </button>
        </Header>
        <Form>
          <h4>Basic Information</h4>
          <Input
            title="Name"
            name="name"
            placeholder="Enter name of unit."
            onChange={this.handleInputs}
            value={name}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(UnitsCreate)));
