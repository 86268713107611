import { gql } from "apollo-boost";

const getBranches = (client, userId) => {
  return new Promise(function(resolve, reject) {
    client
      .query({
        variables: {
          userId
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
              createdOn
            }
          }
        `
      })
      .then(result => {
        if (result.errors) {
          reject(new Error(result.errors[0].message));
        } else {
          resolve(result.data.branches);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export { getBranches };
