import React from "react";
import APITable from "../../APITable";

const startChange = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [
    {
      name: "Amount",
      desc: "Specifies the total amount to the machine",
    },
  ];

  let resp = [
    {
      name: "Amount",
      desc: "Returns the total amount that the machine was assigned",
    },
    {
      name: "Deposited",
      desc: "Denominations that were deposited into the machine",
      type: "DenominationType[]",
    },
    {
      name: "Dispensed",
      desc: "Denominations that were dispensed out of the machine",
      type: "DenominationType[]",
    },
  ];

  return (
    <APITable
      title="Start Change"
      desc="Starts a change operation to machine, can be used with StartCashIn. Once the the machine detects cash deposited more or equal to sales total, then the machine will stop and automatically change."
      command="StartChange"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default startChange;
