import React from "react";
import APITable from "../../APITable";

const collect = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [
    {
      name: "Denominations",
      desc: "Specify denominations to be transported",
      type: "DenominationType[]",
    },
    {
      name: "Option",
      desc: "Option to change how the collection behave",
      tags: ["optional"],
      type: " ",
      el: [
        {
          name: "Denominations",
          desc:
            "Recycling denominations that were collected (0 → No | 1 → Yes)",
          type: "string",
        },
        {
          name: "Mix",
          desc: "Mix denominations that were collected (0 → No | 1 → Yes)",
          type: "string",
        },
        {
          name: "IFCassette",
          desc:
            "Denomination collected that were inside the Interface cassette (0 → No | 1 → Yes)",
          type: "string",
        },
      ],
    },
  ];
  let resp = [
    {
      name: "Collected",
      desc: "Denominations that were collected",
      type: "DenominationType[]",
      el: [
        {
          name: "Denominations",
          desc: "Recycling denominations that were collected",
          type: "DenominationType[]",
        },
        {
          name: "Mix",
          desc: "Mix denominations that were collected",
          type: "DenominationType[]",
        },
        {
          name: "IFCassette",
          desc:
            "Denomination collected that were inside the Interface cassette",
          type: "DenominationType[]",
        },
      ],
    },
  ];

  return (
    <APITable
      title="Collection"
      desc="Transports cash from the recycling stackers to the collection cassettes/box"
      command="Collect"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default collect;
