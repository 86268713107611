import React from "react";
import APITable from "../../APITable";

const disableDenominations = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [
    {
      name: "Denominations",
      desc: "Denominations to be prohibited",
      type: "DenominationType[]",
    },
  ];

  let resp = [];

  return (
    <APITable
      title="Disable Denominations"
      desc="Prohibit denominations to be accepted by the machine"
      command="DisableDenominations"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default disableDenominations;
