import React, { Component } from "react";
import Policies from "./Policies";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import Input from "../../common/Input";
import Form from "../../common/Form";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      statements: [],
      loading: true,
      companyResources: undefined,
    };
  }

  componentDidMount() {
    // Get available resources and permissions
    this.props.client
      .query({
        variables: { companyId: this.props.userAuth.companyId },
        query: gql`
          query getData($companyId: String!) {
            companyResources(companyId: $companyId) {
              id
              name
              permissions {
                id
                description
                action
                resourceId
              }
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          return;
        }

        this.setState({
          loading: false,
          companyResources: result.data.companyResources,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  policyChanged = (statements) => {
    this.setState({ statements });
  };

  add = () => {
    const { name, statements } = this.state;

    if (!name) {
      alert("Please enter a name!");
      return;
    }

    if (!statements || statements.length === 0) {
      alert("Please select at least one Statement");
      return;
    }

    let permissions = statements.reduce((prev, curr) => {
      return prev.concat(...curr.permissions);
    }, []);

    if (!permissions || permissions.length === 0) {
      alert("Please select Permissions");
      return;
    }

    let permissionIds = permissions.map((p) => +p);

    this.props.client
      .mutate({
        variables: {
          roleDetail: {
            name: name,
            companyId: this.props.userAuth.companyId,
            createdBy: this.props.userAuth.userId,
          },
          permissions: permissionIds,
        },
        mutation: gql`
          mutation createRole($roleDetail: RoleInput!, $permissions: [Int!]!) {
            createRole(roleDetail: $roleDetail, permissions: $permissions) {
              id
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);
        if (!result.data || !result.data.createRole) {
          alert(
            "Unable to create new Role, can be caused by duplicate role name"
          );
        } else {
          alert("success");
          this.props.history.goBack();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  render() {
    const { name, companyResources } = this.state;

    return (
      <div id="roles__create">
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <div className="header">
          <h1>Add new role</h1>
          <div>
            <button className="main slim" onClick={this.add}>
              Add
            </button>
          </div>
        </div>
        <Form loading={this.state.loading}>
          <h4>Basic information</h4>
          <Input
            title="Name"
            name="name"
            placeholder="Enter role name"
            onChange={this.handleInputs}
            value={name}
          />
          <h4>Policies</h4>
          <Policies
            companyResources={companyResources}
            policyChanged={this.policyChanged}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(Create)));
