import React, { Component } from "react";
import { replaceNavigation, addNavigation } from "../../../actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";
import moment from "moment";

class Transactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      take: 100,
      transactions: [],
      totalRecords: 0
    };
  }

  componentDidMount() {
    this.getBranchesTransactions(
      this.props.branchId,
      this.props.selectedDay,
      1,
      100
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedDay !== this.props.selectedDay ||
      prevProps.branchId !== this.props.branchId
    ) {
      this.getBranchesTransactions(
        this.props.branchId,
        this.props.selectedDay,
        1,
        100
      );
    }
  }

  getBranchesTransactions = (branchId, selectedDay, page, take) => {
    if (!branchId) {
      return;
    }
    console.log(branchId);
    console.log(selectedDay);

    // Get hospital transactions available
    this.props.client
      .query({
        variables: {
          branchId,
          date: selectedDay,
          take,
          skip: take * (page - 1)
        },
        query: gql`
          query getData(
            $branchId: String!
            $skip: Int
            $take: Int
            $date: DateTime
          ) {
            branchMachines(branchId: $branchId) {
              id
              name
            }
            transactions(
              branchId: $branchId
              date: $date
              skip: $skip
              take: $take
            ) {
              totalRecords
              transactions {
                id
                hn
                createdOn
                transactionId
                patientName
                bookNo
                billNo
                total
                cashAmount
                privilegeAmount
                status
                machineId
                details {
                  createdOn
                  paymentType
                  amount
                  approveCode
                  merchantId
                  terminalId
                  traceInvoiceNumber
                  status
                }
              }
            }
          }
        `
      })
      .then(result => {
        if (result.data) {
          console.log(result.data);

          const { transactions, branchMachines } = result.data;

          if (!transactions) {
            this.setState({
              totalRecords: 0,
              transactions: [],
              performingAction: false,
              currentPage: page
            });
            return;
          }

          // Re map transactions where the machine name instead
          var machines = branchMachines.reduce((p, c) => {
            p[c.id] = c.name;
            return p;
          }, {});

          var transRemapped = transactions.transactions.map(t => {
            t.machine = machines[t.machineId];

            return t;
          });

          this.setState({
            totalRecords: transactions.totalRecords,
            transactions: transRemapped || [],
            currentPage: page
          });
        } else {
          alert("No sales date of the requested parameters");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { transactions: all, currentPage, take } = this.state;

    if (this.props.machine) {
      // Has a machine filter
      var transactions = all.filter(t => t.machineId === this.props.machine);
    } else {
      transactions = all;
    }

    let transactionRender =
      transactions.length > 0 ? (
        transactions.map((t, index) => {
          return (
            <tr key={index} onClick={() => this.props.onLookup(t)}>
              <td>{(currentPage - 1) * take + index + 1}</td>
              <td>{moment(t.createdOn).format("D/M/YY H:mm")}</td>
              <td>
                {t.bookNo}-{t.billNo}
              </td>
              <td>{t.hn}</td>
              <td className="name">{t.patientName}</td>
              <td>{t.cashAmount + t.privilegeAmount}</td>
              <td>{t.machine}</td>
              <td>
                {t.details.map((d, i) => {
                  switch (d.paymentType) {
                    case 0:
                      return (
                        <span key={`pt${i}`} className="pt cash">
                          Cash
                        </span>
                      );
                    case 1:
                      return (
                        <span key={`pt${i}`} className="pt cashCard">
                          Card
                        </span>
                      );
                    case 2:
                      return (
                        <span key={`pt${i}`} className="pt qr">
                          QR
                        </span>
                      );
                    case 3:
                      return (
                        <span key={`pt${i}`} className="pt privilege">
                          Privilege
                        </span>
                      );
                    default:
                      return null;
                  }
                })}
              </td>
              {/* <td>{status}</td> */}
              {/* <td>
                {t.status !== 1 && !performingAction && (
                  <button onClick={() => this.void(t.id)}>Void</button>
                )}
              </td> */}
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan="100%">No transactions found</td>
        </tr>
      );

    return (
      <div className="list">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Invoice</th>
              <th>HN</th>
              <th>Patient</th>
              <th>Total</th>
              <th>Machine</th>
              <th>Paid by</th>
              {/* <th>Status</th> */}
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>{transactionRender}</tbody>
        </table>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    replaceNavigation: payload => dispatch(replaceNavigation(payload)),
    addNavigation: payload => dispatch(addNavigation(payload))
  };
};

const mapStateToProps = state => {
  return {
    userAuth: state.userAuth
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(Transactions))
);
