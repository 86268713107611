import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import moment from "moment";
import Summary from "../machines/deposit/Summary";
import { Header } from "../../common";
import DateSelection from "../DateSelection";
import { endOfDay } from "date-fns";
import ld from "lodash";

const STATUS = {
  idle: "idle",
  gettingInfo: "gettingInfo",
  gettingRecords: "gettingRecords",
};

class ShiftReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branches: [],
      branchSelected: undefined,
      selectedDay: null,
      toggleDate: false,
      machines: [],
      machineSelected: undefined,
      records: [],
      total: this.currencyFormat(0),
      status: STATUS.gettingInfo,
    };
  }

  componentDidMount() {
    this.getBranches();
  }

  getBranches = () => {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
              createdOn
            }
          }
        `,
      })
      .then((result) => {
        let branches = result.data.branches;

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;
        this.setState(
          {
            branches: branches,
            branchSelected,
            status: STATUS.idle,
          },
          () => {
            this.getRecords();
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  };

  getRecords = () => {
    if (!this.state.branchSelected) {
      return;
    }

    this.setState({ status: STATUS.gettingRecords }, () => {
      console.log("Getting records");

      this.props.client
        .query({
          variables: {
            branchId: this.state.branchSelected,
            date: this.state.selectedDay,
          },
          query: gql`
            query getData($branchId: String!, $date: DateTime) {
              shifts(branchId: $branchId, date: $date) {
                id
                createdOn
                total
                posId
                userId
                details {
                  total
                  userId
                  category
                }
              }
              branchUsers(branchId: $branchId) {
                id
                name
              }
            }
          `,
        })
        .then((result) => {
          if (!result.data) {
            return;
          }

          let { shifts, branchUsers } = result.data;

          let users = {};

          for (const bu of branchUsers) {
            users[bu.id] = bu.name;
          }

          let tmpShifts = ld.cloneDeep(shifts);

          for (const shift of tmpShifts) {
            shift.user = users[shift.userId] || "anonymous";
            shift.createdOn = moment(shift.createdOn).format("D/M/YY H:mm");

            let newDetails = [];

            for (const detail of shift.details) {
              let { user, total, category } = detail;

              let username = users[detail.userId] || "anonymous";

              let index = newDetails.findIndex((d) => d.category === category);

              if (index < 0) {
                // New category
                newDetails.push({
                  category,
                  netTotal: total,
                  records: [
                    {
                      user: username,
                      total: total,
                    },
                  ],
                });

                continue;
              }

              // Add to existing category
              newDetails[index].records.push({
                user: username,
                total: total,
              });
              newDetails[index].netTotal += total;
            }

            shift.details = newDetails;
          }

          this.setState({
            records: tmpShifts,
            status: STATUS.idle,
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error: "Unable to connect to server" });
        });
    });
  };

  toggleDateSelection = () => {
    this.setState({ toggleDate: true });
  };

  closeDateSelection = () => {
    this.setState({ toggleDate: false }, () => {
      //   this.getRecords();
    });
  };

  onDateSelected = (date) => {
    this.setState({ toggleDate: false, selectedDay: endOfDay(date) }, () => {
      this.getRecords();
    });
  };

  handleInput = (e) => {
    var elementName = e.target.name;

    this.setState({ [e.target.name]: e.target.value }, () => {
      if (elementName === "machineSelected") {
        // this.getRecords();
      } else if (elementName === "branchSelected") {
        // this.getMachines();
        this.getRecords();
      }
    });
  };

  currencyFormat(amount) {
    var format = new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
      minimumFractionDigits: 2,
    });

    return format.format(amount);
  }

  exportExcel = () => {
    // ! Not implemented yet
    if (!this.state.branchSelected) {
      alert("Please select branch");
      return;
    }

    this.props.client
      .query({
        variables: {
          branchId: this.state.branchSelected,
          date: this.state.selectedDay,
        },
        query: gql`
          query getExcel($branchId: String!, $date: DateTime) {
            exportShifts(branchId: $branchId, date: $date)
          }
        `,
      })
      .then((result) => {
        // File name
        let reportDate = moment(this.state.selectedDay).format("D-M-YY");
        let currentDate = moment().format("DMYYHMM");
        let fileName = `ShiftReport_${reportDate}_(${currentDate})`;

        this.downloadFile(result.data.exportShifts, fileName);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadFile(blobContent, name) {
    let FileSaver = require("file-saver");
    let blob = new Blob(
      [
        this.base64toBlob(
          blobContent,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ),
      ],
      {}
    );
    FileSaver.saveAs(blob, `${name}.xlsx`);
  }

  render() {
    const {
      status,
      branches,
      branchSelected,
      toggleDate,
      selectedDay,
      records,
    } = this.state;

    let transactions = records;

    const style1 = {
      background: "#E7F5FF",
    };

    const style2 = {
      background: "#ffffff",
    };

    let total = records.reduce((prev, curr) => prev + curr.total, 0);

    return (
      <div id="report__transaction">
        <Header title="Shift Report" level="1">
          <button onClick={this.exportExcel}>Export excel</button>
        </Header>
        <div className="summary">
          <Summary
            grandTotal={this.currencyFormat(total)}
            received={"-"}
            changed={"-"}
          />
        </div>
        <div className="transactions">
          <div className="header">
            <h2>Filter</h2>
          </div>
          <div className="filter">
            <div>
              <label>Branches</label>
              <select
                disabled={status !== STATUS.idle ? true : false}
                name="branchSelected"
                value={branchSelected}
                onChange={this.handleInput}
              >
                {!branchSelected && <option>Select branch</option>}
                {branches.map((b) => (
                  <option key={b.id} value={b.id}>
                    {b.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Date</label>
              <input
                disabled={status !== STATUS.idle ? true : false}
                readOnly={true}
                type="text"
                value={
                  selectedDay
                    ? moment(selectedDay).format("D MMM YYYY")
                    : "Real-time"
                }
                onClick={this.toggleDateSelection}
              />
            </div>
          </div>
          <div className="records">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>POS Id</th>
                  <th>Performed by</th>
                  <th>Grand Total</th>
                  <th>Net Total</th>
                  <th>Category</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {status === STATUS.gettingRecords ? (
                  <tr>
                    <td colSpan="100%">Retrieving records</td>
                  </tr>
                ) : (
                  transactions.length === 0 && (
                    <tr>
                      <td colSpan="100%">No shifts found</td>
                    </tr>
                  )
                )}
                {transactions.map((t, i) =>
                  t.details.map((detail, j) => (
                    <>
                      <tr style={i % 2 === 0 ? style1 : style2}>
                        {j === 0 ? (
                          <>
                            <td rowSpan={t.details.length}>{i + 1}</td>
                            <td rowSpan={t.details.length}>{t.createdOn}</td>
                            <td rowSpan={t.details.length}>{t.posId}</td>
                            <td rowSpan={t.details.length}>{t.user}</td>
                            <td rowSpan={t.details.length}>
                              {this.currencyFormat(t.total)}
                            </td>
                          </>
                        ) : (
                          <></>
                        )}
                        <td>{detail.netTotal}</td>
                        <td>{detail.category}</td>
                        <td>
                          {detail.records.map((record, k) => (
                            <Fragment>
                              <label>{`${record.user} : ${this.currencyFormat(
                                record.total
                              )}`}</label>
                              <br />
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                    </>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        {toggleDate && (
          <DateSelection
            selection={selectedDay}
            close={this.closeDateSelection}
            onSelect={this.onDateSelected}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps)(ShiftReport));
