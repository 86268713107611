import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Input, Form, Header } from "../../common";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originalName: "",
      name: "",
      loading: true,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) {
      return;
    }

    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { id },
        query: gql`
          query getKitchen($id: String!) {
            kitchen(ID: $id) {
              name
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.setState({
            name: result.data.kitchen.name,
            originalName: result.data.kitchen.name,
            loading: false,
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  onSave = () => {
    const { name } = this.state;
    const { id } = this.props.match.params;

    if (!id) {
      return;
    }

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id: id,
          name,
        },
        mutation: gql`
          mutation updateKitchen($id: String!, $name: String!) {
            updateKitchen(id: $id, name: $name)
          }
        `,
      })
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          for (const error of result.errors) {
            alert(`Error: ${error.message}`);
          }

          return;
        }

        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to create kitchen.");
      });
  };

  render() {
    const { name, originalName, loading } = this.state;

    return (
      <div>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title={`Editing ${originalName}`}>
          <button className="main" onClick={this.onSave}>
            Create
          </button>
        </Header>
        <Form loading={loading}>
          <Input
            title="Name"
            name="name"
            placeholder="Enter name of kitchen."
            onChange={this.handleInputs}
            value={name}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(Edit)));
