import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Header, Form, Select } from "../../common";

class ShopsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shops: undefined,
      loading: true,
      branches: [],
      branchSelected: undefined,
    };
  }

  componentDidMount() {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches;

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState(
          {
            branches: branches,
            branchSelected,
            loading: false,
          },
          () => {
            if (branchSelected) {
              this.getBranchShops(branchSelected);
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  getBranchShops = (branchId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { branchId },
        query: gql`
          query getShops($branchId: String!) {
            shops(branchId: $branchId) {
              id
              name
              createdOn
              kitchen {
                id
                name
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.setState({ shops: result.data.shops });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleBranch = (e) => {
    this.setState({ [e.name]: e.value }, () => {
      this.getBranchShops(e.value);
    });
  };

  createShop = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  editShop = (shopId) => {
    this.props.history.push(`${this.props.location.pathname}/edit/${shopId}`);
  };

  deleteShop = (shopId) => {
    var confirm = window.confirm(`Confirm deletion?`);

    if (!confirm) {
      return;
    }

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: {
          id: shopId,
        },
        mutation: gql`
          mutation deleteShop($id: String!) {
            deleteShop(id: $id) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          alert("Deactivated shop successfully!");

          this.getBranchShops(this.state.branchSelected);
        }
      })
      .catch((error) => {
        //console.log(error.message);
        alert("Unable to delete shop");
      });
  };

  render() {
    const { shops, branches, branchSelected } = this.state;

    let shopRender =
      shops && shops.length > 0 ? (
        shops.map((s, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{s.name}</td>
            <td>
              <button className="slim" onClick={() => this.editShop(s.id)}>
                Edit
              </button>
              <button className="slim" onClick={() => this.deleteShop(s.id)}>
                Delete
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="100%">No shop have been added</td>
        </tr>
      );
    return (
      <>
        <Header title="Shops">
          <button className="slim" onClick={this.createShop}>
            Create
          </button>
        </Header>
        <Form>
          <Header title="Filter" level="4" />
          <Select
            title="Branch"
            value={branchSelected}
            name="branchSelected"
            placeholder="Select branch to lookup"
            options={branches.map((b) => {
              b.value = b.id;
              return b;
            })}
            onChange={this.handleBranch}
          />
        </Form>
        <div>
          <table className="shop__table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>{shopRender}</tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(ShopsList)));
