import React from "react";
import APITable from "../../APITable";

const getWholeInventory = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let resp = [
    {
      name: "Inventory",
      desc: "The whole inventory inside the machine combined",
    },
  ];

  return (
    <APITable
      title="Get Whole Inventory"
      desc="Retrieves the machine’s whole inventory combined together"
      command="GetWholeInventory"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      resp={resp}
    />
  );
};

export default getWholeInventory;
