import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

// Import related components
import BranchesList from "./BranchesList";
import BranchesCreate from "./BranchesCreate";

class Branches extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  createNewCompany = () => {};

  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={match.url} render={BranchesList} />
        <Route exact path={`${match.url}/create`} render={BranchesCreate} />
      </div>
    );
  }
}

export default withRouter(Branches);
