import React, { Component } from "react";
import { Form } from "../../../../common";
import FixedPromotion from "./FixedPromotion";
import PercentPromotion from "./PercentPromotion";
import ValuePromotion from "./ValuePromotion";

class PromotionDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: undefined,
    };
  }

  componentDidMount() {
    if (this.props.onChanged) this.props.onChanged(this.props.defaultValue);
  }

  onChanged = (e) => {
    if (this.props.onChanged) this.props.onChanged(e);
  };

  render() {
    let { type, branchId, defaultValue } = this.props;

    if (!type) {
      return null;
    }

    switch (+type) {
      case 1:
        var detailToRender = (
          <>
            <PercentPromotion branchId={branchId} />
          </>
        );
        break;
      case 2:
        detailToRender = (
          <>
            <ValuePromotion branchId={branchId} />
          </>
        );
        break;
      case 3:
        detailToRender = (
          <>
            <FixedPromotion
              defaultValue={defaultValue}
              branchId={branchId}
              onChanged={this.onChanged}
            />
          </>
        );
        break;
      default:
        detailToRender = null;
        break;
    }

    return <Form>{detailToRender}</Form>;
  }
}

export default PromotionDetail;
