import React, { Component } from "react";
import { gql } from "apollo-boost";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import Select from "../../common/Select";
import { Form } from "../../common";

class Accessibility extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authLevel: undefined,
      branches: [],
      branchSelected: undefined,
      groups: [],
      groupSelected: undefined,
    };
  }

  componentDidMount() {
    // Get branches
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
          companyId: this.props.userAuth.companyId,
        },
        query: gql`
          query getData($userId: String!, $companyId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
            groups(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          // Couldn't get data
        }

        console.log(result.data);

        let branches = result.data.branches.map((b) => {
          return {
            name: b.name,
            value: b.id,
          };
        });

        let groups = result.data.groups.map((g) => {
          return {
            name: g.name,
            value: g.id,
          };
        });

        this.setState({
          branches,
          groups,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: "Unable to connect to server" });
      });
  }

  changeLevel = (level) => {
    let { groupSelected, branchSelected, authLevel } = this.state;

    authLevel = level;

    if (level === 0) {
      groupSelected = undefined;
      branchSelected = undefined;
    } else if (level === 1) {
      branchSelected = undefined;
    }

    this.setState({ groupSelected, branchSelected, authLevel }, this.onChange);
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value }, this.onChange);
  };

  onChange = () => {
    console.log(this.state);

    this.props.onChange(this.state);
  };

  render() {
    const {
      authLevel,
      branches,
      branchSelected,
      groups,
      groupSelected,
    } = this.state;

    const { authLevelError, groupError, branchError } = this.props.validation;

    // Generate options depending on the access level selected
    var options = <></>;

    if (authLevel > 0) {
      let groupSection = (
        <>
          <Select
            title="Group"
            value={groupSelected}
            name="groupSelected"
            placeholder="Select a group"
            options={groups}
            onChange={this.handleSelect}
            error={groupError}
          />
        </>
      );

      if (authLevel === 1) {
        // Company
        options = <>{groupSection}</>;
      } else if (authLevel === 2) {
        // Branch
        options = (
          <>
            {groupSection}
            <Select
              title="Branch"
              value={branchSelected}
              name="branchSelected"
              placeholder="Select a branch"
              options={branches}
              onChange={this.handleSelect}
              error={branchError}
            />
          </>
        );
      }
    }

    return (
      <Form className="accessibility">
        <h4>Accessibility</h4>
        <div>
          <label>Authority level</label>
          <span className="error">{authLevelError}</span>
          <div className="authorityLevels">
            <div
              className={authLevel === 0 ? "level selected" : "level"}
              onClick={() => this.changeLevel(0)}
            >
              <div className="checkbox" />
              <div className="content">
                <label>Administrative</label>
                <span>Highest accessibility</span>
              </div>
            </div>
            <div
              className={authLevel === 1 ? "level selected" : "level"}
              onClick={() => this.changeLevel(1)}
            >
              <div className="checkbox" />
              <div className="content">
                <label>Company</label>
                <span>Multiple branches access</span>
              </div>
            </div>
            <div
              className={authLevel === 2 ? "level selected" : "level"}
              onClick={() => this.changeLevel(2)}
            >
              <div className="checkbox" />
              <div className="content">
                <label>Branch</label>
                <span>Specific branch access</span>
              </div>
            </div>
          </div>
          {options}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(connect(mapStateToProps)(Accessibility));
