import React, { Component } from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
// import Machine from "./Machine";

import "./style.scss";
import { withRouter } from "react-router-dom";

class Machines extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      machines: [],
      inventoryToPreview: undefined,
    };
  }

  componentDidMount = () => {
    document.addEventListener("keydown", this.escFunction, false);

    this.props.client
      .query({
        variables: {
          branchId: this.props.branchId,
        },
        query: gql`
          query getMachines($branchId: String!) {
            machineOverviews(branchId: $branchId) {
              id
              name
              reachability
              receiptStatus
              cashDevices {
                reachability
              }
              inventoryLevel
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        // let machines = result.data.branchMachines.map((m, i) => {
        //   return {
        //     id: m.id,
        //     code: m.code,
        //     name: m.name,
        //     isOnline: true,
        //     inventory: 0,
        //     inventoryStatus: 0,
        //     recyclingTotal: 0,
        //     collectionTotal: 0,
        //     hasError: false
        //   };
        // });

        let machines = result.data.machineOverviews.map((m, i) => {
          return {
            id: m.id,
            name: m.name,
            reachability: m.reachability,
            receiptStatus: m.receiptStatus,
            cashDevices: m.cashDevices,
            inventoryLevel: m.inventoryLevel,
          };
        });

        this.setState({ loading: false, machines });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.setState({ inventoryToPreview: undefined });
    }
  };

  showPreview = (recycling, collection) => {};

  preview = (machine) => {
    this.props.history.push(`/machines/detail?id=${machine.id}`, {
      ...machine,
    });
  };

  closePreview = () => {
    console.log("closing");

    this.setState({ inventoryToPreview: undefined });
  };

  getProgressStyle = (percentage) => {
    let barAreaColor = "";
    let barBorderColor = "#999";

    if (percentage === 0) {
      // Empty
      barAreaColor = "red";
      barBorderColor = "red";
    } else if (percentage <= 10) {
      // Low
      barAreaColor = "orange";
    } else if (percentage >= 90) {
      // Near full or Full
      barAreaColor = "#266ff0";
    } else {
      // Exist
      barAreaColor = "green";
    }

    if (percentage < 5 && percentage > 0) {
      percentage = 5;
    }

    return {
      barStyle: {
        borderColor: barBorderColor,
      },
      areaStyle: {
        width: `${percentage}%`,
        backgroundColor: barAreaColor,
      },
    };
  };

  render() {
    const { machines, loading } = this.state;

    if (loading) {
      // var machineRender = (
      //   <tr>
      //     <td colSpan="4">Retrieving machines</td>
      //   </tr>
      // );
    } else {
      // if (machines.length > 0) {
      //   machineRender = machines.map((m, i) => (
      //     <Machine key={`machine${i}`} detail={m} preview={this.preview} />
      //   ));
      // } else {
      //   machineRender = (
      //     <tr>
      //       <td colSpan="4">No machines have been added</td>
      //     </tr>
      //   );
      // }
    }

    return (
      <>
        <div className="dashboard__card machines">
          <h1>
            Machines<span>{machines.length}</span>
          </h1>
          <div className="overview__list">
            {machines.map((m, i) => {
              const isOnline = m.reachability ? "online" : "offline";

              if (m.receiptStatus === -1) {
                var receiptStatus = "offline";
              } else if (m.receiptStatus === 0) {
                receiptStatus = "ok";
              } else if (m.receiptStatus === 1) {
                receiptStatus = "low";
              }

              var inventoryStyle = this.getProgressStyle(m.inventoryLevel);

              return (
                <div
                  key={`machine_${i}`}
                  className="overview__item"
                  onClick={() => this.preview(m)}
                >
                  <span className={`machine__connectivity ${isOnline}`} />
                  <img
                    className="machine__type"
                    height="20"
                    src={
                      process.env.PUBLIC_URL + "/assets/icons/kiosk-small.svg"
                    }
                    alt="kiosk"
                  />
                  <div className="name">{m.name}</div>
                  <div className="inventory__level">
                    <div style={inventoryStyle.areaStyle} />
                  </div>
                  <div className="receipt__status">
                    <img
                      className="receiptPrinter"
                      height="12"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/receiptPrinter.svg"
                      }
                      alt="receipt"
                    />
                    <span className={receiptStatus} />
                  </div>
                  <div className="preview">
                    <svg
                      className="table__more right"
                      width="5"
                      height="10"
                      viewBox="0 0 8 16"
                      role="img"
                      aria-hidden="true"
                    >
                      <path
                        d="M5 8l-5 8h3l5-8-5-8H0z"
                        fill="#687992"
                        fillRule="evenodd"
                        role="presentation"
                      ></path>
                    </svg>
                    <svg
                      className="table__more left"
                      width="5"
                      height="10"
                      viewBox="0 0 8 16"
                      role="img"
                      aria-hidden="true"
                    >
                      <path
                        d="M5 8l-5 8h3l5-8-5-8H0z"
                        fill="#687992"
                        fillRule="evenodd"
                        role="presentation"
                      ></path>
                    </svg>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withApollo(Machines));
