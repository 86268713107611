import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";
import moment from "moment";

class MachineTransactionSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branchId: props.branchId,
      sales: 0,
      deposited: 0,
      dispensed: 0,
      loading: true,
    };
  }

  componentDidMount() {
    this.getSummary();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({ loading: true }, () => this.getSummary());
    }
  }

  getSummary = () => {
    if (!this.state.branchId) {
      return;
    }

    let {
      filter: { start, end },
    } = this.props;

    this.props.client
      .query({
        variables: {
          branchId: this.state.branchId,
          startDate: start,
          endDate: end,
        },
        query: gql`
          query getBranchSummary(
            $branchId: String!
            $startDate: DateTime
            $endDate: DateTime
          ) {
            branchSummary(
              branchId: $branchId
              startDate: $startDate
              endDate: $endDate
            ) {
              sales
              deposited
              dispensed
            }
          }
        `,
      })
      .then((response) => {
        if (!response.data) {
          return;
        }

        let { sales, deposited, dispensed } = response.data.branchSummary;

        this.setState({
          sales,
          deposited,
          dispensed,
        });
      })
      .catch((err) => {});
  };

  currencyFormat(amount) {
    var format = new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
      minimumFractionDigits: 2,
    });

    return format.format(amount);
  }

  downloadFile(blobContent, name) {
    let FileSaver = require("file-saver");
    let blob = new Blob(
      [
        this.base64toBlob(
          blobContent,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ),
      ],
      {}
    );
    FileSaver.saveAs(blob, `${name}.xlsx`);
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  saveAsXlsxFile = () => {
    if (!this.state.branchId) {
      return;
    }

    let {
      filter: { start, end },
    } = this.props;

    this.props.client
      .query({
        variables: {
          branchId: this.state.branchId,
          startDate: start,
          endDate: end,
        },
        query: gql`
          query getBranchSummary(
            $branchId: String!
            $startDate: DateTime
            $endDate: DateTime
          ) {
            exportBranchSummary(
              branchId: $branchId
              startDate: $startDate
              endDate: $endDate
            )
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        // File name
        let currentDate = moment().format("DMYYHMM");
        let startFormatted = moment(start).format("D-M-YY");
        let endFormatted = moment(end).format("D-M-YY");
        let fileName = `MachineTransactionSummary_(${startFormatted}_${endFormatted})_(${currentDate})`;

        this.downloadFile(result.data.exportBranchSummary, fileName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let { sales, deposited, dispensed } = this.state;

    return (
      <>
        <div className="dashboard__card salesSummary">
          <div className="dashboard__heading span">
            <img
              height="32"
              src={
                process.env.PUBLIC_URL +
                "/assets/icons/dashboard/machineTranasction.png"
              }
              alt=""
            />
            <h3>Machine transactions</h3>
            <div className="actions">
              <button className="slim" onClick={this.saveAsXlsxFile}>
                Export excel
              </button>
            </div>
          </div>
          <h1>Grand Total</h1>
          <p>{this.currencyFormat(sales + deposited + dispensed)}</p>
          <h2>Transactions total</h2>
          <label>{this.currencyFormat(sales)}</label>
          <h2>Float deposited</h2>
          <label>{this.currencyFormat(deposited)}</label>
          <h2>Float dispensed</h2>
          <label>{this.currencyFormat(dispensed)}</label>
        </div>
      </>
    );
  }
}

export default withApollo(MachineTransactionSummary);
