import React, { Component } from "react";
import { Route, withRouter, NavLink } from "react-router-dom";

// Import components
import StartSales from "./doc/startSales";
import CancelSales from "./doc/cancelSales";
import Refund from "./doc/refund";
import Dispense from "./doc/dispense";

import "./style.scss";

// Import style
class ControlCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      match: { url },
      location: { pathname },
    } = this.props;

    return (
      <div className="documentation">
        <nav>
          <h1>Control Center</h1>
          <h2>
            <NavLink exact to={`${url}`} activeClassName="selected">
              Overview
            </NavLink>
          </h2>
          <h2>
            <NavLink
              to={`${url}/requests/startSales`}
              className={pathname.includes("requests") ? "selected" : ""}
            >
              Requests
            </NavLink>
          </h2>
          <NavLink activeClassName="selected" to={`${url}/requests/startSales`}>
            Start Sales
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/cancelSales`}
          >
            Cancel Sales
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/refund`}>
            Refund
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/dispense`}>
            Dispense
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/replenish`}>
            Replenish<span className="tag__beta">beta</span>
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/collect`}>
            Collect<span className="tag__beta">beta</span>
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/skim`}>
            Skim<span className="tag__beta">beta</span>
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/endOfDay`}>
            End of Day<span className="tag__beta">beta</span>
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/restoreWindow`}
          >
            Restore Window
          </NavLink>
          <h2>
            <NavLink
              to={`${url}/notifications/heartbeat`}
              className={pathname.includes("notifications") ? "selected" : ""}
            >
              Notifications
            </NavLink>
          </h2>
          <ul>
            <li>
              <NavLink
                activeClassName="selected"
                to={`${url}/notifications/heartbeat`}
              >
                Heartbeat
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="selected"
                to={`${url}/notifications/statusChanged`}
              >
                Status Changed
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="selected"
                to={`${url}/notifications/depositMonitor`}
              >
                Deposit Monitor
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="content">
          <Route
            exact
            path={`${url}/requests/startSales`}
            component={StartSales}
          />
          <Route
            exact
            path={`${url}/requests/cancelSales`}
            component={CancelSales}
          />
          <Route exact path={`${url}/requests/refund`} component={Refund} />
          <Route exact path={`${url}/requests/dispense`} component={Dispense} />
        </div>
      </div>
    );
  }
}

export default withRouter(ControlCenter);
