import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import qs from "querystring";
import { connect } from "react-redux";
import moment from "moment";

import Header from "../../../common/Header";
import Form from "../../../common/Form";

class TransactionDetails extends Component {
  constructor(props) {
    super(props);

    let params = qs.parse(this.props.location.search.replace("?", ""));

    this.state = {
      id: params.id || undefined,
      transaction: undefined,
      transactionDetails: undefined,
    };
  }

  componentDidMount() {
    if (!this.state.id) {
      alert("No transactionId provided!");
      this.return();
    }

    this.getTransaction();
  }

  getTransaction = () => {
    //console.log(this.state.id);
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { id: this.state.id },
        query: gql`
          query getTransaction($id: String!) {
            transaction(ID: $id) {
              transactionId
              machineCode
              billNumber
              totalAmount
              received
              changed
              createdDate
              machineId
              machine
              details {
                item {
                  itemCode
                  sku
                  name
                  displayName
                  image
                  createdOn
                }
                price
                quantity
                amount
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          console.log("Result:", result.data);
          this.setState({ transaction: result.data.transaction }, () => {
            console.log(this.state.transaction);
          });
        }
      })
      .catch((error) => {
        alert("Error");
      });
  };

  return = () => {
    //this.props.history.push("/reports/selfOrdering/transactions");
    this.props.history.goBack();
  };

  render() {
    const { transaction } = this.state;
    //console.log("transactionId", transaction.transactionId);
    return (
      <>
        {transaction && (
          <div>
            <button className="return" onClick={this.return}>
              Return
            </button>
            <Header
              title={transaction.transactionId}
              level="3"
              icon={process.env.PUBLIC_URL + "/assets/icons/kiosk-small.svg"}
            ></Header>
            <div className="transaction__info">
              <Form className="summary">
                <span>{transaction.totalAmount}</span>
                <label>Total Amount</label>
                <div className="details">
                  <div className="detail">
                    <span>{transaction.received}</span>
                    <label>Received</label>
                  </div>
                  <div className="detail">
                    <span>{transaction.changed}</span>
                    <label>Changed</label>
                  </div>
                </div>
              </Form>
              <Form className="info">
                <h4>Transaction info</h4>
                <label>Date</label>
                <span>
                  {moment(transaction.createdDate).format("D/M/YY H:mm a")}
                </span>
                <label>Machine</label>
                <span>{transaction.machine}</span>
              </Form>
              <Form className="payments">
                <h4>Payments</h4>
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ItemCode</th>
                      <th>SKU</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction.details.map((d, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{d.item.itemCode}</td>
                          <td>{d.item.sku}</td>
                          <td>{d.item.displayName}</td>
                          <td>{d.price}</td>
                          <td>{d.quantity}</td>
                          <td>{d.amount}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Form>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    apollo: state.apollo,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps)(TransactionDetails))
);
