import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import moment from "moment";
import { Header, Form } from "../../common";

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
    };
  }

  componentDidMount() {
    this.fetchRoles();
  }

  fetchRoles = () => {
    this.props.client
      .query({
        variables: { companyId: this.props.userAuth.companyId },
        query: gql`
          query getRoles($companyId: String!) {
            roles(companyId: $companyId) {
              id
              createdOn
              name
              user {
                name
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.setState({ roles: result.data.roles });
        }
      })
      .catch((err) => {});
  };

  create = () => {
    const { match } = this.props;

    this.props.history.push(`${match.url}/create`);
  };

  edit = (id) => {
    this.props.history.push(`${this.props.match.url}/edit?id=${id}`);
  };

  delete = (id) => {
    this.props.client
      .mutate({
        variables: {
          id,
        },
        mutation: gql`
          mutation delete($id: String!) {
            deleteRole(roleId: $id)
          }
        `,
      })
      .then((response) => {
        if (response.data && response.data.deleteRole) {
          // Success
          alert("Delete role successfully");

          this.fetchRoles();
        } else {
          alert("There are still Groups using this Role!");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  render() {
    const { roles } = this.state;

    return (
      <div>
        <Form>
          <Header title="Roles">
            <button className="slim" onClick={this.create}>
              Create
            </button>
          </Header>
          <table className="c-roles__list">
            <thead>
              <tr>
                <td>#</td>
                <td>Name</td>
                <td>Created on</td>
                <td>Created by</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {roles.map((role, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{role.name}</td>
                  <td>{moment(role.createdOn).format("D/M/YY H:mm")}</td>
                  <td>{role.user.name}</td>
                  <td>
                    <button className="slim" onClick={() => this.edit(role.id)}>
                      Edit
                    </button>
                    <button
                      className="slim"
                      onClick={() => this.delete(role.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(List)));
