import React, { Component } from "react";

export default class InventoryBar extends Component {
  render() {
    let { denominationInfo } = this.props;

    const value = denominationInfo.value;
    const pieces = denominationInfo.pieces;
    const watermark = denominationInfo.watermark;
    let max = Number(denominationInfo.max);

    // Calculate level percentage
    while (pieces > max) {
      max += max;
    }

    var percentage = (pieces / max) * 100;
    percentage = percentage >= 100 ? 100 : percentage;

    let barAreaColor = "";
    let barBorderColor = "#999";

    if (percentage === 0) {
      // Empty
      barAreaColor = "red";
      barBorderColor = "red";
    } else if (percentage <= 10) {
      // Low
      barAreaColor = "orange";
    } else if (percentage >= 90) {
      // Near full or Full
      barAreaColor = "#266ff0";
    } else {
      // Exist
      barAreaColor = "green";
    }

    if (percentage < 5 && percentage > 0) {
      percentage = 5;
    }

    let barStyle = {
      borderColor: barBorderColor,
    };

    let areaStyle = {
      height: `${percentage}%`,
      backgroundColor: barAreaColor,
    };

    let wPercent = (watermark / max) * 100;
    wPercent = wPercent >= 100 ? 100 : wPercent;
    let watermarkStyle = {
      bottom: `calc(${wPercent}% - 2px)`,
    };

    return (
      <div className="inventoryBar">
        <table>
          <tbody>
            <tr>
              <td>{pieces}</td>
            </tr>
            <tr>
              <td>
                <div className="progressbar" style={barStyle}>
                  <div className="area" style={areaStyle} />
                  {watermark > 0 && (
                    <>
                      <div
                        className="watermarkLevel"
                        style={watermarkStyle}
                      ></div>
                      <div className="watermarkLevel__tooltip">
                        {`Watermark ${watermark}`}
                      </div>
                    </>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>{value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
