import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

import TransactionsList from "./transactionsList";
import TransactionDetails from "./transactionDetails";

import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import { ApolloClient, InMemoryCache, HttpLink } from "apollo-boost";
import { SubNavigation } from "../../../subNavigation";
import {
  addNavigation,
  replaceNavigation,
  navigateTo,
} from "../../../../actions";

import "./style.scss";

class transactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      loading: true,
      clientSelfOrdering: undefined,
    };
  }
  componentDidMount() {
    // Change the sub nav bar first
    this.props.replaceNavigation({
      url: "transactions",
      title: "SelfOrdering",
    });

    this.props.addNavigation({
      url: "transactions",
      title: "transaction",
    });

    // Get name
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
            user(id: $userId) {
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches;

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState(
          {
            branches: branches,
            branchSelected,
            loading: false,
            user: result.data.user.name,
          },
          () => {
            if (branchSelected) {
              // this.getBranchesTransactions(branchSelected);
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });

    let graphqlLocation = `${process.env.REACT_APP_GRAPHQL_ADDR_SELFORDERING}`;
    console.log(graphqlLocation);

    const cache = new InMemoryCache();

    var client = new ApolloClient({
      cache,
      link: new HttpLink({
        uri: `${graphqlLocation}`,
      }),
    });
    this.setState({ clientSelfOrdering: client });
  }

  render() {
    const { match } = this.props;

    if (!this.state.clientSelfOrdering) {
      return <h1>Loading</h1>;
    }
    return (
      <div className="transactions">
        <SubNavigation />
        {/* <Greeting name={this.state.user} /> */}
        <Route
          exact
          path={match.url}
          render={(props) => <TransactionsList {...props} />}
        />
        <Route
          path={`${match.url}/transactionDetails`}
          render={(props) => <TransactionDetails {...props} />}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNavigation: (payload) => dispatch(addNavigation(payload)),
    replaceNavigation: (payload) => dispatch(replaceNavigation(payload)),
    navigateTo: (payload) => dispatch(navigateTo(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(transactions))
);
