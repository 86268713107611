import React, { Component } from "react";
import { withApollo, Subscription } from "react-apollo";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import qs from "querystring";
import { connect } from "react-redux";
import QueueCard from "./QueueCard";
import { Header } from "../../../common";
import CompletedQueue from "./CompletedQueue";

import { ApolloClient, InMemoryCache } from "apollo-boost";
import { WebSocketLink } from "apollo-link-ws";

const sub = gql`
  subscription onNewQueue($kitchenId: String!) {
    listenForNewQueue(kitchenId: $kitchenId) {
      id
      queueNumber
      createdOn
    }
  }
`;

const subscriptionClient = new ApolloClient({
  link: new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_WS_SELFORDERING,
    options: {
      timeout: -1,
      reconnect: true,
    },
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    watchMutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    mutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

class KitchenQueues extends Component {
  constructor(props) {
    super(props);

    let params = qs.parse(this.props.location.search.replace("?", ""));

    this.state = {
      kitchenId: params.id || undefined,
      kitchenName: undefined,
      kitchen: undefined,
      queues: undefined,
      newQueues: undefined,
      completeQueues: undefined,
      receievedQueues: undefined,
      popup: false,
      popupDetail: undefined,
      isFullScreen: false,
    };
  }

  componentDidMount() {
    if (!this.state.kitchenId) {
      alert("No kitchen id provided!");
      this.props.history.goBack();
    }

    console.log("kq", this.props);

    this.getKitchenInfo()
      .then(() => {
        this.getKitchenQueues(-1);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getKitchenInfo = () => {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          context: {
            route: "selfOrdering",
          },
          variables: { id: this.state.kitchenId },
          query: gql`
            query getKitchen($id: String!) {
              kitchen(ID: $id) {
                name
              }
            }
          `,
        })
        .then((result) => {
          if (result.data) {
            this.setState({ kitchenName: result.data.kitchen.name }, () => {
              resolve(true);
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getKitchenQueues = (status) => {
    // get queues of kitchen
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { kitchenId: this.state.kitchenId, status: status },
        query: gql`
          query getNewQueues($kitchenId: String!) {
            QueuesByKitchen(kitchenId: $kitchenId) {
              id
              queueNumber
              status
              transactionDetails {
                item {
                  id
                  brand {
                    name
                  }
                  sku
                  itemCode
                  name
                  displayName
                  group {
                    name
                  }
                  image
                }
                quantity
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          console.log("Result: ", result.data);
          let queues = result.data.QueuesByKitchen;

          // Separate queues to their rightful place
          let newQueues = queues.filter((q) => q.status === -1);
          let completeQueues = queues.filter((q) => q.status === 0);
          let receievedQueues = queues.filter((q) => q.status === 1);

          // Set state to render
          this.setState({ newQueues, completeQueues, receievedQueues });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  showPopup = (id, status) => {
    switch (+status) {
      case 0:
        var queues = this.state.completeQueues;
        break;
      case -1:
        queues = this.state.newQueues;
        break;
      case 1:
        queues = this.state.receievedQueues;
        break;
      default:
        alert("Go home you're drunk a.k.a impossible");
        break;
    }

    var detail = queues.find((q) => q.id === id);

    this.setState({ popup: true, popupDetail: detail }, () => {});
  };

  closePopup = () => {
    this.setState({ popup: false });
  };

  toggleFullScreen = () => {
    this.setState({ isFullScreen: !this.state.isFullScreen });
  };

  updateNewQueues(queues) {
    this.setState({ newQueues: queues });
  }

  updateQueueStatus = (kitchenQueueId, status) => {
    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables: { kitchenQueueId, status },
        mutation: gql`
          mutation updateQueueStatus(
            $kitchenQueueId: String!
            $status: Float!
          ) {
            updateKitchenQueueStatus(
              kitchenQueueId: $kitchenQueueId
              status: $status
            ) {
              id
              queueNumber
              status
              transactionDetails {
                item {
                  id
                  brand {
                    name
                  }
                  sku
                  itemCode
                  name
                  displayName
                  group {
                    name
                  }
                  image
                }
                quantity
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          if (result.errors) {
            alert(result.errors[0].message);
            return;
          }

          // Move to complete queue
          let newKitchenQueue = result.data.updateKitchenQueueStatus;

          let { newQueues, completeQueues, receievedQueues } = this.state;

          // Check old status of the queue to remove from
          if (newKitchenQueue.status === 0) {
            // Remove from newQueues
            newQueues = newQueues.filter((q) => q.id !== newKitchenQueue.id);

            // Add to completedQueues
            if (!completeQueues) {
              completeQueues = [];
            }

            completeQueues.push(newKitchenQueue);
          } else if ((newKitchenQueue.status = 1)) {
            // Remove from completeQueues
            completeQueues = completeQueues.filter(
              (q) => q.id !== newKitchenQueue.id
            );

            // Add to receivedQueues
            if (!receievedQueues) {
              receievedQueues = [];
            }

            receievedQueues.push(newKitchenQueue);
          }

          // Hide popup
          this.setState({
            newQueues,
            completeQueues,
            receievedQueues,
            popup: false,
            popupDetail: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Unable to update status!");
      });
  };

  render() {
    const {
      kitchenId,
      kitchenName,
      newQueues,
      completeQueues,
      isFullScreen,
    } = this.state;

    if (newQueues) {
      // newQueues.push(...newQueues);
    }

    let newQueuesRender = newQueues ? (
      newQueues.map((n) => (
        <QueueCard
          key={n.id}
          detail={n}
          updateQueueStatus={this.updateQueueStatus}
        />
      ))
    ) : (
      <h2>No queues.</h2>
    );

    let completeQueuesRender = completeQueues ? (
      completeQueues.map((n) => (
        <CompletedQueue
          key={n.id}
          detail={n}
          updateQueueStatus={this.updateQueueStatus}
        />
      ))
    ) : (
      <h2>No queues</h2>
    );

    return (
      <>
        {!isFullScreen && (
          <button
            className="return"
            onClick={() => this.props.history.goBack()}
          >
            Back
          </button>
        )}
        <div
          className={
            isFullScreen ? "grid-container fullScreen" : "grid-container"
          }
        >
          <div className="kitchen__info">
            {!isFullScreen ? (
              <Header title={kitchenName}>
                {
                  <button
                    className="slim"
                    onClick={() => this.toggleFullScreen()}
                  >
                    Full Screen
                  </button>
                }
              </Header>
            ) : (
              <>
                <span>{kitchenName}</span>
                <div className="close" onClick={this.toggleFullScreen}>
                  <img
                    height="16"
                    width="16"
                    alt="close-popup"
                    src={process.env.PUBLIC_URL + "/assets/icons/close.svg"}
                  />
                </div>
              </>
            )}
          </div>
          <h3>New Queues</h3>
          <h3>Complete Now</h3>
          {/* <div className="grid-channel new">{newQueuesRender}</div> */}
          <Subscription
            client={subscriptionClient}
            subscription={sub}
            variables={{ kitchenId: kitchenId }}
          >
            {(result) => {
              if (!result.loading) {
                console.log("Sub queue", result.data);
              }

              if (result.data && result.data.listenForNewQueue) {
                const newQueues = result.data.listenForNewQueue;

                let renderToAddOn = newQueues.map((n) => (
                  <QueueCard
                    key={n.id}
                    detail={n}
                    updateQueueStatus={this.updateQueueStatus}
                  />
                ));

                return <div className="grid-channel new">{renderToAddOn}</div>;
              }

              return <div className="grid-channel new">{newQueuesRender}</div>;
            }}
          </Subscription>
          <div className="grid-channel complete">{completeQueuesRender}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    apollo: state.apollo,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(KitchenQueues)));
