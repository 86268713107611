import React, { PureComponent, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";

import "./NavigationBar.scss";
import { connect } from "react-redux";
import { userSignedOut } from "../../actions";
import SubLink from "./SubLink";
import { withApollo } from "react-apollo";

const links = [
  {
    type: "link",
    title: "Dashboard",
    pathname: "/dashboard",
    className: "dashboard",
  },
  {
    type: "header",
    title: "Manage",
  },
  {
    type: "link",
    title: "Branches",
    pathname: "/branches",
    className: "branches",
    resourceId: 7,
  },
  {
    type: "link",
    title: "Machines",
    pathname: "/machines",
    className: "machines",
    resourceId: 8,
  },
  {
    type: "link",
    title: "Watermarks",
    pathname: "/watermarks",
    className: "watermarks",
    resourceId: 9,
  },
  {
    type: "link",
    title: "Floats",
    pathname: "/floats",
    className: "floats",
    resourceId: 10,
  },
  {
    type: "link",
    title: "User",
    pathname: "/users",
    className: "users",
    resourceId: 2,
  },
  {
    type: "link",
    title: "Access Control",
    pathname: "/accessControl",
    className: "users",
    resourceId: 3,
  },
  {
    type: "link",
    title: "Schedules",
    pathname: "/schedules",
    className: "schedules",
    resourceId: 11,
  },
  {
    type: "header",
    title: "Point of Sales",
    access: [111, 112, 131],
  },
  {
    type: "link",
    title: "Shops",
    pathname: "/shops",
    className: "dashboard",
    access: [111, 112, 131],
  },
  {
    type: "link",
    title: "Items",
    pathname: "/items",
    className: "dashboard",
    access: [111, 112, 131],
  },
  {
    type: "link",
    title: "Brands",
    pathname: "/brands",
    className: "dashboard",
    access: [111, 112, 131],
  },
  {
    type: "link",
    title: "Units",
    pathname: "/units",
    className: "dashboard",
    access: [111, 112, 131],
  },

  {
    type: "link",
    title: "Categories",
    pathname: "/categories",
    className: "dashboard",
    access: [111, 112, 131],
  },
  {
    type: "link",
    title: "Groups",
    pathname: "/groups",
    className: "dashboard",
    access: [111, 112, 131],
  },
  {
    type: "link",
    title: "Promotions",
    pathname: "/promotions",
    className: "dashboard",
    access: [111, 112, 131],
  },
  {
    type: "header",
    title: "Apps",
    access: [111, 112, 131],
  },
  {
    type: "link",
    title: "Kitchens",
    pathname: "/app/kitchens",
    className: "dashboard",
    access: [111, 112, 131],
  },
  {
    type: "link",
    title: "Customer displays",
    pathname: "/app/customerDisplays",
    className: "dashboard",
    access: [111, 112, 131],
  },
  {
    type: "header",
    title: "Reports",
  },
  {
    type: "link",
    title: "Healthcare",
    pathname: "/reports/healthcare",
    className: "healthcare__report",
    access: [141],
  },
  {
    type: "links",
    title: "Point of Sales",
    access: [111, 112, 131],
    links: [
      {
        title: "Transactions",
        pathname: "/reports/pos/transactions",
      },
    ],
  },
  {
    type: "link",
    title: "Shifts",
    pathname: "/reports/shifts",
    access: [111, 112, 131],
  },
  {
    type: "links",
    title: "Machines",
    links: [
      {
        title: "Transactions",
        pathname: "/reports/machines/deposits",
      },
      {
        title: "Replenisments",
        pathname: "/reports/machines/replenishments",
      },
      {
        title: "Exchanges",
        pathname: "/reports/machines/exchanges",
      },
      {
        title: "Skimmings",
        pathname: "/reports/machines/skimmings",
      },
    ],
  },
];

class NavigationBar extends PureComponent {
  constructor(props) {
    super(props);

    let enableReports = false;
    let enableSettings = false;

    var currentLocation = props.location.pathname;

    this.state = {
      loading: true,
      toggled: true,
      isManageRoute: currentLocation.includes("/manage"),
      reportsEnabled: enableReports,
      settingsEnabled: enableSettings,
      windowSize: props.windowSize,
      links: [],
    };
  }

  componentDidMount() {
    this.getPermissions();
  }

  toggle = () => {
    this.setState({ toggled: !this.state.toggled });
  };

  getPermissions = () => {
    let permissions = [];

    const isAdmin = this.props.userAuth.group.isAdmin;

    if (!isAdmin) {
      permissions = this.props.userAuth.permissions || [];
    }

    let leanedLinks = [];

    // Filter out links unaccessibile
    let modules = this.props.userAuth.modules;

    for (const link of links) {
      if (link.access) {
        if (link.access.some((a) => modules.includes(a))) {
          leanedLinks.push(link);
        }
      } else if (link.resourceId && !isAdmin) {
        if (permissions.some((p) => p.resourceId === link.resourceId)) {
          leanedLinks.push(link);
        }
      } else {
        leanedLinks.push(link);
      }
    }

    // Loop again to remove empty headers
    leanedLinks = leanedLinks.reduce((prev, curr) => {
      if (prev.length > 0) {
        if (prev[prev.length - 1].type === "header" && curr.type === "header") {
          prev.pop();
        }
      }
      prev.push(curr);

      return prev;
    }, []);

    this.setState({ links: leanedLinks, loading: false });
  };

  render() {
    let globbalAdminLinks = (
      <>
        <NavLink
          to="/overseer/dashboard"
          className="link"
          activeClassName="active"
        >
          <div className="link__content">
            <svg height="16" width="16" viewBox="0 0 32 32">
              <rect className="cls-1" x="2" y="2" width="11" height="28" />
              <rect className="cls-1" x="19" y="2" width="11" height="14" />
              <rect className="cls-1" x="19" y="22" width="11" height="8" />
            </svg>
            <span>Dashboard</span>
          </div>
        </NavLink>
        <NavLink
          to="/overseer/companies"
          className="link"
          activeClassName="active"
        >
          <div className="link__content">
            <svg height="16" width="16" viewBox="0 0 32 32">
              <rect className="cls-1" x="2" y="2" width="11" height="28" />
              <rect className="cls-1" x="19" y="2" width="11" height="14" />
              <rect className="cls-1" x="19" y="22" width="11" height="8" />
            </svg>
            <span>Companies</span>
          </div>
        </NavLink>
        <NavLink to="/overseer/users" className="link" activeClassName="active">
          <div className="link__content">
            <svg height="16" width="16" viewBox="0 0 32 32">
              <rect className="cls-1" x="2" y="2" width="11" height="28" />
              <rect className="cls-1" x="19" y="2" width="11" height="14" />
              <rect className="cls-1" x="19" y="22" width="11" height="8" />
            </svg>
            <span>Users</span>
          </div>
        </NavLink>
      </>
    );

    let { userAuth, loading } = this.props;

    if (loading) {
      return (
        <nav id="main__nav" className="active">
          <div className="nav__content">
            <img
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
              height="24"
              alt="overseerLogo"
            />
            <div className="links_container">
              <div className="links"></div>
            </div>
          </div>
        </nav>
      );
    }

    let normalLinks = (
      <>
        {this.state.links.map((link, index) => {
          // Check whether the link has an access prop
          if (link.access) {
            // Check if the company can access if or not; by checking the modules
            const modulesSet = new Set(userAuth.modules);

            if (!link.access.some((el) => modulesSet.has(el))) {
              return null;
            }
          }

          if (link.type === "link") {
            return (
              <NavLink
                key={index}
                to={link.pathname}
                className="link"
                activeClassName="active"
              >
                <div className="link__content">
                  <img
                    height="16"
                    width="16"
                    alt=""
                    className={
                      link.className
                        ? `link__icon ${link.className}`
                        : "link__icon"
                    }
                  />
                  <span>{link.title}</span>
                </div>
              </NavLink>
            );
          } else if (link.type === "header") {
            return (
              <Fragment key={index}>
                <hr />
                <label className="linkHeader">{link.title}</label>
              </Fragment>
            );
          } else if (link.type === "links") {
            return (
              <Fragment key={index}>
                <SubLink
                  link={link}
                  currentPath={this.props.location.pathname}
                />
              </Fragment>
            );
          }
          return <hr />;
        })}
      </>
    );

    const { isAdmin, adminLevel } = this.props.userAuth.group;
    const { nav, windowSize } = this.props;

    let navClassname = "active";

    if (windowSize === "small") {
      navClassname = nav ? "small active" : "small";
    } else if (windowSize === "medium") {
      navClassname = nav ? "active" : "";
    }

    return (
      <nav id="main__nav" className={navClassname}>
        <div className="nav__content">
          <img
            className="logo"
            src={process.env.PUBLIC_URL + "/assets/logo.png"}
            height="24"
            alt="overseerLogo"
          />
          <div className="links_container">
            <div className="links">
              {isAdmin && adminLevel === 0 ? globbalAdminLinks : normalLinks}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userSignedOut: () => dispatch(userSignedOut()),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
    nav: state.nav,
  };
};

export default withApollo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar))
);
