import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { addNavigation, replaceNavigation } from "../../../actions";

class Overview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      branches: []
    };
  }

  componentDidMount() {
    // Change the sub nav bar first
    this.props.replaceNavigation({
      url: "/dashboard",
      title: "dashboard"
    });
  }

  render() {
    const { branches } = this.props;

    // if (loading) {
    //   return <>Loading</>;
    // }

    return (
      <>
        <div className="dashboard__card branches">
          <div>
            <h1>Branches</h1>
            <table className="dashboard__table">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {branches.map(b => (
                  <tr key={b.id} onClick={() => this.props.onBranchSelected(b)}>
                    <td>{b.code}</td>
                    <td>{b.name}</td>
                    <td>
                      <svg
                        className="table__more right"
                        width="5"
                        height="10"
                        viewBox="0 0 8 16"
                        role="img"
                        aria-hidden="true"
                      >
                        <path
                          d="M5 8l-5 8h3l5-8-5-8H0z"
                          fill="#687992"
                          fillRule="evenodd"
                          role="presentation"
                        ></path>
                      </svg>
                      <svg
                        className="table__more left"
                        width="5"
                        height="10"
                        viewBox="0 0 8 16"
                        role="img"
                        aria-hidden="true"
                      >
                        <path
                          d="M5 8l-5 8h3l5-8-5-8H0z"
                          fill="#687992"
                          fillRule="evenodd"
                          role="presentation"
                        ></path>
                      </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addNavigation: payload => dispatch(addNavigation(payload)),
    replaceNavigation: payload => dispatch(replaceNavigation(payload))
  };
};

const mapStateToProps = state => {
  return {
    userAuth: state.userAuth
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(Overview))
);
