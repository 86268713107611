import React, { Component } from "react";
import { Form } from "../../common";

export default class Card extends Component {
  updateQueueStatus = (id, status) => {
    if (this.props.updateQueueStatus) this.props.updateQueueStatus(id, status);
  };
  render() {
    let { detail } = this.props;

    if (!detail) {
      return null;
    }

    return (
      <div key={detail.id} className="cDisplay__queueCard">
        <Form>
          <h1>{detail.queueNumber}</h1>
        </Form>
      </div>
    );
  }
}
