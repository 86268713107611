import React, { Component } from "react";
import { Form } from "../common";

const thaiDenoms = [
  { value: 1000, device: 1 },
  { value: 500, device: 1 },
  { value: 100, device: 1 },
  { value: 50, device: 1 },
  { value: 20, device: 1 },
  { value: 10, device: 2 },
  { value: 5, device: 2 },
  { value: 2, device: 2 },
  { value: 1, device: 2 },
  { value: 0.5, device: 2 },
  { value: 0.25, device: 2 },
];

const singaporeDenoms = [
  { value: 1000, device: 1 },
  { value: 100, device: 1 },
  { value: 50, device: 1 },
  { value: 10, device: 1 },
  { value: 5, device: 1 },
  { value: 2, device: 1 },
  { value: 1, device: 2 },
  { value: 0.5, device: 2 },
  { value: 0.2, device: 2 },
  { value: 0.1, device: 2 },
  { value: 0.05, device: 2 },
];

export default class DenominationSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currency: props.currency || "THB",
      editMode: false,
      watermarkId: "",
      watermarkName: "",
      pieces: [],
      rowDisabled: [],
      denominationsAvailable: [],
      denominationsDevice: [],
      branch: undefined,
    };
  }

  componentDidMount() {
    // * Get denominations
    this.setDefaultValue(this.state.currency);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currency !== this.props.currency) {
      this.onCurrencyChanged(this.props.currency);
    }
  }

  setDefaultValue = (currency) => {
    if (!this.props.defaultValue) {
      return;
    }
    // Render denomination selection
    let pieces = [];
    let thresholds = [];
    let rowDisabled = [];
    let denominationsAvailable = [];
    let denominationsDevice = [];

    for (const denom of this.props.defaultValue) {
      pieces.push(denom.pieces);
      thresholds.push(0);
      rowDisabled.push(!denom.include);
      denominationsAvailable.push(denom.value);
      denominationsDevice.push(denom.device);
    }

    this.setState(
      {
        currency,
        pieces,
        thresholds,
        rowDisabled,
        denominationsAvailable,
        denominationsDevice,
      },
      // Notifying parent
      () => this.notifyParent
    );
  };

  notifyParent = () => {
    this.props.onChange(this.state);
  };

  handlePiecesChange = (event) => {
    let pieces = this.state.pieces.slice();

    if (!isNaN(event.target.value)) {
      pieces[event.target.name] = Number(event.target.value);
    } else if (event.target.value === "") {
      pieces[event.target.name] = 0;
    }

    this.setState({ pieces }, () => {
      this.notifyParent();
    });
  };

  onChangeExcludeValue = (event) => {
    const rowNo = Number(event.target.name);

    let tempRowDisabled = this.state.rowDisabled;
    let tempPieces = this.state.pieces;
    let tempThresholds = this.state.thresholds;

    tempRowDisabled[rowNo] = !event.target.checked;
    tempPieces[rowNo] = 0;
    tempThresholds[rowNo] = 0;

    this.setState(
      {
        rowDisabled: tempRowDisabled,
        pieces: tempPieces,
        thresholds: tempThresholds,
      },
      () => {
        this.notifyParent();
      }
    );
  };

  onCurrencyChanged = (currency) => {
    // Render denomination selection
    let pieces = [];
    let thresholds = [];
    let rowDisabled = [];
    let denominationsAvailable = [];
    let denominationsDevice = [];

    let denomsToUse = [];

    if (currency === "THB") {
      denomsToUse = thaiDenoms;
    } else {
      denomsToUse = singaporeDenoms;
    }

    for (const denom of denomsToUse) {
      pieces.push(0);
      thresholds.push(0);
      rowDisabled.push(true);
      denominationsAvailable.push(denom.value);
      denominationsDevice.push(denom.device);
    }

    this.setState(
      {
        currency,
        pieces,
        thresholds,
        rowDisabled,
        denominationsAvailable,
        denominationsDevice,
      },
      () => this.notifyParent
    );
  };

  render() {
    const { currency, pieces, denominationsAvailable } = this.state;

    var grandTotal = pieces.reduce((previous, current, index) => {
      return previous + current * denominationsAvailable[index];
    }, 0);

    return (
      <Form>
        <div>
          <h2>Denomination</h2>
          <span className="subHeader">{currency}</span>
          <span className="total">
            Grand Total: <b>{grandTotal}</b>
          </span>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Value</th>
                <th>Include</th>
                <th>Pieces</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.state.denominationsAvailable.map((denom, index) => (
                <tr key={`denom${index}`}>
                  <td>{`${denom}`}</td>
                  <td>
                    <input
                      type="checkbox"
                      name={index}
                      checked={!this.state.rowDisabled[index]}
                      onChange={this.onChangeExcludeValue}
                    />
                  </td>
                  <td>
                    <input
                      name={index}
                      placeholder="0"
                      maxLength="4"
                      onChange={this.handlePiecesChange}
                      type="text"
                      value={
                        this.state.rowDisabled[index] ? "-" : pieces[index]
                      }
                      disabled={this.state.rowDisabled[index] ? "disabled" : ""}
                    />
                  </td>
                  <td>{denom * pieces[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Form>
    );
  }
}
