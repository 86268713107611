import React from "react";
import APITable from "../../APITable";

const cancelChange = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [];

  let resp = [];

  return (
    <APITable
      title="Cancel Change"
      desc="Attempts to cancels the current change operation and dispenses out the cash that was deposited beforehand"
      command="CancelChange"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default cancelChange;
