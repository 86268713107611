import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

// Import components
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

// Import Styles
import "./Watermark.scss";

class Watermarks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { match } = this.props;
    return (
      <main className="watermarks">
        <Route
          path={`${match.url}/create`}
          render={(props) => <Create {...props} returnPath={match.url} />}
        />
        <Route
          path={`${match.url}/edit`}
          render={(props) => <Edit {...props} returnPath={match.url} />}
        />
        <Route exact path={match.url} component={List} />
      </main>
    );
  }
}

export default withRouter(Watermarks);
