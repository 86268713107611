import React, { Component } from "react";
import { Input, Select } from "../../../../common";

export default class FixedItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: props.value.price || undefined,
      selectedItem: props.value.itemId || undefined,
    };
  }

  handleSelect = (e) => {
    this.setState({ selectedItem: e.value }, () => {
      this.onChange();
    });
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, () => {
      this.onChange();
    });
  };

  remove = () => {
    if (this.props.onRemoved) this.props.onRemoved();
  };

  onChange = () => {
    if (!this.props.onChange) {
      return;
    }

    this.props.onChange({
      id: this.state.selectedItem,
      price: this.state.price,
    });
  };

  render() {
    let { items } = this.props;
    let { selectedItem, price } = this.state;

    return (
      <tr>
        <td>
          <Select
            value={selectedItem}
            name="itemSelected"
            placeholder="Select an item."
            options={items || []}
            onChange={this.handleSelect}
          />
        </td>
        <td>
          <Input
            name="price"
            placeholder="Enter fixed price"
            onChange={this.handleInputs}
            value={price}
          />
        </td>
        {this.props.canRemove && (
          <td>
            <span className="delete" onClick={this.remove}>
              Remove
            </span>
          </td>
        )}
      </tr>
    );
  }
}
