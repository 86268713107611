import React, { Component, Fragment } from "react";

// Import InventoryBar component
import InventoryBar from "./InventoryBar";
import CollectionBar from "./CollectionBar";

// Style
import "./style.scss";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";

class InventoryPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventory: undefined,
      watermark: undefined,
      loading: true,
    };
  }

  componentDidMount() {
    this.props.client
      .query({
        variables: {
          machineId: this.props.id,
        },
        query: gql`
          query getInv($machineId: String!) {
            machineWatermark(machineId: $machineId) {
              id
              denominations {
                value
                pieces
                include
                device
              }
            }
            machineInventory(machineId: $machineId) {
              types {
                name
                type
                denominations {
                  currency
                  value
                  pieces
                  max
                }
              }
            }
          }
        `,
      })
      .then((response) => {
        let inventories = response.data.machineInventory;
        let watermark = response.data.machineWatermark;

        // group types into recyclers collections and rejects
        let recycling = [];
        let collection = [];
        for (const inv of inventories) {
          for (const type of inv.types) {
            let grouped = this.groupDenominations(type.denominations);
            let toAdd = grouped.map((g) => {
              g.name = type.name;
              return g;
            });
            if (type.type === 1) {
              // Recycler
              recycling.push(...toAdd);
            } else if (type.type === 2) {
              // Collection
              collection.push(...toAdd);
            } else {
              // Reject
              // recyling.push(...grouped);
            }
          }
        }

        this.setState({
          inventory: {
            recycling,
            collection,
          },
          watermark,
          loading: false,
        });
      })
      .catch((error) => {
        console.log("Error", error.message);
      });
  }

  groupTypes(prev, curr) {
    if (prev.length > 0) {
    }

    let index = prev.findIndex((v) => v.type === curr.name);

    if (index < 0) {
      // New
      prev.push({
        type: curr.name,
        denominations: [curr],
      });
    } else {
      // Add to existing
      prev[index].denominations.push(curr);
    }

    return prev;
  }

  groupDenominations(denominations) {
    // const clonedDenominations = cloneDeep(denominations);

    let grouped = [];

    for (const denomination of denominations) {
      let i;

      var filter = grouped.filter((item, index) => {
        i = index;
        return item.value === denomination.value;
      });

      if (!filter.length) {
        grouped.push(denomination);
      } else {
        grouped[i].pieces += denomination.pieces;
      }
    }

    return grouped;
  }

  render() {
    const { inventory, watermark } = this.state;

    if (!inventory) {
      return <div className="machine__preview"></div>;
    }

    const { recycling, collection } = inventory;

    let recyclingGrouped = recycling.reduce(this.groupTypes, []);

    let collectionGrouped = collection.reduce(this.groupTypes, []);

    // Add watermark level to recycling denominations
    for (const device of recyclingGrouped) {
      for (let denomination of device.denominations) {
        let dwatermarks = watermark.denominations.filter(
          (wd) => wd.value === denomination.value
        );

        if (dwatermarks.length > 0) {
          let wd = dwatermarks[0];
          denomination.watermark = wd.pieces;
        } else {
          denomination.watermark = 0;
        }
      }
    }

    console.log("Recycling", recyclingGrouped);

    return (
      <div id="machine__inventory">
        <div className="container">
          <h2>Recyclers</h2>
          {recyclingGrouped.map((r, i) => (
            <Fragment key={`${r.name}${i}`}>
              <div className="container__header">
                <h3>{r.type}</h3>
                <label>
                  {`: ${r.denominations
                    .filter((d) => d.pieces !== 0)
                    .reduce((prev, curr) => {
                      return prev + curr.value * curr.pieces;
                    }, 0)}`}
                </label>
              </div>
              <div className="inventory__container">
                {r.denominations.map((denom, j) => (
                  <InventoryBar key={j} denominationInfo={denom} />
                ))}
              </div>
            </Fragment>
          ))}
        </div>
        <div className="container">
          <h2>Collections</h2>
          {collectionGrouped.map((r, i) => (
            <Fragment key={`${r.name}${i}`}>
              <div className="container__header">
                <h3>{r.type}</h3>
                <label>
                  {`: ${r.denominations
                    .filter((d) => d.pieces !== 0)
                    .reduce((prev, curr) => {
                      return prev + curr.value * curr.pieces;
                    }, 0)}`}
                </label>
              </div>
              <CollectionBar key={i} denominations={r.denominations} />
            </Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default withApollo(InventoryPreview);
