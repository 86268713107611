import React, { Component } from "react";
import DateSelection from "../../../../reports/DateSelection";
import moment from "moment";

export default class Daily extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      toggleStart: false,
    };
  }

  toggleStartDateSelection = () => {
    this.setState({ toggleStart: true });
  };

  closeDateSelection = () => {
    this.setState({ toggleStart: false, toggleEnd: false });
  };

  onStartDateSelected = (date) => {
    this.setState(
      {
        toggleStart: false,
        startDate: date,
        endDate: new Date(date.getTime()),
      },
      () => {
        this.props.onDateSelected(this.state.startDate, this.state.endDate);
      }
    );
  };

  render() {
    let { startDate, toggleStart } = this.state;
    return (
      <div className="c-dFilterDaily">
        <label>Date</label>
        <input
          readOnly={true}
          type="text"
          value={moment(startDate).format("D MMM YYYY")}
          onClick={this.toggleStartDateSelection}
        />
        {toggleStart && (
          <DateSelection
            disableRealTime={true}
            selection={startDate}
            close={this.closeDateSelection}
            onSelect={this.onStartDateSelected}
          />
        )}
      </div>
    );
  }
}
