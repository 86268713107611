import React, { Component } from "react";
import DateSelection from "../../../../reports/DateSelection";
import moment from "moment";

export default class RangeFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      toggleStart: false,
      toggleEnd: false,
    };
  }

  toggleStartDateSelection = () => {
    this.setState({ toggleStart: true });
  };

  toggleEndDateSelection = () => {
    this.setState({ toggleEnd: true });
  };

  closeDateSelection = () => {
    this.setState({ toggleStart: false, toggleEnd: false });
  };

  onStartDateSelected = (date) => {
    if (date > this.state.endDate) {
      alert("Invalid Start date");
      return;
    }

    this.setState({ toggleStart: false, startDate: date }, () => {
      this.props.onDateSelected(this.state.startDate, this.state.endDate);
    });
  };

  onEndDateSelected = (date) => {
    if (date < this.state.startDate) {
      alert("Invalid Start date");
      return;
    }

    this.setState({ toggleEnd: false, endDate: date }, () => {
      this.props.onDateSelected(this.state.startDate, this.state.endDate);
    });
  };

  render() {
    let { startDate, endDate, toggleStart, toggleEnd } = this.state;
    return (
      <div className="c-dFilterRange">
        <label>From</label>
        <div />
        <label>To</label>
        <input
          readOnly={true}
          type="text"
          value={moment(startDate).format("D MMM YYYY")}
          onClick={this.toggleStartDateSelection}
        />
        <div />
        <input
          readOnly={true}
          type="text"
          value={moment(endDate).format("D MMM YYYY")}
          onClick={this.toggleEndDateSelection}
        />
        {toggleStart && (
          <DateSelection
            disableRealTime={true}
            selection={startDate}
            close={this.closeDateSelection}
            onSelect={this.onStartDateSelected}
          />
        )}
        {toggleEnd && (
          <DateSelection
            disableRealTime={true}
            selection={endDate}
            close={this.closeDateSelection}
            onSelect={this.onEndDateSelected}
          />
        )}
      </div>
    );
  }
}
