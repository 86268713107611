import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class cancelSales extends Component {
  render() {
    return (
      <>
        <h1>Cancel Sales</h1>
        <p className="description">
          Cancel the current sales transaction operating
        </p>
        <p className="command">
          Command<span>CancelSales</span>
        </p>
        <h2>Request body</h2>
        <table>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Id</td>
              <td>string</td>
              <td>
                Assigns an identifier to distinguish this request from others,
                this is usefull for when multiple event handlers are assigned to
                this completed callback, this field can be used to distinguish
                the request from other same request
              </td>
            </tr>
            <tr>
              <td>Sequence Number</td>
              <td>string</td>
              <td>Assign a another unique identifier for this request</td>
            </tr>
          </tbody>
        </table>
        <h2>Response body</h2>
        <table>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Result</td>
              <td>int</td>
              <td>The request's result code</td>
            </tr>
            <tr>
              <td>MachineId</td>
              <td>string</td>
              <td>Machine that was operated on</td>
            </tr>
            <tr>
              <td>Id</td>
              <td>string</td>
              <td>The unique indentifier of the request</td>
            </tr>
            <tr>
              <td>Sequence Number</td>
              <td>string</td>
              <td>The secondary unique indentifier the request</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default withRouter(cancelSales);
