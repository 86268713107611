import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import UnitsList from "./UnitsList";
import { gql } from "apollo-boost";
import { SubNavigation } from "../../subNavigation";
import { addNavigation, replaceNavigation, navigateTo } from "../../../actions";

import "./style.scss";
import Create from "./Create";
import Edit from "./Edit";

class units extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      loading: true,
      branches: [],
      branchSelected: undefined,
    };
  }

  componentDidMount() {
    // Change the sub nav bar first
    this.props.replaceNavigation({
      url: "units",
      title: "PointOfSales",
    });

    this.props.addNavigation({
      url: "units",
      title: "unit",
    });

    // Get name
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
            user(id: $userId) {
              name
            }
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        let branches = result.data.branches;

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState(
          {
            branches: branches,
            branchSelected,
            loading: false,
            user: result.data.user.name,
          },
          () => {
            if (branchSelected) {
              // this.getBranchesTransactions(branchSelected);
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  render() {
    const { match } = this.props;

    return (
      <div className="units">
        <SubNavigation />
        <Route
          exact
          path={match.url}
          render={(props) => <UnitsList {...props} />}
        />
        <Route
          exact
          path={`${match.url}/create`}
          render={(props) => <Create {...props} />}
        />
        <Route
          exact
          path={`${match.url}/edit/:id`}
          render={(props) => <Edit {...props} />}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNavigation: (payload) => dispatch(addNavigation(payload)),
    replaceNavigation: (payload) => dispatch(replaceNavigation(payload)),
    navigateTo: (payload) => dispatch(navigateTo(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(units))
);
