import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

class PromotionConditionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promotionConditions: undefined,
      loading: true,
      branches: [],
      branchSelected: undefined,
    };
  }

  componentDidMount() {
    this.props.client
      .query({
        variables: {
          userId: this.props.userAuth.userId,
        },
        query: gql`
          query branches($userId: String!) {
            branches(userId: $userId) {
              id
              code
              name
            }
          }
        `,
      })
      .then((result) => {
        //console.log(result.data);

        let branches = result.data.branches;

        let branchSelected = branches.length === 1 ? branches[0].id : undefined;

        this.setState(
          {
            branches: branches,
            branchSelected,
            loading: false,
          },
          () => {
            if (branchSelected) {
              this.getPromotionConditions(branchSelected);
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ error: "Unable to connect to server" });
      });
  }

  getPromotionConditions = (branchId) => {
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { branchId },
        query: gql`
          query getPromotionConditions($branchId: String!) {
            promotionConditions(branchId: $branchId) {
              id
              condition
              validUntil
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          this.setState({
            promotionConditions: result.data.promotionConditions,
          });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  handleBranch = (e) => {
    let branchId = e.target.value;

    this.setState({ [e.target.name]: branchId }, () => {
      this.getPromotionConditions(branchId);
    });
  };

  createPromotionCondition = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  render() {
    const { promotionConditions, branches, branchSelected } = this.state;

    let promotionConditionRender = promotionConditions ? (
      promotionConditions.map((pc, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{pc.id}</td>
          <td>{pc.condition}</td>
          <td>{pc.validUntil}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="100%">No promotion conditions have been added</td>
      </tr>
    );
    return (
      <div>
        <label>Branch</label>
        <select
          name="branchSelected"
          value={branchSelected}
          onChange={this.handleBranch}
        >
          {!branchSelected && <option>Select branch</option>}
          {branches &&
            branches.map((b) => (
              <option key={b.id} value={b.id}>
                {b.name}
              </option>
            ))}
        </select>
        <br />
        <button onClick={this.createItem}>Create</button>
        <div>
          <table className="promotionCondition__table">
            <thead>
              <tr>
                <th>#</th>
                <th>Id</th>
                <th>Condition</th>
                <th>ValidUntil</th>
              </tr>
            </thead>
            <tbody>{promotionConditionRender}</tbody>
          </table>
        </div>
        {/* <ul>
          {promotionConditions &&
            promotionConditions.map((value, index) => (
              <li
                key={value.id}
              >{`${value.id} ${value.condition} ${value.validUntil}`}</li>
            ))}
        </ul> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps)(PromotionConditionsList))
);
