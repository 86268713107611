import React, { Component } from "react";
import "./style.scss";
import { removeAlert } from "../../../actions";
import { connect } from "react-redux";

class Toast extends Component {
  remove = () => {
    this.props.removeAlert(this.props.id);
  };

  render() {
    let { title, msg, type } = this.props;

    msg = msg.replace("Network error: ", "");

    return (
      <div className="toast">
        <div className="toast__content">
          {title && <h5>{title}</h5>}
          <span>{msg}</span>
        </div>
        <div className={`toast__tag ${type || "info"}`} />
        <img
          className="toast__remove"
          height="12"
          width="12"
          alt="close-popup"
          src={process.env.PUBLIC_URL + "/assets/icons/remove.svg"}
          onClick={this.remove}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeAlert: (payload) => dispatch(removeAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
