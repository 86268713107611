import React, { Component } from "react";
import { Route, withRouter, NavLink, Link } from "react-router-dom";
import GetServiceStatus from "./doc/getServiceStatus";
import getMachineStatus from "./doc/getMachineStatus";
import getDispensableInventory from "./doc/getDispensableInventory";
import getDetailedInventory from "./doc/getDetailedInventory";
import getWholeInventory from "./doc/getWholeInventory";
import startCashIn from "./doc/startCashIn";
import cancelCashIn from "./doc/cancelCashIn";
import endCashIn from "./doc/endCashIn";
import startCashOut from "./doc/startCashOut";
import refreshTotalSales from "./doc/refreshTotalSales";
import startChange from "./doc/startChange";
import cancelChange from "./doc/cancelChange";
import reset from "./doc/reset";
import startReplenishmentFromEntrance from "./doc/startReplenishmentFromEntrance";
import endReplenishmentFromEntrance from "./doc/endReplenishmentFromEntrance";
import cancelReplenishmentFromEntrance from "./doc/cancelReplenishmentFromEntrance";
import collect from "./doc/collect";
import partialCollect from "./doc/partialCollect";
import verificationCollect from "./doc/verificationCollect";
import getMachines from "./doc/getMachines";
import disableDenominations from "./doc/disableDenominations";
import enableDenominations from "./doc/enableDenominations";
import returnCash from "./doc/returnCash";
import unlock from "./doc/unlock";
import lock from "./doc/lock";

// Import style
class BridgeService extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      match: { url },
      location: { pathname },
    } = this.props;

    return (
      <div className="documentation">
        <nav>
          <Link to="/developers/api">
            <button
              className="return"
              onClick={() => this.props.history.goBack()}
            >
              Return
            </button>
          </Link>
          <h1>Bridge Service</h1>
          <h2>
            <NavLink exact to={`${url}`} activeClassName="selected">
              Overview
            </NavLink>
          </h2>
          <h2>
            <NavLink
              to={`${url}/requests/startSales`}
              className={pathname.includes("requests") ? "selected" : ""}
            >
              Requests
            </NavLink>
          </h2>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/getServiceStatus`}
          >
            Get Service Status
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/getMachineStatus`}
          >
            Get Machine Status
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/getDispensableInventory`}
          >
            Get Dispensabe Inventory
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/getWholeInventory`}
          >
            Get Whole Inventory
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/getDetailedInventory`}
          >
            Get Detailed Inventory
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/startCashIn`}
          >
            Start Cash In
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/endCashIn`}>
            End Cash In
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/cancelCashIn`}
          >
            Cancel Cash In
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/startCashOut`}
          >
            Start Cash Out
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/refreshTotalSales`}
          >
            Refresh Total Sales
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/startChange`}
          >
            Start Change
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/cancelChange`}
          >
            Cancel Change
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/reset`}>
            Reset
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/startReplenishmentFromEntrance`}
          >
            Start Replenishment (Entrance)
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/endReplenishmentFromEntrance`}
          >
            End Replenishment (Entrance)
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/cancelReplenishmentFromEntrance`}
          >
            Cancel Replenishment (Entrance)
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/collect`}>
            Collection
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/partialCollect`}
          >
            Partial Collection
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/verificationCollect`}
          >
            Verification Collection
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/unlock`}>
            Unlock Collection Unit
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/lock`}>
            Lock Collection Unit
          </NavLink>
          <NavLink activeClassName="selected" to={`${url}/requests/returnCash`}>
            Return Cash
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/getMachines`}
          >
            Get Machines
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/disableDenominations`}
          >
            Disable Denominations
          </NavLink>
          <NavLink
            activeClassName="selected"
            to={`${url}/requests/enableDenominations`}
          >
            Enable Denominations
          </NavLink>
          <h2>
            <NavLink
              to={`${url}/notifications/heartbeat`}
              className={pathname.includes("notifications") ? "selected" : ""}
            >
              Notifications
            </NavLink>
          </h2>
        </nav>
        <div className="content">
          <Route
            exact
            path={`${url}/requests/getServiceStatus`}
            component={GetServiceStatus}
          />
          <Route
            exact
            path={`${url}/requests/getMachineStatus`}
            component={getMachineStatus}
          />
          <Route
            exact
            path={`${url}/requests/getDispensableInventory`}
            component={getDispensableInventory}
          />
          <Route
            exact
            path={`${url}/requests/getWholeInventory`}
            component={getWholeInventory}
          />
          <Route
            exact
            path={`${url}/requests/getDetailedInventory`}
            component={getDetailedInventory}
          />
          <Route
            exact
            path={`${url}/requests/startCashIn`}
            component={startCashIn}
          />
          <Route
            exact
            path={`${url}/requests/endCashIn`}
            component={endCashIn}
          />
          <Route
            exact
            path={`${url}/requests/cancelCashIn`}
            component={cancelCashIn}
          />
          <Route
            exact
            path={`${url}/requests/startCashOut`}
            component={startCashOut}
          />
          <Route
            exact
            path={`${url}/requests/refreshTotalSales`}
            component={refreshTotalSales}
          />
          <Route
            exact
            path={`${url}/requests/startChange`}
            component={startChange}
          />
          <Route
            exact
            path={`${url}/requests/cancelChange`}
            component={cancelChange}
          />
          <Route exact path={`${url}/requests/reset`} component={reset} />
          <Route
            exact
            path={`${url}/requests/startReplenishmentFromEntrance`}
            component={startReplenishmentFromEntrance}
          />
          <Route
            exact
            path={`${url}/requests/endReplenishmentFromEntrance`}
            component={endReplenishmentFromEntrance}
          />
          <Route
            exact
            path={`${url}/requests/cancelReplenishmentFromEntrance`}
            component={cancelReplenishmentFromEntrance}
          />
          <Route exact path={`${url}/requests/collect`} component={collect} />
          <Route
            exact
            path={`${url}/requests/partialCollect`}
            component={partialCollect}
          />
          <Route
            exact
            path={`${url}/requests/verificationCollect`}
            component={verificationCollect}
          />
          <Route exact path={`${url}/requests/unlock`} component={unlock} />
          <Route exact path={`${url}/requests/lock`} component={lock} />
          <Route
            exact
            path={`${url}/requests/returnCash`}
            component={returnCash}
          />
          <Route
            exact
            path={`${url}/requests/getMachines`}
            component={getMachines}
          />
          <Route
            exact
            path={`${url}/requests/disableDenominations`}
            component={disableDenominations}
          />
          <Route
            exact
            path={`${url}/requests/enableDenominations`}
            component={enableDenominations}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(BridgeService);
