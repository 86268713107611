import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { gql } from "apollo-boost";
import { TabControl, Tab } from "../../../../common";

class ItemLeaderboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      type: "items",
    };
  }

  currencyFormat(amount) {
    var format = new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: "THB",
      minimumFractionDigits: 2,
    });

    return format.format(amount);
  }

  componentDidMount() {
    this.getItemLeaderboard();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({ loading: true }, () => {
        if (this.state.type === "items") {
          this.getItemLeaderboard();
        } else {
          this.getGroupLeaderboard();
        }
      });
    }
  }

  getItemLeaderboard = () => {
    if (!this.props.branchId) {
      return;
    }

    console.log("Getting new data");

    let {
      filter: { start, end },
    } = this.props;

    this.props.client
      .query({
        variables: {
          branchId: this.props.branchId,
          startDate: start,
          endDate: end,
        },
        context: {
          route: "selfOrdering",
        },
        query: gql`
          query getSummary(
            $branchId: String!
            $startDate: DateTime!
            $endDate: DateTime!
          ) {
            branchPointOfSalesSummary(
              branchId: $branchId
              startDate: $startDate
              endDate: $endDate
            ) {
              item {
                id
                name
                displayName
              }
              total
              quantity
            }
          }
        `,
      })
      .then((response) => {
        if (!response.data) {
          return;
        }

        let data = response.data.branchPointOfSalesSummary;

        data = data.sort((a, b) => (+a.quantity < +b.quantity ? 1 : -1));

        this.setState({ data, loading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ data: [], loading: false });
      });
  };

  getGroupLeaderboard = () => {
    if (!this.props.branchId) {
      return;
    }

    console.log("Getting new data");

    let {
      filter: { start, end },
    } = this.props;

    this.props.client
      .query({
        variables: {
          branchId: this.props.branchId,
          startDate: start,
          endDate: end,
        },
        context: {
          route: "selfOrdering",
        },
        query: gql`
          query getSummary(
            $branchId: String!
            $startDate: DateTime!
            $endDate: DateTime!
          ) {
            groupLeaderboard(
              branchId: $branchId
              startDate: $startDate
              endDate: $endDate
            ) {
              group {
                id
                name
              }
              total
              quantity
            }
          }
        `,
      })
      .then((response) => {
        if (!response.data) {
          return;
        }

        let data = response.data.groupLeaderboard;

        data = data.sort((a, b) => (+a.quantity < +b.quantity ? 1 : -1));

        this.setState({ data, loading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ data: [], loading: false });
      });
  };

  onSwitch = (tabIndex) => {
    this.setState(
      { loading: true, type: tabIndex === 0 ? "items" : "groups" },
      () => {
        if (tabIndex === 0) {
          this.getItemLeaderboard();
        } else if (tabIndex === 1) {
          this.getGroupLeaderboard();
        }
      }
    );
  };

  render() {
    let { loading, data, type } = this.state;

    let content = (
      <tr>
        <td colSpan="4">Loading</td>
      </tr>
    );

    if (!loading) {
      if (data && data.length > 0) {
        content = data.map((d, i) => (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{type === "items" ? d.item.displayName : d.group.name}</td>
            <td>{d.quantity}</td>
            <td>{this.currencyFormat(d.total)}</td>
          </tr>
        ));
      } else {
        content = (
          <tr>
            <td colSpan="4">No data found</td>
          </tr>
        );
      }
    }

    return (
      <div className="c-posLeaderboard">
        <div className="c-posLeaderboard__groupType">
          <h3>Item sales leaderboard</h3>
          <div className="c-posLeaderboard__groupTypeSelection">
            <TabControl onSwitch={this.onSwitch}>
              <Tab title="Items"></Tab>
              <Tab title="Groups"></Tab>
            </TabControl>
          </div>
        </div>
        <table className="itemRank__table">
          <thead>
            {type === "items" ? (
              <>
                <tr>
                  <th>Rank</th>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Sales total</th>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <th>Rank</th>
                  <th>Group</th>
                  <th>Quantity</th>
                  <th>Sales total</th>
                </tr>
              </>
            )}
          </thead>
          <tbody>{content}</tbody>
        </table>
      </div>
    );
  }
}

export default withApollo(ItemLeaderboard);
