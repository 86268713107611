import { gql } from "apollo-boost";
import moment from "moment";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import ItemLeaderboard from "./ItemLeaderboard";
import SalesSummary from "./SalesSummary";

class PointOfSales extends Component {
  componentDidMount() {
    console.log("Props", this.props);
  }

  downloadFile(blobContent, name) {
    let FileSaver = require("file-saver");
    let blob = new Blob(
      [
        this.base64toBlob(
          blobContent,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ),
      ],
      {}
    );
    FileSaver.saveAs(blob, `${name}.xlsx`);
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  saveAsXlsxFile = () => {
    let {
      branchId,
      filter: { start, end },
    } = this.props;

    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: {
          branchId,
          startDate: start,
          endDate: end,
        },
        query: gql`
          query getBranchSummary(
            $branchId: String!
            $startDate: DateTime
            $endDate: DateTime
          ) {
            exportBranchPointOfSalesSummary(
              branchId: $branchId
              startDate: $startDate
              endDate: $endDate
            )
          }
        `,
      })
      .then((result) => {
        console.log(result.data);

        // File name
        let currentDate = moment().format("DMYYHMM");
        let startFormatted = moment(start).format("D-M-YY");
        let endFormatted = moment(end).format("D-M-YY");
        let fileName = `PointOfSalesSummary(${startFormatted}_${endFormatted})_(${currentDate})`;

        this.downloadFile(
          result.data.exportBranchPointOfSalesSummary,
          fileName
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let { branchId, filter } = this.props;

    if (!branchId || !filter) {
      return <div></div>;
    }

    return (
      <div className="dashboard__card dashboardPOSSummary">
        <div className="dashboard__heading span">
          <img
            height="32"
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/dashboard/salesSummary.png"
            }
            alt=""
          />
          <h3>Point of Sales</h3>
          <div className="actions">
            <button className="slim" onClick={this.saveAsXlsxFile}>
              Export excel
            </button>
          </div>
        </div>
        <SalesSummary branchId={branchId} filter={filter} />
        <ItemLeaderboard branchId={branchId} filter={filter} />
      </div>
    );
  }
}

export default withApollo(PointOfSales);
