import { gql } from "apollo-boost";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { addAlert } from "../../actions";
import { Form, Header, Select, Switch } from "../common";

class DispenseFloat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dispenseFloats: [],
      dispenseFloatSelected: undefined,
      error: "",
      enabled: false,
    };
  }

  componentDidMount() {
    if (this.props.userAuth.authorityLevel <= 1) {
      // Company
      this.getByCompany(this.props.userAuth.companyId);
    } else {
      // Branch
      this.getByBranch(this.props.userAuth.branchId);
    }
  }

  getByCompany = (companyId) => {
    this.props.client
      .query({
        variables: { userId: this.props.userId, companyId },
        query: gql`
          query getDF($companyId: String!, $userId: String!) {
            user(id: $userId) {
              dispenseFloatId
            }
            companyDispenseFloats(companyId: $companyId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          this.onCompletedGettingFloats(undefined, []);
        }

        // Get current selection
        let dfId = result.data.user.dispenseFloatId;

        let dispenseFloats = result.data.companyDispenseFloats.map((g) => {
          return {
            name: g.name,
            value: g.id,
          };
        });

        this.onCompletedGettingFloats(dfId, dispenseFloats);
      })
      .catch((error) => {
        console.log(error);
        this.onCompletedGettingFloats(undefined, []);
      });
  };

  getByBranch = (branchId) => {
    this.props.client
      .query({
        variables: { userId: this.props.userId, branchId },
        query: gql`
          query getDF($branchId: String, $userId: String!) {
            user(id: $userId) {
              dispenseFloatId
            }
            dispenseFloats(branchId: $branchId) {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          this.onCompletedGettingFloats(undefined, []);
        }

        // Get current selection
        let dfId = result.data.user.dispenseFloatId;

        let dispenseFloats = result.data.dispenseFloats.map((g) => {
          return {
            name: g.name,
            value: g.id,
          };
        });

        this.onCompletedGettingFloats(dfId, dispenseFloats);
      })
      .catch((error) => {
        console.log(error);
        this.onCompletedGettingFloats(undefined, []);
      });
  };

  update = (to = true) => {
    if (to && !this.state.dispenseFloatSelected) {
      return;
    }

    this.props.addAlert({
      type: "info",
      title: "Applying new changes",
      msg: "Hang tight, while we're applying changes",
    });

    this.props.client
      .mutate({
        variables: {
          userId: this.props.userId,
          dispenseFloatId: to ? this.state.dispenseFloatSelected : undefined,
        },
        mutation: gql`
          mutation update($userId: String!, $dispenseFloatId: String) {
            updateUserDispenseFloat(
              userId: $userId
              dispenseFloatId: $dispenseFloatId
            ) {
              id
            }
          }
        `,
      })
      .then((result) => {
        if (!result.data) {
          this.props.addAlert({
            type: "error",
            title: "Failed to update float",
            msg: "Please try again later",
          });
          return;
        }

        this.props.addAlert({
          type: "success",
          title: "Success",
          msg: "User's dispense float updated",
        });

        if (!to) {
          this.setState({
            dispenseFloatSelected: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onCompletedGettingFloats = (current, df) => {
    this.setState({
      loading: false,
      dispenseFloatSelected: current,
      dispenseFloats: df,
      enabled: current ? true : false,
    });
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value });
  };

  onToggleChanged = (e) => {
    let checked = e.target.checked;

    if (!checked) {
      this.update(false);
    }

    this.setState({ enabled: e.target.checked });
  };

  render() {
    const {
      loading,
      dispenseFloats,
      dispenseFloatSelected,
      error,
      enabled,
    } = this.state;

    return (
      <Form loading={loading}>
        <Header title="Dispense float" level="3">
          <Switch
            name="enabled"
            isOn={enabled}
            handleToggle={this.onToggleChanged}
          />
        </Header>
        {enabled && (
          <>
            <Select
              title="Dispense float"
              value={dispenseFloatSelected}
              name="dispenseFloatSelected"
              placeholder="Select a dispense float"
              options={dispenseFloats}
              onChange={this.handleSelect}
              description="Limits the amount of cash that can be dispensed"
              error={error}
            />
            <Header level="3">
              <button className="main slim" onClick={this.update}>
                Update
              </button>
            </Header>
          </>
        )}
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(DispenseFloat)
);
