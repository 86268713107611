import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import AWS from "aws-sdk";

import { Header, Input, Form } from "../../../common";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originalName: "",
      originalImage: undefined,
      companyId: this.props.userAuth.companyId,
      branchId: undefined,
      id: "",
      sku: "",
      itemCode: "",
      name: "",
      displayName: "",
      price: "",
      imageName: undefined,
      image: undefined,
      saving: false,
    };
  }

  componentDidMount() {
    let { id } = this.props.match.params;

    // * Get item's information
    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { id },
        query: gql`
          query getItem($id: String!) {
            item(ID: $id) {
              id
              sku
              itemCode
              name
              displayName
              price
              image
              branchId
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let { item } = result.data;

          this.setState({
            ...item,
            originalName: item.displayName,
            originalImage: item.image,
          });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }

  handleFile = (e) => {
    var files = e.target.files;

    if (files.length > 1) {
      // Too many
      alert("You can only select 1 file");
    } else if (files.length === 1) {
      this.setState({ itemImage: files[0] });
    } else {
      this.setState({ itemImage: null });
    }
  };

  handleInputs = (textbox) => {
    this.setState({ [textbox.name]: textbox.value }, this.onChange);
  };

  onSave = () => {
    const { name, displayName, sku, itemCode, price, itemImage } = this.state;

    if (!name || !displayName || !sku || !itemCode || !price) {
      alert("Please fill in all areas");
      console.error("State for checking missing data", this.state);
      return;
    }

    this.setState({ saving: true }, () => {
      // * Save new image if have uploaded a new one
      if (itemImage) {
        this.saveImage()
          .then((result) => {
            this.internalUpdateItem(result);
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        this.internalUpdateItem();
      }
    });
  };

  internalUpdateItem = (newImage) => {
    let { id } = this.props.match.params;

    const {
      name,
      displayName,
      sku,
      itemCode,
      price,
      originalImage,
    } = this.state;

    let variables = {
      item: {
        id,
        name,
        displayName,
        sku,
        itemCode,
        price: +price,
        image: newImage || originalImage,
      },
    };

    this.props.client
      .mutate({
        context: {
          route: "selfOrdering",
        },
        variables,
        mutation: gql`
          mutation updateItem($item: UpdateItemInput!) {
            updateItem(item: $item)
          }
        `,
      })
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          for (const error of result.errors) {
            alert(`Error: ${error.message}`);
          }

          return;
        }

        if (result.data) {
          alert("Success!");
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Unable to edit item");
      });
  };

  saveImage = () => {
    return new Promise((resolve, reject) => {
      const { branchId, itemImage } = this.state;

      // Configure client for use with Spaces
      var spacesEndpoint = new AWS.Endpoint(
        process.env.REACT_APP_SPACES_ENDPOINT
      );

      var s3 = new AWS.S3({
        endpoint: spacesEndpoint,
        accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
      });

      var params = {
        Body: this.state.itemImage,
        Bucket: process.env.REACT_APP_SPACES_BUCKET,
        Key: `items/${branchId}/${itemImage.name}`,
        ACL: "public-read",
        ContentType: itemImage.type,
      };

      s3.upload(params, (err, data) => {
        if (!err) {
          resolve(data.Location.toString());
        } else {
          reject(err);
        }
      });
    });
  };

  render() {
    const {
      originalName,
      originalImage,
      sku,
      itemCode,
      name,
      displayName,
      price,
      saving,
    } = this.state;

    return (
      <div>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title={`Editing ${originalName}`}>
          {!saving && (
            <button className="slim" onClick={this.onSave}>
              Save
            </button>
          )}
        </Header>
        <Form>
          <Header title="General information" level="4" />
          <Input
            title="Name"
            name="name"
            placeholder="Enter name of item."
            onChange={this.handleInputs}
            value={name}
          />
          <Input
            title="Display name"
            name="displayName"
            placeholder="Enter display name of item."
            onChange={this.handleInputs}
            value={displayName}
          />
          <Input
            title="SKU"
            name="sku"
            placeholder="Enter sku of item."
            onChange={this.handleInputs}
            value={sku}
          />
          <Input
            title="Item code"
            name="itemCode"
            placeholder="Enter itemCode of item."
            onChange={this.handleInputs}
            value={itemCode}
          />
          <Input
            title="Price"
            name="price"
            placeholder="Enter price of item."
            onChange={this.handleInputs}
            value={price}
          />
          <label>Original image</label>
          <img
            src={originalImage}
            alt="itemImg"
            height="128"
            style={{
              "align-self": "flex-start",
              "object-fit": "contain",
            }}
          />
          <label>Image</label>
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={this.handleFile}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(withApollo(connect(mapStateToProps)(Edit)));
