var defaultVal = [];

const apolloReducer = (state = defaultVal, action) => {
  switch (action.type) {
    case "ADD_ALERT":
      let newState = [];
      let { payload } = action;
      Object.assign(newState, state);

      // Add id to the new alert payload
      payload.id =
        newState.length > 0 ? newState[newState.length - 1].id + 1 : 1;

      newState.push(action.payload);
      return newState;
    case "REMOVE_ALERT":
      let alertId = +action.payload;
      let newState2 = [];

      Object.assign(newState2, state);

      return newState2.filter((a) => a.id !== alertId);
    default:
      // Remove all previous alerts if refreshed
      return defaultVal;
  }
};

export default apolloReducer;
