import React from "react";
import APITable from "../../APITable";

const startCashOut = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let req = [
    {
      name: "Denominations",
      desc: "Specify which denominations to dispense from the machine",
      type: "DenominationType[]",
    },
  ];

  let resp = [
    {
      name: "Dispensed",
      desc: "Denominations that were dispensed out of the machine",
      type: "DenominationType[]",
    },
  ];

  return (
    <APITable
      title="Start Cash Out"
      desc="Manually dispense cash out from the machine"
      command="StartCashOut"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      req={req}
      resp={resp}
    />
  );
};

export default startCashOut;
