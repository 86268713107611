import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Form, Input, Select, Header } from "../common";
import AWS from "aws-sdk";
import { addAlert } from "../../actions";

class BranchesCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      name: "",
      currency: undefined,
      logoFile: null,
      idError: "",
      nameError: "",
      currencyError: "",
      creating: false,
    };
  }

  handleInputs = (event) => {
    this.setState({ [event.name]: event.value });
  };

  handleSelect = (e) => {
    this.setState({ [e.name]: e.value }, this.onChange);
  };

  handleFile = (e) => {
    var files = e.target.files;
    if (files.length > 1) {
      // Too many
      alert("You can only select 1 file");
    } else if (files.length === 1) {
      // Ok
      this.setState({ logoFile: files[0] });
    } else {
      this.setState({ logoFile: null });
    }
  };

  validate = () => {
    const { id, name, currency } = this.state;

    if (!id || !name || !currency) {
      let idError = !id ? "Cannot not be blank" : "";
      let nameError = !name ? "Cannot not be blank" : "";
      let currencyError = !currency ? "Select a currency" : "";

      this.setState({ idError, nameError, currencyError });

      return false;
    }

    this.setState({ idError: "", nameError: "", currencyError: "" });

    return true;
  };

  create = async () => {
    let { id, name, currency, logoFile: image } = this.state;
    const {
      userAuth: { companyId },
    } = this.props;

    let isValid = this.validate();

    if (!isValid) {
      return;
    }

    // Change id to be all uppercased
    id = id.toUpperCase();

    this.props.addAlert({
      type: "info",
      title: "Adding branch",
      msg: "Hang tight while we're adding the branch",
    });

    var addImageSucess = true;

    if (image) {
      // Upload images to spaces
      var s3 = new AWS.S3({
        endpoint: new AWS.Endpoint(process.env.REACT_APP_SPACES_ENDPOINT),
        accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
      });

      // Get new image name before uploading
      var imageName = image.name;
      var extIndex = imageName.lastIndexOf(".");
      imageName = `${id}${imageName.slice(extIndex, imageName.length)}`;

      var params = {
        Body: image,
        Bucket: process.env.REACT_APP_SPACES_BUCKET,
        Key: `logos/${companyId}/${imageName}`,
        ACL: "public-read",
        ContentType: image.type,
      };

      try {
        var imageData = await s3.upload(params).promise();
      } catch (error) {
        this.props.addAlert({
          type: "error",
          title: "Creation failed",
          msg: error.message,
        });

        addImageSucess = false;
      }
    }

    if (!addImageSucess) {
      return;
    }

    this.setState({ creating: true }, () => {
      this.props.client
        .mutate({
          variables: {
            branchDetail: {
              code: id,
              name: name,
              currency: currency,
              logo: imageData ? imageData.Location.toString() : null,
              logoDataType: imageData ? "url" : null,
            },
            userId: this.props.userAuth.userId,
          },
          mutation: gql`
            mutation createBranch(
              $branchDetail: BranchInput!
              $userId: String!
            ) {
              createBranch(branchDetail: $branchDetail, userId: $userId) {
                id
              }
            }
          `,
        })
        .then((result) => {
          if (result.data) {
            this.props.addAlert({
              type: "success",
              title: "All set",
              msg: "Your branch has been created",
            });
            this.props.history.goBack();
          }
        })
        .catch((error) => {
          this.props.addAlert({
            type: "error",
            title: "Creation failed",
            msg: error.message,
          });
          this.setState({ creating: false });
        });
    });
  };

  render() {
    const {
      id,
      name,
      currency,
      idError,
      nameError,
      currencyError,
      creating,
    } = this.state;

    let currencies = [
      {
        name: "Singapore Dollar (SGD)",
        value: "THB",
      },
      {
        name: "Thai Baht (THB)",
        value: "SGD",
      },
    ];

    return (
      <div>
        <button className="return" onClick={() => this.props.history.goBack()}>
          Back
        </button>
        <Header title="Add new branch">
          <button
            className="main slim"
            disabled={creating}
            onClick={this.create}
          >
            Add
          </button>
        </Header>
        <Form loading={false}>
          <h4>Branch information</h4>
          <Input
            title="Code"
            name="id"
            placeholder="Enter a code"
            description="Will be recorded in all capital letters"
            error={idError}
            onChange={this.handleInputs}
            value={id}
          />
          <Input
            title="Name"
            name="name"
            placeholder="Enter role name"
            error={nameError}
            onChange={this.handleInputs}
            value={name}
          />
          <Select
            title="Currency"
            value={currency}
            name="currency"
            placeholder="Select primary currency"
            error={currencyError}
            options={currencies}
            onChange={this.handleSelect}
          />
          <label>Logo</label>
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={this.handleFile}
          />
          <span className="description">Leave blank if don't have</span>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAlert: (payload) => dispatch(addAlert(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.userAuth,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(BranchesCreate))
);
