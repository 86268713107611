import React from "react";
import APITable from "../../APITable";

const reset = () => {
  let includeDefault = true;
  let haveMachineId = true;

  let resp = [
    {
      name: "Status",
      desc: "Status of the machine after resetting",
      type: " ",
      el: [
        {
          name: "Controller",
          desc: "Controller's status",
        },
        {
          name: "Note",
          desc: "Note’s status",
        },
        {
          name: "Coin",
          desc: "Coin’s status",
        },
      ],
    },
  ];

  return (
    <APITable
      title="Reset"
      desc="Attempts to reset the machine"
      command="Reset"
      includeDefault={includeDefault}
      haveMachineId={haveMachineId}
      resp={resp}
    />
  );
};

export default reset;
