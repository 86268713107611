import { gql } from "apollo-boost";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Header } from "../../../../common";
import FixedItems from "./FixedItems";

class FixedPromotion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: undefined,
      itemSelected: props.defaultValue || [{ id: undefined, value: 0.0 }], // * { id: "", value: 0.00 }
    };
  }

  componentDidMount() {
    this.getBranchesItems(this.props.branchId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.branchId === this.props.branchId) {
      return;
    }

    this.getBranchesItems(this.props.branchId);
  }

  getBranchesItems = (branchId) => {
    if (!branchId) {
      return;
    }

    this.props.client
      .query({
        context: {
          route: "selfOrdering",
        },
        variables: { branchId },
        query: gql`
          query getItems($branchId: String!) {
            items(branchId: $branchId) {
              id
              name
              price
            }
          }
        `,
      })
      .then((result) => {
        if (result.data) {
          let items = result.data.items.map((i) => {
            return {
              name: i.name,
              value: i.id,
            };
          });

          this.setState({ items });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  onSelected = (index, e) => {
    console.log("Selected onchange", e);

    let { itemSelected } = this.state;

    // * Find duplicate in array first
    let dupIndex = itemSelected.findIndex((s) => s.id === e.id);

    if (dupIndex >= 0 && dupIndex !== index) {
      // * Duplicate
      alert("Duplicate item!");
      return;
    }

    itemSelected[index] = e;

    this.setState({ itemSelected }, () => {
      if (this.props.onChanged) this.props.onChanged(itemSelected);
    });
  };

  addItem = (e) => {
    let { itemSelected } = this.state;

    itemSelected.push({ id: undefined, price: undefined });

    this.setState({ itemSelected });
  };

  onRemovedItem = (index) => {
    let { itemSelected } = this.state;

    itemSelected.splice(index, 1);

    this.setState({ itemSelected });
  };

  render() {
    let { items, itemSelected } = this.state;

    return (
      <>
        <Header title="Discount details" level="4" subtitle="Fixed" />
        <h4>Items to be price fixed</h4>
        <div>
          <table id="policies__table">
            <thead>
              <tr>
                <th>Items</th>
                <th>Fixed price</th>
              </tr>
            </thead>
            <tbody>
              {itemSelected.map((is, index) => (
                <FixedItems
                  key={index}
                  items={items}
                  value={is}
                  onChange={(e) => this.onSelected(index, e)}
                  onRemoved={(e) => this.onRemovedItem(index)}
                  canRemove={itemSelected.length > 1}
                />
              ))}
            </tbody>
          </table>
          <button className="main slim" onClick={this.addItem}>
            Add another item
          </button>
        </div>
      </>
    );
  }
}

export default withApollo(FixedPromotion);
